const ModalBase = (props) => {
    return (
        <div className="absolute flex items-center top-0 left-0 justify-center w-full h-full" style={{ backgroundColor: "rgba(0,0,0,0.7)" }}>
            {props.children}
        </div>
    )

}

export function setModalBase(Component) {
    return (
        <ModalBase>
            <Component/>
        </ModalBase>
    )
}