import React, { useEffect, useState, useContext, useDebugValue } from "react";
import { useForm } from "react-hook-form";
import { getList, post } from "../../../services";
import {
  StyleInputFormError,
  StyleInput2,
  StyleSelect,
  StyleInputError,
  StyleInput,
} from "../../../features/Constantes";
import ModalFirma from "./ModalFirma";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Oval } from "react-loader-spinner";
import NumberFormat from "react-number-format";
import { MailsRegExp } from "../../../services/Utilitario";
import moment from "moment";
import { actualizarEmpresa } from "../../../features/NavBar/navBar.slice";
import { ToastContainer, toast } from "react-toastify";
import { PuntoVentaContext } from "../InfoEmpresa/PuntoVentaProvider";
import { Switch, Transition } from "@headlessui/react";
import { NavLink, useNavigate } from "react-router-dom";

const InfoEmpresa = (props) => {
  const handlesShow = () => {
    setPass(!Pass);
  };
  const [isEdited, setIsEdited] = useState(false);
  const [EmpresaInfo, setEmpresaInfo] = useState({});
  const [IsLoading, setIsLoading] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [enabledConta, setEnabledConta] = useState(false);
  const [Pass, setPass] = useState(true);

  const { puntoDeVenta, setPuntoDeVenta } = useContext(PuntoVentaContext);
  const dispatch = useDispatch();

  const permisos = useSelector((store) => store.permisosEmpresa.permisos);

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    watch,
  } = useForm();

  const RucObs = watch("RUC");
  const numeroCelularObs = watch("numeroCelular");

  const [TextUploadFirma, setTextUploadFirma] = useState(
    "SOLO ARCHIVOS *.p12, *.pfx"
  );
  const [LoadingImage, setLoadingImage] = useState(true);
  const [InformacionFirma, setInformacionFirma] = useState(null);
  const [checkAgenteRetencion, setCheckAgenteRetencion] = useState(false);
  const [Preview, setPreview] = useState(
    process.env.PUBLIC_URL + "/img/default-logo.png"
  );

  useEffect(() => {
    if (props.idEmpresa) {
      setIsEdited(true);
      (async () => {
        let getInfo = await getList(`api/firmas/${props.idEmpresa}`);
        setInformacionFirma(getInfo === "" ? null : getInfo);
        try {
          let infoEmpresa = await axios.get(
            `api/empresas/edit/${props.idEmpresa}`
          );
          setEmpresaInfo(infoEmpresa.data._embedded);

          let getImage = await axios.get(
            `api/empresas/image/${props.idEmpresa}`
          );

          setPreview("data:image/png;base64," + getImage.data);
          setLoadingImage(false);
        } catch (ex) {
          // console.log(ex);
          setLoadingImage(false);
        }
      })();
    } else {
      setLoadingImage(false);
    }
  }, []);

  useEffect(() => {
    if (props.idEmpresa) {
      setValue(
        "TipoEmpresa",
        EmpresaInfo.personaNatural ? "NATURAL" : "JURIDICA"
      );
      setValue("numeroCelular", EmpresaInfo.numeroCelular);
      setValue("RUC", EmpresaInfo.ruc);
      setValue("razonSocial", EmpresaInfo.razonSocial);
      setValue("nombreComercial", EmpresaInfo.nombreComercial);
      setValue("direccionMatriz", EmpresaInfo.direccionMatriz);
      setValue("direccionSucursal", EmpresaInfo.direccionSucursal);
      setValue("correo", EmpresaInfo.correo);
      setValue("regimenMicroempresa", EmpresaInfo.regimenMicroempresa);
      setValue("obligadoContabilidad", EmpresaInfo.obligadoContabilidad);
      setValue("agenteRetencion", EmpresaInfo.agenteRetencion);
      setValue("ContribuyenteRimpe", EmpresaInfo.contribuyenteRimpe);
      setValue("TipoRegimen", EmpresaInfo.contribuyenteRimpe);
      setValue("contribuyenteEspecial", EmpresaInfo.contribuyenteEspecial);
      setValue("puntoventa", EmpresaInfo.puntoventa);
      setValue("rucContador", EmpresaInfo.identificacionContador);
      setValue("razonContador", EmpresaInfo.razonSocialContador);
      setValue("correoContador", EmpresaInfo.correoContador);
      setValue("celularContador", EmpresaInfo.numeroCelularContador);

      setEnabled(EmpresaInfo.puntoventa);
      setEnabledConta(EmpresaInfo.obligadoContabilidad);

      // eslint-disable-next-line default-case
      switch (EmpresaInfo.agenteRetencion) {
        case "1":
          setCheckAgenteRetencion(true);
          break;

        case null:
          setCheckAgenteRetencion(false);
          break;
      }
    }
  }, [EmpresaInfo, props.idEmpresa, setPuntoDeVenta, setValue]);

  const handleChangeImage = (e) => {
    setLoadingImage(true);
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = (el) => {
      setPreview(reader.result);
      setLoadingImage(false);
    };
  };
  const permisosInEmpresa = useSelector(
    (store) => store.permisosEmpresa.permisos
  );
  const handle_checkAgenteRetencion = (e) => {
    setCheckAgenteRetencion(!checkAgenteRetencion);
    setValue("agenteRetencion", checkAgenteRetencion ? null : "1");
  };

  const handleSwitchObligado = () => {
    setEnabledConta(!enabledConta);
    setValue("obligadoContabilidad", enabledConta ? false : true);
  };

  const handleSwitchPuntoVenta = () => {
    setEnabled(!enabled);
    setValue("puntoventa", enabled ? false : true);
  };

  const handleSubmitEmpresa = async () => {
    try {
      if (!props.idEmpresa) {
        let empresaValues = {
          IdEmpresa: "00000000-0000-0000-0000-000000000000",
          Ruc: getValues().RUC,
          RazonSocial: getValues().razonSocial,
          PersonaNatural: getValues().TipoEmpresa === "NATURAL" ? true : false,
          NombreComercial: getValues().nombreComercial,
          DireccionMatriz: getValues().direccionMatriz,
          Correo: getValues().correo,
          ContribuyenteEspecial: getValues().contribuyenteEspecial,
          ContribuyenteRimpe: getValues().ContribuyenteRimpe,
          ObligadoContabilidad: getValues().obligadoContabilidad,
          AgenteRetencion: getValues().agenteRetencion,
          NumeroCelular: getValues().numeroCelular.replaceAll("-", ""),
          CorreoPersonalizado: false,
          Activo: true,
          Puntoventa: getValues().puntoventa,
          Contador: null,
          
        };
       

        let ContadorValues = {
          Identificacion: getValues().rucContador,
          RazonSocial: getValues().razonContador,
          Correo: getValues().correoContador,
          TelefonoCelular: getValues().celularContador,
        };

        let formData = new FormData();

        let e = document.getElementById("dropzone-fileImgEmpresa");
        formData.append("EmpresaReq", JSON.stringify(empresaValues));
        formData.append("Logo", e.files[0]);
        let isContadorValid = Object.values(ContadorValues).some(value => value && value.trim() !== "");
        if(isContadorValid){
        formData.append("ContadorReq", JSON.stringify(ContadorValues));
        }
        //let saveFirma = async () => await axios.post(`api/Empresas/`, formData);
        if(!empresaValues.Ruc){
          toast.error("El campo RUC es requerido");
          return;
        }
        if(empresaValues.Ruc.length !== 13){
          toast.error("El campo RUC debe tener 13 caracteres");
          return;
        }
        const saveFirma = await axios.post(`api/Empresas`, formData);
        if (!saveFirma) {
          if (getValues().firmaElectronicaPass !== "")
            await UploadedFirma(saveFirma.data.idEmpresa);
        }
        const toastPost = () =>
          new Promise((resolve) => setInterval(() => resolve(saveFirma), 2000));

        await toast.promise(toastPost(), {
          pending: {
            render() {
              setIsLoading(true);
              return "Guardando empresa...";
            },
            isLoading: true,
          },
          success: {
            render() {
              setIsLoading(false);
              return "Empresa guardada con exito.";
            },
            autoClose: 2000,
            closeOnClick: true,
          },
          error: {
            render() {
              setIsLoading(false);
              return "Algo ha pasado, intente nuevamente.";
            },
            autoClose: 2000,
            closeOnClick: true,
          },
        });

        props.ListAdminsForNewEmpresa.forEach(async (el) => {
          const postRes = await axios.post(
            `api/usuarioempresa/admin/${saveFirma.data.idEmpresa}`,
            { IdUser: el.idUsuario }
          );
          const toastPost = () =>
            new Promise((resolve) => setInterval(() => resolve(postRes), 2000));

          await toast.promise(toastPost(), {
            pending: {
              render() {
                setIsLoading(true);
                return "Guardando empresa...";
              },
              isLoading: true,
            },
            success: {
              render() {
                setIsLoading(false);
                return "Empresa guardada con exito.";
              },
              autoClose: 2000,
              closeOnClick: true,
            },
            error: {
              render() {
                setIsLoading(false);
                return "Algo ha pasado, intente nuevamente.";
              },
              autoClose: 2000,
              closeOnClick: true,
            },
          });
        });

        dispatch(actualizarEmpresa(empresaValues));
      } else {
        let empresaValues = {
          IdEmpresa: EmpresaInfo.idEmpresa,
          Ruc: getValues().RUC,
          RazonSocial: getValues().razonSocial,
          PersonaNatural: getValues().TipoEmpresa === "NATURAL" ? true : false,
          NombreComercial: getValues().nombreComercial,
          DireccionMatriz: getValues().direccionMatriz,
          Correo: getValues().correo,
          ContribuyenteEspecial: getValues().contribuyenteEspecial,
          ContribuyenteRimpe: getValues().ContribuyenteRimpe,
          ObligadoContabilidad: getValues().obligadoContabilidad,
          AgenteRetencion: getValues().agenteRetencion,
          NumeroCelular: getValues().numeroCelular.replace("-", ""),
          CorreoPersonalizado: false,
          Activo: true,
          Puntoventa: getValues().puntoventa,
          Contador: null
        };

        let ContadorValues = {
          idContador: EmpresaInfo.idContador,
          Identificacion: getValues().rucContador,
          RazonSocial: getValues().razonContador,
          Correo: getValues().correoContador,
          TelefonoCelular: getValues().celularContador,
        };
        let formData = new FormData();

        let e = document.getElementById("dropzone-fileImgEmpresa");
        formData.append("EmpresaReq", JSON.stringify(empresaValues));
        formData.append("Logo", e.files[0]);
        let isContadorValid = Object.values(ContadorValues).some(value => value && value.trim() !== "");
        if(isContadorValid){
        formData.append("ContadorReq", JSON.stringify(ContadorValues));
        }


        const saveFirma = await axios.put(
          `api/Empresas/${EmpresaInfo.idEmpresa}`,
          formData
        );
        const toastPost = () =>
          new Promise((resolve) => setInterval(() => resolve(saveFirma), 2000));

        await toast.promise(toastPost(), {
          pending: {
            render() {
              setIsLoading(true);
              return "Actualizando datos...";
            },
            isLoading: true,
          },
          success: {
            render() {
              setIsLoading(false);
              return "Los datos se actualizaron con exito.";
            },
            autoClose: 2000,
            closeOnClick: true,
          },
          error: {
            render() {
              setIsLoading(false);
              return "Algo ha pasado, intente nuevamente.";
            },
            autoClose: 2000,
            closeOnClick: true,
          },
        });

        if (getValues().firmaElectronicaPass !== "")
          await UploadedFirma(saveFirma.data.idEmpresa);
        //dispatch(actualizarEmpresa(empresaValues))
      }
    } catch (ex) {
      //  console.log(ex);
    }
  };
  const UploadedFirma = async (id) => {
    let formData = new FormData();
    let e = document.getElementById("dropzone-fileFirma");

    if (e.files.length > 0) {
      formData.append("firma", e.files[0]);
      formData.append("clave", getValues().firmaElectronicaPass);

      const resPost = await post(`api/Firmas/GuardarFirma/${id}`, formData);
      const toastPost = () =>
        new Promise((resolve) => setInterval(() => resolve(resPost), 2000));
      toast.promise(toastPost(), {
        pending: {
          render() {
            setIsLoading(true);
            return "Cargando firma...";
          },
          isLoading: true,
        },
        success: {
          render() {
            setIsLoading(false);
            return "Firma cargada.";
          },
          autoClose: 2000,
          closeOnClick: true,
        },
        error: {
          render() {
            setIsLoading(false);
            return "Algo ha sucedido, intente nuevamente.";
          },
          autoClose: 2000,
          closeOnClick: true,
        },
      });
    } else {
      //  console.log("No se ha seleccionado un archivo de firma.");
    }
  };

  // const UploadedFirma = async (id) => {
  //   let formData = new FormData();
  //   let e = document.getElementById("dropzone-fileFirma");
  //   formData.append("firma", e.files[0]);
  //   formData.append("clave", getValues().firmaElectronicaPass);

  //   await post(`api/Firmas/GuardarFirma/${id}`, formData);
  // };

  const handleClickInfoFirma = async () => {
    try {
      let formData = new FormData();
      let e = document.getElementById("dropzone-fileFirma");
      formData.append("firma", e.files[0]);
      formData.append("clave", getValues().firmaElectronicaPass);
      let infoReq = await post(`api/Firmas/GetDataFirma`, formData);
      setInformacionFirma(infoReq.data);
    } catch {
      setInformacionFirma(404);
    }
  };

  const handleChangeFirma = async (ex) => {
    setTextUploadFirma(ex.target.value.split(`\\`)[2]);
    let reader = new FileReader();
    reader.readAsDataURL(ex.target.files[0]);
    setShowButton(true);
  };


  const [showButton, setShowButton] = useState(false);
  const [showButton2, setShowButton2] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const paswordWatch = watch("firmaElectronicaPass");

  useEffect(() => {
    if (paswordWatch !== "") {
      setShowButton2(true);
    } else {
      setShowButton2(false);
    }

    if (showButton && showButton2) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [paswordWatch, showButton, showButton2]);









  const [isOpen, setIsOpen] = useState(true);
  const [isOpen2, setIsOpen2] = useState(true);
  const [isOpen3, setIsOpen3] = useState(true);

  const navigate = useNavigate();


  return (
    <>
      <div>
        <div className="bg-white dark:bg-gray-700 p-4 rounded-md">
          <div className="bg-[#DBDBDB] dark:bg-gray-700 flex  justify-between items-center p-4 rounded-md">
            <div>
              <h1 className="text-2xl font-bold  dark:text-gray-300">
                {isEdited ? "Editar información de empresa" : "Añadir empresa"}
              </h1>
              <span className="dark:text-gray-300">Esta información se mostrará públicamente</span>{" "}
            </div>
            <div className="   space-x-3 inline-block">




            <button
            type="button"
                className="bg-[#AAAAAA] group hover:bg-[#04BC53]  relative p-2 rounded-lg"
                disabled={IsLoading}
                onClick={() => navigate("/emision/reporte/lista-empresas")}
              >
       
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-10 h-10 fill-current text-white dark:text-gray-300"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
       
                <div className="absolute mx-auto w-24  -top-8 -left-7 hidden dark:bg-white dark:text-black    rounded-md bg-gray-800 group-hover:flex text-center p-1">
                  <p className="text-white dark:text-black  mx-auto text-sm">
                    Cancelar
                  </p>
                </div>
              </button>






              <button
                className="bg-[#AAAAAA] group relative hover:bg-[#04BC53] p-2 rounded-lg"
                disabled={IsLoading}
                onClick={IsLoading ? null : handleSubmitEmpresa}
              >
                {IsLoading ? (
                  <Oval
                    height={18}
                    width={60}
                    color="#FFFFFF"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#233876"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-10 h-10 fill-current text-white dark:text-gray-300"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2" />
                    <path d="M12 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                    <path d="M14 4l0 4l-6 0l0 -4" />
                  </svg>
                )}
                <div className="absolute mx-auto w-24  -top-8 -left-7 hidden dark:bg-white dark:text-black    rounded-md bg-gray-800 group-hover:flex text-center p-1">
                  <p className="text-white dark:text-black  mx-auto text-sm">
                    Guardar
                  </p>
                </div>
              </button>
            </div>
          </div>
        </div>

        <div className="my-4">
          <div className="bg-[#00314D] flex justify-between items-center p-3 rounded-t-lg  text-white">
            <p>Datos Generales</p>
            <svg
              onClick={() => setIsOpen(!isOpen)}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 lg:hidden md:hidden"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m19.5 8.25-7.5 7.5-7.5-7.5"
              />
            </svg>
          </div>
          <Transition
            show={isOpen}
            enter="transition ease-out duration-300" // Aumenta la duración de entrada
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-300" // Aumenta la duración de salida
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {" "}
            <div className="bg-white dark:bg-gray-700 grid px-4 py-4 rounded-b-lg  lg:grid-cols-4 gap-6">
              <div className="relative z-0  w-full group">
                <label
                  htmlFor="company-website"
                  className="block text-sm font-medium text-black dark:text-white"
                >
                  {" "}
                  Tipo empresa{" "}
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <select
                    className={StyleSelect}
                    name="TipoEmpresa"
                    id="TipoEmpresa"
                    {...register("TipoEmpresa")}
                  >
                    <option value="NATURAL">NATURAL</option>
                    <option value="JURIDICA">JURIDICA</option>
                  </select>
                </div>
              </div>
              <div className="relative z-0  w-full group">
                <label
                  htmlFor="company-website"
                  className="block text-sm font-medium text-black dark:text-white"
                >
                  {" "}
                  Ruc{" "}
                </label>
                <div className="mt-1 flex rounded-md shadow-sm ">
                  <span className="inline-flex items-center dark:bg-gray-700 dark:border-gray-600  px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                      className="w-5 h-5 fill-gray-500 dark:fill-white"
                    >
                      <path d="M336 64h-80a64 64 0 0 0-128 0H48a48 48 0 0 0-48 48v352a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V112a48 48 0 0 0-48-48zM192 40a24 24 0 1 1-24 24 24 24 0 0 1 24-24zm144 418a6 6 0 0 1-6 6H54a6 6 0 0 1-6-6V118a6 6 0 0 1 6-6h42v36a12 12 0 0 0 12 12h168a12 12 0 0 0 12-12v-36h42a6 6 0 0 1 6 6zm-99.2-106h-5a103.25 103.25 0 0 1-79.7 0h-5c-37.01 0-67.1 25.79-67.1 57.6v6.4a16 16 0 0 0 16 16h192a16 16 0 0 0 16-16v-6.4c0-31.81-30.09-57.6-67.2-57.6zM192 336a64 64 0 1 0-64-64 64 64 0 0 0 64 64z" />
                    </svg>
                  </span>
                  <input
                    //readOnly
                    type="text"
                    name="RUC"
                    className={StyleInput2}
                    placeholder="xxx-xxx-xxxxxxxxx"
                    {...register("RUC", {
                      required: {
                        value: true,
                        message: "Nombre es requerido",
                      },
                      maxLength: {
                        value: 13,
                        message: "No más de 13 carácteres!",
                      },
                      minLength: {
                        value: 13,
                        message: "Mínimo 13 carácteres",
                      },
                    })}
                    onChange={(e) => {
                      const trimmedValue = e.target.value.trim(); // Eliminar espacios en blanco
                      setValue("RUC", trimmedValue); // Actualizar el valor del campo
                    }}
                    value={RucObs}
                  />
                </div>
              </div>
              <div className="relative z-0  w-full group lg:col-span-2">
                <label
                  htmlFor="company-website"
                  className="block text-sm font-medium text-black dark:text-white"
                >
                  {" "}
                  Razón social{" "}
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <span className="inline-flex items-center dark:bg-gray-700 dark:border-gray-600  px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                      className="w-5 h-5 fill-gray-500 dark:fill-white"
                    >
                      <path d="M630.6 364.9l-90.3-90.2c-12-12-28.3-18.7-45.3-18.7h-79.3c-17.7 0-32 14.3-32 32v79.2c0 17 6.7 33.2 18.7 45.2l90.3 90.2c12.5 12.5 32.8 12.5 45.3 0l92.5-92.5c12.6-12.5 12.6-32.7.1-45.2zm-182.8-21c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24c0 13.2-10.7 24-24 24zM48 463.8v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 7.7 0 15.1 1.3 22.2 3.3v-49c-7.3-1.2-14.6-2.2-22.2-2.2-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 303.9 0 364 0 438.3v25.6c0 26.5 21.5 48 48 48h352c9.7 0 18.7-2.9 26.3-7.9l-40.1-40.1H48zm176-175.9c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.4 80 144c0 79.5 64.5 143.9 144 143.9zM224 48c52.9 0 96 43 96 96 0 52.9-43.1 96-96 96s-96-43.1-96-96c0-53 43.1-96 96-96z" />
                    </svg>
                  </span>
                  <input
                    //readOnly
                    type="text"
                    id="razonSocial"
                    name="razonSocial"
                    className={StyleInput2}
                    placeholder="xxx-xxx-xxxxxxxxx"
                    {...register("razonSocial", {
                      required: {
                        value: true,
                        message: "Nombre es requerido",
                      },
                      maxLength: {
                        value: 300,
                        message: "No más de 300 carácteres!",
                      },
                    })}
                  />
                </div>
              </div>

              <div className="relative z-0  w-full group">
                <label
                  htmlFor="company-website"
                  className="block text-sm font-medium text-gray-700 dark:text-white"
                >
                  {" "}
                  Nombre comercial{" "}
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <span className="inline-flex items-center  dark:bg-gray-700 dark:border-gray-600  px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                      className="w-5 h-5 fill-gray-500 dark:fill-white"
                    >
                      <path d="M244 384h-40c-6.63 0-12 5.37-12 12v40c0 6.63 5.37 12 12 12h40c6.63 0 12-5.37 12-12v-40c0-6.63-5.37-12-12-12zm0-192h-40c-6.63 0-12 5.37-12 12v40c0 6.63 5.37 12 12 12h40c6.63 0 12-5.37 12-12v-40c0-6.63-5.37-12-12-12zm-96 0h-40c-6.63 0-12 5.37-12 12v40c0 6.63 5.37 12 12 12h40c6.63 0 12-5.37 12-12v-40c0-6.63-5.37-12-12-12zm0 192h-40c-6.63 0-12 5.37-12 12v40c0 6.63 5.37 12 12 12h40c6.63 0 12-5.37 12-12v-40c0-6.63-5.37-12-12-12zm0-96h-40c-6.63 0-12 5.37-12 12v40c0 6.63 5.37 12 12 12h40c6.63 0 12-5.37 12-12v-40c0-6.63-5.37-12-12-12zm96 0h-40c-6.63 0-12 5.37-12 12v40c0 6.63 5.37 12 12 12h40c6.63 0 12-5.37 12-12v-40c0-6.63-5.37-12-12-12zm288 96h-40c-6.63 0-12 5.37-12 12v40c0 6.63 5.37 12 12 12h40c6.63 0 12-5.37 12-12v-40c0-6.63-5.37-12-12-12zm0-96h-40c-6.63 0-12 5.37-12 12v40c0 6.63 5.37 12 12 12h40c6.63 0 12-5.37 12-12v-40c0-6.63-5.37-12-12-12zm84-96H512V24c0-13.26-10.74-24-24-24H280c-13.26 0-24 10.74-24 24v72h-32V16c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 16v80h-64V16c0-8.84-7.16-16-16-16H80c-8.84 0-16 7.16-16 16v80H24c-13.26 0-24 10.74-24 24v376c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16V144h256V48h160v192h128v256c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16V216c0-13.26-10.75-24-24-24zM404 96h-40c-6.63 0-12 5.37-12 12v40c0 6.63 5.37 12 12 12h40c6.63 0 12-5.37 12-12v-40c0-6.63-5.37-12-12-12zm0 192h-40c-6.63 0-12 5.37-12 12v40c0 6.63 5.37 12 12 12h40c6.63 0 12-5.37 12-12v-40c0-6.63-5.37-12-12-12zm0-96h-40c-6.63 0-12 5.37-12 12v40c0 6.63 5.37 12 12 12h40c6.63 0 12-5.37 12-12v-40c0-6.63-5.37-12-12-12z" />
                    </svg>
                  </span>
                  <input
                    type="text"
                    id="nombreComercial"
                    name="nombreComercial"
                    className={StyleInput2}
                    //placeholder="xxx-xxx-xxxxxxxxx"
                    {...register("nombreComercial", {
                      maxLength: {
                        value: 300,
                        message: "No más de 300 carácteres!",
                      },
                    })}
                    maxLength="300"
                  />
                </div>
              </div>

              <div className="relative z-0  w-full group">
                <label
                  htmlFor="company-website"
                  className="block text-sm font-medium text-black dark:text-white"
                >
                  {" "}
                  Correo{" "}
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <span className="inline-flex items-center dark:bg-gray-700 dark:border-gray-600  px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      className="w-5 h-5 fill-gray-500 dark:fill-white"
                    >
                      <path d="M494.586 164.516c-4.697-3.883-111.723-89.95-135.251-108.657C337.231 38.191 299.437 0 256 0c-43.205 0-80.636 37.717-103.335 55.859-24.463 19.45-131.07 105.195-135.15 108.549A48.004 48.004 0 0 0 0 201.485V464c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V201.509a48 48 0 0 0-17.414-36.993zM464 458a6 6 0 0 1-6 6H54a6 6 0 0 1-6-6V204.347c0-1.813.816-3.526 2.226-4.665 15.87-12.814 108.793-87.554 132.364-106.293C200.755 78.88 232.398 48 256 48c23.693 0 55.857 31.369 73.41 45.389 23.573 18.741 116.503 93.493 132.366 106.316a5.99 5.99 0 0 1 2.224 4.663V458zm-31.991-187.704c4.249 5.159 3.465 12.795-1.745 16.981-28.975 23.283-59.274 47.597-70.929 56.863C336.636 362.283 299.205 400 256 400c-43.452 0-81.287-38.237-103.335-55.86-11.279-8.967-41.744-33.413-70.927-56.865-5.21-4.187-5.993-11.822-1.745-16.981l15.258-18.528c4.178-5.073 11.657-5.843 16.779-1.726 28.618 23.001 58.566 47.035 70.56 56.571C200.143 320.631 232.307 352 256 352c23.602 0 55.246-30.88 73.41-45.389 11.994-9.535 41.944-33.57 70.563-56.568 5.122-4.116 12.601-3.346 16.778 1.727l15.258 18.526z" />
                    </svg>
                  </span>
                  <input
                    type="text"
                    id="correo"
                    name="correo"
                    className={StyleInput2}
                    {...register("correo", {
                      required: {
                        value: true,
                        message: "Nombre es requerido",
                      },
                      maxLength: {
                        value: 300,
                        message: "No más de 300 carácteres!",
                      },
                      minLength: {
                        value: 4,
                        message: "Mínimo 4 carácteres",
                      },
                      pattern: MailsRegExp,
                    })}
                    maxLength="300"
                  />
                </div>
              </div>

              <div className="relative z-0 mb-6 w-full group">
                <label
                  htmlFor="company-website"
                  className="block text-sm font-medium text-gray-700 dark:text-white"
                >
                  {" "}
                  Celular{" "}
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <span className="inline-flex items-center px-3 rounded-l-md dark:bg-gray-700 dark:border-gray-600 border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 fill-gray-500 dark:fill-white"
                      viewBox="0 0 512 512"
                    >
                      <path d="M484.25 330l-101.59-43.55a45.86 45.86 0 0 0-53.39 13.1l-32.7 40a311.08 311.08 0 0 1-124.19-124.12l40-32.7a45.91 45.91 0 0 0 13.1-53.42L182 27.79a45.63 45.63 0 0 0-52.31-26.61L35.5 22.89A45.59 45.59 0 0 0 0 67.5C0 313.76 199.68 512.1 444.56 512a45.58 45.58 0 0 0 44.59-35.51l21.7-94.22a45.75 45.75 0 0 0-26.6-52.27zm-41.59 134.09C225.08 463.09 49 287 48 69.3l90.69-20.9 42.09 98.22-68.09 55.71c46.39 99 98.19 150.63 197 197l55.69-68.11 98.19 42.11z" />
                    </svg>
                  </span>
                 <input
                type="text"
                id="NumeroCelular"
                name="NumeroCelular"
                className={
                  errors.NumeroCelular ? StyleInputError : StyleInput
                }
                {...register("numeroCelular", {
                  maxLength: {
                    value: 14,
                    message: "No más de 13 carácteres!",
                  },
                  minLength: {
                    value: 4,
                    message: "Mínimo 4 carácteres",
                  },
                })}
                maxLength={14}
                pattern="^\+?[0-9]+$"
                onChange={(e) => {
                  const value = e.target.value;
                  const regex = /^\+?[0-9]*$/;
                  const plusCount = (value.match(/\+/g) || []).length;
                  if (
                    regex.test(value) &&
                    plusCount <= 1 &&
                    (plusCount === 0 || value.startsWith("+"))
                  ) {
                    setValue("telefonocelular", value);
                  } else {
                    let sanitizedValue = value.replace(/[^0-9]/g, "");
                    if (value.startsWith("+")) {
                      sanitizedValue = "+" + sanitizedValue;
                    }
                    setValue("telefonocelular", sanitizedValue);
                  }
                }}
              />
                </div>
              </div>

              <div className="relative z-0 w-full group">
                <label
                  htmlFor="company-website"
                  className="block text-sm font-medium text-black dark:text-white"
                >
                  Tipo regimen
                </label>
                <select
                  className={StyleSelect}
                  name="ContribuyenteRimpe"
                  id="ContribuyenteRimpe"
                  {...register("ContribuyenteRimpe")}
                >
                  <option value="1">Regimen Rimpe Emprededores</option>
                  <option value="2">Regimen Rimpe Negocios Popular</option>
                  <option value="0">Regimen General</option>
                </select>
              </div>

              <div className="relative z-0 lg:col-span-3 w-full group">
                <label
                  htmlFor="company-website"
                  className="block text-sm font-medium text-black dark:text-white"
                >
                  {" "}
                  Dirección matriz{" "}
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <span className="inline-flex items-center dark:bg-gray-700 dark:border-gray-600  px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                      className="w-5 h-5 fill-gray-500 dark:fill-white"
                    >
                      <path d="M267.73 192h40.54c7.13 0 12.68-6.17 11.93-13.26l-4.6-43.58a8 8 0 0 0-7.96-7.16h-39.29c-4.09 0-7.53 3.09-7.96 7.16l-4.6 43.58c-.74 7.09 4.82 13.26 11.94 13.26zm-7.37 112h55.29c9.5 0 16.91-8.23 15.91-17.68l-5.07-48c-.86-8.14-7.72-14.32-15.91-14.32h-45.15c-8.19 0-15.05 6.18-15.91 14.32l-5.07 48c-1 9.45 6.41 17.68 15.91 17.68zm13.06-208h29.16c4.75 0 8.45-4.12 7.96-8.84l-1.69-16a8 8 0 0 0-7.96-7.16h-25.78c-4.09 0-7.53 3.09-7.96 7.16l-1.69 16c-.49 4.72 3.21 8.84 7.96 8.84zm48.98 240h-68.8c-8.19 0-15.05 6.18-15.91 14.32l-8.45 80c-1 9.45 6.41 17.68 15.91 17.68h85.69c9.5 0 16.91-8.23 15.91-17.68l-8.45-80c-.85-8.14-7.71-14.32-15.9-14.32zM173.35 64h-16a7.99 7.99 0 0 0-7.38 4.92L1.25 425.85C-3.14 436.38 4.6 448 16.02 448h44c7.11 0 13.37-4.69 15.36-11.52L181.03 74.24c1.49-5.12-2.35-10.24-7.68-10.24zm401.4 361.85L426.04 68.92a8 8 0 0 0-7.38-4.92h-16c-5.33 0-9.17 5.12-7.68 10.24l105.65 362.24A15.996 15.996 0 0 0 515.99 448h44c11.41 0 19.15-11.62 14.76-22.15z" />
                    </svg>
                  </span>
                  <input
                    type="text"
                    id="direccionMatriz"
                    name="direccionMatriz"
                    className={StyleInput2}
                    //placeholder="xxx-xxx-xxxxxxxxx"
                    {...register("direccionMatriz", {
                      required: {
                        value: true,
                        message: "Nombre es requerido",
                      },
                      maxLength: {
                        value: 300,
                        message: "No más de 300 carácteres!",
                      },
                      minLength: {
                        value: 4,
                        message: "Mínimo 4 carácteres",
                      },
                    })}
                    maxLength="300"
                  />
                </div>
              </div>

              <div className="relative z-0 my-auto  w-full group">
                <div className="relative z-0  w-full group">
                  <label
                    htmlFor="company-website"
                    className="block text-sm font-medium text-black dark:text-white"
                  >
                    {" "}
                    Contribuyente especial{" "}
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <span className="inline-flex items-center px-3 dark:bg-gray-700 dark:border-gray-600  rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                        className="w-5 h-5 fill-gray-500 dark:fill-white"
                      >
                        <path d="M160 320c53 0 96-43 96-96s-43-96-96-96-96 43-96 96 43 96 96 96zm0-144c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zm257 81l72-72 24.3 24.3c11.3 11.3 30.7 3.3 30.7-12.7V108c0-6.6-5.4-12-12-12h-88.5c-16 0-24.1 19.4-12.7 30.7L455 151l-55 55-55-55c-9.4-9.4-24.6-9.4-33.9 0l-30.7 30.7C285 195 288 209.1 288 224c0 6.8-1 13.4-2 20l42-42 55 55c9.4 9.3 24.6 9.3 34 0zM592 0H208c-26.5 0-48 22.2-48 49.6V96c6.4 0 11.4.6 15.8 1.6 5.4.7 10.7 1.5 15.9 2.9 4.2 1 8.2 2.3 12.2 3.8 1.3.5 2.8.7 4.1 1.3V48h384v320H352v48h240c26.5 0 48-22.2 48-49.6V49.6C640 22.2 618.5 0 592 0zM226.8 342c-9.9 0-19.9 1.5-29.6 4.4C185.4 350 173 352 160 352s-25.4-2-37.2-5.6c-9.7-2.9-19.7-4.4-29.6-4.4-30.2 0-59.7 13.5-76.9 39.1C6 396.4 0 414.8 0 434.7V472c0 22.1 17.9 40 40 40h240c22.1 0 40-17.9 40-40v-37.3c0-19.8-6-38.2-16.3-53.5-17.3-25.7-46.7-39.2-76.9-39.2zM272 464H48v-29.3c0-9.6 2.8-18.8 8.1-26.7 7.5-11.2 21.4-17.9 37.1-17.9 5.3 0 10.6.8 15.6 2.3 16.8 5.1 34 7.7 51.2 7.7s34.4-2.6 51.2-7.7c5.1-1.5 10.3-2.3 15.6-2.3 15.7 0 29.5 6.7 37.1 17.9 5.3 7.9 8.1 17.1 8.1 26.7z" />
                      </svg>
                    </span>
                    <input
                      type="text"
                      className={
                        errors.contribuyenteEspecial
                          ? StyleInputError
                          : StyleInput2
                      }
                      placeholder=""
                      {...register("contribuyenteEspecial", {
                        maxLength: {
                          value: 5,
                          message:
                            "Contribuyente especial no debe tener más de 5 carácteres!",
                        },
                        minLength: {
                          value: 3,
                          message:
                            "Contribuyente especial debe tener mínimo 3 carácteres",
                        },
                        pattern: {
                          value: /^\d{3,5}$/,
                          message:
                            "Contribuyente especial debe tener solo numeros!",
                        },
                      })}
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <div className="py-4 ">
                  <Switch
                    checked={checkAgenteRetencion}
                    onChange={handle_checkAgenteRetencion}
                    className={`${
                      checkAgenteRetencion ? "bg-blue-600" : "bg-gray-400"
                    } relative inline-flex flex-shrink-0 md:h-6 md:w-11 w-9 h-5  border-2 my-auto border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={`${
                        checkAgenteRetencion ? "translate-x-5" : "translate-x-0"
                      } pointer-events-none inline-block md:h-5 md:w-5 w-4 h-4 transform rounded-full bg-white  shadow-lg ring-0 transition duration-200 ease-in-out`}
                    />
                  </Switch>
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="company-website"
                    className="block text-sm  font-medium text-gray-700 dark:text-white"
                  >
                    Agente retención
                  </label>
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <div className="py-4">
                  <Switch
                    checked={getValues().obligadoContabilidad}
                    onChange={handleSwitchObligado}
                    className={`${
                      enabledConta ? "bg-blue-600" : "bg-gray-400"
                    } relative inline-flex flex-shrink-0 md:h-6 md:w-11 w-9 h-5  border-2 my-auto border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={`${
                        enabledConta ? "translate-x-5" : "translate-x-0"
                      } pointer-events-none inline-block md:h-5 md:w-5 w-4 h-4 transform rounded-full bg-white  shadow-lg ring-0 transition duration-200 ease-in-out`}
                    />
                  </Switch>
                </div>

                <div className="ml-3 text-sm">
                  <label
                    htmlFor="comments"
                    className="font-medium text-black dark:text-white"
                  >
                    Obligado a llevar contabilidad
                  </label>
                  {/* <p className="text-gray-500 text-justify">
                    Las personas naturales obligadas a llevar contabilidad
                    deben emitir y entregar comprobantes de venta
                    autorizados por el Servicio de Rentas Internas (SRI)º
                    en todas sus transacciones e incluir la frase
                    “Obligado a llevar contabilidad” en dichos documentos.
                  </p> */}
                </div>
              </div>
              {permisosInEmpresa.find(
                (el) => el.rolId === "80ff2f96-00f0-4a21-aa3d-01ed687a2970"
              ) && (
                <fieldset>
                  <div className="flex items-center">
                    <div className="py-4">
                      <Switch
                        checked={getValues().puntoventa}
                        onChange={handleSwitchPuntoVenta}
                        className={`${
                          enabled ? "bg-blue-600" : "bg-gray-400"
                        } relative inline-flex flex-shrink-0 md:h-6 md:w-11 w-9 h-5  border-2 my-auto border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                      >
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className={`${
                            enabled ? "translate-x-5" : "translate-x-0"
                          } pointer-events-none inline-block md:h-5 md:w-5 w-4 h-4 transform rounded-full bg-white  shadow-lg ring-0 transition duration-200 ease-in-out`}
                        />
                      </Switch>
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="company-website"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        Impresión punto de venta
                      </label>
                    </div>
                  </div>
                </fieldset>
              )}
            </div>
          </Transition>
        </div>
        <div className="my-4   relative lg:flex md:flex w-full  lg:space-y-0 md:space-y-0  space-y-4    md:space-x-3  lg:space-x-3 ">
          <div className="w-full relative">
          <div className="bg-white h-full rounded-lg">
            <div className="bg-[#00314D] flex justify-between items-center p-3 rounded-t-lg  text-white">
              <p>Subir Logo</p>

              <svg
                onClick={() => setIsOpen2(!isOpen2)}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4 lg:hidden md:hidden"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                />
              </svg>
            </div>

            <Transition
              show={isOpen2}
              enter="transition ease-out duration-300" // Aumenta la duración de entrada
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-300" // Aumenta la duración de salida
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="bg-white dark:bg-gray-700 my-1  relative  p-3 rounded-b-lg">
                <div className="relative z-0 mb-6 w-full group ">
                  <div className="w-full  ">
                    {!LoadingImage ? (
                      <img
                       className="w-96 h-44 mx-auto border-2 border-gray-300 rounded-lg object-cover"
                        src={Preview}
                        alt="your logo"
                      />
                    ) : (
                      <div
                      className="w-96 h-44 mx-auto border-2 flex justify-center items-center border-gray-300 rounded-lg object-cover"
                       
                      >
                        <Oval
                          height={50}
                          width={50}
                          color="#FFFFFF"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                          ariaLabel="oval-loading"
                          secondaryColor="#233876"
                          strokeWidth={2}
                          strokeWidthSecondary={2}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="mx-auto flex justify-center">
                  <input
                    className="form-control
               block
               w-auto
               px-3
               py-1.5
               text-base
               font-normal
               dark:border-gray-600
               dark:bg-gray-700
               dark:text-white
               text-gray-700
               bg-white bg-clip-padding
               border border-solid border-gray-300
               rounded
               transition
               ease-in-out
               m-0
               focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    accept="image/png, image/gif, image/jpeg"
                    id="dropzone-fileImgEmpresa"
                    onChange={handleChangeImage}
                    type="file"
                  />
                </div>
              </div>
            </Transition>
          </div>
        </div>
        <div className="relative w-full  ">
            <div className="bg-[#00314D] flex    lg:justify-start md:justify-start justify-between items-center p-3 rounded-t-lg text-center  text-white">
              <p>Datos / Contador</p>
              <svg
                onClick={() => setIsOpen3(!isOpen3)}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4 lg:hidden md:hidden"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                />
              </svg>
            </div>
            <Transition
              show={isOpen3}
              className={"h-auto"}
              enter="transition ease-out duration-300" // Aumenta la duración de entrada
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-300" // Aumenta la duración de salida
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="bg-white dark:bg-gray-700  h-[16.6rem]   rounded-b-lg  ">
             

                <div className="w-full gap-6 px-5 pt-10   grid grid-cols-2 ">


                <div className="">
                <label
                  htmlFor="company-website"
                  className="block text-sm font-medium text-black dark:text-white"
                >
                  {" "}
                  Ruc{" "}
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <span className="inline-flex items-center dark:bg-gray-700 dark:border-gray-600  px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      className="w-5 h-5 fill-gray-500 dark:fill-white"
                    >
                      <path d="M494.586 164.516c-4.697-3.883-111.723-89.95-135.251-108.657C337.231 38.191 299.437 0 256 0c-43.205 0-80.636 37.717-103.335 55.859-24.463 19.45-131.07 105.195-135.15 108.549A48.004 48.004 0 0 0 0 201.485V464c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V201.509a48 48 0 0 0-17.414-36.993zM464 458a6 6 0 0 1-6 6H54a6 6 0 0 1-6-6V204.347c0-1.813.816-3.526 2.226-4.665 15.87-12.814 108.793-87.554 132.364-106.293C200.755 78.88 232.398 48 256 48c23.693 0 55.857 31.369 73.41 45.389 23.573 18.741 116.503 93.493 132.366 106.316a5.99 5.99 0 0 1 2.224 4.663V458zm-31.991-187.704c4.249 5.159 3.465 12.795-1.745 16.981-28.975 23.283-59.274 47.597-70.929 56.863C336.636 362.283 299.205 400 256 400c-43.452 0-81.287-38.237-103.335-55.86-11.279-8.967-41.744-33.413-70.927-56.865-5.21-4.187-5.993-11.822-1.745-16.981l15.258-18.528c4.178-5.073 11.657-5.843 16.779-1.726 28.618 23.001 58.566 47.035 70.56 56.571C200.143 320.631 232.307 352 256 352c23.602 0 55.246-30.88 73.41-45.389 11.994-9.535 41.944-33.57 70.563-56.568 5.122-4.116 12.601-3.346 16.778 1.727l15.258 18.526z" />
                    </svg>
                  </span>
                  <input
                    type="text"
                    id="rucContador"
                    name="rucContador"
                    className={StyleInput2}
                    {...register("rucContador", {
                      required: {
                        value: true,
                        message: "Nombre es requerido",
                      },
                      maxLength: {
                        value: 300,
                        message: "No más de 300 carácteres!",
                      },
                      minLength: {
                        value: 4,
                        message: "Mínimo 4 carácteres",
                      },
                      pattern: MailsRegExp,
                    })}
                    maxLength="300"
                  />
                </div>
                </div>
              <div className="">
                <label
                  htmlFor="company-website"
                  className="block text-sm font-medium text-black dark:text-white"
                >
                  {" "}
                 Correo
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <span className="inline-flex items-center dark:bg-gray-700 dark:border-gray-600  px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      className="w-5 h-5 fill-gray-500 dark:fill-white"
                    >
                      <path d="M494.586 164.516c-4.697-3.883-111.723-89.95-135.251-108.657C337.231 38.191 299.437 0 256 0c-43.205 0-80.636 37.717-103.335 55.859-24.463 19.45-131.07 105.195-135.15 108.549A48.004 48.004 0 0 0 0 201.485V464c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V201.509a48 48 0 0 0-17.414-36.993zM464 458a6 6 0 0 1-6 6H54a6 6 0 0 1-6-6V204.347c0-1.813.816-3.526 2.226-4.665 15.87-12.814 108.793-87.554 132.364-106.293C200.755 78.88 232.398 48 256 48c23.693 0 55.857 31.369 73.41 45.389 23.573 18.741 116.503 93.493 132.366 106.316a5.99 5.99 0 0 1 2.224 4.663V458zm-31.991-187.704c4.249 5.159 3.465 12.795-1.745 16.981-28.975 23.283-59.274 47.597-70.929 56.863C336.636 362.283 299.205 400 256 400c-43.452 0-81.287-38.237-103.335-55.86-11.279-8.967-41.744-33.413-70.927-56.865-5.21-4.187-5.993-11.822-1.745-16.981l15.258-18.528c4.178-5.073 11.657-5.843 16.779-1.726 28.618 23.001 58.566 47.035 70.56 56.571C200.143 320.631 232.307 352 256 352c23.602 0 55.246-30.88 73.41-45.389 11.994-9.535 41.944-33.57 70.563-56.568 5.122-4.116 12.601-3.346 16.778 1.727l15.258 18.526z" />
                    </svg>
                  </span>
                  <input
                    type="text"
                    id="correoContador"
                    name="correoContador"
                    className={StyleInput2}
                    {...register("correoContador", {
                      required: {
                        value: true,
                        message: "Nombre es requerido",
                      },
                      maxLength: {
                        value: 300,
                        message: "No más de 300 carácteres!",
                      },
                      minLength: {
                        value: 4,
                        message: "Mínimo 4 carácteres",
                      },
                      pattern: MailsRegExp,
                    })}
                    maxLength="300"
                  />
                </div>
              </div>

              <div className="">
                <label
                  htmlFor="company-website"
                  className="block text-sm font-medium text-black dark:text-white"
                >
                  Razón social{" "}
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <span className="inline-flex items-center dark:bg-gray-700 dark:border-gray-600  px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      className="w-5 h-5 fill-gray-500 dark:fill-white"
                    >
                      <path d="M494.586 164.516c-4.697-3.883-111.723-89.95-135.251-108.657C337.231 38.191 299.437 0 256 0c-43.205 0-80.636 37.717-103.335 55.859-24.463 19.45-131.07 105.195-135.15 108.549A48.004 48.004 0 0 0 0 201.485V464c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V201.509a48 48 0 0 0-17.414-36.993zM464 458a6 6 0 0 1-6 6H54a6 6 0 0 1-6-6V204.347c0-1.813.816-3.526 2.226-4.665 15.87-12.814 108.793-87.554 132.364-106.293C200.755 78.88 232.398 48 256 48c23.693 0 55.857 31.369 73.41 45.389 23.573 18.741 116.503 93.493 132.366 106.316a5.99 5.99 0 0 1 2.224 4.663V458zm-31.991-187.704c4.249 5.159 3.465 12.795-1.745 16.981-28.975 23.283-59.274 47.597-70.929 56.863C336.636 362.283 299.205 400 256 400c-43.452 0-81.287-38.237-103.335-55.86-11.279-8.967-41.744-33.413-70.927-56.865-5.21-4.187-5.993-11.822-1.745-16.981l15.258-18.528c4.178-5.073 11.657-5.843 16.779-1.726 28.618 23.001 58.566 47.035 70.56 56.571C200.143 320.631 232.307 352 256 352c23.602 0 55.246-30.88 73.41-45.389 11.994-9.535 41.944-33.57 70.563-56.568 5.122-4.116 12.601-3.346 16.778 1.727l15.258 18.526z" />
                    </svg>
                  </span>
                  <input
                    type="text"
                    id="razonContador"
                    name="razonContador"
                    className={StyleInput2}
                    {...register("razonContador", {
                      required: {
                        value: true,
                        message: "Nombre es requerido",
                      },
                      maxLength: {
                        value: 300,
                        message: "No más de 300 carácteres!",
                      },
                      minLength: {
                        value: 4,
                        message: "Mínimo 4 carácteres",
                      },
                      pattern: MailsRegExp,
                    })}
                    maxLength="300"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="company-website"
                  className="block text-sm font-medium text-black dark:text-white"
                >
                  {" "}
                  Celular
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <span className="inline-flex items-center dark:bg-gray-700 dark:border-gray-600  px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      className="w-5 h-5 fill-gray-500 dark:fill-white"
                    >
                      <path d="M494.586 164.516c-4.697-3.883-111.723-89.95-135.251-108.657C337.231 38.191 299.437 0 256 0c-43.205 0-80.636 37.717-103.335 55.859-24.463 19.45-131.07 105.195-135.15 108.549A48.004 48.004 0 0 0 0 201.485V464c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V201.509a48 48 0 0 0-17.414-36.993zM464 458a6 6 0 0 1-6 6H54a6 6 0 0 1-6-6V204.347c0-1.813.816-3.526 2.226-4.665 15.87-12.814 108.793-87.554 132.364-106.293C200.755 78.88 232.398 48 256 48c23.693 0 55.857 31.369 73.41 45.389 23.573 18.741 116.503 93.493 132.366 106.316a5.99 5.99 0 0 1 2.224 4.663V458zm-31.991-187.704c4.249 5.159 3.465 12.795-1.745 16.981-28.975 23.283-59.274 47.597-70.929 56.863C336.636 362.283 299.205 400 256 400c-43.452 0-81.287-38.237-103.335-55.86-11.279-8.967-41.744-33.413-70.927-56.865-5.21-4.187-5.993-11.822-1.745-16.981l15.258-18.528c4.178-5.073 11.657-5.843 16.779-1.726 28.618 23.001 58.566 47.035 70.56 56.571C200.143 320.631 232.307 352 256 352c23.602 0 55.246-30.88 73.41-45.389 11.994-9.535 41.944-33.57 70.563-56.568 5.122-4.116 12.601-3.346 16.778 1.727l15.258 18.526z" />
                    </svg>
                  </span>
                  <input
                    type="text"
                    id="celularContador"
                    name="celularContador"
                    className={StyleInput2}
                    {...register("celularContador", {
                      required: {
                        value: true,
                        message: "Nombre es requerido",
                      },
                      maxLength: {
                        value: 300,
                        message: "No más de 300 carácteres!",
                      },
                      minLength: {
                        value: 4,
                        message: "Mínimo 4 carácteres",
                      },
                      pattern: MailsRegExp,
                    })}
                    maxLength="300"
                  />
                </div>
              </div>
                </div>

              </div>
            </Transition>
          </div>
        </div>

        <div className=" w-full relative   ">
            <div className="bg-white h-full rounded-lg">

           
            <div className="bg-[#00314D] flex    lg:justify-start md:justify-start justify-between items-center p-3 rounded-t-lg text-center  text-white">
              <p>Firma Electrónica P12</p>
              <svg
                onClick={() => setIsOpen3(!isOpen3)}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4 lg:hidden md:hidden"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                />
              </svg>
            </div>

            <Transition
              show={isOpen3}
              className={"h-auto"}
              enter="transition ease-out duration-300" // Aumenta la duración de entrada
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-300" // Aumenta la duración de salida
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="bg-white dark:bg-gray-700 px-56  my-1 rounded-b-lg  ">
                <div className="relative flex align-middle justify-center flex-col z-0  w-full group ">
                  <div className="flex justify-around w-2/3 mx-auto  items-center flex-col">
                    <label
                      htmlFor="dropzone-fileFirma"
                      className="flex flex-col justify-center items-center h-32 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 w-2/3"
                    >
                      <div className="flex flex-col justify-center items-center w-full pt-5 pb-6">
                        <svg
                          aria-hidden="true"
                          className="mb-3 w-10 h-10 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          ></path>
                        </svg>
                        <p className="mb-2 text-sm text-gray-500 dark:text-white ">
                          <span className="font-semibold text-base dark:text-white">
                            Subir firma
                          </span>
                        </p>
                        <p className="text-gray-500 text-sm text-center dark:text-gray-400">
                          <b>{TextUploadFirma}</b>
                        </p>
                      </div>
                      <input
                        accept=".pfx,.p12"
                        id="dropzone-fileFirma"
                        onChange={handleChangeFirma}
                        type="file"
                        className="hidden"
                      />
                    </label>
                  </div>
                  <div className="relative z-0  lg:flex mx-auto space-x-2 my-3 group">
                    <div className="   ">
                      <label
                        htmlFor="company-website"
                        className="block text-sm font-medium text-gray-700 dark:text-white"
                      >
                        {" "}
                        Contraseña{" "}
                      </label>
                      <div className="relative">
                        <input
                          type={Pass ? "text" : "password"}
                          className={
                            errors.firmaElectronicaPass
                              ? StyleInputFormError
                              : StyleInput + " pr-10"
                          }
                          placeholder=" "
                          id="firmaElectronicaPass"
                          name="firmaElectronicaPass"
                          {...register("firmaElectronicaPass", {})}
                        />
                        <button
                          type="button"
                          onClick={handlesShow}
                          className="absolute  top-0 mr-1 right-0 bottom-0 duration-300 transition-colors"
                        >
                          {Pass ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5  text-gray-600 "
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5  text-gray-600 "
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                              />
                            </svg>
                          )}
                        </button>
                      </div>
                    </div>
                 

                    <Transition
                      show={showModal}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <div className="pt-4 my-auto  ">
                        <button
                          type="button"
                          className="mx-auto "
                          onClick={handleClickInfoFirma}
                          data-bs-toggle={"modal"}
                          data-bs-target={showModal && "#firmaInfoModal"}
                        >
                          <p
                            className={`py-2 px-5 rounded-md ${
                              showModal
                                ? "bg-[#04BC53] hover:bg-green-600"
                                : "bg-gray-300 cursor-not-allowed"
                            }   text-white text-sm font-medium`}
                          >
                            Validar certificado
                          </p>
                        </button>
                      </div>
                    </Transition>


                    {/* <p
                  className="mt-2 text-sm text-gray-500"
                  id="email-description"
                >
                  Digite la contraseña de su firma electrónica
                </p> */}
                  </div>

                  <div div className="text-center my-2 dark:text-white">
                    {InformacionFirma !== null ? (
                      <>
                        <p className="text-sm">
                          <b>Vence el: </b>
                          {moment(InformacionFirma.fechaExpiracion).format(
                            "LLL"
                          )}
                        </p>
                        <p className="text-sm">
                          <b>Fue subida el: </b>
                          {moment(InformacionFirma.fechaModificacion).format(
                            "LLL"
                          )}
                        </p>
                      </>
                    ) : (
                      <p className="text-sm">No hay firma subida!</p>
                    )}{" "}
                  </div>
                </div>
              </div>
            </Transition>
            </div>
          </div>
      </div>
      <ModalFirma InformacionFirma={InformacionFirma} />
    </>
    //</form>
  );
};

export default InfoEmpresa;
