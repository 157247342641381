import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getListV2 } from "../../../services";

import { EmitirGuiaRemisionContext } from "./EmitirGuiaRemisionProvider";

import { roundToTwo } from "../../../services/Utilitario";
import {
  StyleInput,
  StyleInputError,
  StyleLabel,
  StyleLabelError,
  StyleSelect,
  StyleSelectForm,
} from "../../../features/Constantes";
import MesageWarningTable from "../../../features/components/MesageWarningTable";
import { LargeModalForm } from "../../../features/components/LargeModalForm";
import InputNumber from "../../../features/components/common/InputNumber";
import LengthCounter from "../../../features/components/LengthCounter";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const Modals = () => {
  const {
    ItemDetalle,
    setItemDetalle,
    ActivarModalDetalle,
    setActivarModalDetalle,
    setActivacion,
    Activacion,
    DetalleList,
    setDetalleList,
    DestinatariosList,
    setDestinatariosList,
  } = useContext(EmitirGuiaRemisionContext);

  const [IsLoading, setIsLoading] = useState(false);
  const [TempDetalleItem, setTempDetalleItem] = useState({});
  const [ImpuestosList, setImpuestosList] = useState([]);
  const [InformacionAdicionalList, setInformacionAdicionalList] = useState([]);

  const [inputNombre, setInputNombre] = useState("");
  const [inputValor, setInputValor] = useState("");

  const {
    register,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: { descripcion: "", },
  });

  const empresa = useSelector((store) => store.empresa.empresa);



  useEffect(() => {

    setTempDetalleItem({ ...ItemDetalle });
    if (ItemDetalle.informacionAdicionalServicioFactura
    ) {
      let listItems = [];
      ItemDetalle?.informacionAdicionalServicioFactura
        ?.forEach((el) => {
          listItems.push(el);
        });
      setInformacionAdicionalList(listItems);

    }

    (async () => {
      let data = await getListV2(
        empresa,
        "GetAllimpDetalles",
        "?activo=true&idImpuesto=1"
      );
      setImpuestosList(data._embedded);
    })();
    setActivacion(false);
  }, [Activacion]);

  const handleChangeImpuesto = (e) => {
    const index = e.target.selectedOptions[0].getAttribute("data-index");

    const item = {
      ...TempDetalleItem,
      [e.target.name]: e.target.value,
      idImpuestoIvaNavigation: { ...ImpuestosList[index] },
    };
    setTempDetalleItem(item);
  };

  const calcularValorNeto = (item) =>
    roundToTwo(parseFloat(item.precioUnitario) * parseFloat(item.cantidad));

  const calcularValorConDescuento = (item) =>
    roundToTwo(calcularValorNeto(item) - parseFloat(item.descuento));

  const calcularTotal = (item) => {
    if (item.descuento === undefined) return 0;
    if (item.descuento === null) return 0;
    let valorTotal = roundToTwo(
      item.idImpuestoIvaNavigation
        ? calcularValorConDescuento(item) +
        calcularValorConDescuento(item) *
        item.idImpuestoIvaNavigation.porcentaje
        : calcularValorConDescuento(item)
    );
    return valorTotal;
  };




  const handleSubmitModal = () => {
    if (calcularTotal(TempDetalleItem) >= 0) {

      DestinatariosList[TempDetalleItem.index].detalles[
        TempDetalleItem.indexItem
      ] = {
        ...TempDetalleItem,
        informacionAdicionalServicioFactura: InformacionAdicionalList,
      };

      document.getElementById("editDetalleGuiaModalclose").click();
      setDestinatariosList([...DestinatariosList]);
      setItemDetalle({});
      setTempDetalleItem({});
      setActivarModalDetalle(false);
    }
  };

  // const handleSubmitModal = () => {
  //   const total = calcularTotal(TempDetalleItem);

  //   if (total >= 0) {
  //     const updatedDetalle = {
  //       ...TempDetalleItem,
  //       informacionAdicionalServicioFactura: InformacionAdicionalList,
  //     };

  //     const destinatario = DestinatariosList[TempDetalleItem.index];
  //      
  //     if (destinatario) {
  //       const detalle = destinatario.detalles[TempDetalleItem.indexItem];


  //       if (detalle) {
  //         destinatario.detalles[TempDetalleItem.index][TempDetalleItem.indexItem] = updatedDetalle;

  //         document.getElementById("editDetalleGuiaModalclose").click();
  //         setDestinatariosList([...DestinatariosList]);
  //         setItemDetalle({});
  //         setTempDetalleItem({});
  //       } else {
  //         console.error("Detalle no encontrado en DestinatariosList.");
  //       }
  //     } else {
  //       console.error("Destinatario no encontrado en DestinatariosList.");
  //     }
  //   } else {
  //     console.error("El cálculo del total es menor que 0.");
  //   }
  // };



  const handleDeleteElement = (index) => {
    InformacionAdicionalList.splice(index, 1);
    setInformacionAdicionalList([...InformacionAdicionalList]);
  };

  const onBlurHandlerNombre = (event) => {
    setInputNombre(event.target.value);
  };

  const onBlurHandlerValor = (event) => {
    setInputValor(event.target.value);
  };

  const onClickHandlerInfoAdicional = (event) => {
    event.preventDefault();
    setIsLoading(true);
    const newInfoAdicionalItem = { nombre: inputNombre, valor: inputValor };
    if (newInfoAdicionalItem.nombre === "" || newInfoAdicionalItem.valor === "") {
      setTimeout(() => { setIsLoading(false) }, 2000);
      return toast.info('Asegurate de rellenar los campos!', { autoClose: 2000, closeOnClick: true })
    }
     
    if (InformacionAdicionalList.length <= 2) {
      setInformacionAdicionalList([newInfoAdicionalItem, ...InformacionAdicionalList,]);
      setInputNombre("");
      setInputValor("");
      setTimeout(() => { setIsLoading(false) }, 2000);
    }
    else {
      setTimeout(() => { setIsLoading(false) }, 2000);
      return toast.info('No puedes agregar más de 3 detalles adicionales a un producto!', { autoClose: 2000, closeOnClick: true })
    }
  };

  const [Open, SetOpen] = useState(false);

  return (
    <LargeModalForm
      buttonModal={"editDetalleGuiaModal"}
      submitText={"Guardar"}
      title={`EDITAR DETALLE | ${TempDetalleItem.codigoPrincipal}`}
      submitModal={handleSubmitModal}
    >
      <div className="grid grid-cols-5 gap-3 my-2">
        <div className="relative z-0 mb-2 col-span-4 w-full group">
        <label
            htmlFor="descripcion"
         
          >
            DESCRIPCIÓN{" "}
            <LengthCounter
              itemLength={
                watch("descripcion") === undefined
                  ? "hola"
                  : watch("descripcion")
              }
            />
          </label>
          <input
            type="text"
            placeholder=" "
            //id="guiaRemision"
            name="descripcion"
            className={errors["descripcion"] ? StyleInputError : StyleInput}
            {...register("descripcion", {
              required: {
                value: true,
                message: "Descripción es requerido",
              },
              maxLength: {
                value: 300,
                message: "Descripción no debe tener más de 300 carácteres!",
              },
              minLength: {
                value: 1,
                message: "Descripción debe tener mínimo 1 carácter",
              },
            })}
            maxLength="300"

            value={`${TempDetalleItem.descripcion}`}
            onChange={
              (e) =>

                setTempDetalleItem({
                  ...TempDetalleItem,
                  descripcion: e.target.value,
                })

            }

          />
        
        </div>
        <div className="relative z-0 mb-2 w-full group pt-1 ">
         
            <InputNumber
              identifier="cantidad"
              name="CANTIDAD"
              decimalMax={4}
              value={TempDetalleItem.cantidad}
              onChange={(e) =>
                setTempDetalleItem({
                  ...TempDetalleItem,
                  cantidad: e.target.value,
                })
              }
            />
          
        </div>
      </div>


      <div>
          <div>
            <p
              onClick={() => SetOpen(!Open)}
              className="text-lg font-semibold p-2 border-b border-b-gray-400 select-none cursor-pointer dark:text-white flex gap-x-2  items-center   font-body"
            >
              Información adicional (Opcional){" "}
              {!Open ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              )}
            </p>
            {Open ? (
              <div>

             
              <div className="w-full pb-3 grid md:grid-cols-6 md:gap-2 mb-2 md:mb-0 relative my-2">
              <div className="md:col-span-2 relative z-0 mb-2 w-full group">
              <label htmlFor="codigo">
                  NOMBRE
                </label>
                <input
                  type="text"
                  className={StyleInput}
                  placeholder=" "
                  id="nombre"
                  name="nombre"
                  onChange={onBlurHandlerNombre}
                  value={inputNombre}
                />
              
              </div>
    
              <div className="md:col-span-3 relative z-0 mb-2 w-full group">
              <label htmlFor="codigo" >
                  DETALLE
                </label>
                <input
                  type="text"
                  className={StyleInput}
                  placeholder=" "
                  id="valor"
                  name="valor"
                  onChange={onBlurHandlerValor}
                  value={inputValor}
                />
              
              </div>
    
              <div className="relative z-0 mb-2 w-full ">
                <button
                  disabled={IsLoading}
                  onClick={onClickHandlerInfoAdicional}
                  type="button"
                  className=" flex gap-2 rounded-lg absolute bottom-0 p-2 items-center px-2 text-sm font-medium leading-5 focus:outline-none focus:shadow-outline-gray bg-blue-600 hover:bg-blue-500 text-white"
                >
                  {
                    IsLoading ? '...' : (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className=" w-5 h-5 dark:stroke-white"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg> AGREGAR
                      </>
                    )
                  }
    
                </button>
              </div>
            </div>
                     <div className="py-0  flex-wrap justify-between mb-0">
               
                   {InformacionAdicionalList.length > 0 &&
                    <div className="overflow-x-auto  relative overflow-hidden shadow ring-1 ring-black  ring-opacity-5 md:rounded-lg">
                    <table className="w-full text-sm text-center text-gray-500 dark:text-gray-400">
                      <thead className="text-xs text-gray-700 uppercase divide-y bg-gray-100 dark:bg-gray-600 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="py-1 px-6 dark:text-white ">
                            NOMBRE
                          </th>
                          <th scope="col" className="py-1 px-6 dark:text-white  ">
                            DETALLE
                          </th>
                          <th scope="col" className="py-1 px-6"></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 dark:divide-gray-600 bg-white">
                        {InformacionAdicionalList.length === 0 ? (
                          <MesageWarningTable
                            colSpan="3"
                            message="No se ha ingresado información"
                          />
                        ) : null}
                        {InformacionAdicionalList.map((el, index) => (
                          <tr
                            key={index}
                            className="bg-white dark:bg-gray-800 dark:hover:bg-gray-900 hover:bg-gray-200  border-b-gray-600  "
                          >
                            <td className="py-1 px-6">{el.nombre}</td>
                            <th
                              scope="row"
                              className="py-0 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {el.valor}
                            </th>
                            <td className="py-1 px-6 text-red-500 hover:text-red-600 ">
                              <button onClick={() => handleDeleteElement(index)}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                  />
                                </svg>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                   
                   }
                    
                   </div> </div>
            
            ) : (
              <></>
            )}
          </div>
        </div>













    
     
    </LargeModalForm>
  );
};

export default Modals;