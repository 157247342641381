import React, { useContext, useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import { StyleInput, StyleSelectForm } from "../../../../features/Constantes";
import MesageWarningTable from "../../../../features/components/MesageWarningTable";
import { ModalAddImpuestoContext } from "./ModalAddImpuestoProvider";
import moment from "moment";
import { getfechaperiodofiscal } from "../../../../services/Utilitario";

const DividendosSection = () => {

  const {
    DividendosList,
    setDividendosList,
    RentaList, IvaList, setRentaList, setIvaList
  } = useContext(ModalAddImpuestoContext);

  const [TipoRetencionSelected, setTipoRetencionSelected] = useState("IVA");

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
  } = useForm("onBlur");


  const AddDividendo = () => {
    let data =  {...getValues(), ejerFisUtDiv: getValues().ejerFisUtDiv.split("-")[0]};
    let indiceDoc = data.DocumentoDividendo.split("-")[0];
    let tipoDoc = data.DocumentoDividendo.split("-")[1];
    // console.log(data);
    if(tipoDoc === "RENTA") {
      RentaList[indiceDoc] = {...RentaList[indiceDoc], Dividendos: data};
      setRentaList([...RentaList]);
    setDividendosList([...DividendosList, RentaList[indiceDoc]]);

    } else {
      IvaList[indiceDoc] = {...IvaList[indiceDoc], Dividendos: data};
      // console.log(IvaList[indiceDoc]);
      setIvaList([...IvaList]);
    setDividendosList([...DividendosList, IvaList[indiceDoc]]);
    }
    setValue("fechaPagoDiv", moment().format("yyyy-MM-DD"));
    setValue("imRentaSoc", "");
    setValue("ejerFisUtDiv", getfechaperiodofiscal());
  };

  const handleDeleteElementoDDividendo = (index) => {
    DividendosList.splice(index, 1);
    setDividendosList([...DividendosList]);
  };

  useEffect(() => {
    setValue("fechaPagoDiv", moment().format("yyyy-MM-DD"));
    setValue("imRentaSoc", "");
    setValue("ejerFisUtDiv", getfechaperiodofiscal());
  }, []);
  
  /* useEffect(() => { console.log(TipoRetencionSelected)}, [TipoRetencionSelected]) */
  // useEffect(() => TipoRetencionSelected )

  
  useEffect(() => {
   
  }
  , [TipoRetencionSelected]);

  return (
    <>
      <div
        className=" p-4  rounded-lg dark:bg-gray-800 my-auto"
        id="dashboard"
        role="tabpanel"
        aria-labelledby="dashboard-tab"
      >
        <div className="py-0  flex-wrap justify-between mb-0">
          <div className="w-full grid md:grid-cols-4 md:gap-2 mb-2 md:mb-0">
          <div className="relative z-0 mb-2 w-full group">
              <label className="block text-sm font-medium mb-3  text-black dark:text-white">
                {" "}
                Tipo de retencion{" "}
              </label>
              <select value={TipoRetencionSelected} onChange={e => setTipoRetencionSelected(e.target.value)} className={StyleSelectForm} name="tipoDoc" id="tipoDoc">
                  <option value="IVA">IVA</option>
                  <option value="RENTA">RENTA</option>
                </select>
            </div>
            <div className="relative z-0 mb-2 w-full group">
              <label className="block text-sm font-medium mb-3  text-black dark:text-white">
                {" "}
                Documento Dividendo{" "}
              </label>
              <select className={StyleSelectForm} name="DocumentoDividendo" id="DocumentoDividendo"  {...register("DocumentoDividendo", {
                  required: {
                    value: true,
                    message: "DocumentoDividendo es requerido",
                  },
                })}>
                  {TipoRetencionSelected === "RENTA" 
                  ? RentaList.filter(el => !el.Dividendos).map((el, index) => <option key={index} value={index + "-RENTA"}>({el.CodigoRetencion} - {el.PorcentajeRetener}%) - ${el.BaseImponible}</option>) 
                  : IvaList.filter(el => !el.Dividendos).map((el, index) => <option key={index} value={index+ "-IVA"}>(<b>COD:</b> {el.CodigoRetencion} - %{el.PorcentajeRetener}) - ${el.BaseImponible}</option>
                  )}
                </select>
            </div>
          <div className="relative z-0 mb-2 w-full group">
              <label className="block text-sm font-medium mb-3  text-black dark:text-white">
                {" "}
                Fecha Pago Div.{" "}
              </label>
              <input
                type="date"
                className={StyleInput}
                placeholder=" "
                id="fechaPagoDiv"
                name="fechaPagoDiv"
                {...register("fechaPagoDiv", {
                  required: {
                    value: true,
                    message: "fechaPagoDiv es requerido",
                  },
                })}
              />
            </div>
            <div className="relative z-0 mb-2 w-full group">
              <label className="block text-sm font-medium mb-3  text-black dark:text-white">
                {" "}
                Im. Renta Soc{" "}
              </label>
              <input
                type="number"
                className={StyleInput}
                placeholder=" "
                id="imRentaSoc"
                name="imRentaSoc"
                {...register("imRentaSoc", {
                  required: {
                    value: true,
                    message: "imRentaSoc es requerido",
                  },
                })}
              />
            </div>
            <div className="relative z-0 mb-2 w-full group">
              <label className="block text-sm font-medium mb-3  text-black dark:text-white">
                {" "}
                Ejer, Fis. Ut. Div.
              </label>
              <input
                type="month"
                className={StyleInput}
                placeholder=" "
                id="ejerFisUtDiv"
                name="ejerFisUtDiv"
                {...register("ejerFisUtDiv", {
                  required: {
                    value: true,
                    message: "ejerFisUtDiv es requerido",
                  },
                })}
              />
            </div>
            <div className="relative z-0 mb-2 pt-7  w-full group">
              <button
                onClick={handleSubmit(AddDividendo)}
                type="button"
                className="  flex   dark:hover:bg-gray-700 hover:bg-gray-200  rounded-lg p-2 items-center justify-between px-2   text-sm font-medium leading-5  dark:text-gray-400 focus:outline-none focus:shadow-outline-gray"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 dark:stroke-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="overflow-x-auto relative  mt-3">
            <table className="w-full text-sm text-left text-gray-500 divide-y divide-gray-300 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                <th scope="col" className="py-1 px-6 ">
                  Documento Dividendo
                  </th>
                  <th scope="col" className="py-1 px-6 ">
                  Fecha Pago Div.
                  </th>
                  <th scope="col" className="py-1 px-6 ">
                  Im. Renta Soc
                  </th>
                  <th scope="col" className="py-1 px-6 ">
                  Ejer, Fis. Ut. Div.
                  </th>
                  <th scope="col" className="py-1 px-6 "></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-300">
                {DividendosList.length === 0 ? (
                  <MesageWarningTable
                    colSpan="4"
                    message="No se ha ingresado información"
                  />
                ) : null}
                {DividendosList.map((el, index) => (
                  <tr key={index} className="bg-white dark:bg-gray-800">
                    <td>({el.CodigoRetencion}%{el.PorcentajeRetener}) - ${el.BaseImponible}</td>
                    <td className="py-1 px-6">{el.Dividendos.fechaPagoDiv}</td>
                    <th
                      scope="row"
                      className="py-0 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      ${el.Dividendos.imRentaSoc}
                    </th>
                    <th
                      scope="row"
                      className="py-0 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {el.Dividendos.ejerFisUtDiv}
                    </th>
                    <td className="py-1 px-6 text-red-500 hover:text-red-600 ">
                      <button
                        onClick={() => handleDeleteElementoDDividendo(index)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                          />
                        </svg>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default DividendosSection;
