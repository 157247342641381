import React, { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

export default function ModalComponent({
  isOpen,
  onClose,
  title,
  children,
  dimensiones,
}) {
  const sizes = {
    largo: "lg:w-[48rem] md:w-[48rem]",
    mediano: "lg:w-[28rem] md:w-[28rem]",
  };

  const dimensionClass = sizes[dimensiones] || sizes.mediano;

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={onClose}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              {isOpen ? (
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
              ) : (
                <Dialog.Overlay className="fixed inset-0 " />
              )}
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={`inline-block   relative max-w-5xl ${dimensionClass} h-72 p-6 my-8 overflow-hidden  align-middle transition-all transform bg-white shadow-xl rounded-3xl`}
              >
                {title && (
                  <Dialog.Title
                    as="h3"
                    className="text-lg flex justify-between font-medium leading-6 text-gray-900"
                  >
                    {title}
                    <button
                      className="text-gray-500 hover:text-gray-700"
                      onClick={onClose}
                    >
                      <svg
                        className="h-6 w-6"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path d="M6 18L18 6M6 6l12 12"></path>
                      </svg>
                    </button>
                  </Dialog.Title>
                )}

                <div className="flex items-center    h-full ">
                  {children}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
