import React, { useEffect } from "react";
import MenuGeneral from "./MenuGeneral";
import Logo from "../../img/isologo.png";
import { Fragment } from "react";
import { Dialog, Popover, Tab, Transition } from "@headlessui/react";
import { NavLink } from "react-router-dom";
import TutorialesModal from "./TutorialesModal";
import { useSelector } from "react-redux";
import { PreguntasModal } from "./PreguntasModal";

const AsideBar = (props) => {
  const usuario = useSelector((store) => store.app.user);

  usuario.esActivo ? <></> : <></>;

  return (
    <>
      <TutorialesModal />
      <PreguntasModal />
      <div className="dark:bg-gray-700   bg-[#00314D] ">
        <div
          className={`lg:flex hidden ${
            props.OpenMenu ? "lg:w-[259px]" : "lg:w-20"
          } flex-col overflow-y-auto   lg:duration-300 inset-y-0 p-3`}
        >
          <div className="flex items-center flex-shrink-0 gap-x-4 px-2">
            <img
              src={Logo}
              className="cursor-pointer duration-500 w-10 bg-white rounded-md p-1"
              alt="asd"
            />
            <h1
              className={`text-white origin-left font-medium text-xl  duration-300 ${
                !props.OpenMenu && "hidden"
              }`}
            >
              Intuito S.A.
            </h1>
          </div>
          <nav className="flex  flex-col   justify-between">
            <div className="p-1">
              <MenuGeneral
                openItem={props.openItem}
                setOpenItem={props.setOpenItem}
                toggleItem={props.toggleItem}
                OpenMenu={props.OpenMenu}
                setOpenMenu={props.setOpenMenu}
                subMenu={props.subMenu}
                setSubMenu={props.setSubMenu}
              />
              <footer className="  ">
                <ul className="flex flex-col gap-2 py-2 text-white">
                  <li
                    data-bs-toggle="modal"
                    data-bs-target="#tutorialesModal"
                    className=" text-sm flex items-center w-full group  hover:text-black gap-x-4 cursor-pointer p-2 hover:bg-gray-100 rounded-md"
                    title={!props.OpenMenu ? "Tutoriales" : ""}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M17.011 9.385v5.128l3.989 3.487v-12z"></path>
                      <path d="M3.887 6h10.08c1.468 0 3.033 1.203 3.033 2.803v8.196a.991 .991 0 0 1 -.975 1h-10.373c-1.667 0 -2.652 -1.5 -2.652 -3l.01 -8a.882 .882 0 0 1 .208 -.71a.841 .841 0 0 1 .67 -.287z"></path>
                    </svg>

                    <span
                      className={`${
                        !props.OpenMenu && "hidden"
                      } origin-left duration-200`}
                    >
                      Tutoriales
                    </span>
                    <span
                      className={`${
                        !props.openMobil && "hidden"
                      } origin-left duration-200`}
                    >
                      Tutoriales
                    </span>
                  </li>
                  <li
                    data-bs-toggle="modal"
                    data-bs-target="#preguntasModal"
                    className="text-sm flex items-center w-full group  hover:text-black gap-x-4 cursor-pointer p-2 hover:bg-gray-100 rounded-md"
                    title={!props.OpenMenu ? "Preguntas frecuentes" : ""}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-question-mark"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M8 8a3.5 3 0 0 1 3.5 -3h1a3.5 3 0 0 1 3.5 3a3 3 0 0 1 -2 3a3 4 0 0 0 -2 4" />
                      <path d="M12 19l0 .01" />
                    </svg>

                    <span
                      className={`${
                        !props.OpenMenu && "hidden"
                      } origin-left duration-200`}
                    >
                      Preguntas frecuentes
                    </span>
                    <span
                      className={`${
                        !props.openMobil && "hidden"
                      } origin-left duration-200`}
                    >
                      Preguntas frecuentes
                    </span>
                  </li>
                </ul>
              </footer>
            </div>
          </nav>
        </div>

        <Transition.Root show={props.openMobil} as={Fragment}>
          <Dialog as="div" className=" z-40 " onClose={props.setOpenMobil}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className=" bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex p-5 pt-8  w-72 flex-col overflow-y-auto bg-[#00314D] pb-12 shadow-xl">
                  <div className="flex   pb-5">
                    <button
                      type="button"
                      className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                      onClick={() => props.setOpenMobil(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="items-center flex gap-x-4 ">
                    <img
                      src={Logo}
                      className={` cursor-pointer duration-500 w-10 bg-white  rounded-md p-1`}
                      alt="asd"
                    />
                    <h1
                      className={`text-white origin-left font-medium text-xl  duration-300 ${
                        !props.OpenMenu && ""
                      }`}
                    >
                      Intuito S.A.
                    </h1>
                  </div>
                  <MenuGeneral
                    openMobil={props.openMobil}
                    setOpenMobil={props.setOpenMobil}
                  />
                  <ul className="pt-6 ">
                    <li
                      data-bs-toggle="modal"
                      data-bs-target="#tutorialesModal"
                      className=" text-sm flex items-center w-full group text-gray-300 hover:text-black gap-x-4 cursor-pointer p-2 hover:bg-gray-100 rounded-md"
                      title={!props.OpenMenu ? "Tutoriales" : ""}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M17.011 9.385v5.128l3.989 3.487v-12z"></path>
                        <path d="M3.887 6h10.08c1.468 0 3.033 1.203 3.033 2.803v8.196a.991 .991 0 0 1 -.975 1h-10.373c-1.667 0 -2.652 -1.5 -2.652 -3l.01 -8a.882 .882 0 0 1 .208 -.71a.841 .841 0 0 1 .67 -.287z"></path>
                      </svg>

                      <span
                        className={`${
                          !props.openMobil && "hidden"
                        } origin-left duration-200`}
                      >
                        Tutoriales
                      </span>
                    </li>
                    <li
                      data-bs-toggle="modal"
                      data-bs-target="#preguntasModal"
                      className=" text-sm flex items-center w-full group text-gray-300 hover:text-black gap-x-4 cursor-pointer p-2 hover:bg-gray-100 rounded-md"
                      title={!props.OpenMenu ? "Preguntas frecuentes" : ""}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-question-mark"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M8 8a3.5 3 0 0 1 3.5 -3h1a3.5 3 0 0 1 3.5 3a3 3 0 0 1 -2 3a3 4 0 0 0 -2 4" />
                        <path d="M12 19l0 .01" />
                      </svg>

                      <span
                        className={`${
                          !props.openMobil && "hidden"
                        } origin-left duration-200`}
                      >
                        Preguntas frecuentes
                      </span>
                    </li>
                  </ul>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </>
  );
};

export default AsideBar;
