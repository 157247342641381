import ModalComponent from "../../components/ModalComponents/ModalComponent";

export const ModalFirma = ({ isOpenModalFirma, toggleModal }) => {


    
    return (
     <>
     <ModalComponent isOpen={isOpenModalFirma} onClose={toggleModal}>
     <div>
          <p className="text-lg font-medium leading-6 text-gray-900">
            ¿Cómo descargo mi firma?
          </p>
          <div className="mt-2 text-left">
            <p className="text-sm text-gray-500 ">
              <ul className="py-2 space-y-2">
                <li>
                  1) Ingresa al link
                  <a
                    className="pl-2 text-blue-600"
                    href="https://accounts.anf.es/signin#"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>Descargar firma</span>
                  </a>
                </li>
                <li>
                  2) Accede a: Usuario y Contraseña (escribe tu DNI que es tu
                  cédula).
                </li>
                <li>
                  3) Iniciado sesión da clic en el botón PFX o P12 escriba tu
                  PIN y se descargará tu firma en formato de archivo PFX o P12.
                </li>
              </ul>
            </p>
          </div>
        </div>
     </ModalComponent>
     
     </>
    );
    }
