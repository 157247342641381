import moment from "moment";

export const FormatoFechaApi = "YYYY-MM-DDTHH:mm:ss.SSS";

export const FormatoFechaV1 = "YYYY-MM-DD";

export const FormatoFechaperiodofiscal = "YYYY-MM";

export const UTF8RegExp =/^[a-zA-ZÀ-ÖØ-öø-ÿ0-9\s\.\,\-\_\'\"\$\(\)\@\[\]\/\=\¿\¡\?\!\+\`\~\\\*\&\^\%\$\#\@\:\;\+]+$/g;

export const MailsRegExp = /^([^\s@]+@[^\s@]+\.[^\s@]+)(;[^\s@]+@[^\s@]+\.[^\s@]+)*$/;

// /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\,?/g;

export const getFechaFin = () => moment().format().substring(0, 10);

export const getFechaInicio = () => moment().format().substring(0, 10);

export const getNoventaDiasAtras = () =>  moment().subtract(3, "months").format().substring(0, 10);

export const getfechaperiodofiscal = (fecha) =>  moment(fecha).format(FormatoFechaperiodofiscal);

/**
 *
 * @param {Number} number Numero para rellenar ceros
 * @param {Number} [width] longitud total que se desea rellenar con ceros
 * @returns {Number} valor con los ceros rellenados
 */
export const rellenarCeros = (number, width = 3) => {
  var numberOutput = Math.abs(number);
  var length = number.toString().length;
  var zero = "0";

  if (width <= length) {
    return number;
  } else {
    if (number < 0) {
      return "-" + zero.repeat(width - length) + numberOutput.toString();
    } else {
      return zero.repeat(width - length) + numberOutput.toString();
    }
  }
};
//export const getFechaInicio = () => moment().startOf("day").subtract(1, "days").format().substring(0, 10)

//export const getFechaInicioTz = () => moment().tz().zonesForCountry('America/Guayaquil').format().substring(0, 10)

export const getFechaInicioApi = (fechaInicio) =>
  moment(fechaInicio).format(FormatoFechaApi);
export const getFechaFinApi = (fechaFin) =>
  moment(fechaFin)
    .add(23, "hours")
    .add(59, "minutes")
    .add(59, "seconds")
    .format(FormatoFechaApi);

export const getFechaV1 = (fecha) => moment(fecha).format(FormatoFechaV1);

export function delay(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export function roundToTwo(num) {
  return +(Math.round(num + "e+2") + "e-2");
}

export function redondear(params) {
  return Number.parseFloat(params).toFixed(3);
}

export function roundNumber(num, scale) {
  if (!("" + num).includes("e")) {
    return +(Math.round(num + "e+" + scale) + "e-" + scale);
  } else {
    var arr = ("" + num).split("e");
    var sig = "";
    if (+arr[1] + scale > 0) {
      sig = "+";
    }
    return +(
      Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) +
      "e-" +
      scale
    );
  }
}
