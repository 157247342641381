import { NavLink } from "react-router-dom";

export const TransaccionesMenu = () => {
  return (
    <section
      style={{ padding: "10px" }}
      className="w-full lg:h-[400px] h-fit mt-5 relative bg-white rounded-lg"
    >
      <div
        className="bg-blue-900 rounded-lg p-12"
        style={{ height: "240px", width: "100%" }}
      >
        <h1 className="text-3xl text-white font-semibold">Transacciones</h1>
        <div
          className="grid sm:grid-cols-1 gap-10 lg:grid-cols-4 bg-transparent md:grid-cols-2 h-full"
          style={{
            width: "100%",
            padding: "55px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <NavLink
            exact="true"
            to={"/inventario/operaciones/transacciones/ingresos"}
            className="dark:bg-gray-700 gap-3 flex flex-col text-center bg-gray-50 hover:bg-[#E5F4FD] items-center justify-center rounded-lg shadow-xl hover:drop-shadow-xl divide-y divide-gray-200  hover:scale-105 duration-300 transition ease-in-out delay-150"
            style={{ minHeight: "150px", height: "180px", minWidth: "300px" }}
          >
            <img src="/ingresos.png" alt="Ingresos" className="w-24 h-24" />
            Ingresos
          </NavLink>
          <NavLink
            exact="true"
            to={"/inventario/operaciones/transacciones/egresos"}
            className="dark:bg-gray-700 gap-3 flex flex-col text-center bg-gray-50 hover:bg-[#E5F4FD]  items-center justify-center rounded-lg shadow-xl hover:drop-shadow-xl divide-y divide-gray-200  hover:scale-105 duration-300 transition ease-in-out delay-150"
            style={{ minHeight: "150px", height: "180px", minWidth: "300px" }}
          >
            <img src="/decreciente.png" alt="Egresos" className="w-16 h-16" />
            Egresos
          </NavLink>
          <NavLink
            exact="true"
            to={"/inventario/operaciones/transacciones/transferencias"}
            className="dark:bg-gray-700 gap-3 flex flex-col text-center bg-gray-50 hover:bg-[#E5F4FD] items-center justify-center rounded-lg shadow-xl hover:drop-shadow-xl divide-y divide-gray-200 hover:scale-105 duration-300 transition ease-in-out delay-150"
            style={{ minHeight: "150px", height: "180px", minWidth: "300px" }}
          >
            <img
              src="/transferencia-de-dinero.png"
              alt="Valoracion"
              className="w-24 h-24"
            />
            Transferencia
          </NavLink>
          <NavLink
            exact="true"
            to={"/inventario/operaciones/transacciones/ajuste-inventario"}
            className="dark:bg-gray-700 gap-3 flex flex-col text-center bg-gray-50 hover:bg-[#E5F4FD] items-center justify-center rounded-lg shadow-xl hover:drop-shadow-xl divide-y divide-gray-200 hover:scale-105 duration-300 transition ease-in-out delay-150"
            style={{ minHeight: "150px", height: "180px", minWidth: "300px" }}
          >
            <img
              src="/planificacion.png"
              alt="Planificacion"
              className="w-16 h-16"
            />
            Ajuste de Inventario
          </NavLink>
        </div>
      </div>
    </section>
  );
};
