import React from "react";
import NumberFormat from "react-number-format";
import { StyleInput, StyleInputError, StyleLabel, StyleLabelError } from "../../Constantes";
import { set, useFormContext } from "react-hook-form";

const InputNumberUF = (props) => {

    const {
        register,
        formState: { errors },
        setValue,
        watch
      } = useFormContext();

      const valObs = watch(props.name);

  return (
    <>
      <NumberFormat
        className={errors[props.name] ? StyleInputError : StyleInput}
        decimalScale={props.decimalMax}
        defaultValue={0}
        {...register(props.name, props.validations)}
        {...props}
        onValueChange={e => setValue(props.name, e.value)}
        value={valObs}
      />
      <label
        htmlFor={props.name}
        className={errors[props.name] ? StyleLabelError : StyleLabel}
      >
        {props.title || props.name}
      </label>
    </>
  );
};

export default InputNumberUF;
