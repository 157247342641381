import { FormProvider, useForm } from "react-hook-form"
import { SearchIcon } from "../../../../Icons/ButtonIcons"
import { SelectGlobal } from "../../context/selectContext"
import { InputGlobal } from "../../context/inputContext"

export const TransaccionesEmitidasForm = () => {

    const methods = useForm()

    const searchTransaction = (data) => {

    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(searchTransaction)} className="w-full bg-white rounded-lg p-4 shadow-md flex flex-col gap-4">
                <h1 className="font-bold text-3xl p-6 pr-0 pl-0 border-b-2 border-gray-400">Reporte de Transacciones Emitidas</h1>
                <div className="formCrearBodega p-8 bg-gray-300 rounded-lg text-[12px]">
                    <InputGlobal type={"date"} title={"Fecha de Inicio"} name={"fechaInicio"}/>
                    <InputGlobal type={"date"} title={"Fecha de Fin"} name={"fechaFin"}/>
                    <InputGlobal  title={"Bodega / Ubicacion"} name={"bodega&Ubicacion"}/>
                    <SelectGlobal title={"Tipo"} name={"tipo"}>
                         <option value="">Todos</option>
                    </SelectGlobal>
                </div>
                <div className="flex flex-row gap-1 text-white font-semibold text-[12px]">
                <button type="submit" className="p-3 rounded-lg bg-blue-600 hover:bg-blue-700 pr-5 pl-5 flex flex-row gap-2 items-center"><span><SearchIcon /></span>Buscar</button>
                </div>
            </form>
        </FormProvider>
    )
}