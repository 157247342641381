import React from "react";

import FormFirmaelect from "./FormFirmaelect";

const FirmaElec = () => {
  return (
   
      <div className="mx-4 my-4">
        <FormFirmaelect />
      </div>
   
  );
};

export default FirmaElec;
