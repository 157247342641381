export const CrearProductoList = () => {
    return (
        <>
            <div className="w-full bg-white h-fit p-12 shadow-md rounded-lg flex flex-col gap-8">
                <span className="ml-4">Mostrar <select className="p-1 rounded-md border-2 border-gray-500">
                    <option value="">50</option>
                </select> Registros</span>
                <table className="self-center w-full border-2 text-[12px]">
                    <thead>
                        <tr className="bg-gray-300 text-center">
                            <th style={{ padding: "15px" }}><input type="checkbox" /></th>
                            <th>Código</th>
                            <th>Nombre del Producto</th>
                            <th>Tipo de Producto</th>
                            <th>Nombre de la Categoria</th>
                            <th>Precio de Venta</th>
                            <th>Activar</th>
                            <th style={{ padding: "15px" }}>Opciones</th>
                        </tr>
                    </thead>
                    <tbody>
                    </tbody>
                </table>
            </div></>
    )
}