import { useState } from "react";
export const useCheckBodegas = (bodegas) => {

  const [IsCheckedAll, setIsCheckedAll] = useState(false);
  const [bodegasChecked, setBodegasChecked] = useState([]);


  const handleSelectAll = () => {
    if(!IsCheckedAll) {
      const allIDs = bodegas.map(el =>  el.codigo);
      const AllItems = new Set([...allIDs, ...bodegasChecked]);

      setBodegasChecked([...AllItems]);
    } else {
      setBodegasChecked([]);
    }
  }

  const handleClick = (id) => {
    const isChecked = bodegasChecked.find(el => el === id);
    if(isChecked) {
      const itemsWithoutUncheck = bodegasChecked.filter(el => el !== id);
      setIsCheckedAll(false)
      setBodegasChecked([...itemsWithoutUncheck]);
    } else {
      setBodegasChecked(prevState => [...prevState, id]);
    }
  };

  const handleSubmitChecked = () => {
    return [...bodegasChecked];
  }

  return {
    handleSelectAll,
    handleClick,
    handleSubmitChecked,
    IsCheckedAll, setIsCheckedAll,
    bodegasChecked,
    setBodegasChecked,
  };
};

