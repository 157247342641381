import { TransaccionesEmitidasForm } from "./TransaccionesEmitidasForm";
import { TransaccionesEmitidasList } from "./TransaccionesEmitidasList";

export const TransaccionesEmitidas = () => {
  return (
    <section>
      <TransaccionesEmitidasForm />
      <TransaccionesEmitidasList />
    </section>
  );
};
