import { useFormContext } from "react-hook-form";
import { getNoventaDiasAtras } from "../../../../../services/Utilitario";

export default function IngresoGeneral ( ) {
  const { register } = useFormContext();

  return (
    <>
      <div className="">
        <div name="InformeGeneral" className="border rounded-lg">
          <p className="bg-[#003B5B] text-white font-semibold rounded-t-lg p-3">
            Información General
          </p>

          <div name="Formularios" className="grid grid-cols-5 gap-6 p-4">
            <div>
              <p>Tipo de Operación</p>
              <input
                type="text"
                name="tipoOperacion"
                {...register("tipoOperacion", { required: true })}
                placeholder="Número de transacción"
                className="border border-gray-300 bg-[#EBEBEB] rounded-md p-1 w-full"
                value="Ingreso"
              />
            </div>
            <div>
              <p>Fecha de Ingreso</p>
              <input
                type="date"
                className=" border border-gray-300 rounded-md p-1 w-full"
                {...register("fechaCreacion", { required: true })}
                min={getNoventaDiasAtras()}
              />
            </div>
            <div>
              <p>Bodega / Ubicación</p>
              <input
                type="text"
                placeholder="Digite la ubicación"
                className=" border border-gray-300 rounded-md p-1 w-full"
                {...register("ubicacion", { required: true })}
              />
            </div>
            <div className="col-span-2">
              <p>Detalle / Descripción</p>
              <input
                type="text"
                className=" border border-gray-300 rounded-md p-1 w-full"
                name="Detalle"
                {...register("detalle", { required: true })}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
