import React, { useContext, useEffect, useState } from "react";
import MesageError from "../../../features/components/MessageError";
import { Fragment } from 'react'
import { ReporteFacturaContext } from "./ReporteFacturaUsuarioProvider";
import { useSelector } from 'react-redux'
import {
  StyleSelect,
  StyleInput,
  StyleLabel,
} from "../../../features/Constantes";

import { getListV2 } from "../../../services";
import { rellenarCeros } from "../../../services/Utilitario";
import NumberFormat from "react-number-format";
import axios from "axios";
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const FormFacturaUsuario = () => {
  const { formik, cargandoData, setPaginacion, initialStatePaginacion,setStart } = useContext(ReporteFacturaContext);

  const [Estados, setEstados] = useState([]);
  const [Establecimientos, setEstablecimientos] = useState([]);
  const [PuntosEmision, setPuntosEmision] = useState([]);
  const [TipoDocumento, setTipoDocumento] = useState([]);

  const empresa = useSelector(store => store.empresa.empresa);
  const usuario = useSelector(store => store.app.user);

  useEffect(() => {
    (async () => {
      //ObtenerEstablecimientosPorEmpresa
      const GetAllEstablecimientos = await getListV2(empresa, 'ObtenerEstablecimientosPorEmpresa');
      setEstablecimientos(GetAllEstablecimientos._embedded);
    })();
    (async () => {
      const GetAllEstados = await getListV2(empresa, 'GetEstados');
      setEstados(GetAllEstados._embedded);
    })();
    (async () => {
      const GetAllTipoDoc = await getListV2(empresa, 'BuscarTiposDocumento');
      if(GetAllTipoDoc == null) return;
      setTipoDocumento(GetAllTipoDoc);
    })();
  }, []);

  const handleChangePuntoEmision = (e) => {
    formik.setFieldValue('secuencial', '')
    formik.handleChange(e);
  }

  const handleChangeEstablecimiento = async (e) => {
    let establecimientoId = e.target.selectedOptions[0].getAttribute(
      "data-id-establecimiento"
    );
    const index = e.target.selectedOptions[0].getAttribute(
      "data-index"
    );

    const establecimiento = Establecimientos[index]

    //console.log(establecimientoId)
    //console.log(establecimiento.idEstablecimiento)

    const GetAllPuntosEmision = await getListV2(establecimiento, 'GetListPuntosEmision')

    setPuntosEmision(GetAllPuntosEmision._embedded);

    formik.setFieldValue('secuencial', '')
    formik.handleChange(e);
  };

  const handleDownloadEXCEL = async (e) => {
    let tipoFecha = '';
    
    if(formik.values.tipoFecha === '1') tipoFecha = 'FechaEmision';
    if(formik.values.tipoFecha === '2') tipoFecha = 'FechaAutorizacion';
    if(formik.values.tipoFecha === '3') tipoFecha = 'FechaRegistro';

    const data = await getListV2(empresa,
      'DescargarExcel', 
    `?fechaInicio=${formik.values.fechaInicio.replaceAll('-', '/')}&fechaFin=${formik.values.fechaFin.replaceAll('-', '/')}&tipoDocumento=${formik.values.TipoDocumento}&ptoEmision=${formik.values.puntoEmision}&establecimiento=${formik.values.establecimiento}&secuencial=${formik.values.secuencial}&estado=${formik.values.estado}&identificacion=${formik.values.receptor || ""}&campoFecha=${tipoFecha}&idEmpresa=${empresa.idEmpresa}`);

    //Logica para descargar el xlsx
    let a = document.createElement("a");
    a.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + data.archivo;
    a.download = Date.now() + ".xlsx";
    a.click();
  }

  const handleChangeSearchReportes = (e) => {
    if(e.target.name === "secuencial") {
      if(e.target.value.length > 18) {
        return e.target.value;
      }
    }

    if(e.target.name === "cliente") {
      if(e.target.value.length > 20) return e.target.value;
    }

    formik.handleChange(e);
  }

  const handleDownloadReporteDetallado = async (e) => {
    let tipoFecha = '';
    
    if(formik.values.tipoFecha === '1') tipoFecha = 'FechaEmision';
    if(formik.values.tipoFecha === '2') tipoFecha = 'FechaAutorizacion';
    if(formik.values.tipoFecha === '3') tipoFecha = 'FechaRegistro';
    
    const data = await axios.get(`api/ReporteDetallado/${usuario.identificacion}/${empresa.idEmpresa}?fechaInicio=${formik.values.fechaInicio.replaceAll('-', '/')}&fechaFin=${formik.values.fechaFin.replaceAll('-', '/')}&tipoDocumento=${formik.values.TipoDocumento}&ptoEmision=${formik.values.puntoEmision}&establecimiento=${formik.values.establecimiento}&secuencial=${formik.values.secuencial}&estado=${formik.values.estado}&identificacion=${formik.values.receptor || ""}&campoFecha=${tipoFecha}&idEmpresa=${empresa.idEmpresa}`);

    //Logica para descargar el xlsx
    let a = document.createElement("a");
    a.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + data.archivo;
    a.download = Date.now() + ".xlsx";
    a.click();
  }

  return (
    <>
      <h2 className="my-6 pl-4 text-2xl font-semibold text-gray-700 dark:text-gray-200">
        Reporte de documentos emitidos
      </h2>
      <hr className="border-1 dark:border-gray-800 " />
      <div className="px-4 py-3  ">
        <form onSubmit={(e) => {
          setPaginacion(initialStatePaginacion)
          formik.handleSubmit(e);
          setStart(0);
          }}>
          <div className="grid md:grid-cols-5 md:gap-6 mt-6">


          <div className="relative z-0 mb-6 w-full group">
              <input
                type="date"
                className={StyleInput}
                name="fechaInicio"
                id="fechaInicio"
                value={formik.values.fechaInicio}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder=" "
              />
             
              <label htmlFor="fechaInicio" className="peer-focus:font-semibold absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold">
                Fecha inicio
              </label>
              
            </div>
            <div className="relative z-0 mb-6 w-full group">
              <input
                type="date"
                name="fechaFin"
                id="fechaFin"
                value={formik.values.fechaFin}
                onChange={formik.handleChange}
                className={StyleInput}
                placeholder=" "
              />
              
              <label htmlFor="fechaFin"className="peer-focus:font-semibold absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold">
                Fecha fin
              </label>
            </div>



            {/* <div className="relative z-0 mb-6 w-full group">
              <input
                type="text"
                className={StyleInput}
                placeholder=" "
                id="cliente"
                name="cliente"
                value={formik.values.cliente}
                onChange={handleChangeSearchReportes}
                onBlur={formik.handleBlur}
              />
              <label htmlFor="cliente" className={StyleLabel}>
                Cliente / Ruc
              </label>
              {formik.touched.cliente && formik.errors.cliente ? (
                <MesageError message={formik.errors.cliente} />
              ) : null}
            </div> */}


           {/* ESTADO */}



           <div className="relative z-0 mb-6 w-full group">
              <select
                type="text"
                name="TipoDocumento"
                id="TipoDocumento"
                className={StyleSelect}
                placeholder=" "
                defaultValue={formik.values.estado}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option key="00" value="" >
                  TODOS
                </option>
                {TipoDocumento.map(el => {
                  return <option key={el.idTipoDocumento} value={el.codigo} >
                  {el.documento}
                </option>
                })}
              </select>
              
                   <label htmlFor="estado" className="peer-focus:font-semibold absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold">
               Tipo doc.
             </label>
            </div>
                 <div className="relative z-0 mb-6 w-full group">
            </div>
          </div>
  



          <button
            type="submit"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none font-medium rounded-lg
            text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 mb-2"
            
          >
            {cargandoData ? (
              <>
                <svg
                  aria-hidden="true"
                  role="status"
                  className="inline mr-3 w-4 h-4 animate-spin text-white"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
                Cargando...
              </>
            ) : (
              <>
                <svg xmlns="http://www.w3.org/2000/svg" 
                className="inline mr-2 -ml-1 w-5 h-5 text-white" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor" 
                strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
                </svg>

                BUSCAR
              </>
            )}
          </button>
        </form>
      </div>
      <hr className="border-1  dark:border-gray-800" />
    </>
  );
};

export default FormFacturaUsuario;
