import { useState } from "react";
const useCheck = (documentos) => {

  const [IsCheckedAll, setIsCheckedAll] = useState(false);
  const [documentsChecked, setDocumentsChecked] = useState([]);
  const [IsOpenModal, setIsOpenModal] = useState(true);
  const [TypeDownload, setTypeDownload] = useState("");

  const handleSelectAll = () => {
    if(!IsCheckedAll) {
      const allIDs = documentos.map(el =>  el.idDocumentoElectronico);
      const AllItems = new Set([...allIDs, ...documentsChecked]);

      setDocumentsChecked([...AllItems]);
    } else {
      setDocumentsChecked([]);
    }
  }

  const handleClick = (index) => {
    const isChecked = documentsChecked.find(el => el === documentos[index].idDocumentoElectronico);
    if(isChecked) {
      const itemsWithoutUncheck = documentsChecked.filter(el => el !== documentos[index].idDocumentoElectronico);
      setIsCheckedAll(false)
      setDocumentsChecked([...itemsWithoutUncheck]);
    } else {
      setDocumentsChecked(prevState => [...prevState, documentos[index].idDocumentoElectronico]);
    }
  };

  const handleSubmitChecked = () => {
    return [...documentsChecked];
  }

  return {
    handleSelectAll,
    handleClick,
    handleSubmitChecked,
    IsCheckedAll, setIsCheckedAll,
    documentsChecked,
    setDocumentsChecked,
    IsOpenModal,
    setIsOpenModal,
    TypeDownload, setTypeDownload
  };
};

export default useCheck;
