
import { useDispatch } from "react-redux"

export const ModalButtons = ({closeFn}) => {

    const dispatch = useDispatch()
    return (
        <div className="flex flex-row h-fit gap-4 w-full justify-end items-center text-white">
            <button className="bg-gray-600 p-6 h-8 text-[12px] hover:bg-gray-700 flex items-center justify-center w-28 rounded-xl" onClick={() => dispatch(closeFn())}>Cancelar</button>
            <button type="submit" className="bg-green-400 hover:bg-green-600 p-6 h-8 text-[12px]  w-28 flex items-center justify-center rounded-xl">Guardar</button>
        </div>
    )
}