import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  StyleInput,
  StyleInputError,
  StyleLabel,
  StyleLabelError,
} from "../../../features/Constantes";
import { LargeModalForm } from "../../../features/components/LargeModalForm";
import { useForm, useFormContext } from "react-hook-form";
import NumberFormat from "react-number-format";
import { getListV2, getListV4 } from "../../../services";
import { EmitirFacturaContext } from "./EmitirFacturaProvider";
import { FormaPagoMap, ImpuestosMap } from "../helpers";
import useFacturaExportacion from "./hooks/FacturaExportacion/useFacturaExportacion";

const BuscarFacturaModalUseForm = () => {
  const {
    setDetalleList,
    formik,
    setFormaPagoList,
    setReembolsoList,
    setInformacionAdicionalList,
    setActiveReembolso,
  } = useContext(EmitirFacturaContext);

  const [IsLoading, setIsLoading] = useState(false);
  const [impuestosList, setImpuestosList] = useState([]);
  const [ErrorSecuencialState, setErrorSecuencialState] = useState(0);

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      secuencialFactura: "000-000-000000000",
    },
  });

  useEffect(() => {
    getListV2(empresa, "GetAllimpDetalles", "?activo=true&idImpuesto=1").then(
      (response) => setImpuestosList(response._embedded)
    );
  }, []);

  const methods = useFormContext();

  const { setIsActiveExportacion } = useFacturaExportacion();

  const empresa = useSelector((store) => store.empresa.empresa);

  const renderMessage = (val) => {
    switch (val) {
      case 1:
        return (
          <p className=" text-green-500 text-sm -translate-y-5 mt-2">
            El comprobante ingresado esta autorizado.
          </p>
        );

      case 2:
        return (
          <p className=" text-yellow-500 text-sm -translate-y-5 mt-2 ">
            El comprobante ingresado existe, pero no está autorizado.
          </p>
        );

      case 3:
        return (
          <p className="  text-sm text-red-600 -translate-y-5 mt-2">
            El comprobante ingresado no existe.
          </p>
        );

      default:
        break;
    }
  };

  const handleSubmitModal = async () => {
    try {
      setIsLoading(true);
      let datas = getValues().secuencialFactura.split("-");

      let res = await getListV2(
        empresa,
        "DuplicarFactura",
        `?establecimiento=${Number(datas[0])}&puntoEm=${Number(
          datas[1]
        )}&secuencial=${Number(datas[2])}`
      );
      /* let dato = res.retorno.infoFactura.identificacionComprador;
      console.log(dato); */
      setInformacionAdicionalList([
        ...res.retorno.infoAdicional.campoAdicional,
      ]);
      let identificacionCliente =
        res.retorno.infoFactura.identificacionComprador.trim();
      const valores = await getListV2(
        empresa,
        "SearchContribuyenteForForm",
        `?identificacion=${
          identificacionCliente.length === 13
            ? identificacionCliente.slice(0, 10)
            : identificacionCliente
        }`
      );

      methods.setValue(
        "clienteIdentificacion",
        res.retorno.infoFactura.identificacionComprador
      );
      methods.setValue("clienteRazonSocial", valores.razonSocial);
      methods.setValue("clienteDireccion", valores.direccion);
      methods.setValue("clienteEmail", valores.correo);
      methods.setValue("clienteTelefono", valores.telefonoCelular || "");
      methods.setValue("idContribuyente", valores.idContribuyente);

      setFormaPagoList(
        res.retorno.infoFactura.pagos.map((el) => {
          return {
            tipoPago: FormaPagoMap[el.formaPago].IdFormaPagoSri,
            descripcionTipoPago: FormaPagoMap[el.formaPago].Descripcion,
            tiempoPago: el.unidadTiempo,
            plazoPago: el.plazo,
            valor: el.total,
          };
        })
      );

      const NuevoModeloProductos = res.retorno.detalles.map((el) => {
        return {
          idServicio: null,
          idEmpresa: empresa.idEmpresa,
          idImpuestoIva:
            ImpuestosMap[el.impuestos[0].codigoPorcentaje].idImpuestoDetalle,
          codigoPrincipal: el.codigoPrincipal,
          codigoAuxiliar: el.codigoAuxiliar,
          descripcion: el.descripcion,
          precioUnitario: Number(el.precioUnitario),
          descuento: el.descuento,
          cantidad: el.cantidad,
          activo: true,
          tipo: 1,
          iva: Number(el.impuestos[0].valor),
          subTotal: Number(el.precioTotalSinImpuesto),
          fechaCreacion: "2022-09-28T11:17:25.26",
          idImpuestoIvaNavigation:
            ImpuestosMap[el.impuestos[0].codigoPorcentaje],
          servicioDetalle: el.detallesAdicionales,
        };
      });
      setDetalleList([...NuevoModeloProductos]);

      console.log(impuestosList);
      if (res.retorno.reembolsos && res.retorno.reembolsos.length > 0) {
        const modeloReembolso = res.retorno.reembolsos.map((el) => {
          const ModeloDataReembolso = el.detalleImpuestos.map((item) => {
            return {
              Impuesto: impuestosList.find(
                (x) => String(x.codigo) === String(item.codigoPorcentaje)
              ),
              SubTotalReembolso: item.baseImponibleReembolso,
              TotalIvaReembolso: (item.impuestoReembolso),
            };
          });

          return {
            AutorizacionComprobante: el.numeroautorizacionDocReemb,
            FechaEmisionComprobante: el.fechaEmisionDocReembolso,
            NumeroComprobante: `${el.estabDocReembolso}-${el.ptoEmiDocReembolso}-${el.secuencialDocReembolso} `,
            RazonSocialProveedor: "Proveedor",
            RucProveedor: el.identificacionProveedorReembolso,
            TipoDocumento: el.codDocReembolso,
            TipoProveedor: el.tipoProveedorReembolso,
            DataReembolso: ModeloDataReembolso,
          };
        });
        console.log(modeloReembolso);
        setReembolsoList([...modeloReembolso]);
        setActiveReembolso(true);
      }

      if (res.retorno.infoFactura.comercioExterior !== null) {
        setIsActiveExportacion(true);
        methods.reset({
          exportacion: {
            paisAdquisicion: res.retorno.infoFactura.paisAdquisicion,
            paisOrigen: res.retorno.infoFactura.paisOrigen,
            paisDestino: res.retorno.infoFactura.paisDestino,
            incotermLugar: res.retorno.infoFactura.lugarIncoTerm,
            puertoEmbarque: res.retorno.infoFactura.puertoEmbarque,
            puertoDestino: res.retorno.infoFactura.puertoDestino,
            incotermFactura: res.retorno.infoFactura.incoTermFactura,
            incotermTotalSinImp:
              res.retorno.infoFactura.incoTermTotalSinImpuestos,
            fleteInternacional: res.retorno.infoFactura.fleteInternacional,
            seguroInternacional: res.retorno.infoFactura.seguroInternacional,
            gastosAduaneros: res.retorno.infoFactura.gastosAduaneros,
            gastosTransporteOtros:
              res.retorno.infoFactura.gastosTransporteOtros,
          },
        });
      }

      if (res !== "" && res !== undefined) {
        if (
          res.retorno.docElectronico.idEstadoDocumentoElectronico.toUpperCase() !==
          "1777AA9D-FA14-49BD-B9F0-ECE6D2952756"
        ) {
          setErrorSecuencialState(2);
        } else {
          setErrorSecuencialState(1);
        }
      }
    } catch (ex) {
      //console.log(ex);
      setErrorSecuencialState(3);
    } finally {
      setIsLoading(false);
      document.getElementById("searchFacturaModal").click();
    }
  };

  const handleComprobarComprobante = async () => {
    try {
      let datas = getValues().secuencialFactura.split("-");
      let res = await getListV4(
        empresa,
        "ComprobarAutorizado",
        `?establecimiento=${Number(datas[0])}&puntoEm=${Number(
          datas[1]
        )}&secuencial=${Number(datas[2])}`
      );
      if (res.status === 200) {
        setErrorSecuencialState(1);
      } else if (res.status === 206) {
        setErrorSecuencialState(2);
      } else {
        setErrorSecuencialState(3);
      }
    } catch (ex) {
      // console.log(ex);
      setErrorSecuencialState(3);
    }
  };

  return (
    <LargeModalForm
      buttonModal={"searchFacturaModal"}
      IsLoading={IsLoading}
      submitText={"Agregar"}
      title="Replicar factura"
      submitModal={handleSubmit(handleSubmitModal)}
    >
      <div className="w-full py-4 mb-2 md:mb-0 mt-5 grid md:grid-cols-3 md:gap-6">
        <div className="w-full col-span-2">
          <NumberFormat
            format="###-###-#########"
            id="secuencialFactura"
            name="secuencialFactura"
            placeholder=" "
            className={errors.secuencialFactura ? StyleInputError : StyleInput}
            {...register("secuencialFactura", {
              required: true,
            })}
            onChange={(e) => setValue("secuencialFactura", e.target.value)}
            value={getValues().secuencialFactura}
          />
          <label
            htmlFor="secuencialFactura"
            className={errors.secuencialFactura ? StyleLabelError : StyleLabel}
          >
            Secuencial comprobante
          </label>
          {renderMessage(ErrorSecuencialState)}
        </div>
        <div className="mb-2  w-full group">
          <button
            onClick={handleComprobarComprobante}
            type="button"
            className="  text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2 text-center dark:bg-green-600 dark:focus:ring-green-800 dark:hover:bg-green-700"
          >
            COMPROBAR
          </button>
        </div>
      </div>
    </LargeModalForm>
  );
};

export default BuscarFacturaModalUseForm;