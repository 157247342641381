import { post } from ".";

export const  PostLiquidacion = async (doc) => {
    try {
        return await post("/api/Liquidacion/GuardarLiquidacion", doc);
    } 
    catch (error) {
     
        throw error;
    }
}