import { CrearProductoForm } from "./CrearProductoForm";
import { CrearProductoList } from "./CrearProductoList";

export const CrearProducto = () => {
  return (
    <section className="w-full h-full flex flex-col gap-10">
      <CrearProductoForm />
      <CrearProductoList />
    </section>
  );
};
