import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  StyleInput,
  StyleInputError,
  StyleLabel,
  StyleLabelError,
} from "../../../features/Constantes";
import { LargeModalForm } from "../../../features/components/LargeModalForm";
import { useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { getListV2, getListV4 } from "../../../services";
import { EmitirGuiaRemisionContext } from "./EmitirGuiaRemisionProvider";
import { ImpuestosMap } from "../helpers";
import moment from "moment";
import { data } from "autoprefixer";
import { toast } from "react-toastify";
import { EmitirFacturaContext } from "../EmitirFactura/EmitirFacturaProvider";

const BuscarFacturaModal = () => {
  
  const { setDestinatariosList, formik, DestinatariosList, setInformacionAdicionalList } = useContext(
    EmitirGuiaRemisionContext
  );

  const [IsLoading, setIsLoading] = useState(false);
  const [ErrorSecuencialState, setErrorSecuencialState] = useState(0);

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    reset,
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    setValue("secuencialFactura", "000-000-000000000");
  }, []);

  const [estbDestino, setEstbDestino] = useState("");

  const empresa = useSelector((store) => store.empresa.empresa);

  const handleEstbDestinoChange = (e) => {
    const inputValue = e.target.value;
    const trimmedValue = inputValue.trim(); // Recorta los espacios en blanco al principio y al final
    setEstbDestino(trimmedValue);
  };

  const renderMessage = (val) => {
    switch (val) {
      case 1:
        return (
          <p className=" text-green-500 text-sm -translate-y-5 mt-2">
            El comprobante ingresado esta autorizado.
          </p>
        );

      case 2:
        return (
          <p className=" text-yellow-500 text-sm -translate-y-5 mt-2 ">
            El comprobante ingresado existe, pero no está autorizado.
          </p>
        );

      case 3:
        return (
          <p className="  text-sm text-red-600 -translate-y-5 mt-2">
            El comprobante ingresado no existe.
          </p>
        );

      default:
        break;
    }
  };
  const [inputValue, setInputValue] = useState("");

  const handleChange = (e) => {
    // Obtener el valor actual del input
    let value = e.target.value;

    // Eliminar cualquier carácter que no sea un número
    value = value.replace(/[^0-9]/g, "");

    // Limitar la longitud a 3 caracteres
    if (value.length > 3) {
      value = value.substr(0, 3);
    }

    // Actualizar el estado del input
    setInputValue(value);
  };
  const handleSubmitModal = async () => {
    try {
      setIsLoading(true);
      // console.log(DestinatariosList);

      let datas = getValues().secuencialFactura.split("-");
      let datasString = datas.join("-");

      const existe = DestinatariosList.some(
        (element) => element.NumDocSustento === datasString
      );

      if (existe) {
        toast.error("La factura ya ha sido ingresada");
      } else {
        let res = await getListV2(
          empresa,
          "DuplicarFactura",
          `?establecimiento=${Number(datas[0])}&puntoEm=${Number(
            datas[1]
          )}&secuencial=${Number(datas[2])}`
        );
     
        res.retorno.detalles[0].numAutDocSustento = res.numeroDocumento;

        let identificacionCliente =
          res.retorno.infoFactura.identificacionComprador.trim();
        
        setInformacionAdicionalList([...res.retorno.infoAdicional.campoAdicional]);

        const valores = await getListV2(
          empresa,
          "SearchContribuyenteForForm",
          `?identificacion=${
            identificacionCliente.length === 13
              ? identificacionCliente.slice(0, 10)
              : identificacionCliente
          }`
        );

        formik.setFieldValue(
          "clienteIdentificacion",
          res.retorno.infoFactura.identificacionComprador
        );

        formik.setFieldValue("clienteRazonSocial", valores.razonSocial);
        formik.setFieldValue("clienteDireccion", valores.direccion);
        formik.setFieldValue("clienteEmail", valores.correo);
        formik.setFieldValue("clienteTelefono", valores.telefonoCelular || "");
        formik.setFieldValue("idContribuyente", valores.idContribuyente);

        const NuevoModeloDestinatarios = res.retorno.detalles.map((el) => {
          return {
            idServicio: null,
            idEmpresa: empresa.idEmpresa,
            idImpuestoIva:
              ImpuestosMap[el.impuestos[0].codigoPorcentaje].idImpuestoDetalle,
            codigoPrincipal: el.codigoPrincipal,
            codigoAuxiliar: el.codigoAuxiliar,
            descripcion: el.descripcion,
            precioUnitario: Number(el.precioUnitario),
            descuento: el.descuento,
            cantidad: el.cantidad,
            activo: true,
            tipo: 1,
            iva: Number(el.impuestos[0].valor),
            numAutDocSustento: el.numAutDocSustento,
            subTotal: Number(el.precioTotalSinImpuesto),
            fechaCreacion: "2022-09-28T11:17:25.26",
            idImpuestoIvaNavigation:
              ImpuestosMap[el.impuestos[0].codigoPorcentaje],
            servicioDetalle: el.detallesAdicionales,
          };
        });

        let dateSplited = res.retorno.infoFactura.fechaEmision.split("/");
        var codEstab = getValues().estbDestino;
        var docAduanero = getValues().docAduanero;
        var motivoTraslado = getValues().motivoTraslado;
        var ruta = getValues().rutaDestino;
        var direcDestino = getValues().direcDestino;
        
  

        setDestinatariosList((prevState) => {
          return [
            ...prevState,
            {
              CodDocSustento: "01",
              /* CodEstabDestino: getValues().estbDestino,
              DocAduaneroUnico: getValues().docAduanero,
              MotivoTraslado: getValues().motivoTraslado,
              Ruta: getValues().rutaDestino, */
              CodEstabDestino: codEstab,
              DocAduaneroUnico: docAduanero,
              MotivoTraslado: motivoTraslado,
              Ruta: ruta,
              numAutDocSustento: res.numeroDocumento,
              DirDestinatario: direcDestino ,
              FechaEmisionDocSustento: `${dateSplited[1]}-${dateSplited[0]}-${dateSplited[2]}`,
              IdentificacionDestinatario:
                res.retorno.infoFactura.identificacionComprador,
              NumDocSustento: `${res.retorno.infoTributaria.estab}-${res.retorno.infoTributaria.ptoEmi}-${res.retorno.infoTributaria.secuencial}`,
              RazonSocialDestinatario:
                res.retorno.infoFactura.razonSocialComprador,
              detalles: [...NuevoModeloDestinatarios],
            },
          ];
        });

        setTimeout(() => {
          // console.log("hoaooooooooooooooooooooooooo");
          // console.log(DestinatariosList);
        }, 1000);

        reset({
          CodEstabDestino: "",
          DocAduaneroUnico: "",
          MotivoTraslado: "",
          Ruta: "",
        });

        if (res.retorno !== "" && res.retorno !== undefined) {
          if (
            res.retorno.docElectronico.idEstadoDocumentoElectronico.toUpperCase() !==
            "1777AA9D-FA14-49BD-B9F0-ECE6D2952756"
          ) {
            setErrorSecuencialState(2);
          } else {
            setErrorSecuencialState(1);
          }
        }
      }
    } catch (ex) {
      console.log(ex);
      setErrorSecuencialState(3);
    } finally {
      setIsLoading(false);
      document.getElementById("searchFacturaModalGuia").click();
    }
  };

  const handleComprobarComprobante = async () => {
    try {
      let datas = getValues().secuencialFactura.split("-");
      let res = await getListV4(
        empresa,
        "ComprobarAutorizado",
        `?establecimiento=${Number(datas[0])}&puntoEm=${Number(
          datas[1]
        )}&secuencial=${Number(datas[2])}`
      );
      if (res.status === 200) {
        setErrorSecuencialState(1);
      } else if (res.status === 206) {
        setErrorSecuencialState(2);
      } else {
        setErrorSecuencialState(3);
      }
    } catch (ex) {
     // console.log(ex);
      setErrorSecuencialState(3);
    }
  };

  return (
    <LargeModalForm
      buttonModal={"searchFacturaModalGuia"}
      IsLoading={IsLoading}
      submitText={"Agregar"}
      title="AGREGAR FACTURA"
      submitModal={handleSubmit(handleSubmitModal)}
    >
      <div className="w-full mb-2 py-4 md:mb-0 mt-5 grid md:grid-cols-3 md:gap-6">
        <div className="w-full col-span-2">
          <NumberFormat
            format="###-###-#########"
            id="secuencialFactura"
            name="secuencialFactura"
            placeholder=" "
            className={errors.secuencialFactura ? StyleInputError : StyleInput}
            {...register("secuencialFactura", {
              required: true,
            })}
            onChange={(e) => setValue("secuencialFactura", e.target.value)}
            value={getValues().secuencialFactura}
          />
          <label
            htmlFor="secuencialFactura"
            className={errors.secuencialFactura ? StyleLabelError : StyleLabel}
          >
            Secuencial comprobante
          </label>
          {renderMessage(ErrorSecuencialState)}
        </div>
        <div className="mb-2 w-full group">
          <button
            onClick={handleComprobarComprobante}
            type="button"
            className="  text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2 text-center dark:bg-green-600 dark:focus:ring-green-800 dark:hover:bg-green-700"
          >
            Comprobar
          </button>
        </div>
      </div>
      <div className="grid md:grid-cols-4 md:gap-4 my-2">
        <div className="col-span-2">
          <input
            type="text"
            id="motivoTraslado"
            name="motivoTraslado"
            placeholder=" "
            className={errors.motivoTraslado ? StyleInputError : StyleInput}
            {...register("motivoTraslado", {
              required: true,
            })}
          />
          <label
            htmlFor="motivoTraslado"
            className={errors.motivoTraslado ? StyleLabelError : StyleLabel}
          >
            MOTIVO TRASLADO
          </label>
        </div>
        <div className="col-span-2">
          <input
            type="text"
            id="direcDestino"
            name="direcDestino"
            placeholder=" "
            className={errors.direcDestino ? StyleInputError : StyleInput}
            {...register("direcDestino", {
              required: true,
            })}
          />
          <label
            htmlFor="direcDestino"
            className={errors.direcDestino ? StyleLabelError : StyleLabel}
          >
            DIR DESTINO
          </label>
        </div>
        <div>
          <input
            type="text"
            id="rutaDestino"
            name="rutaDestino"
            placeholder=" "
            className={errors.rutaDestino ? StyleInputError : StyleInput}
            {...register("rutaDestino", {
              required: true,
            })}
          />
          <label
            htmlFor="rutaDestino"
            className={errors.rutaDestino ? StyleLabelError : StyleLabel}
          >
            RUTA DESTINO
          </label>
        </div>
        <div>
          {/* <input
            type="text"
            id="estbDestino"
            name="estbDestino"
            placeholder=" "
            className={errors.estbDestino ? StyleInputError : StyleInput}
            {...register("estbDestino", {
              maxLength: 3,
            })}
          /> */}
          {/* <NumberFormat
            format="###"
            id="estbDestino"
            name="estbDestino"
            className={errors.estbDestino ? StyleInputError : StyleInput}
           
           
          /> */}

          <input
            type="text"
            id="estbDestino"
            name="estbDestino"
            maxLength={3}
            className={errors.estbDestino ? StyleInputError : StyleInput}
            onInput={handleChange}
            value={inputValue}
            {...register("estbDestino", {
              required: true,
              minLength: {
                value: 3,
                message: "No más de 3 carácteres!",
              },
              maxLength: {
                value: 3,
                message: "No más de 3 carácteres!",
              },
            })}
          />
          <label
            htmlFor="estbDestino"
            className={errors.estbDestino ? StyleLabelError : StyleLabel}
          >
            ESTABL. DESTINO
          </label>
        </div>
        <div>
          <input
            type="text"
            id="docAduanero"
            name="docAduanero"
            placeholder=" "
            className={errors.docAduanero ? StyleInputError : StyleInput}
            {...register("docAduanero")}
          />

          <label
            htmlFor="docAduanero"
            className={errors.docAduanero ? StyleLabelError : StyleLabel}
          >
            DOC. ADUANERO
          </label>
        </div>
      </div>
    </LargeModalForm>
  );
};

export default BuscarFacturaModal;
