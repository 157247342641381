import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import { getListV2 } from "../../services";
import { StyleInputDisabled } from "../Constantes";
import MesageError from "./MessageError";
import { useFormContext } from "react-hook-form";
import InputText from "./common/InputText";
import InputNumber from "./common/InputNumber";
import { SectionFilter } from "./SectionFilter";
import { Transition } from "@headlessui/react";

const ContribuyenteUseForm = ({ IsShowConsumFinal, Totales,  setConsumidorFinalToggle,
  ConsumidorFinalToggle }) => {
  const empresa = useSelector((store) => store.empresa.empresa);

  const [BuscarCliente, setBuscarCliente] = useState(false);
  const [BuscarClienteIndetificacion, setBuscarClienteIndetificacion] =
    useState(false);

  const [BuscarClienteRazonSocial, setBuscarClienteRazonSocial] = useState([]);
  const [BuscarClienteRazonSocialText, setBuscarClienteRazonSocialText] =
    useState("");

  const {
    setValue,
    handleSubmit,
    formState: { errors },
    watch,
  } = useFormContext();

  const clienteTelfObs = watch("clienteTelefono");
  const clienteIdentificacionObs = watch("clienteIdentificacion");

  useEffect(() => {
    if (clienteIdentificacionObs === "9999999999999" && !IsShowConsumFinal) {
      setConsumidorFinalToggle(true);
    }
  }, [clienteIdentificacionObs, IsShowConsumFinal]);

  const handleOnclick = (e) => {
    // solo se muestra mientras no se este buscando por cedula
    if (!BuscarClienteIndetificacion) {
      setBuscarCliente(!BuscarCliente);
    }
  };

  useEffect(() => {
    const timeOutId = setTimeout(
      () => handleSearchRazonSocial(BuscarClienteRazonSocialText),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [BuscarClienteRazonSocialText]);

  const handleSearchRazonSocial = async (param) => {
    if (param) {
      if (param.trim() !== "") {
        if (param.trim().length > 2) {
          const data = await getListV2(
            empresa,
            "GetContribuyenteByRazonSocial",
            `?razonSocial=${param.trim()}&isActive=1&empresaId=${
              empresa.idEmpresa
            }`
          );
          setBuscarClienteRazonSocial(data._embedded);
        }
      }
    }
  };

  const handleOnClickCliente = (cliente, index) => {
    setBuscarClienteIndetificacion(false);
    setBuscarCliente(false);
    setBuscarClienteRazonSocialText("");
    setBuscarClienteRazonSocial([]);
    setValue("clienteIdentificacion", cliente.identificacion);
    searchByIdentification(cliente.identificacion);
  };

  const searchByIdentification = async (value) => {
    try {
      setBuscarCliente(false);
      let typeIdentification = 5;

      if (!value) return setValue("clienteIdentificacion", "");

      if (value.length === 10) typeIdentification = 1;
      if (value.length === 13) {
        value = value.substr(0, 10);
        typeIdentification = 2;
      }

      setBuscarClienteIndetificacion(true);
      const data = await getListV2(
        empresa,
        "SearchContribuyenteForForm",
        `?identificacion=${value.trim()}&tipoIdentificacion=${typeIdentification}`
      );

      setValue("clienteRazonSocial", data.razonSocial);
      setValue("clienteDireccion", data.direccion);
      setValue("clienteEmail", data.correo);
      setValue("clienteTelefono", data.telefonoCelular || "");
      setValue("idContribuyente", data.idContribuyente);
    } catch (err) {
      setValue("clienteRazonSocial", "");
      setValue("clienteDireccion", "");
      setValue("clienteEmail", "");
      setValue("clienteTelefono", "");
    } finally {
      handleSubmit();
      setBuscarClienteIndetificacion(false);
    }
  };

  useEffect(() => {
    if (ConsumidorFinalToggle) {
      setValue("clienteRazonSocial", "CONSUMIDOR FINAL");
      setValue("clienteIdentificacion", "9999999999999");
      setValue("clienteDireccion", "dasasasasasasasasasasasasas");
      setValue("clienteEmail", "saddsadawadssdasdadssaddas");
      setValue("clienteTelefono", "0999999999");
    } else {
      setValue("clienteRazonSocial", "");
      setValue("clienteDireccion", "");
      setValue("clienteIdentificacion", "");
      setValue("clienteEmail", "");
      setValue("clienteTelefono", "");
    }
  }, [ConsumidorFinalToggle]);

  useEffect(() => {
    if (ConsumidorFinalToggle && Totales.importeTotal > 50) {
      document.getElementById("ModalConsumAlertButton").click();
    }
  }, [Totales]);

  useEffect(() => {
    if (IsShowConsumFinal) {
      setConsumidorFinalToggle(false);
    }
  }, [IsShowConsumFinal]);
  const [isOpen,setIsOpen] = useState(true);
  return (
    <div className="w-full ">
      {ConsumidorFinalToggle && Totales.importeTotal < 50 ? (
        <>
          <div className="border  rounded-lg p">
            <div className="text-sm py-1.5  px-2 text-left bg-[#003B5B] dark:text-gray-50 dark:bg-gray-700 border-transparent rounded-t-lg font-medium leading-6 text-white flex justify-between">
              <h3>Contribuyente / Cliente</h3>

              <div className="flex space-x-1">
                <h3>Consumidor final</h3>
                <input
                  className="ml-2"
                  type="checkbox"
                  name="consumidorFinal"
                  checked={ConsumidorFinalToggle}
                  onChange={(e) => setConsumidorFinalToggle(e.target.checked)}
                  id="consumidorFinal"
                />
                <svg
                   onClick={()=>setIsOpen(!isOpen)}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4 my-auto"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                />
              </svg>
              </div>
            </div>

            <Transition
        show={isOpen}
        enter="transition ease-out duration-300" // Aumenta la duración de entrada
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-300" // Aumenta la duración de salida
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
	   <div className=" grid lg:grid-cols-5 gap-2 md:grid-cols-3 grid-cols-1 p-2">
              {/* <div className="grid md:grid-cols-2 md:gap-1"> */}
              <div className="relative  w-full  ">
                <div className="relative z-0  w-full ">
                  <label className="block text-[0.813rem] font-medium text-black dark:text-white">
                    Ruc / Cédula: *{" "}
                  </label>
                  <input
                    type="text"
                    className={StyleInputDisabled}
                    name="clienteIdentificacion"
                    value={"9999999999999"}
                    disabled
                  />
                </div>
              </div>
              <div className="relative z-0  w-full  ">
                <label
                  htmlFor="company-website"
                  className="block text-[0.813rem] font-medium text-black dark:text-white"
                >
                  {" "}
                  Razón social: *{" "}
                </label>
                <input
                  type="text"
                  className={StyleInputDisabled}
                  value={"CONSUMIDOR FINAL"}
                  disabled
                />
              </div>
              {/* </div> */}
              {/* <div className="grid grid-cols-6 gap-1 w-full"> */}
              <div className="">
                <label className="block text-[0.813rem] font-medium text-black dark:text-white">
                  Correo:{" "}
                </label>
                <input type="text" className={StyleInputDisabled} disabled />
              </div>
              <div className=" ">
                <label className="block text-[0.813rem] font-medium text-black dark:text-white">
                  Teléfono:{" "}
                </label>
                <NumberFormat
                  format="###-###-####"
                  className={StyleInputDisabled}
                  value={""}
                  disabled
                />
              </div>
              <div className="">
                <label className="block text-[0.813rem] font-medium text-black dark:text-white">
                  Dirección:{" "}
                </label>
                <input
                  type="text"
                  value={""}
                  className={StyleInputDisabled}
                  disabled
                />
              </div>
              {/*  </div> */}
            </div>
	  
	  
	   </Transition>
           
          </div>
        </>
      ) : (
        <>
          <div className="border rounded-lg   border-gray-300 dark:border-gray-600">
            <div
              className="text-sm py-1.5  px-2 border text-left bg-[#003B5B] 
            dark:text-gray-50 dark:bg-gray-700 border-transparent rounded-t-lg 
            font-medium leading-6 text-white flex justify-between"
            >
              <h3>Contribuyente / Cliente</h3>
              {IsShowConsumFinal ? (
                <></>
              ) : (
                <div className="flex space-x-1">
                  <h3>Consumidor final</h3>
                  <input
                    className="ml-2"
                    type="checkbox"
                    name="consumidorFinal"
                    checked={ConsumidorFinalToggle}
                    onChange={(e) => setConsumidorFinalToggle(e.target.checked)}
                    id="consumidorFinal"
                  />
                   <svg
                   onClick={()=>setIsOpen(!isOpen)}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
              
                className={`w-4 h-4 my-auto lg:hidden md:hidden duration-200 ${isOpen ? "rotate-180" : ""}`}

              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                />
              </svg>
                </div>
              )}
             
            </div>
            <Transition
        show={isOpen}
        enter="transition ease-out duration-300" // Aumenta la duración de entrada
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-300" // Aumenta la duración de salida
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
	  <div className=" grid md:grid-cols-5 gap-3 mb-0 sm:grid-cols-1 p-2 ">
              {/* <div className="grid md:grid-cols-2 md:gap-1"> */}

              <div className="relative  w-full group ">
                <InputText
                  title={"Ruc / Cédula: *"}
                  maxLength="20"
                  name={"clienteIdentificacion"}
                  placeholder="Digite el RUC / Cédula"
                  onBlur={(e) => searchByIdentification(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      searchByIdentification(e.target.value);
                    }
                  }}
                />
                <button
                  onClick={handleOnclick}
                  type="button"
                  id="dropdownSearchButton"
                  data-dropdown-toggle="dropdownSearch"
                  data-dropdown-placement="bottom"
                  className="absolute inset-y-0 right-0  items-center pl-3 text-sm text-gray-900 pt-4  border border-r-0 border-t-0 border-l-0 border-b-0 border-gray-300  dark:text-gray-400 dark:border-gray-600"
                >
                  {BuscarClienteIndetificacion ? (
                    <>
                      <svg
                        aria-hidden="true"
                        role="status"
                        className="inline mr-3 w-4 h-4 animate-spin"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="#E5E7EB"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentColor"
                        />
                      </svg>
                    </>
                  ) : (
                    <>
                      <svg
                        className="inline mr-2 -ml-1 w-5 h-5 dark:stroke-white dark:hover:stroke-blue-600  "
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        ></path>
                      </svg>
                    </>
                  )}
                </button>

                {errors.clienteIdentificacion ? (
                  <MesageError message={errors.clienteIdentificacion} />
                ) : null}

                <div
                  id="dropdownSearch"
                  className={`${
                    BuscarCliente ? "block" : "hidden"
                  }  bg-white rounded shadow dark:bg-gray-700 absolute w-full`}
                  style={{ zIndex: "1000" }}
                >
                  <div className="p-3">
                    <label htmlFor="input-group-search" className="sr-only">
                      Buscar
                    </label>
                    <div className="relative">
                      <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <svg
                          className="w-5 h-5 text-gray-500 dark:text-gray-400"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <input
                        type="text"
                        id="razonSocialSearch"
                        name="razonSocialSearch"
                        value={BuscarClienteRazonSocialText}
                        onChange={(e) =>
                          setBuscarClienteRazonSocialText(e.target.value)
                        }
                        onBlur={(e) =>
                          setBuscarClienteRazonSocialText(e.target.value)
                        }
                        className="block p-2 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Digite Razón Social / Nombre"
                      />
                    </div>
                  </div>
                  <ul
                    className="overflow-y-auto px-3 pb-3 h-48 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownSearchButton"
                  >
                    {BuscarClienteRazonSocial.length === 0 ? (
                      <li key="01">
                        <div className="text-sm mt-1 text-yellow-400 dark:text-yellow-400 inline-flex">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                          <div style={{ position: "relative" }}>
                            <span className="font-semibold">
                              No se encontraron datos
                            </span>
                          </div>
                        </div>
                      </li>
                    ) : null}
                    {BuscarClienteRazonSocial.map((el, index) => (
                      <li key={index}>
                        <div
                          onClick={() =>
                            handleOnClickCliente(
                              { identificacion: el.identificacion },
                              1
                            )
                          }
                          className="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                            />
                          </svg>
                          <label
                            htmlFor="checkbox-item-11"
                            className="py-2 ml-2 w-full text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                          >
                            {el.identificacion} - {el.razonSocial}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div className="flex items-center p-3 text-sm font-medium text-red-600 bg-gray-50 border-t border-gray-200 dark:border-gray-600  dark:bg-gray-700  dark:text-red-500 ">
                    {/*<svg className="mr-1 w-5 h-5" ariaHidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 6a3 3 0 11-6 0 3 3 0 016 0zM14 17a6 6 0 00-12 0h12zM13 8a1 1 0 100 2h4a1 1 0 100-2h-4z"></path></svg>
Delete user*/}
                  </div>
                </div>
              </div>
              <div className="relative  mb-0 w-full group ">
                <InputText
                  title={"Razón social: *"}
                  maxLength="300"
                  name={"clienteRazonSocial"}
                />
              </div>
              {/* </div> */}

              {/* <div className="grid lg:grid-cols-6 md:grid-cols-6 grid-cols-1 gap-1 w-full"> */}
              <div className="relative  mb-0 w-full group ">
                <InputText title={"Correo: *"} name={"clienteEmail"} />
              </div>
              <div className="relative  mb-2 w-full group ">
                <InputNumber
                  name={"Teléfono:"}
                  format="###-###-####"
                  identifier={"clienteTelefono"}
                  value={clienteTelfObs}
                />
              </div>
              <div className="relative  mb-0 w-full group ">
                <InputText
                  title={"Dirección: *"}
                  maxLength="300"
                  name={"clienteDireccion"}
                />
              </div>
              {/*  </div> */}
            </div>
	  
	  
	   </Transition>
     
          </div>
        </>
      )}
    </div>
  );
};

export default ContribuyenteUseForm;
