import React from "react";
import EmailsModal from "./EmailsModal";
import FormFactura from "./FormFactura";
import ListaFacturas from "./ListaFacturas";
import ModalAnular from "./ModalAnular";
import ModalAutorizar from "./ModalAutorizar";
import ModalLoading from "./ModalLoading";
import { ModalTrazabilidad } from "./ModalTrazabilidad";
import ReporteFacturaProvider from "./ReporteFacturaProvider";

const ReporteEmitidosGeneralUsuario = () => {
  return (
    
      <div className="  mx-4 my-3  ">
        <ReporteFacturaProvider>
          <FormFactura />
          <ListaFacturas />
          <ModalTrazabilidad />
          <EmailsModal />
          <ModalAnular />
          <ModalAutorizar />
        </ReporteFacturaProvider>
      </div>
    
  );
};

export default ReporteEmitidosGeneralUsuario;
