
  export const PlanRuc = [
    {
      text: "Cédula de identidad (escaneada PDF).",
    },
    {
      text: "Foto solo del rostro con cédula en mano.",
    },
    {
      text: "Ruc de la persona natural (escaneada PDF).",
    },
    {
      text: "Llenar el formulario y firmar la autorización de firma (escaneada PDF).",
    },
    
  ];
  