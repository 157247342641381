import React, { useEffect, createContext, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getListV2 } from "../../../services";

export const ReporteContribuyentesContext = createContext();

const ReporteContribuyentesProvider = (props) => {
  const empresa = useSelector((store) => store.empresa.empresa);
  const [start, setStart] = useState(0);

  const initialStatePaginacion = {
    pagina: 1,
    _totalpaginas: 1,
    _totalelementos: 0,
    _links: [],
    _inicioRegistro: 0,
    _totalEnPagina: 0,
  };

  const [documentos, setDocumentos] = useState([]);
  const [Paginacion, setPaginacion] = useState(initialStatePaginacion);
  const [cargandoData, setCargandoData] = useState(false);
  const [ActivarModalAdd, setActivarModalAdd] = useState(false);
  const [ActivarModalEdit, setActivarModalEdit] = useState(false);
  const [InfoEmail, setInfoEmail] = useState("");

  const initialState = {
    Identificacion: "",
    RazonSocial: "",
    Direccion: "",
    TelefonoCelular: "",
    TelefonoConvencional: "",
    Correo: "",
    Activo: "True",
    IsTransportista: "False",
    Placa: "",
    IsPartRel: "False",
  };
  const [NewItem, setNewItem] = useState(initialState);

  const formik = useFormik({
    initialValues: {
      estado: "True",
      identificacion: "",
      razonsocial: "",
      nombre: "",
      cantidad: 50,
      Correo: ""
    },
    // validationSchema: Yup.object({
    //     username: Yup.string()
    //         .max(128, 'Tamaño del campo 20'),
    //     nombre: Yup.string()
    //         .max(128, 'Tamaño del campo 20'),
    // }),
    onSubmit: async (obj) => {
      try {
        setCargandoData(true);

        await fetchData(
          obj.estado,
          obj.identificacion,
          obj.razonsocial,
          obj.Correo,
          obj.cantidad
        );

        setCargandoData(false);
      } catch (error) {
      //  console.log(error);
      }

      setCargandoData(false);
    },
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (
    estado = "True",
    identificacion = "",
    razonsocial = "",
    correo = "",
    cantidad = 50
  ) => {
    try {
        if(identificacion.length === 13) {
            identificacion = identificacion.slice(0,10);
        }

      const res = await getListV2(
        empresa,
        "GetContribuyente",
        `?estado=${estado}&start=${start}&identificacion=${identificacion.trim("")}&razonsocial=${razonsocial}&Correo=${correo}&activo=${estado}&Pagina=${Paginacion.pagina}&Cantidad=${cantidad}`
      );

      if (!(res === undefined)) {
        setPaginacion({
          ...Paginacion,
          _totalpaginas: res._totalpaginas,
          _totalelementos: res._totalelementos,
          _links: res._links,
          _inicioRegistro: res._inicioRegistro,
          _totalEnPagina: res._totalEnPagina,
        });

        setDocumentos(res._embedded);
      }
    } catch (error) {
      setPaginacion(initialStatePaginacion);
      setDocumentos([]);
      setCargandoData(false);
    
     // console.log(error);
     
    }
  };

  return (
    <ReporteContribuyentesContext.Provider
      value={{
        start,
        setStart,
        documentos,
        cargandoData,
        formik,
        ActivarModalAdd,
        setActivarModalAdd,
        ActivarModalEdit,
        setActivarModalEdit,
        NewItem,
        setNewItem,
        initialState,
        Paginacion,
        setPaginacion,
        initialStatePaginacion,
        setInfoEmail,
        InfoEmail
      }}
    >
      {props.children}
    </ReporteContribuyentesContext.Provider>
  );
};

export default ReporteContribuyentesProvider;