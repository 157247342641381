export default function CardDasboard({
  // Props
  text,
  description,
  link,
}) {
  return (
    <>
      <div className="border rounded-lg dark:bg-gray-800 shadow-md h-full lg:px-10 md:px-10 py-5 dark">
        <div className="relative flex items-center space-x-4">
          <div className="p-2 bg-[#E5F4FD] dark:bg-gray-700 rounded-lg max-content ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-10 dark:text-white"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M3 12l3 0" />
              <path d="M12 3l0 3" />
              <path d="M7.8 7.8l-2.2 -2.2" />
              <path d="M16.2 7.8l2.2 -2.2" />
              <path d="M7.8 16.2l-2.2 2.2" />
              <path d="M12 12l9 3l-4 2l-2 4l-3 -9" />
            </svg>
          </div>
          <div>
            <p className="font-semibold dark:text-white">{text}</p>
          </div>
        </div>
        <div className="my-2 flex-col space-y-4">
          <p className="dark:text-white">{description}</p>
          <a
            href={link}
            target="_blank"
            className="text-[#044C74] dark:text-white"
          >
            Saber mas...
          </a>
        </div>
      </div>
    </>
  );
}
