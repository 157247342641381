import React, { createContext, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { post } from "../../../services";
import {
  getFechaFin,
  getFechaInicio,
  getFechaInicioApi,
  rellenarCeros,
} from "../../../services/Utilitario";
import moment from "moment";
import { toast } from "react-toastify";
import { TextMessageCom } from "../../../features/components/TextMessageCom";
import { useHours } from "../../../features/hooks/useHours";
import { useNavigate } from "react-router";

export const EmitirGuiaRemisionContext = createContext();

const EmitirGuiaRemisionProvider = (props) => {
  const empresa = useSelector((store) => store.empresa.empresa);

  const [InformacionAdicionalList, setInformacionAdicionalList] = useState([]);
  const [FormaPagoList, setFormaPagoList] = useState([]);
  const [saveData, setSaveData] = useState(false);
  const [saveDataMessage, setSaveDataMessage] = useState("");
  const [saveDataSvg, setSaveDataSvg] = useState(0);
  const [ObjSaveData, setObjSaveData] = useState({});
  const [DestinatariosList, setDestinatariosList] = useState([]);
  const [DetalleList, setDetalleList] = useState([]);
  const [DataFromAdd, setDataFromAdd] = useState([]);

  const [ActivarModalDetalle, setActivarModalDetalle] = useState(false);
  const [Activacion, setActivacion] = useState(true);
  const [ItemDetalle, setItemDetalle] = useState({});
  const [changeDetalle, setChangeDetalle] = useState("");
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      establecimiento: "",
      puntoEmision: "",
      secuencial: "",
      fechaEmision: getFechaInicioApi(),
      transportistaIdentificacion: "",
      transportistaRazonSocial: "",
      transportistaDireccion: "",
      transportistaEmail: "",
      transportistaTelefono: "",
      fechaInicio: getFechaInicio(),
      fechaFin: getFechaFin(),
      placa: "",
      tipoComprobante: "00",
      secuencialComprobante: "",
      puntoPartidaComprobante: "",
      DestinoComprobante: "",
      tipoIdentificacionTransportista: "",
      IdTransportista: "",
      motivoTrasladoComprobante: "",
      rutaComprobante: "",
      DocumentoAduaneroComprobante: "",
      establecimientoDestino: "",
      numAutDocSustento: "",
    },
    validationSchema: Yup.object({
      /*establecimiento: Yup.string()
                .max(3, 'Tamaño del campo 3'),
            puntoEmision: Yup.string()
                .max(3, 'Tamaño del campo 3'),*/
      secuencial: Yup.string().max(9, "Tamaño del campo 9"),
      GuiaRemision: Yup.string(),
      transportistaIdentificacion: Yup.string()
        .required("Este campo es requerido")
        .max(300),
      transportistaRazonSocial: Yup.string()
        .required("Este campo es requerido")
        .max(300),
      transportistaDireccion: Yup.string()
        .required("Este campo es requerido")
        .max(300),
      transportistaEmail: Yup.string()
        .required("Este campo es requerido")
        .max(300),
      placa: Yup.string().required("Este campo es requerido").max(20),
      // transportistaTelefono: Yup.string().max(20),
      // secuencialComprobante: Yup.string().max(15),
      puntoPartidaComprobante: Yup.string().required().max(300),
      DestinoComprobante: Yup.string().max(300),
      motivoTrasladoComprobante: Yup.string().max(300),
      rutaComprobante: Yup.string().max(300),
      DocumentoAduaneroComprobante: Yup.string().max(20),
      establecimientoDestino: Yup.string().max(3),
      numAutDocSustento: Yup.string().max(15),
    }),
    onSubmit: async (obj) => {
      const destinatariosConDetalles = DestinatariosList.every(
        (x) => x.detalles.length > 0
      );

      if (!destinatariosConDetalles) {
        toast.error(
          "Falta agregar detalle al documento al destinatario " +
            DestinatariosList.find((x) => x.detalles.length < 1)
              .RazonSocialDestinatario
        );
        return;
      }

      setSaveDataSvg(0);
      if (isOver72Hours) {
        setSaveDataMessage(<TextMessageCom />);
      } else {
        setSaveDataMessage("¿ESTÁ SEGURO DE QUE DESEA ENVIAR AL SRI?");
      }
      setSaveData(true);
      //console.log('---------------------')
      //console.log(obj)
      setObjSaveData(obj);
    },
  });

  const [isOpenModalHours, setisOpenModalHours] = useState(false);

  const toggleModal = () => {
    setisOpenModalHours(!isOpenModalHours);
  };

  const { isOver72Hours } = useHours({
    fechaActual: getFechaInicio(),
    FechaInput: formik.values.fechaInicio,
    toggleModal,
  });

  const guardarDocumento = async () => {
    debugger;
    setSaveDataSvg(1);
    setSaveDataMessage("PROCESANDO...");

    if (DestinatariosList.length) {
      try {
        if (DestinatariosList.length > 0) {
          const documento = {
            IdEmpresa: empresa.idEmpresa,
            RucEmpresa: empresa.ruc,
            Establecimiento: ObjSaveData.establecimiento,
            PuntoEmision: ObjSaveData.puntoEmision,
            Secuencial: ObjSaveData.secuencial,
            Ambiente: process.env.REACT_APP_ENVIRONMENT,
            NumAutDocSustento: ObjSaveData.numAutDocSustento,
            FechaEmision: ObjSaveData.fechaInicio,
            FechaInicioTransporte: ObjSaveData.fechaInicio,
            FechaFinTransporte: ObjSaveData.fechaFin,
            TipoIdentificacionTransportista:
              ObjSaveData.tipoIdentificacionTransportista,
            RucTransportista: ObjSaveData.transportistaIdentificacion,
            RazonSocialTransportista: ObjSaveData.transportistaRazonSocial,
            CorreoTransportista: ObjSaveData.transportistaEmail
              .replace(/,/g, ";")
              .replace(/\s+/g, ""),
            IdTransportista: ObjSaveData.IdTransportista,
            Placa: ObjSaveData.placa,
            DirPartida: ObjSaveData.puntoPartidaComprobante,
            destinatario: [...DestinatariosList],
            infoAdicional: InformacionAdicionalList.map((x) => {
              return {
                Nombre: x.nombre,
                Valor: x.valor,
              };
            }),
          };

          if (ObjSaveData.transportistaEmail !== null)
            if (ObjSaveData.transportistaEmail.trim() !== "")
              documento.infoAdicional.push({
                Valor: ObjSaveData.transportistaEmail
                  .replace(/,/g, ";")
                  .replace(/\s+/g, ""),
                Nombre: "email",
              });

          const Establecimiento = ObjSaveData.establecimiento;
          const PuntoEmision = ObjSaveData.puntoEmision;
          const Secuencial = Number(ObjSaveData.secuencial) + 1;
          await post("/api/GuiaRemision/Guardar", documento);

          formik.setFieldValue("establecimiento", Establecimiento);
          formik.setFieldValue("puntoEmision", PuntoEmision);

          formik.setFieldValue("secuencial", rellenarCeros(Secuencial, 9));

        formik.setFieldValue("transportistaIdentificacion", "");
        formik.setFieldValue("transportistaRazonSocial", "");
        formik.setFieldValue("transportistaDireccion", "");
        formik.setFieldValue("transportistaEmail", "");
              
          setFormaPagoList([]);
          setInformacionAdicionalList([]);
          setDestinatariosList([]);
          setItemDetalle({});
          setChangeDetalle("");

          setSaveDataSvg(2);
          setSaveDataMessage("EL DOCUMENTO SE GENERO EXITOSAMENTE");
        }
      } catch (error) {
        //  console.log(error);
        setSaveDataSvg(-1);
        setSaveDataMessage("ERROR AL GENERAR EL DOCUMENTO");
      }
    } else {
      setSaveDataSvg(-1);
      if (DestinatariosList.length < 1) {
        setSaveDataMessage("FALTA AGREGAR DETALLE AL DOCUMENTO");
      } else if (FormaPagoList.length < 1) {
        setSaveDataMessage("EL DOCUMENTO NO POSEE FORMA DE PAGO");
      }
    }
  };

  const handleResetDocument = () => {
    formik.resetForm();
    setItemDetalle({});
    setChangeDetalle("");
    setFormaPagoList([]);
    setInformacionAdicionalList([]);
    setDestinatariosList([]);
  };

  return (
    <EmitirGuiaRemisionContext.Provider
      value={{
        saveData,
        setSaveData,
        saveDataMessage,
        saveDataSvg,
        guardarDocumento,
        formik,
        FormaPagoList,
        setFormaPagoList,
        InformacionAdicionalList,
        setInformacionAdicionalList,
        ActivarModalDetalle,
        setActivarModalDetalle,
        Activacion,
        setActivacion,
        ItemDetalle,
        setItemDetalle,
        changeDetalle,
        setChangeDetalle,
        DestinatariosList,
        setDestinatariosList,
        handleResetDocument,
        DetalleList,
        DataFromAdd,
        isOpenModalHours,
        setisOpenModalHours,
        toggleModal,
        isOver72Hours,
      }}
    >
      {props.children}
    </EmitirGuiaRemisionContext.Provider>
  );
};

export default EmitirGuiaRemisionProvider;
