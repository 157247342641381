import { Transition } from "@headlessui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  StyleInput,
  StyleInputFormError,
} from "../../../../features/Constantes";
import moment from "moment";


export default function SubirFirma({
  InformacionFirma,
  setInformacionFirma,
  handleChangeFirma,
  TextUploadFirma,
  handleClickInfoFirma,
  showModal,
  setIsOpen3,
    isOpen3,
    register
}) {


  const [Pass, setPass] = useState(true);
  const handlesShow = () => {
    setPass(!Pass);
  };

  const {
   
    formState: { errors },
  } = useForm();

  return (
    <>
      <div className="relative w-full  ">
        <div className="bg-[#00314D] flex    lg:justify-start md:justify-start justify-between items-center p-3 rounded-t-lg text-center  text-white">
          <p>Firma Electrónica P12</p>
          <svg
            onClick={() => setIsOpen3(!isOpen3)}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4 lg:hidden md:hidden"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m19.5 8.25-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
        <Transition
          show={isOpen3}
          className={"h-auto"}
          enter="transition ease-out duration-300" // Aumenta la duración de entrada
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-300" // Aumenta la duración de salida
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="bg-white px-56 flex dark:bg-gray-700 h-full pt-2 rounded-b-lg  ">
          {/* <SolicitudFirma/> */}
            <div className="relative flex align-middle justify-center flex-col z-0  w-full group ">
              <div className="flex justify-around w-2/3 mx-auto  items-center flex-col">
                <label
                  htmlFor="dropzone-fileFirma"
                  className="flex flex-col justify-center items-center h-32 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 w-2/3"
                >
                  <div className="flex flex-col justify-center items-center w-full pt-5 pb-6">
                    <svg
                      aria-hidden="true"
                      className="mb-3 w-10 h-10 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      ></path>
                    </svg>
                    <p className="mb-2 text-sm text-gray-500 dark:text-white ">
                      <span className="font-semibold text-base dark:text-white">
                        Subir firma
                      </span>
                    </p>
                    <p className="text-gray-500 text-sm text-center dark:text-gray-400">
                      <b>{TextUploadFirma}</b>
                    </p>
                  </div>
                  <input
                    accept=".pfx,.p12"
                    id="dropzone-fileFirma"
                    onChange={handleChangeFirma}
                    type="file"
                    className="hidden"
                  />
                </label>
              </div>
              <div className="relative z-0  lg:flex mx-auto space-x-2 my-3 group">
                <div className="   ">
                  <label
                    htmlFor="company-website"
                    className="block text-sm font-medium text-gray-700 dark:text-white"
                  >
                    {" "}
                    Contraseña{" "}
                  </label>
                  <div className="relative">
                    <input
                      type={Pass ? "password" : "text"}
                      className={
                        errors.firmaElectronicaPass
                          ? StyleInputFormError
                          : StyleInput + " pr-10"
                      }
                      placeholder=" "
                      id="firmaElectronicaPass"
                      name="firmaElectronicaPass"
                      {...register("firmaElectronicaPass", {})}
                    />
                    <button
                      type="button"
                      onClick={handlesShow}
                      className="absolute  top-0 mr-1 right-0 bottom-0 duration-300 transition-colors"
                    >
                      {!Pass ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5  text-gray-600 "
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5  text-gray-600 "
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                          />
                        </svg>
                      )}
                    </button>
                  </div>
                </div>
                <Transition
                  show={showModal}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <div className="pt-4 my-auto  ">
                    <button
                      type="button"
                      className="mx-auto "
                      onClick={handleClickInfoFirma}
                      data-bs-toggle={"modal"}
                      data-bs-target={showModal && "#firmaInfoModal"}
                    >
                      <p
                        className={`py-2 px-5 rounded-md ${
                          showModal
                            ? "bg-[#04BC53] hover:bg-green-600"
                            : "bg-gray-300 cursor-not-allowed"
                        }   text-white text-sm font-medium`}
                      >
                        Validar certificado
                      </p>
                    </button>
                  </div>
                </Transition>

                {/* <p
                  className="mt-2 text-sm text-gray-500"
                  id="email-description"
                >
                  Digite la contraseña de su firma electrónica
                </p> */}
              </div>

              <div div className="text-center my-2 dark:text-white">
                {InformacionFirma !== null ? (
                  <>
                    <p className="text-sm">
                      <b>Vence el: </b>
                      {moment(InformacionFirma.fechaExpiracion).format("LLL")}
                    </p>
                    <p className="text-sm">
                      <b>Fue subida el: </b>
                      {moment(InformacionFirma.fechaModificacion).format("LLL")}
                    </p>
                  </>
                ) : (
                  <p className="text-sm">No hay firma subida!</p>
                )}{" "}
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </>
  );
}
