import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Logoo from "../../img/isologo.png";
import { NavLink } from "react-router-dom";
import { Oval, Vortex } from "react-loader-spinner";

import { post } from "../../services";

const Reset = () => {
  const { register, handleSubmit } = useForm();
  const [SubmitPass, setSubmitPass] = useState(false);

  const [HandleReset, setHandleReset] = useState(false);

  const [MessageError, setMessageError] = useState(null);

  const onSubmit = async (temp) => {
    try {
      setSubmitPass(true);
      temp.Dominio = window.location.href;
      // console.log(temp);

      await post("/api/cuentas/forgotpass", temp);
      setMessageError(null);
      setHandleReset(true);
    } catch (error) {
    //  console.log(error);
      if(error.request.status === 404) {
        setMessageError("Correo o identificacion incorrecta!");
      }
    }
    finally {
      setSubmitPass(false);
    }
  };

  return (
    <>
      {HandleReset ? (
        <div className="flex items-center justify-center min-h-screen bg-[#00314D]">
          <div className="w-full max-w-lg px-10 py-8 mx-auto bg-white rounded-lg shadow-xl">
            <div className="max-w-md mx-auto space-y-6">
              <center>
                <div className="inline-flex ">
                  <img
                    src={Logoo}
                    className=" self-center w-16 float-left mr-2 block"
                    alt="asd"
                  />
                  <span
                    className=" my-4 block truncate dark:text-white text-3xl font-body"
                    style={{ fontWeight: 800 }}
                  >
                    Intuito S.A.
                  </span>
                </div>
              </center>
              <div className="font-sans text-center text-gray-500">
                <label className="">Recuperación de la cuenta</label>
                <br></br>
                <label className="">
                  Hemos enviado instrucciones para cambiar tu contraseña a tu{" "}
                  <b>correo electrònico </b> . Revisa la bandeja de entrada y la
                  carpeta de spam.
                </label>
              </div>
            </div>
            <div className="pt-5">
              <NavLink exact="true" to="/login">
                <button
                  type="button"
                  className="sm:w-full  md:w-full lg:w-full py-4 px-8  text-white bg-blue-500 rounded-lg shadow-lg hover:bg-blue-900 focus:ring-4 focus:ring-red-100 focus:outline-none"
                >
                  Regresar
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center min-h-screen bg-[#00314D]">
          <div className="w-full max-w-lg px-10 py-8 mx-auto bg-white rounded-lg shadow-xl">
            <div className="max-w-md mx-auto space-y-6">
              <center>
                <div className="inline-flex ">
                  <img
                    src={Logoo}
                    className=" self-center w-16 float-left mr-2 block"
                    alt="asd"
                  />
                  <span
                    className=" my-4 block truncate dark:text-white text-3xl font-body"
                    style={{ fontWeight: 800 }}
                  >
                    Intuito S.A.
                  </span>
                </div>
              </center>

              <div className="font-sans text-center text-gray-500">
                <label className="">Recuperación de la cuenta</label>
                <br></br>
                <label className="">
                  Ingresa tu <b>correo electrónico</b> para reestablecer tu
                  contraseña.
                </label>
              </div>
              {MessageError && <p className="text-center text-red-500">{MessageError}</p>}
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="">
                  <label className="">Correo Eléctronico</label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        className="w-6 h-6 fill-gray-500"
                      >
                        <path d="M504 232C504 95.751 394.053 8 256 8 118.94 8 8 118.919 8 256c0 137.059 110.919 248 248 248 52.926 0 104.681-17.079 147.096-48.321 5.501-4.052 6.423-11.924 2.095-17.211l-15.224-18.597c-4.055-4.954-11.249-5.803-16.428-2.041C339.547 442.517 298.238 456 256 456c-110.28 0-200-89.72-200-200S145.72 56 256 56c109.469 0 200 65.02 200 176 0 63.106-42.478 98.29-83.02 98.29-19.505 0-20.133-12.62-16.366-31.463l28.621-148.557c1.426-7.402-4.245-14.27-11.783-14.27h-39.175a12.005 12.005 0 0 0-11.784 9.735c-1.102 5.723-1.661 8.336-2.28 13.993-11.923-19.548-35.878-31.068-65.202-31.068C183.412 128.66 120 191.149 120 281.53c0 61.159 32.877 102.11 93.18 102.11 29.803 0 61.344-16.833 79.749-42.239 4.145 30.846 28.497 38.01 59.372 38.01C451.467 379.41 504 315.786 504 232zm-273.9 97.35c-28.472 0-45.47-19.458-45.47-52.05 0-57.514 39.56-93.41 74.61-93.41 30.12 0 45.471 21.532 45.471 51.58 0 46.864-33.177 93.88-74.611 93.88z" />
                      </svg>
                    </div>
                    <input
                      type="email"
                      placeholder="Correo eléctronico"
                      className="
                          block 
                          w-full
                          px-4 py-2  
                          text-xl 
                          font-normal
                          form-control dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:border-blue-600 border-gray-500 text-gray-700 dark:bg-gray-700 dark:focus:text-white dark:border-gray-600  dark:placeholder-gray-400 dark:text-white focus:text-gray-700
                          pl-10  
                          border focus:outline-none
                          rounded-lg transition 
                         "
                      {...register("Email", {
                        required: {
                          value: true,
                          message: "Nombre es requerido",
                        },
                        maxLength: {
                          value: 300,
                          message: "No más de 30 carácteres!",
                        },
                        minLength: {
                          value: 5,
                          message: "Mínimo 5 carácteres",
                        },
                      })}
                    />
                  </div>
                  <label className="mt-5">Identificacion</label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z"
                        />
                      </svg>
                    </div>
                    <input
                      type="text"
                      placeholder="Identificacion"
                      className="
                          block 
                          w-full
                          px-4 py-2  
                          text-xl 
                          font-normal
                          form-control dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:border-blue-600 border-gray-500 text-gray-700 dark:bg-gray-700 dark:focus:text-white dark:border-gray-600  dark:placeholder-gray-400 dark:text-white focus:text-gray-700
                          pl-10  
                          border focus:outline-none
                          rounded-lg transition 
                         "
                      {...register("Identificacion", {
                        required: {
                          value: true,
                          message: "Nombre es requerido",
                        },
                        maxLength: {
                          value: 30,
                          message: "No más de 30 carácteres!",
                        },
                        minLength: {
                          value: 2,
                          message: "Mínimo 2 carácteres",
                        },
                      })}
                    />
                  </div>
                  <div className="pt-5">
                    {SubmitPass ? (
                      <button
                        className=" group inline-flex items-center justify-center sm:w-full  md:w-full lg:w-full py-4 px-8  text-white bg-blue-500 rounded-lg shadow-lg hover:bg-blue-900 focus:ring-4 focus:ring-red-100 focus:outline-none"
                        disabled
                      >
                        <Oval
                          height={18}
                          width={18}
                          color="#FFFFFF"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                          ariaLabel="oval-loading"
                          secondaryColor="#233876"
                          strokeWidth={2}
                          strokeWidthSecondary={2}
                        />
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className=" sm:w-full  md:w-full lg:w-full py-4 px-8  text-white bg-blue-500 rounded-lg shadow-lg hover:bg-blue-900 focus:ring-4 focus:ring-red-100 focus:outline-none"
                      >
                        Enviar
                      </button>
                    )}
                  </div>
                </div>

                <div className="pt-4">
                  <div className="font-light text-center text-gray-500">
                    ¿Ya tienes una cuenta?{" "}
                    <NavLink exact="true" to="/login">
                      <b className="text-blue-600">Iniciar sesión</b>
                    </NavLink>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Reset;
