import React from "react";
import { useSelector } from "react-redux";
import {
  StyleCardDasb,
  StyleSvg,
  StyleTextCardDasb

} from "../../features/Constantes";
import { div } from "react-router-dom";
const DashbSkeleton = () => {
  const permisos = useSelector((store) => store.permisosEmpresa.permisos);

  return (
    <div className="space-y-6 xl:mx-4 my-2">
      <div
        style={{ minHeight: "42rem" }}
        className="bg-white shadow pt-8  dark:bg-gray-900  sm:rounded-lg sm:p-6 animate-pulse"
      >
         <h3 className="text-2xl font-medium text-center leading-6 text-blue-600 dark:text-white select-none  bg-gray-300 w-96 h-8 rounded-lg mx-auto">
         
         </h3>
         <p className="mt-1 text-lg text-gray-500 text-center select-none bg-gray-300 w-52 h-8 mx-auto rounded-lg"></p>
         <ul className="grid grid-cols-1  gap-6 sm:grid-cols-1 pt-8 md:grid-cols-3 lg:grid-cols-4">

<li className={ StyleCardDasb }>
    <div exact="true" to="/emision/generar/factura">
    <div className="flex-1 flex flex-col p-8">
      
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className={`bg-gray-300 w-24 h-24 mx-auto rounded-full`}
      >
        
      </svg>

      <h3 className={StyleTextCardDasb +  `w-20 h-5 bg-gray-300 rounded-lg`}>
       
      </h3>
    </div>
    </div>
  </li>
  {/* <button className="transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300 ">
Save Changes
</button> */}
 
 <li className={ StyleCardDasb }>
    <div exact="true" to="/emision/generar/factura">
    <div className="flex-1 flex flex-col p-8">
      
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className={`bg-gray-300 w-24 h-24 mx-auto rounded-full`}
      >
        
      </svg>

      <h3 className={StyleTextCardDasb +  `w-20 h-5 bg-gray-300 rounded-lg`}>
       
      </h3>
    </div>
    </div>
  </li>

  <li className={ StyleCardDasb }>
    <div exact="true" to="/emision/generar/factura">
    <div className="flex-1 flex flex-col p-8">
      
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className={`bg-gray-300 w-24 h-24 mx-auto rounded-full`}
      >
        
      </svg>

      <h3 className={StyleTextCardDasb +  `w-20 h-5 bg-gray-300 rounded-lg`}>
       
      </h3>
    </div>
    </div>
  </li>
  <li className={ StyleCardDasb }>
    <div exact="true" to="/emision/generar/factura">
    <div className="flex-1 flex flex-col p-8">
      
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className={`bg-gray-300 w-24 h-24 mx-auto rounded-full`}
      >
        
      </svg>

      <h3 className={StyleTextCardDasb +  `w-20 h-5 bg-gray-300 rounded-lg`}>
       
      </h3>
    </div>
    </div>
  </li>
  <li className={ StyleCardDasb }>
    <div exact="true" to="/emision/generar/factura">
    <div className="flex-1 flex flex-col p-8">
      
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className={`bg-gray-300 w-24 h-24 mx-auto rounded-full`}
      >
        
      </svg>

      <h3 className={StyleTextCardDasb +  `w-20 h-5 bg-gray-300 rounded-lg`}>
       
      </h3>
    </div>
    </div>
  </li>
  <li className={ StyleCardDasb }>
    <div exact="true" to="/emision/generar/factura">
    <div className="flex-1 flex flex-col p-8">
      
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className={`bg-gray-300 w-24 h-24 mx-auto rounded-full`}
      >
        
      </svg>

      <h3 className={StyleTextCardDasb +  `w-20 h-5 bg-gray-300 rounded-lg`}>
       
      </h3>
    </div>
    </div>
  </li>
  <li className={ StyleCardDasb }>
    <div exact="true" to="/emision/generar/factura">
    <div className="flex-1 flex flex-col p-8">
      
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className={`bg-gray-300 w-24 h-24 mx-auto rounded-full`}
      >
        
      </svg>

      <h3 className={StyleTextCardDasb +  `w-20 h-5 bg-gray-300 rounded-lg`}>
       
      </h3>
    </div>
    </div>
  </li>

  <li className={ StyleCardDasb }>
    <div exact="true" to="/emision/generar/factura">
    <div className="flex-1 flex flex-col p-8">
      
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className={`bg-gray-300 w-24 h-24 mx-auto rounded-full`}
      >
        
      </svg>

      <h3 className={StyleTextCardDasb +  `w-20 h-5 bg-gray-300 rounded-lg`}>
       
      </h3>
    </div>
    </div>
  </li>
</ul>
      </div>
    </div>
  );
};

export default DashbSkeleton;