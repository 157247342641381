import { useContext, useState } from "react";
import { FormProvider } from "react-hook-form";
import { v4 as uuidv } from "uuid";
import { TransferenciasProviderContext } from "./Context/TransferenciasProvider";
import TransferenciasGeneral from "./TransferenciasGeneral";
import TransferenciasDetalle from "./TransferenciasDetalle";

export default function TransferenciasForm() {
  const { methods, rows, setRows, setIsOpen } = useContext(
    TransferenciasProviderContext
  );

  const [number, setNumber] = useState(1);
  const padNumber = (num, size) => {
    let s = "0000" + num;
    return s.substr(s.length - size);
  };

  const Agg = (data) => {
    data.IdIngreso = uuidv();
    data.Detalle = rows;
    setIsOpen(true);

    const existingData = JSON.parse(localStorage.getItem("Transacciones")) || [];
    const updatedData = [...existingData, data];
    localStorage.setItem("Transacciones", JSON.stringify(updatedData));
    setNumber(number + 1);

    methods.reset();
    setRows([
      {
        codigo: "",
        cuenta: "",
        cantidad: "",
        costo: "",
        lote: "",
        fechaCaducidad: "",
      },
    ]);
  };
  const DeleteData = () => {
    methods.reset();
    setRows([
      {
        codigo: "",
        cuenta: "",
        cantidad: "",
        costo: "",
        lote: "",
        fechaCaducidad: "",
      },
    ]);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(Agg)}>
          <div className="bg-white p-4">
            <div className="bg-gray-300 p-4 flex justify-between items-center">
              <p className="font-semibold">Transacción</p>
              <div className="flex items-center space-x-3">
                <p>Ingreso</p>
                <input
                  type="text"
                  placeholder="Número de transacción"
                  {...methods.register("numeroTransaccion")}
                  className="border border-gray-400 bg-[#EBEBEB] p-1 w-full"
                  value={`TRANS-${padNumber(number, 4)}`}
                />
              </div>
            </div>
          </div>
          <div className="p-4 bg-white">
            <TransferenciasGeneral />
            <TransferenciasDetalle />
            <div className="flex justify-end mt-4 space-x-3">
              <button
                onClick={() => DeleteData()}
                className="border p-2 px-3 border-gray-500 rounded-lg"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                  />
                </svg>
              </button>
              <button
                type="submit"
                className="p-3 rounded-lg  bg-[#04BC53] hover:bg-blue-700 pr-5 pl-5 text-white flex flex-row gap-2 items-center"
              >
                Guardar
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
}
