import React, { useContext, useState } from "react";
import IvaSection from "./IvaSection";
import RentaSection from "./RentaSection";
import FormaPagoSection from "./FormaPagoSection";
import { ModalAddImpuestoContext } from "./ModalAddImpuestoProvider";
import DividendosSection from "./DividendosSection";

const AdicionalesImpuesto = ({ getValues }) => {
  const { ShowDividendos } = useContext(ModalAddImpuestoContext);

  const [MostrarImpuestoRetencion, setMostrarImpuestoRetencion] =
    useState("RENTA");

  const styleInactivo =
    "inline-flex px-4 py-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group";
  const styleActivo =
    "inline-flex px-4 py-2 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group";

  return (
    <>
      <div className="border-b border-gray-200 dark:border-gray-700 justify-center">
        <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400  justify-center">
          <li className="mr-2">
            <button
              onClick={() => setMostrarImpuestoRetencion("RENTA")}
              className={
                MostrarImpuestoRetencion === "RENTA"
                  ? styleActivo
                  : styleInactivo
              }
            >
              <svg
                aria-hidden="true"
                className="mr-2 w-5 h-5 "
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
              </svg>
              RENTA
            </button>
          </li>
          <li className="mr-2">
            <button
              onClick={() => setMostrarImpuestoRetencion("IVA")}
              className={
                MostrarImpuestoRetencion === "IVA" ? styleActivo : styleInactivo
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
                  clipRule="evenodd"
                />
              </svg>
              IVA
            </button>
          </li>

          <li className="mr-2">
            <button
              onClick={() => setMostrarImpuestoRetencion("FormaPago")}
              className={
                MostrarImpuestoRetencion === "FormaPago"
                  ? styleActivo
                  : styleInactivo
              }
            >
              <svg
                aria-hidden="true"
                className="mr-2 w-5 h-5 "
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
              </svg>
              FORMA DE PAGO
            </button>
          </li>
          {ShowDividendos && (
            <li className="mr-2">
              <button
                onClick={() => setMostrarImpuestoRetencion("DIVIDENDOS")}
                className={
                  MostrarImpuestoRetencion === "DIVIDENDOS"
                    ? styleActivo
                    : styleInactivo
                }
              >
                <svg
                  aria-hidden="true"
                  className="mr-2 w-5 h-5 "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
                </svg>
                DIVIDENDOS
              </button>
            </li>
          )}
        </ul>
      </div>

      <div id="myTabContent" className=" ">
        {MostrarImpuestoRetencion === "RENTA" ? <RentaSection /> : null}
        {MostrarImpuestoRetencion === "IVA" ? <IvaSection /> : null}
        {MostrarImpuestoRetencion === "DIVIDENDOS" && ShowDividendos ? (
          <DividendosSection />
        ) : null}
        {MostrarImpuestoRetencion === "FormaPago" ? <FormaPagoSection /> : null}
      </div>
    </>
  );
};

export default AdicionalesImpuesto;
