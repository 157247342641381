import { useSelector } from "react-redux";
import ModalComponent from "../components/ModalComponets/ModalComponent";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

export const ModalSoporte = ({ isOpen, toggleModal }) => {
  const usuario = useSelector((store) => store.app.user);
  const empresa = useSelector((store) => store.empresa.empresa);
  const formContainerRef = useRef(null);

  const [isSend, setIsSend] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        if (formContainerRef.current) {
          const script = document.createElement("script");
          script.src =
            "https://intuitosa.youtrack.cloud/static/simplified/form/form-entry.js";
          script.async = true;
          script.setAttribute(
            "data-yt-url",
            "https://intuitosa.youtrack.cloud"
          );
          script.setAttribute("data-theme", "light");
          script.setAttribute("data-lang", "es");
          script.setAttribute("id", "4dc5cab8-b118-4205-91e2-5eba07c6f0c1");

          script.onload = () => {
            if (window.YTFeedbackForm) {
              window.YTFeedbackForm.getClientJSApi(
                "4dc5cab8-b118-4205-91e2-5eba07c6f0c1"
              )
                .then(async (form) => {
                  try {
                    const blocks = form.getBlocks();

                    const rucBlock = blocks.find(
                      (b) => b.title === "RUC Emisor"
                    );
                    if (rucBlock) {
                      await form.setBlockValue(rucBlock.id, empresa.ruc);
                      const rucDiv = document.querySelector(
                        'div[data-test="string"][data-test-title="RUC Emisor"]'
                      );
                      if (rucDiv) {
                        rucDiv.style.display = "none";
                      }
                    } else {
                      console.error(
                        "No se encontró el bloque para RUC Emisor."
                      );
                    }

                    const razonSocialBlock = blocks.find(
                      (b) => b.title === "Razón Social"
                    );
                    if (razonSocialBlock) {
                      await form.setBlockValue(
                        razonSocialBlock.id,
                        empresa.razonSocial
                      );
                      const razonSocialDiv = document.querySelector(
                        'div[data-test="string"][data-test-title="Razón Social"]'
                      );
                      if (razonSocialDiv) {
                        razonSocialDiv.style.display = "none";
                      }
                    } else {
                      console.error(
                        "No se encontró el bloque para Razón Social."
                      );
                    }

                    setTimeout(() => {
                      const button = document.querySelector(
                        'button[type="submit"]'
                      );
                      if (button) {
                        button.querySelector("span").textContent = "Enviar";
                        button.addEventListener("click", () => {
                          toggleModal();
                        });
                        button.addEventListener("click", () => {
                          setIsSend(true);
                        });
                      }

                      const summaryDiv = document.querySelector(
                        'div[data-test="summary"]'
                      );
                      if (summaryDiv) {
                        const label =
                          summaryDiv.querySelector("span.title__ba3d");
                        if (label) {
                          label.textContent = "Comentarios";
                        }
                      }

                      const descripcionDiv = document.querySelector(
                        'div[data-test="description"]'
                      );
                      if (descripcionDiv) {
                        const label =
                          descripcionDiv.querySelector("span.title__ba3d");
                        if (label) {
                          label.textContent = "Descripción";
                        }
                      }

                      const emailDiv = document.querySelector(
                        'div[data-test="email"]'
                      );
                      if (emailDiv) {
                        const label =
                          emailDiv.querySelector("span.title__ba3d");
                        if (label) {
                          label.textContent = "Dirección de correo electrónico";
                        }
                      }

                      const attachmentsDiv = document.querySelector(
                        'div[data-test="attachments-attach-file"]'
                      );
                      if (attachmentsDiv) {
                        const welcomeMessage = attachmentsDiv.querySelector(
                          ".c_welcomeMessage__bac"
                        );
                        if (welcomeMessage) {
                          welcomeMessage.textContent =
                            "Haga clic para cargar o arrastre archivos aquí";
                        }

                        const browseButton = attachmentsDiv.querySelector(
                          'button[data-test="ring-link"]'
                        );
                        if (browseButton) {
                          browseButton.textContent = "Buscar";
                        }
                      }

                      const textBlockDiv = document.querySelector(
                        'div[data-test="block textBlock"]'
                      );
                      if (textBlockDiv) {
                        const userName =
                          usuario.nombre[0].toUpperCase() +
                          usuario.nombre.slice(1).toLowerCase().split(" ")[0];
                        const p = textBlockDiv.querySelector("p");
                        if (p) {
                          p.innerHTML = `Hola, ${userName} ¿Cómo podemos ayudarte?`;
                        }
                      }
                    }, 1000);
                  } catch (error) {
                    console.error(
                      "Error al establecer los valores del formulario:",
                      error
                    );
                  }
                })
                .catch((error) => {
                  console.error(
                    "Error interactuando con la API del formulario:",
                    error
                  );
                });
            } else {
              console.error("YTFeedbackForm no está disponible.");
            }
          };

          formContainerRef.current.appendChild(script);

          return () => {
            if (formContainerRef.current.contains(script)) {
              formContainerRef.current.removeChild(script);
            }
          };
        } else {
          console.log(
            "No se encontró el elemento de referencia del formulario"
          );
        }
      }, 0);

      return () => clearTimeout(timer);
    }
  }, [isOpen, empresa, usuario]);

  useEffect(() => {
    setTimeout(() => {
      if (isSend) {
        const errorPossible = document.querySelector(
          'div[data-test="alert-container"]'
        );
        console.log(errorPossible);
        if (errorPossible) {
          errorPossible.style.display = "none";
          toast.error("Hubo un error enviando el formulario");
        } else {
          toast.success("Su solicitud fue enviada");
        }

        setIsSend(false);
      }
    }, 700);
  }, [isSend]);

  return (
    <ModalComponent title={""} isOpen={isOpen} onClose={toggleModal}>
      <div>
        <div id="render-formulario" ref={formContainerRef}></div>
      </div>
    </ModalComponent>
  );
};
