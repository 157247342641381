import { CrearBodegaItem } from "./CrearBodegaItem"
import { useCheckBodegas } from "./hooks/useCheckBodegas";
import { useContext } from "react";
import { BodegaContext } from "./context/BodegasContext";


export const CrearBodegaList = () => {
    const {bodegas} = useContext(BodegaContext)
    const CheckedHook = useCheckBodegas(bodegas)
    return (
        <>
            <div className="w-full bg-white h-fit p-12 shadow-md rounded-lg flex flex-col gap-8">
                <span className="ml-4">Mostrar <select className="p-1 rounded-md border-2 border-gray-500">
                    <option value="">50</option>
                </select> Registros</span>
                <table className="self-center w-full border-2 text-[12px]">
                    <thead >
                        <tr className="bg-gray-300 text-center">
                            <th style={{ padding: "15px" }}><input type="checkbox" checked={CheckedHook.IsCheckedAll} onChange={() => {
                                CheckedHook.setIsCheckedAll(!CheckedHook.IsCheckedAll)
                                CheckedHook.handleSelectAll()
                            }} /></th>
                            <th>Código</th>
                            <th>Nombre de la Bodega</th>
                            <th>Dirección</th>
                            <th>Establecimiento</th>
                            <th style={{ padding: "15px" }}>Editar</th>
                        </tr>
                    </thead>
                    <tbody>
                       {bodegas.length > 0 && bodegas.map(bodega => <CrearBodegaItem key={bodega.codigo} Bodega={bodega} bodegasChecked={CheckedHook.bodegasChecked} handleClick={CheckedHook.handleClick} />)}
                       {bodegas.length === 0 && <tr className="bg-white text-center font-normal">
                        <td colSpan={6} style={{padding: "15px"}} >No se encontraron datos para la busqueda</td>
                        </tr>}
                    </tbody>
                </table>
            </div>

        </>
    )
}