import React, { useContext } from "react";
import {
  StyleInput,
  StyleLabel,
  ButtonStyleDefault,
} from "../../../features/Constantes";
import { ReporteDocGeneradoContext } from "./ReporteDocGeneradoProvider";
import { useForm } from "react-hook-form";
import { getFechaFin, getFechaInicio } from "../../../services/Utilitario";
import { getReporDocGenerado } from "../../../services";
import { toast } from "react-toastify";
import { SectionFilter } from "../../../features/components/SectionFilter";
import { ButtonDefault } from "../../../features/components/ButtonDefault";
export const FromDocGenerado = () => {
  const { cargandoData,  getReporDocExcel, getReporDoc } =
    useContext(ReporteDocGeneradoContext);

  const initialStateForm = {
    identificacioncontribuyente: "",
    identificacionusuario: "",
    fechaFin: getFechaFin(),
    fechaInicio: getFechaInicio(),
  };

  const {
    register,

    handleSubmit,
    getValues,
  } = useForm({
    defaultValues: initialStateForm,
  });

  /*   React.useEffect(() => {
    setValue("fechaFin", initialStateForm.fechaFin);
    setValue("fechaInicio", initialStateForm.fechaInicio);
  }, [setValue, initialStateForm]); */

  const handleSubmitModal = (e) => {
    let identificacioncontribuyente =
      getValues().identificacioncontribuyente.trim();

    if (identificacioncontribuyente === "") {
      toast.info("La identificación del contribuyente no puede ir vacío.");
      return;
    }
    getReporDoc(e);
  };

  const handlexcel = () => {
    let identificacioncontribuyente =
      getValues().identificacioncontribuyente.trim();
    let identificacionusuario = getValues().identificacionusuario.trim();

    if (identificacioncontribuyente === "") {
      toast.info("La identificación del contribuyente no puede ir vacío.");
      return;
    }
    let fechaFin = getValues().fechaFin;
    let fechaInicio = getValues().fechaInicio;
    let parametros = {
      identificacioncontribuyente: identificacioncontribuyente,
      identificacionusuario: identificacionusuario,
      fechaFin: fechaFin,
      fechaInicio: fechaInicio,
    };
    getReporDocExcel(parametros);
  };
  return (
    <>



<SectionFilter title={"Busqueda"}>
          <div className="lg:px-4 md:px-4 lg:py-3 md:py-3 md:hidden lg:hidden  ">
            <form onSubmit={handleSubmit(handleSubmitModal)}>
              <div className="grid md:grid-cols-4 gap-2 md:gap-2 lg:p-3 md:p-3 mx-1 rounded-lg bg-gray-300 dark:bg-gray-700">
                <div className="relative z-0 lg:mb-0   w-full group">
                  <label
                    htmlFor="fechaInicio"
                    className="text-sm font-semibold dark:text-white"
                  >
                    Fecha inicio
                  </label>
                  <input
                    type="date"
                    className={StyleInput}
                    name="fechaInicio"
                    id="fechaInicio"
                    placeholder=" "
                    {...register("fechaInicio")}
                  />
                </div>
                <div className="relative z-0 lg:mb-0   w-full group">
                  <label
                    htmlFor="fechaInicio"
                    className="text-sm font-semibold"
                  >
                    Fecha fin
                  </label>
                  <input
                    type="date"
                    name="fechaFin"
                    id="fechaFin"
                    className={StyleInput}
                    placeholder=" "
                    {...register("fechaFin")}
                  />
                </div>

                <div className="relative z-0  w-full group">
                  <label
                    htmlFor="fechaInicio"
                    className="text-sm font-semibold"
                  >
                    Identificación contribuyente
                  </label>
                  <input
                    type="text"
                    className={StyleInput}
                    placeholder=" "
                    id="identificacioncontribuyente"
                    name="identificacioncontribuyente"
                    {...register("identificacioncontribuyente")}
                  />
                </div>

                <div className="relative z-0  w-full group">
                  <label
                    htmlFor="fechaInicio"
                    className="text-sm font-semibold"
                  >
                    Identificación usuario
                  </label>
                  <input
                    type="text"
                    className={StyleInput}
                    placeholder=" "
                    id="identificacionusuario"
                    name="identificacionusuario"
                    {...register("identificacionusuario")}
                  />
                </div>
              </div>

              <div className="mt-2 mx-1 flex-1 space-y-2  mb-2  ">
              <button
                type="submit"
                className={
                  ButtonStyleDefault +
                  " bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500"
                }
              >
                {cargandoData ? (
                  <>
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline mr-3 w-4 h-4 animate-spin text-white"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    CARGANDO...
                  </>
                ) : (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="-ml-0.5 mr-2 h-5 w-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                      />
                    </svg>
                    BUSCAR
                  </>
                )}
              </button>
               
                <ButtonDefault
                  title={"Generar Excel"}
                  onClick={handlexcel}
                  color={"descargas"}
                  svg={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-brand-office w-5 h-5 mr-1"
                      viewBox="0 0 55 55"
                      strokeWidth="3"
                      stroke="currentColor"
                      //stroke="#008F39"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M 28.875 0 C 28.855469 0.0078125 28.832031 0.0195313 28.8125 0.03125 L 0.8125 5.34375 C 0.335938 5.433594 -0.0078125 5.855469 0 6.34375 L 0 43.65625 C -0.0078125 44.144531 0.335938 44.566406 0.8125 44.65625 L 28.8125 49.96875 C 29.101563 50.023438 29.402344 49.949219 29.632813 49.761719 C 29.859375 49.574219 29.996094 49.296875 30 49 L 30 44 L 47 44 C 48.09375 44 49 43.09375 49 42 L 49 8 C 49 6.90625 48.09375 6 47 6 L 30 6 L 30 1 C 30.003906 0.710938 29.878906 0.4375 29.664063 0.246094 C 29.449219 0.0546875 29.160156 -0.0351563 28.875 0 Z M 28 2.1875 L 28 6.53125 C 27.867188 6.808594 27.867188 7.128906 28 7.40625 L 28 42.8125 C 27.972656 42.945313 27.972656 43.085938 28 43.21875 L 28 47.8125 L 2 42.84375 L 2 7.15625 Z M 30 8 L 47 8 L 47 42 L 30 42 L 30 37 L 34 37 L 34 35 L 30 35 L 30 29 L 34 29 L 34 27 L 30 27 L 30 22 L 34 22 L 34 20 L 30 20 L 30 15 L 34 15 L 34 13 L 30 13 Z M 36 13 L 36 15 L 44 15 L 44 13 Z M 6.6875 15.6875 L 12.15625 25.03125 L 6.1875 34.375 L 11.1875 34.375 L 14.4375 28.34375 C 14.664063 27.761719 14.8125 27.316406 14.875 27.03125 L 14.90625 27.03125 C 15.035156 27.640625 15.160156 28.054688 15.28125 28.28125 L 18.53125 34.375 L 23.5 34.375 L 17.75 24.9375 L 23.34375 15.6875 L 18.65625 15.6875 L 15.6875 21.21875 C 15.402344 21.941406 15.199219 22.511719 15.09375 22.875 L 15.0625 22.875 C 14.898438 22.265625 14.710938 21.722656 14.5 21.28125 L 11.8125 15.6875 Z M 36 20 L 36 22 L 44 22 L 44 20 Z M 36 27 L 36 29 L 44 29 L 44 27 Z M 36 35 L 36 37 L 44 37 L 44 35 Z" />
                    </svg>
                  }
                />
              </div>
            </form>
          </div>
        </SectionFilter>






      <div className="bg-white  dark:bg-gray-700 p-3 rounded-lg">
        <h2 className=" pl-4 text-2xl py-4 border-b font-semibold text-gray-700 dark:text-gray-200">
          Reporte a usuarios emisores
        </h2>

        <div className="px-4 py-3 lg:block md:block hidden  ">
          <form onSubmit={handleSubmit(handleSubmitModal)}>
            <div className="grid md:grid-cols-4 gap-2 md:gap-2 p-3 rounded-lg bg-gray-300 dark:bg-gray-700">
              <div className="relative z-0 lg:mb-0   w-full group">
                <label
                  htmlFor="fechaInicio"
                  className="text-sm font-semibold dark:text-white"
                >
                  Fecha inicio
                </label>
                <input
                  type="date"
                  className={StyleInput}
                  name="fechaInicio"
                  id="fechaInicio"
                  placeholder=" "
                  {...register("fechaInicio")}
                />
              </div>
              <div className="relative z-0 lg:mb-0   w-full group">
                <label
                  htmlFor="fechaInicio"
                  className="text-sm font-semibold dark:text-white"
                >
                  Fecha fin
                </label>
                <input
                  type="date"
                  name="fechaFin"
                  id="fechaFin"
                  className={StyleInput}
                  placeholder=" "
                  {...register("fechaFin")}
                />
              </div>

              <div className="relative z-0  w-full group">
                <label
                  htmlFor="fechaInicio"
                  className="text-sm font-semibold dark:text-white"
                >
                  Identificación contribuyente
                </label>
                <input
                  type="text"
                  className={StyleInput}
                  placeholder=" "
                  id="identificacioncontribuyente"
                  name="identificacioncontribuyente"
                  {...register("identificacioncontribuyente")}
                />
              </div>

              <div className="relative z-0  w-full group">
                <label
                  htmlFor="fechaInicio"
                  className="text-sm font-semibold dark:text-white"
                >
                  Identificación usuario
                </label>
                <input
                  type="text"
                  className={StyleInput}
                  placeholder=" "
                  id="identificacionusuario"
                  name="identificacionusuario"
                  {...register("identificacionusuario")}
                />
              </div>
            </div>

            <div className="mt-2 mx-1 flex space-x-2  mb-2  ">
              <button
                type="submit"
                className={
                  ButtonStyleDefault +
                  " bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500"
                }
              >
                {cargandoData ? (
                  <>
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline mr-3 w-4 h-4 animate-spin text-white"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    CARGANDO...
                  </>
                ) : (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="-ml-0.5 mr-2 h-5 w-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                      />
                    </svg>
                    BUSCAR
                  </>
                )}
              </button>
              {/* <ButtonDefault
                color={"primary"}
                type={"submit"}
                title={"Buscar"}
                svg={
                  cargandoData ? (
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline mr-3 w-4 h-4 animate-spin text-white"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="-ml-0.5 mr-2 h-5 w-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                      />
                    </svg>
                  )
                }
              /> */}
              <ButtonDefault
                title={"Generar Excel"}
                onClick={handlexcel}
                color={"descargas"}
                svg={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-brand-office w-5 h-5 mr-1"
                    viewBox="0 0 55 55"
                    strokeWidth="3"
                    stroke="currentColor"
                    //stroke="#008F39"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M 28.875 0 C 28.855469 0.0078125 28.832031 0.0195313 28.8125 0.03125 L 0.8125 5.34375 C 0.335938 5.433594 -0.0078125 5.855469 0 6.34375 L 0 43.65625 C -0.0078125 44.144531 0.335938 44.566406 0.8125 44.65625 L 28.8125 49.96875 C 29.101563 50.023438 29.402344 49.949219 29.632813 49.761719 C 29.859375 49.574219 29.996094 49.296875 30 49 L 30 44 L 47 44 C 48.09375 44 49 43.09375 49 42 L 49 8 C 49 6.90625 48.09375 6 47 6 L 30 6 L 30 1 C 30.003906 0.710938 29.878906 0.4375 29.664063 0.246094 C 29.449219 0.0546875 29.160156 -0.0351563 28.875 0 Z M 28 2.1875 L 28 6.53125 C 27.867188 6.808594 27.867188 7.128906 28 7.40625 L 28 42.8125 C 27.972656 42.945313 27.972656 43.085938 28 43.21875 L 28 47.8125 L 2 42.84375 L 2 7.15625 Z M 30 8 L 47 8 L 47 42 L 30 42 L 30 37 L 34 37 L 34 35 L 30 35 L 30 29 L 34 29 L 34 27 L 30 27 L 30 22 L 34 22 L 34 20 L 30 20 L 30 15 L 34 15 L 34 13 L 30 13 Z M 36 13 L 36 15 L 44 15 L 44 13 Z M 6.6875 15.6875 L 12.15625 25.03125 L 6.1875 34.375 L 11.1875 34.375 L 14.4375 28.34375 C 14.664063 27.761719 14.8125 27.316406 14.875 27.03125 L 14.90625 27.03125 C 15.035156 27.640625 15.160156 28.054688 15.28125 28.28125 L 18.53125 34.375 L 23.5 34.375 L 17.75 24.9375 L 23.34375 15.6875 L 18.65625 15.6875 L 15.6875 21.21875 C 15.402344 21.941406 15.199219 22.511719 15.09375 22.875 L 15.0625 22.875 C 14.898438 22.265625 14.710938 21.722656 14.5 21.28125 L 11.8125 15.6875 Z M 36 20 L 36 22 L 44 22 L 44 20 Z M 36 27 L 36 29 L 44 29 L 44 27 Z M 36 35 L 36 37 L 44 37 L 44 35 Z" />
                  </svg>
                }
              />
            </div>
          </form>
        </div>

       
      </div>
    </>
  );
};
