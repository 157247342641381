import { CrearSeccionForm } from "./CrearSeccionForm";
import { CrearSeccionList } from "./CrearSeccionList";

export const CrearSeccion = () => {
  return (
    <section className="w-full h-full flex flex-col gap-10">
      <CrearSeccionForm />
      <CrearSeccionList />
    </section>
  );
};
