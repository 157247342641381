import React, { createContext } from "react";
import useSecuencialLoader from "./SecuencialLoader/useSecuencialLoader";

export const SecuencialProviderContext = createContext();

const SecuencialProvider = (props) => {
  const { EstablecimientoSelector, PuntoEmisionSelector } =
    useSecuencialLoader();

  return (
    <SecuencialProviderContext.Provider
      value={{
        EstablecimientoSelector,
        PuntoEmisionSelector,
       
      }}
    >
      {props.children}
    </SecuencialProviderContext.Provider>
  );
};

export default SecuencialProvider;
