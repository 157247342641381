import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import { getListV2 } from "../../../services";
import MesageError from "../../../features/components/MessageError";
import {
  StyleSelect,
  StyleInput,
  StyleInputError,
  ButtonStyleDefault,
} from "../../../features/Constantes";
import { EmitirGuiaRemisionContext } from "./EmitirGuiaRemisionProvider";
import { toast } from "react-toastify";
import { Transition } from "@headlessui/react";

const DestinatarioForm = () => {
  const { setDestinatariosList, DestinatariosList, formik } = useContext(
    EmitirGuiaRemisionContext
  );

  const [DetalleList, setDetalleList] = useState([]);
  const [ValueComprobante, setValueComprobante] = useState("00");
  const [
    BuscarClienteIndetificacionError,
    setBuscarClienteIndetificacionError,
  ] = useState(false);
  const [BuscarClienteRazonSocial, setBuscarClienteRazonSocial] = useState([]);
  const [BuscarClienteRazonSocialText, setBuscarClienteRazonSocialText] =
    useState("");
  const [BuscarCliente, setBuscarCliente] = useState(false);
  const [BuscarClienteIndetificacion, setBuscarClienteIndetificacion] =
    useState(false);
  const [IsLoading, setIsLoading] = useState(false);

  const empresa = useSelector((store) => store.empresa.empresa);

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
    setValue,
  } = useForm({ mode: "onBlur" });

  const handleOnClickCliente = (cliente, index) => {
    setBuscarClienteIndetificacionError(false);
    setBuscarClienteIndetificacion(false);
    setBuscarCliente(false);
    // console.log(cliente);
    setValue("IdentificacionDestinatario", cliente.identificacion);
    setValue("DirDestinatario", cliente.direccion);
    setValue("clienteRazonSocial", cliente.razonSocial);
    setValue("idContribuyente", cliente.idContribuyente);
    //handleOnBlurCliente(cliente.identificacion);
  };

  const handleBlurSecuencialComprobante = async (e) => {
    try {
      let datas = e.target.value;
      let res = await getListV2(
        empresa,
        "GetDetallesBySecuencial",
        `?Establecimiento=${datas.substr(0, 3)}&PuntoEmision=${datas.substr(
          4,
          3
        )}&Secuencial=${datas.substr(8, 9)}`
      );
      // console.log(res);
      if (res !== "" && res !== undefined) {
        setValue(
          "FechaEmisionDocSustento",
          moment(res.fechaEmision).format("YYYY-MM-DD")
        );
        setDetalleList(res);
      }
    } catch (ex) {
      // console.log(ex);
      setDetalleList([]);
    }
  };

  // const handleDeleteDestinatario = (i) => {
  //   DestinatariosList.splice(i, 1);
  //   setDestinatariosList([...DestinatariosList]);
  // }

  const handleChangeTipoComprobante = (e) => {
    const valor = e.target.value;
    // console.log("El valor de e.target.value es:", valor);
    setValueComprobante(valor);
  };

  const handleAddDestinatario = (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (getValues().IdentificacionDestinatario === "") {
      toast.error("Faltan los datos del Destinatario ");
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
      return;
    }
    if (getValues().tipoComprobante === "01") {
      if (getValues().secuencialComprobante === "") {
        toast.error("Faltan ingresar la secuencial ");
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
        return;
      }
      if (getValues().FechaEmisionDocSustento === "") {
        toast.error("Faltan ingresar la Fecha ");
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
        return;
      }
    }

    if (getValues().motivoTraslado === "") {
      toast.error("Falta el campo Motivo traslado");
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
      return;
    }

    if (getValues().rutaComprobante === "") {
      toast.error("Falta el campo Ruta ");
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
      return;
    }

    if (getValues().establecimientoDestino === "") {
      toast.error("Falta el campo establ. destino");
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
      return;
    }

    if (getValues().establecimientoDestino.length !== 3) {
      toast.error(
        "El campo establ. destino debe contener exactamente 3 dígitos"
      );
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
      return;
    }

    let valores = {
      CodDocSustento:
        getValues().tipoComprobante === "00"
          ? null
          : getValues().tipoComprobante,
      NumDocSustento: getValues().secuencialComprobante
        ? getValues().secuencialComprobante
        : "",
      NumAutDocSustento: getValues().documentoAutorizacion,
      FechaEmisionDocSustento: getValues().FechaEmisionDocSustento,
      DirDestinatario: getValues().DirDestinatario,
      MotivoTraslado: getValues().motivoTraslado,
      Ruta: getValues().rutaComprobante,
      IdentificacionDestinatario: getValues().IdentificacionDestinatario,
      RazonSocialDestinatario: getValues().clienteRazonSocial,
      CodEstabDestino: getValues().establecimientoDestino,
      DocAduaneroUnico: getValues().DocumentoAduaneroComprobante,
      detalles: [],
    };

    setValue("tipoComprobante", "00");
    setValueComprobante("00");
    setValue("secuencialComprobante", "");
    setValue("documentoAutorizacion", "");
    setValue("FechaEmisionDocSustento", "");
    setValue("DirDestinatario", "");
    setValue("motivoTraslado", "");
    setValue("rutaComprobante", "");
    setValue("IdentificacionDestinatario", "");
    setValue("clienteRazonSocial", "");
    setValue("establecimientoDestino", "");
    setValue("DocumentoAduaneroComprobante", "");
    setValue("DestinoComprobante", "");
    //setValue("tipoComprobante","01");
    setDestinatariosList([...DestinatariosList, valores]);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const handleOnBlurCliente = async (value) => {
    setBuscarCliente(false);

    if (value) {
      if (value.length > 5) {
        setBuscarClienteIndetificacionError(false);

        setBuscarClienteIndetificacion(true);

        // agregar el codigo de buscar por cedula en la tabla contribuyentes
        try {
          if (value.length === 13) {
            value = value.substr(0, 10);
          }

          const data = await getListV2(
            empresa,
            "GetContribuyente",
            `?Identificacion=${value.trim()}&Cantidad=1&Pagina=1`
          );

          const valores = data._embedded[0];

          setValue("DirDestinatario", valores.direccion);
          setValue("idContribuyente", valores.idContribuyente);
          setValue("clienteRazonSocial", valores.razonSocial);
        } catch (err) {
          setValue("DirDestinatario", "");
          setValue("idContribuyente", "");
          setValue("clienteRazonSocial", "");
        }
        //formik.handleChange(e);

        setBuscarClienteIndetificacion(false);
      } else {
        setBuscarClienteIndetificacionError(true);
      }
    } else {
      setBuscarClienteIndetificacionError(false);
      //setValue("IdentificacionDestinatario", "");
    }
  };

  const handleOnclick = (e) => {
    // solo se muestra mientras no se este buscando por cedula
    if (!BuscarClienteIndetificacion) {
      setBuscarCliente(!BuscarCliente);
    }
  };

  useEffect(() => {
    const timeOutId = setTimeout(
      () => handleSearchRazonSocial(BuscarClienteRazonSocialText),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [BuscarClienteRazonSocialText]);

  const handleSearchRazonSocial = async (param) => {
    if (param) {
      if (param.trim() !== "") {
        if (param.trim().length > 2) {
          const data = await getListV2(
            empresa,
            "GetContribuyenteByRazonSocial",
            `?razonSocial=${param.trim()}&empresaId=${
              empresa.idEmpresa
            }&isActive=1`
          );
          setBuscarClienteRazonSocial(data._embedded);
        }
      }
    }
  };

  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="border dark:border-gray-600 rounded-lg p">
      <div className="text-sm py-1.5  px-2 text-left bg-[#003B5B] dark:text-gray-50 dark:bg-gray-700 border-transparent rounded-t-lg font-medium leading-6 text-white flex justify-between">
        <h3>Destinatario / Cliente</h3>
        <div className="flex space-x-2">
          <p>Aplicar factura</p>

          <input
            type="checkbox"
            //className={StyleCheckbox}
            name="tipoComprobante"
            id="tipoComprobante"
            checked={ValueComprobante === "01"} // Comprueba si el estado es '01' para marcar el checkbox
            onChange={(e) => {
              handleChangeTipoComprobante(e);
              // Si el checkbox está marcado, establece el estado en '01', de lo contrario, '00'
              const value = e.target.checked ? "01" : "00";
              setValueComprobante(value);
            }}
          />
          <svg
          onClick={() => setIsOpen(!isOpen)}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className={`"w-4 h-4 my-auto lg:hidden md:hidden text-white" duration-150 ${isOpen ? "rotate-180" : <></>}`}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m19.5 8.25-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
      </div>
      <Transition
        show={isOpen}
        enter="transition ease-out duration-300" // Aumenta la duración de entrada
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-300" // Aumenta la duración de salida
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
      <div className="p-4 border-b dark:border-b-gray-600">
        <div className="w-full  grid md:grid-cols-6 md:gap-2 gap-2  mb-2  ">
          {ValueComprobante !== "00" && (
            <>
              <div className="relative  w-full group">
                <label
                  htmlFor="company-website"
                  className="block text-sm font-medium text-black dark:text-white"
                >
                  {" "}
                  Sec. Comprobante:{" "}
                </label>
                <NumberFormat
                  format="###-###-#########"
                  id="secuencialComprobante"
                  name="secuencialComprobante"
                  placeholder=" "
                  className={StyleInput}
                  {...register("secuencialComprobante", {})}
                  onBlur={handleBlurSecuencialComprobante}
                  onChange={(e) =>
                    setValue("secuencialComprobante", e.target.value)
                  }
                  value={getValues().secuencialComprobante}
                />
              </div>
              <div className="relative  w-full group">
                <label
                  htmlFor="company-website"
                  className="block text-sm font-medium text-black dark:text-white"
                >
                  {" "}
                  Fecha doc. sustento:{" "}
                </label>
                <input
                  type="date"
                  /* className={
                  errors.FechaEmisionDocSustento ? StyleInputError : StyleInput
                } */
                  className={
                    formik.touched.FechaEmisionDocSustento &&
                    formik.errors.FechaEmisionDocSustento
                      ? StyleInputError
                      : StyleInput
                  }
                  placeholder=" "
                  id="FechaEmisionDocSustento"
                  name="FechaEmisionDocSustento"
                  {...register("FechaEmisionDocSustento", {
                    required: {
                      value: true,
                      message: "Nombre es requerido",
                    },
                  })}
                />
              </div>
              <div className="relative col-span-2  w-full group">
                <label
                  htmlFor="company-website"
                  className="block text-sm font-medium text-black dark:text-white"
                >
                  {" "}
                  Número autorización:{" "}
                </label>
                <NumberFormat
                  format="#################################################"
                  id="documentoAutorizacion"
                  name="documentoAutorizacion"
                  {...register("documentoAutorizacion", {
                    required: true,
                  })}
                  onChange={(e) =>
                    setValue("documentoAutorizacion", e.target.value)
                  }
                  value={getValues().documentoAutorizacion}
                  className={
                    formik.touched.documentoAutorizacion &&
                    formik.errors.documentoAutorizacion
                      ? StyleInputError
                      : StyleInput
                  }
                />
              </div>
              <div className="relative  w-full group">
                <label
                  htmlFor="company-website"
                  className="block text-sm invisible font-medium text-black dark:text-white"
                >
                  {" "}
                  Ejemplo{" "}
                </label>
                <button
                  type="button"
                  className="  text-center mx-auto text-white  dark:bg-gray-700 border-1   border hover:shadow-md bg-blue-600 hover:bg-blue-800 dark:hover:bg-gray-700  rounded-lg py-1.5  text-sm font-medium leading-5  dark:text-gray-400 focus:outline-none focus:shadow-outline-grayduration-150 ease-in-out w-full"
                  data-bs-toggle="modal"
                  data-bs-target="#searchFacturaModalGuia"
                  id="searchFacturaModalButton"
                >
                  Consultar secuencial
                </button>
              </div>
            </>
          )}
        </div>
        <div className="w-full  grid md:grid-cols-6 md:gap-2    ">
          <div className="relative  w-full group">
            <label className="block text-sm font-medium text-black dark:text-white">
              {" "}
              Identificación: *{" "}
            </label>
            <input
              type="text"
              className={
                errors.IdentificacionDestinatario ? StyleInputError : StyleInput
              }
              name="IdentificacionDestinatario"
              id="IdentificacionDestinatario"
              placeholder="Digite número de Identificación"
              {...register("IdentificacionDestinatario", {
                required: {
                  value: true,
                  message: "Nombre es requerido",
                },
                maxLength: {
                  value: 300,
                  message: "No más de 300 carácteres!",
                },
                minLength: {
                  value: 4,
                  message: "Mínimo 4 carácteres",
                },
              })}
              onBlur={(e) => handleOnBlurCliente(e.target.value)}
              maxLength="20"
            />

            {errors.IdentificacionDestinatario ? (
              <MesageError message={formik.errors.IdentificacionDestinatario} />
            ) : null}
            <button
              onClick={handleOnclick}
              type="button"
              id="dropdownSearchButton"
              data-dropdown-toggle="dropdownSearch"
              data-dropdown-placement="bottom"
              className="absolute inset-y-0 right-0  items-center pl-3 text-sm text-gray-900 pt-4  border border-r-0 border-t-0 border-l-0 border-b-0 border-gray-300  dark:text-gray-400 dark:border-gray-600"
            >
              {BuscarClienteIndetificacion ? (
                <>
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline mr-3 w-4 h-4 animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                </>
              ) : (
                <>
                  <svg
                    className="inline mr-2 -ml-1 w-5 h-5 dark:stroke-white dark:hover:stroke-blue-600  "
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    ></path>
                  </svg>
                </>
              )}
            </button>
            <div
              id="dropdownSearch"
              className={`${
                BuscarCliente ? "block" : "hidden"
              }  bg-white rounded shadow dark:bg-gray-700 absolute w-full`}
              style={{ zIndex: "15" }}
            >
              <div className="p-3">
                <label htmlFor="input-group-search" className="sr-only">
                  Buscar
                </label>
                <div className="relative">
                  <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg
                      className="w-5 h-5 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="razonSocialSearch"
                    name="razonSocialSearch"
                    value={BuscarClienteRazonSocialText}
                    onChange={(e) =>
                      setBuscarClienteRazonSocialText(e.target.value)
                    }
                    onBlur={(e) =>
                      setBuscarClienteRazonSocialText(e.target.value)
                    }
                    className="block p-2 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Digite Razón Social / Nombre"
                  />
                </div>
              </div>
              <ul
                className="overflow-y-auto px-3 pb-3 h-48 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownSearchButton"
              >
                {BuscarClienteRazonSocial.length === 0 ? (
                  <li key="01">
                    <div className="text-sm mt-1 text-yellow-400 dark:text-yellow-400 inline-flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      <div style={{ position: "relative" }}>
                        <span className="font-semibold">
                          No se encontraron datos
                        </span>
                      </div>
                    </div>
                  </li>
                ) : null}
                {BuscarClienteRazonSocial.map((el, index) => (
                  <li key={index}>
                    <div
                      onClick={() => handleOnClickCliente(el)}
                      className="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                        />
                      </svg>
                      <label
                        htmlFor="checkbox-item-11"
                        className="py-2 ml-2 w-full text-sm font-medium text-gray-900 rounded dark:text-gray-300"
                      >
                        {el.identificacion} - {el.razonSocial}
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="relative  w-full group">
            <label
              htmlFor="company-website"
              className="block text-sm font-medium text-black dark:text-white"
            >
              {" "}
              Razón social: *{" "}
            </label>
            <input
              type="text"
              /* className={errors.clienteRazonSocial ? StyleInputError : StyleInput} */
              className={
                formik.touched.clienteRazonSocial &&
                formik.errors.clienteRazonSocial
                  ? StyleInputError
                  : StyleInput
              }
              placeholder=" "
              id="clienteRazonSocial"
              name="clienteRazonSocial"
              {...register("clienteRazonSocial", {
                required: {
                  value: true,
                  message: "Nombre es requerido",
                },
                maxLength: {
                  value: 300,
                  message: "No más de 300 carácteres!",
                },
                minLength: {
                  value: 4,
                  message: "Mínimo 4 carácteres",
                },
              })}
              maxLength="300"
            />
          </div>
          <div className="relative  w-full group">
            <label
              htmlFor="company-website"
              className="block text-sm font-medium text-black dark:text-white"
            >
              {" "}
              Dirección destino: *{" "}
            </label>
            <input
              type="text"
              /* className={errors.DirDestinatario ? StyleInputError : StyleInput} */
              className={
                formik.touched.DirDestinatario && formik.errors.DirDestinatario
                  ? StyleInputError
                  : StyleInput
              }
              placeholder=" "
              id="DirDestinatario"
              name="DirDestinatario"
              {...register("DirDestinatario", {
                required: {
                  value: true,
                  message: "Nombre es requerido",
                },
                maxLength: {
                  value: 300,
                  message: "No más de 300 carácteres!",
                },
                minLength: {
                  value: 4,
                  message: "Mínimo 4 carácteres",
                },
              })}
              maxLength="300"
            />
          </div>
          <div className="relative   w-full group">
            <label
              htmlFor="company-website"
              className="block text-sm font-medium text-black dark:text-white"
            >
              {" "}
              Motivo traslado: *{" "}
            </label>
            <input
              type="text"
              /* className={errors.MotivoTraslado ? StyleInputError : StyleInput} */
              className={
                formik.touched.motivoTraslado && formik.errors.motivoTraslado
                  ? StyleInputError
                  : StyleInput
              }
              placeholder=" "
              id="motivoTraslado"
              name="motivoTraslado"
              {...register("motivoTraslado", {
                required: {
                  value: true,
                  message: "Nombre es requerido",
                },
                maxLength: {
                  value: 300,
                  message: "No más de 300 carácteres!",
                },
                minLength: {
                  value: 4,
                  message: "Mínimo 4 carácteres",
                },
              })}
              maxLength="300"
            />
          </div>
          <div className="relative  w-full group">
            <label
              htmlFor="company-website"
              className="block text-sm font-medium text-black dark:text-white"
            >
              {" "}
              Ruta: *{" "}
            </label>
            <input
              type="text"
              /* className={errors.rutaComprobante ? StyleInputError : StyleInput} */
              className={
                formik.touched.rutaComprobante && formik.errors.rutaComprobante
                  ? StyleInputError
                  : StyleInput
              }
              placeholder=" "
              id="rutaComprobante"
              name="rutaComprobante"
              {...register("rutaComprobante", {
                required: {
                  value: true,
                  message: "Nombre es requerido",
                },
              })}
              maxLength="300"
            />
          </div>
          <div className="relative  w-full group">
            <label
              htmlFor="company-website"
              className="block text-sm font-medium text-black dark:text-white"
            >
              {" "}
              Establ. destino: *
            </label>
            <NumberFormat
              // format="###"
              maxLength="3"
              id="establecimientoDestino"
              name="establecimientoDestino"
              {...register("establecimientoDestino", {
                required: {
                  value: true,
                  message: "establecimientoDestino es requerido",
                },
                maxLength: {
                  required: true,
                  value: 3,
                  message: "No más de 3 carácteres!",
                },
              })}
              onChange={(e) =>
                setValue("establecimientoDestino", e.target.value)
              }
              value={getValues().establecimientoDestino}
              placeholder="000"
              /* className={
              errors.establecimientoDestino ? StyleInputError : StyleInput
            } */
              className={
                formik.touched.establecimientoDestino &&
                formik.errors.establecimientoDestino
                  ? StyleInputError
                  : StyleInput
              }
            />
          </div>
          <div className="relative  w-full group">
            <label
              htmlFor="company-website"
              className="block text-sm font-medium text-black dark:text-white"
            >
              {" "}
              Doc. aduanero (Opc):
            </label>
            <input
              type="text"
              /*  className={
              errors.DocumentoAduaneroComprobante ? StyleInputError : StyleInput
            } */
              className={
                formik.touched.DocumentoAduaneroComprobante &&
                formik.errors.DocumentoAduaneroComprobante
                  ? StyleInputError
                  : StyleInput
              }
              placeholder=" "
              id="DocumentoAduaneroComprobante"
              name="DocumentoAduaneroComprobante"
              {...register("DocumentoAduaneroComprobante", {
                maxLength: {
                  value: 20,
                  message: "No más de 20 carácteres!",
                },
                minLength: {
                  value: 4,
                  message: "Mínimo 4 carácteres",
                },
              })}
              maxLength="20"
            />
          </div>
        </div>
      </div>
      <div className="relative flex justify-end  w-full group px-4 my-2">
        <button
          disabled={IsLoading}
          onClick={(e) => handleAddDestinatario(e)}
          className=" inline-block text-white bg-blue-600 dark:bg-gray-900 dark:border-gray-600 lg:w-auto w-full hover:bg-blue-700 font-semibold py-2 px-5 text-sm border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:shadow-outline-grayduration-150 ease-in-out"
        >
          {IsLoading ? (
            "..."
          ) : (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="inline mr-1   -ml-1 w-5 h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              Agregar
            </>
          )}
        </button>
      </div>
      </Transition>
    </div>
  );
};

export default DestinatarioForm;
