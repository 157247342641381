export const PlanAdicional = [
  {
    text: "Módulo de ATS limitado $ 45.00 anual.",
  },
  {
    text: "Consulta y filtro de comprobantes por fecha de autorización $ 60.00 más IVA.",
  },
  {
    text: "Integración de TaxFlash con sistema de cliente.",
  },
  {
    text:"Precio se analizará dependiendo la integración solicitada y desarrollo a la medida previa."
  },
  {
    text:"Servicio en la Nube, permite almacenar información para visualizar en cualquier computadora."
  }
  ,
  {
    text:"Personalización de Excel."
  }

];
