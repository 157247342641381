import React, { useContext, useEffect, useRef, useState } from "react";
import {
  cargando,
  customStyles,
  sinDatos,
  StyleInput,
  StyleLabelForm,
  StyleSelect,
  StyleSelectAsync,
} from "../../../features/Constantes";
import { useFormik } from "formik";
import * as Yup from "yup";
import debounce from "lodash.debounce";
import MesageError from "../../../features/components/MessageError";
import { getList, getListV2 } from "../../../services";
import { useSelector } from "react-redux";
import { EmitirGuiaRemisionContext } from "./EmitirGuiaRemisionProvider";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";
import { Transition } from "@headlessui/react";

const FormBuscarDetalle = () => {
  const empresa = useSelector((store) => store.empresa.empresa);

  // const [ItemDetalle, setItemDetalle] = useState({});
  // const [changeDetalle, setChangeDetalle] = useState("");

  const [BuscarDetalleNombre, setBuscarDetalleNombre] = useState("");
  const [BuscarDetalleCodigo, setBuscarDetalleCodigo] = useState("");
  const [DataSearchBuscarDetalle, setDataSearchBuscarDetalle] = useState([]);
  const selectAssyncRef = useRef(null);
  const [IsLoading, setIsLoading] = useState(false);

  const {
    setDetalleList,
    DetalleList,
    DestinatariosList,
    setDestinatariosList,
    ItemDetalle,
    setItemDetalle,
    changeDetalle,
    setChangeDetalle,
  } = useContext(EmitirGuiaRemisionContext);

  const formik = useFormik({
    initialValues: {
      cantidad: 1,
      selectedDestinatario: "",
    },
    validationSchema: Yup.object({
      cantidad: Yup.number()
        .min(1, "La cantidad no puede ser menor o igual a 0")
        .required("Campo Requerido"),
      selectedDestinatario: Yup.string().required(
        "Seleccione a un destinatario"
      ),
    }),
    onSubmit: async (obj) => {
      setIsLoading(true);
      try {
        if (ItemDetalle !== undefined) {
          if (ItemDetalle.codigoPrincipal) {
            // console.log(obj);
            if (ItemDetalle.descuento === null) ItemDetalle.descuento = 0;
            if (ItemDetalle.descuento === undefined) ItemDetalle.descuento = 0;
            ItemDetalle.cantidad = obj.cantidad;

            let element = DestinatariosList.find(
              (el) => el.IdentificacionDestinatario === obj.selectedDestinatario
            );
            element.detalles = [...element.detalles, ItemDetalle];
            // element.detalles = [...element.detalles, ItemDetalle];
            element.detalles.forEach((element) => {
              element.informacionAdicionalServicioFactura =
                element.servicioDetalle;
            });

            setDestinatariosList([...DestinatariosList]);
            setDetalleList([{ ...ItemDetalle }, ...DetalleList]);
            formik.resetForm();
            selectAssyncRef.current.setValue([], "clear");
            setItemDetalle({});
            setChangeDetalle("");

            setTimeout(() => {
              setIsLoading(false);
            }, 2000);
            return;
          } else {
            toast("Asegurate de ingresar el código del producto!", {
              type: "info",
              autoClose: 2000,
              closeOnClick: true,
            });
            setTimeout(() => {
              setIsLoading(false);
            }, 2000);
            return;
          }
        } else {
          toast("If codigo principal!", {
            type: "info",
            autoClose: 2000,
            closeOnClick: true,
          });
          setTimeout(() => {
            setIsLoading(false);
          }, 2000);
          return;
        }
      } catch (error) {
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
        // console.log(error);
      }
    },
  });

  useEffect(() => {
    // console.log(DestinatariosList);
    if (DestinatariosList.length >= 1)
      formik.setFieldValue(
        "selectedDestinatario",
        DestinatariosList[0].IdentificacionDestinatario
      );
  }, [DestinatariosList]);

  useEffect(() => {
    const timeOutId = setTimeout(
      () => handleSearchBuscarDetalle(BuscarDetalleNombre),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [BuscarDetalleNombre]);

  useEffect(() => {
    const timeOutId = setTimeout(
      () => handleSearchBuscarDetalle(BuscarDetalleCodigo, "servicio"),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [BuscarDetalleCodigo]);

  useEffect(() => {
    formik.validateForm();
  }, [ItemDetalle]);

  const handleSearchBuscarDetalle = async (value, type = "name") => {
    if (!value) return;
    if (value.trim().length < 2) return;

    let data;

    if (type === "name") {
      data = await getListV2(
        empresa,
        "GetServiciosByName",
        `?nombre=${value}&tipo=1`
      );
    } else {
      data = await getListV2(
        empresa,
        "SearchServicioByCode",
        `?codigo=${value}&tipo=1`
      );
    }

    if (data._embedded === null) return;
    setDataSearchBuscarDetalle(data._embedded);
  };

  const handleChangeCantidad = (e) => {
    if (e.target.value.length >= 10) {
      return e.target.value;
    }
    if (e.target.value.match(/^\d{0,}(\.\d{0,4})?$/)) {
      formik.handleChange(e);
      return e.target.value;
    }
  };

  const buscarServicio = async (busquedad) => {
    let res = await getList(
      `api/SearchReportesServicios/ObtenerServicios?busquedad=${busquedad}&idEmpresa=${empresa.idEmpresa}&tipoBusqueda=codigo&tipo=1`
    );
    return res;
  };

  useEffect(() => {
    servicesCount();
  }, []);

  const [options, setOptions] = useState([]);

  const servicesCount = async () => {
    let res = await getList(
      `api/SearchReportesServicios/servicios/${empresa.idEmpresa}?codigoPrincipal=&start=0&descripcion=&Estado=True&cantidad=3&tipo=0&Pagina=1&Cantidad=10`
    );
    setOptions(res._embedded);
  };

  const loadOptions = React.useCallback(
    debounce((inputText, callback) => {
      buscarServicio(inputText).then((options) => callback(options));
    }, 400),
    []
  );

  const valorSeleccionado = async (valor) => {
    setItemDetalle(valor);
    // console.log(valor);
  };

  const [isOpen, setIsOpen] = useState(true);
  const permisos = useSelector((store) => store.permisosEmpresa.permisos);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex flex-wrap justify-between dark:border-gray-600 border rounded-t-lg rounded-b-lg flex-col ">
        <h3 className="text-sm flex justify-between py-1.5  px-2 text-left bg-[#003B5B] dark:text-gray-50 dark:bg-gray-700 border-transparent rounded-t-lg font-medium leading-6 text-white">
          <div className="flex group relative">
          Productos
          {permisos.find((el) => el.codigo === "23") ? (
              <button
                data-bs-toggle="modal"
                data-bs-target="#addProductoModal"
                type="button"
                className={
                  " bg-white hover:bg-gray-50 hover:text-white focus:ring-gray-500 ml-2 rounded-md px-1 py-1 flex justify-center align-middle"
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="inline w-5 h-5 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </button>
            ) : (
              <></>
            )}
            <div className="absolute mx-auto w-32 z-50 -top-8 -left-4 hidden dark:bg-white dark:text-black     rounded-md bg-gray-800 group-hover:flex text-center p-1">
              <p className="text-white dark:text-black mx-auto text-sm">
                Crear Producto{" "}
              </p>
            </div>
          </div>
        
            <div className="flex items-center">

           
          <svg
          onClick={() => setIsOpen(!isOpen)}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className={`w-4 h-4 my-auto duration-150 ${isOpen ? "rotate-180":<></>} lg:hidden md:hidden text-white`}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m19.5 8.25-7.5 7.5-7.5-7.5"
          />
        </svg>
        </div>
        </h3>
      
        <Transition
        show={isOpen}
        enter="transition ease-out duration-300" // Aumenta la duración de entrada
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-300" // Aumenta la duración de salida
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="flex lg:flex-row  flex-col gap-3  my-2  p-3  justify-center ">
          <div className="basis-1/4 pt-2 ">
            <label
              htmlFor="selectedDestinatario"
              className="block  text-[0.813rem] font-medium text-black dark:text-white"
            >
              {" "}
              Destinatario:{" "}
            </label>
            <select
              className={StyleSelect}
              placeholder=" "
              id="selectedDestinatario"
              name="selectedDestinatario"
              value={formik.values.selectedDestinatario}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              {DestinatariosList.map((el, index) => (
                <option key={index} value={el.IdentificacionDestinatario}>
                  {el.RazonSocialDestinatario} - {el.IdentificacionDestinatario}
                </option>
              ))}
            </select>

            <p className="absolute">
              {formik.touched.selectedDestinatario &&
              formik.errors.selectedDestinatario ? (
                <MesageError message={formik.errors.selectedDestinatario} />
              ) : null}
            </p>
          </div>
          <div className="basis-[60%] my-auto ">
            <label className="block text-[0.813rem] font-medium text-black mb-6 dark:text-white"></label>
            <AsyncSelect
              placeholder="Digite el nombre del Productos / Servicios"
              noOptionsMessage={() => sinDatos}
              styles={customStyles}
              className={StyleSelectAsync}
              loadOptions={loadOptions}
              getOptionLabel={(e) =>
                e.codigoPrincipal +
                " - " +
                e.descripcion +
                ` -  $${e.precioUnitario}`
              }
              getOptionValue={(e) => e.idServicio}
              defaultInputValue={""}
              defaultOptions={options}
              options={options}
              loadingMessage={() => cargando}
              onChange={(e) => {
                valorSeleccionado(e);
                setChangeDetalle(e);
              }}
              id="producto"
              name="producto"
              ref={selectAssyncRef}
              value={changeDetalle}
            />
          </div>
          <div className="basis-[15%] my-auto">
            <label className="block text-[0.813rem] font-medium text-black dark:text-white">
              {" "}
              Cantidad:{" "}
            </label>
            <input
              type="text"
              className={StyleInput}
              placeholder=" "
              id="cantidad"
              name="cantidad"
              value={formik.values.cantidad}
              onChange={handleChangeCantidad}
              onBlur={formik.handleBlur}
            />
          </div>

          <div className="basis-1/4 ">
            <button
              type="submit"
              className=" inline-block mt-4 text-white bg-blue-600 dark:border-gray-600 dark:bg-gray-900 w-full lg:w-28  hover:bg-blue-700  font-semibold p-2 text-[0.813rem] border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:shadow-outline-grayduration-150 ease-in-out"
              onClick={formik.handleSubmit}
              disabled={IsLoading}
            >
              {IsLoading ? (
                "Cargando..."
              ) : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="inline mr-1   -ml-1 w-5 h-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  AGREGAR
                </>
              )}
            </button>
          </div>
        </div>
        </Transition>
      </div>
    </form>
  );
};

export default FormBuscarDetalle;
