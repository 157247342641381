import React, { useState, useEffect } from "react";
import { StyleInput } from "../../../../features/Constantes";
import { MailsRegExp } from "../../../../services/Utilitario";
//import useGlobalToastify from "../../../../features/hooks/GlobalToastify/useGlobalToastify";
import { toast } from "react-toastify";

const MailsFields = ({ setValue, defValue = "" }) => {
  const [InputValue, setInputValue] = useState("");
  const [IsLoading, setIsLoading] = useState(false);

  const mailsArray =
    defValue === "" || defValue === null ? [] : defValue.split(";");

  const handleAddMail = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (InputValue === "") {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
      return toast.info("Asegurese de ingresar un correo antes.", {
        autoClose: 2000,
        closeOnClick: true,
      });
    }
    if (!MailsRegExp.test(InputValue)) {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
      return toast.info("El valor ingresado no es el un correo", {
        autoClose: 2000,
        closeOnClick: true,
      });
    }

    let mails = defValue === "" || defValue === null ? [] : defValue.split(";");

    let data = [...mails, InputValue];

    setValue(data.join(";"));
    setInputValue("");
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const handleDeleteMail = (el) => {
    const mails = defValue.split(";");

    let index = mails.indexOf(el);

    if (index !== -1) mails.splice(index, 1);

    setValue(mails.join(";"));
  };

  const handleFocus = () => {
    if (InputValue.includes(" ")) {
      setInputValue(InputValue.replace(/\s/g, ""));
    }
  };

  return (
    <div className="">
      <div className="flex space-x-1">
        <input
          className={StyleInput}
          type="text"
          name="mail"
          id="mail"
          pattern="[A-Za-z]{3}"
          value={InputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={handleFocus}
        />
        <button
          disabled={IsLoading}
          type="button"
          onClick={(e) => handleAddMail(e)}
          className="bg-[#044C74] dark:bg-gray-700 dark:hover:bg-gray-800 hover:bg-[#044C74] py-1 px-2 rounded-md text-white border-0 ring-1 ring-blue-400"
        >
          {IsLoading ? (
            "..."
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path
                fillRule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 9a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V15a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V9Z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </button>
      </div>

      <div className="my-2  max-h-28 overflow-y-auto  overflow-x-auto">
        {mailsArray.map((el, key) => (
          <div className="bg-gray-50 dark:bg-gray-700 dark:border-gray-700  shadow-md">
            <div
              key={key}
              className="flex  justify-between border-b-[1px] py-0.5 border-gray-50 dark:border-gray-800"
            >
              <div className="mx-auto dark:text-white">{el}</div>
              <button
                type="button"
                className="right-0 relative"
                onClick={(e) => handleDeleteMail(el)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5 text-gray-600 dark:text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MailsFields;
