import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Oval } from "react-loader-spinner";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import {
  StyleInput,
  StyleInputError,
  StyleLabel,
  StyleLabelError,
  StyleSelect,
} from "../../../../features/Constantes";
import { getListV2, PostElement } from "../../../../services";
import { MailsRegExp, UTF8RegExp } from "../../../../services/Utilitario";
import MailsForm from "../components/MailsForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import "react-phone-number-input/style.css";

const AddContribuyente = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
    setValue,
    setError,
    clearErrors,
    watch,
  } = useForm({ mode: "onSubmit" });

  const navigate = useNavigate();

  const initialState = {
    identificacion: "",
    razonsocial: "",
    telefonocelular: "",
    telefonoconvencional: "",
    activo: "True",
    direccion: "",
    correo: "",
    idTipoIdentificacion: "",
    IsTransportista: "0",
    Placa: "",
    IsPartRel: "False",
  };

  const [ToggleIsTransportista, setToggleIsTransportista] = useState("0");
  const [ImpLista, setImpLista] = useState([]);
  const [IsLoading, setIsLoading] = useState(false);

  const [ShowButton, setshowButton] = useState(false);

  const [NewItem, setNewItem] = useState(initialState);
  const [OpenCorreo, setOpenCorreo] = useState(false);
  const celularObs = watch("telefonocelular");
  const convencionalObs = watch("telefonoconvencional");
  const identificacionObs = watch("identificacion");

  const empresa = useSelector((store) => store.empresa.empresa);
  useEffect(() => {
    (async () => {
      let impList = await getListV2(
        empresa,
        "GetTipoIdentificacion",
        "?activo=true"
      );
      let filterElements = impList._embedded.filter((el) => el.codigo !== "07");
      setImpLista(filterElements);

      setNewItem({
        ...initialState,
        idEmpresa: empresa.idEmpresa,
        idTipoIdentificacion: impList._embedded[0].idTipoIdentificacion,
      });
    })();
  }, []);

  const [EsRucUse, setEsRucUse] = useState(false);
  const [EsCedulaUse, setEsCedulaUse] = useState(false);

  const handleSubmitModal = async () => {
    setIsLoading(true);

    let TotalValues = { ...getValues() };
    TotalValues.identificacion = TotalValues.identificacion.trim();
    TotalValues.razonsocial = TotalValues.razonsocial.trim();
    TotalValues.correo = TotalValues.correo.trim();
    TotalValues.telefonocelular = TotalValues.telefonocelular
      ? TotalValues.telefonocelular.replaceAll("-", "")
      : TotalValues.telefonocelular;
    TotalValues.telefonoconvencional = TotalValues.telefonoconvencional
      ? TotalValues.telefonoconvencional.replaceAll("-", "")
      : TotalValues.telefonoconvencional;
    TotalValues.IsTransportista = ToggleIsTransportista === "1";
    TotalValues.IsPartRel = TotalValues.IsPartRel === "1";
    TotalValues.activo = TotalValues.activo === "1";

    if (TotalValues.identificacion === "") {
      setIsLoading(false);
      return toast.warning("El campo identificacion es obligatorio!", {
        isLoading: false,
        autoClose: 2000,
      });
    }
    if (TotalValues.razonsocial === "") {
      setIsLoading(false);
      return toast.warning("El campo razón social es obligatorio!", {
        isLoading: false,
        autoClose: 2000,
      });
    }
    if (TotalValues.direccion.trim() === "") {
      setIsLoading(false);
      return toast.warning("El campo direccion es obligatorio!", {
        isLoading: false,
        autoClose: 2000,
      });
    }

    try {
      if (getValues().identificacion.length > 10) {
        const identificacionSinUltimosTres = getValues().identificacion.replace(
          /001$/,
          ""
        );
        await axios.get(
          `api/contribuyente/existe/${empresa.idEmpresa}?identificacion=${identificacionSinUltimosTres}`
        );
      } else {
        await axios.get(
          `api/contribuyente/existe/${empresa.idEmpresa}?identificacion=${
            getValues().identificacion
          }`
        );
      }
      clearErrors();
    } catch {
      setError("identificacion", "Ya existe esta identificacion");

      toast.error("Ya existe esta identificación");
      setIsLoading(false);
      return;
    }

    if (TotalValues.EsRuc === "1") {
    } else if (
      TotalValues.EsCedula === "1" &&
      TotalValues.identificacion.length === 10
    ) {
      TotalValues.identificacion = TotalValues.identificacion + "001";
    }

    if (TotalValues.EsRuc || TotalValues.EsCedula) {
      delete TotalValues.EsRuc;
      delete TotalValues.EsCedula;
    }

    // if (TotalValues.correo !== "") {
    //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //   if (!emailRegex.test(TotalValues.correo)) {
    //     setIsLoading(false);
    //     return toast.warning("El correo ingresado no es válido!", {
    //       isLoading: false,
    //       autoClose: 2000,
    //     });
    //   }
    // }
    if (TotalValues.correo === "") {
      const emails = TotalValues.correo.split(";");
      for (let i = 0; i < emails.length; i++) {
        const email = emails[i].trim();
        if (!MailsRegExp.test(email)) {
          setIsLoading(false);
          return toast.warning("El correo ingresado no es válido!", {
            isLoading: false,
            autoClose: 2000,
          });
        }
      }
    }

    const postRes = await PostElement(
      empresa,
      "PostContribuyente",
      TotalValues
    );

    const toastPromise = () =>
      new Promise((resolve) => setInterval(() => resolve(postRes), 2000));

    toast.promise(toastPromise(), {
      pending: {
        render() {
          setIsLoading(true);
          return "Añadiendo contribuyente...";
        },
        isLoading: true,
      },
      success: {
        render() {
          setNewItem(initialState);
          reset(initialState);
          setToggleIsTransportista("0");
          setNewItem({
            ...initialState,
            idTipoIdentificacion: ImpLista[0].idTipoIdentificacion,
          });
          setIsLoading(false);
          navigate("/emision/reporte/contribuyentes");
          return "Contribuyente registrado con exito!";
        },
        autoClose: 2000,
        closeOnClick: true,
      },
      error: {
        render() {
          setIsLoading(false);
          return "Algo a pasado, intente nuevamente";
        },
        autoClose: 2000,
        closeOnClick: true,
      },
    });
    setIsLoading(false);
  };

  const handleChangeIdentificacion = (e) => {
    let isValid = /^[a-zA-Z0-9 -]*$/.test(e.target.value);
    if (isValid) {
      setValue("identificacion", e.target.value);
    }
    return e.preventDefault();
  };

  const [tipoIdentificacion, setTipoIdentificacion] = useState("");

  useEffect(() => {
    const tipoIdentificacionMap = [
      { pattern: /^\d{10}$/, type: "Cédula" }, // Cédula con 10 dígitos
      { pattern: /^\d{13}$/, type: "RUC" }, // RUC con 13 dígitos
      { pattern: /^\d{11}$/, type: "Pasaporte" }, // 11 o 12 dígitos, vacío
      { pattern: /^[a-zA-Z0-9]+$/, type: "Pasaporte" }, // Pasaporte con letras y números
    ];
    const match = tipoIdentificacionMap.find((item) =>
      item.pattern.test(identificacionObs)
    );
    setTipoIdentificacion(match ? match.type : "");
  }, [identificacionObs]);

  return (
    <div className="space-y-6 xl:mx-4 my-2 mt-5 bg-white dark:bg-gray-700 p-4">
      <div className="bg-[#DBDBDB] dark:bg-gray-800 dark:text-white p-5 rounded-md ">
        <p className="font-semibold text-xl dark:text-white  text-black">
          Información del Contribuyente / Cliente
        </p>
        <p>Agregar información</p>
      </div>

      <form
        onSubmit={handleSubmit(handleSubmitModal)}
        className="bg-white shadow  dark:bg-gray-900 sm:rounded-lg md:py-2 md:px-4 py-5 px-2"
      >
        <p className="p-2 border-b text-lg text-black font-semibold border-b-gray-400">
          Datos Generales
        </p>
        <div className="relative z-0 mb-2  pt-2 w-full  mt-7 ">
          <div className="w-full grid md:grid-cols-5  lg:grid-cols-5 grid-cols-1 md:gap-4 mb-2  ">
          <div className="relative z-0 mb-2 w-full ">
              <input
                type="text"
                className={errors.identificacion ? StyleInputError : StyleInput}
                placeholder=" "
                id="identificacion"
                name="identificacion"
                {...register("identificacion", {
                  required: {
                    value: true,
                    message: "Identificación es requerido",
                  },
                  maxLength: {
                    value: 20,
                    message: "No más de 20 carácteres!",
                  },
                  minLength: {
                    value: 3,
                    message: "Mínimo 3 carácteres",
                  },
                  pattern: /^[a-zA-Z0-9 -]+$/,
                })}
                maxLength="20"
        
              />
              <div
                htmlFor="identificacion"
                className={errors.correo ? StyleLabelError : StyleLabel}
              >
                <div className=" flex  relative">
                  Identificación
                  {tipoIdentificacion && ` / ${tipoIdentificacion}`} *
                  <div className="px-2    group">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 30 30"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                      />
                    </svg>
                    <div
                      className="absolute   text-white text-sm
                      -top-14 -left-1 hidden dark:bg-white dark:text-black 
                       rounded-md bg-gray-800 group-hover:flex text-center p-1"
                    >
                      Número de RUC, cédula o pasaporte
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative z-0 mb-2 w-full group">
              <input
                type="text"
                className={errors.razonsocial ? StyleInputError : StyleInput}
                placeholder=" "
                id="razonsocial"
                name="razonsocial"
                {...register("razonsocial", {
                  required: {
                    value: true,
                    message: "Nombre es requerido",
                  },
                  maxLength: {
                    value: 300,
                    message: "No más de 300 carácteres!",
                  },
                  minLength: {
                    value: 4,
                    message: "Mínimo 4 carácteres",
                  },
                  pattern: UTF8RegExp,
                })}
                maxLength="300"
              />
              <label
                htmlFor="razonsocial"
                className={errors.razonsocial ? StyleLabelError : StyleLabel}
              >
                Razón social *
              </label>
            </div>
            <div className="relative z-0 mb-2 w-full group">
              <input
                type="text"
                className={errors.direccion ? StyleInputError : StyleInput}
                placeholder=" "
                id="direccion"
                name="direccion"
                {...register("direccion", {
                  required: {
                    value: true,
                    message: "Nombre es requerido",
                  },
                  maxLength: {
                    value: 300,
                    message: "No más de 300 carácteres!",
                  },
                  minLength: {
                    value: 3,
                    message: "Mínimo 3 carácteres",
                  },
                  pattern: UTF8RegExp,
                })}
                maxLength="300"
              />
              <label
                htmlFor="direccion"
                className={errors.direccion ? StyleLabelError : StyleLabel}
              >
                Dirección *
              </label>
            </div>
            <div className="relative z-0 w-full col-span-2 ">
              <input
                type="text"
                className={errors.correo ? StyleInputError : StyleInput}
                placeholder=" "
                id="correo"
                name="correo"
                {...register("correo", {
                  required: {
                    value: true,
                    message: "Correo es requerido",
                  },
                  maxLength: {
                    value: 300,
                    message: "No más de 300 carácteres!",
                  },
                  minLength: {
                    value: 4,
                    message: "Mínimo 4 carácteres",
                  },
                  pattern: MailsRegExp,
                })}
                maxLength="300"
                onChange={(e) => {
                  const trimmedValue = e.target.value.trim(); // Eliminar espacios en blanco
                  setValue("correo", trimmedValue); // Actualizar el valor del campo
                }}
              />
              <div
                htmlFor="correo"
                className={errors.correo ? StyleLabelError : StyleLabel}
              >
                <div className=" flex ">
                  Correo *{" "}
                  {/*  <label className="border bg-green-400 relative"> */}
                  <div className="px-2 relative group">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 30 30"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                      />
                    </svg>
                    <div
                      className="absolute   text-white text-sm
                      -top-8 -left-8 hidden dark:bg-white dark:text-black 
                       rounded-md bg-gray-800 group-hover:flex text-center p-1"
                    >
                      ejemplo1@hotmail.com;ejemplo2@hotmail.com;...
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative z-0 mb-2 w-full ">
              <input
                type="text"
                id="telefonocelular"
                name="telefonocelular"
                className={
                  errors.telefonocelular ? StyleInputError : StyleInput
                }
                {...register("telefonocelular", {
                  maxLength: {
                    value: 14,
                    message: "No más de 13 carácteres!",
                  },
                  minLength: {
                    value: 4,
                    message: "Mínimo 4 carácteres",
                  },
                })}
                maxLength={14}
                pattern="^\+?[0-9]+$"
                onChange={(e) => {
                  const value = e.target.value;
                  const regex = /^\+?[0-9]*$/;
                  const plusCount = (value.match(/\+/g) || []).length;
                  if (
                    regex.test(value) &&
                    plusCount <= 1 &&
                    (plusCount === 0 || value.startsWith("+"))
                  ) {
                    setValue("telefonocelular", value);
                  } else {
                    let sanitizedValue = value.replace(/[^0-9]/g, "");
                    if (value.startsWith("+")) {
                      sanitizedValue = "+" + sanitizedValue;
                    }
                    setValue("telefonocelular", sanitizedValue);
                  }
                }}
              />

              <div
                htmlFor="telefonoconvencional"
                className={
                  errors.telefonoconvencional ? StyleLabelError : StyleLabel
                }
              >
                <div className=" flex ">
                  Teléfono celular (Opcional){" "}
                  {/*  <label className="border bg-green-400 relative"> */}
                  <div className="px-2 relative group">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 30 30"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                      />
                    </svg>
                    <div
                      className="absolute  text-white text-sm  -top-8 -left-8 hidden dark:bg-white dark:text-black   
                        rounded-md bg-gray-800 group-hover:flex text-center p-1 "
                    >
                      (EJ:0958922462)
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative z-0  w-full group">
              <select
                name="activo"
                id="activo"
                className={StyleSelect + " hidden"}
                x-model="item.gst"
                {...register("activo", {})}
              >
                <option value="1">ACTIVO</option>
                <option value="0">INACTIVO</option>
              </select>
              <label htmlFor="activo" className={StyleLabel + " hidden"}>
                Estado
              </label>
            </div>
          </div>
        </div>

        {/* <div className="relative z-0 mb-2 w-full group">
          <div className="w-full grid md:grid-cols-2 md:gap-4 mb-2 ">
            <div className="relative z-0 mb-2 w-full group">
              <NumberFormat
                format="##-####-###"
                className={
                  errors.telefonoconvencional ? StyleInputError : StyleInput
                }
                placeholder=" "
                id="telefonoconvencional"
                name="telefonoconvencional"
                {...register("telefonoconvencional", {
                  maxLength: {
                    value: 15,
                    message: "No más de 20 carácteres!",
                  },
                  minLength: {
                    value: 4,
                    message: "Mínimo 4 carácteres",
                  },
                })}
                onChange={(e) =>
                  setValue("telefonoconvencional", e.target.value)
                }
                value={convencionalObs}
              />
              <label
                htmlFor="telefonoconvencional"
                className={
                  errors.telefonoconvencional ? StyleLabelError : StyleLabel
                }
              >
                Teléfono convencional <b>(EJ: 04-289-0005)</b>
              </label>
            </div>
         
          </div>
        </div> */}

        <div className="relative z-0   w-full group">
          {/* <div className="w-full grid md:grid-cols-3 md:gap-4  ">
            <div className="relative z-0  w-full group">
              <select
                name="activo"
                id="activo"
                className={StyleSelect}
                x-model="item.gst"
                {...register("activo", {})}
              >
                <option value="1">ACTIVO</option>
                <option value="0">INACTIVO</option>
              </select>
              <label htmlFor="activo" className={StyleLabel}>
                Estado
              </label>
            </div>

            {["RUC"].includes(tipoIdentificacion) && (
              <div className="relative z-0 w-full group">
                <select
                  name="EsRuc"
                  id="EsRuc"
                  className={StyleSelect}
                  x-model="item.gst"
                  {...register("EsRuc", {})}
                //onChange={handleEsRucChange}
                >
                  <option value="1">SI</option>
                  <option value="0">NO</option>
                </select>
                <label htmlFor="EsRuc" className={StyleLabel}>
                  También es Cédula
                </label>
              </div>
            )}

            {["Cédula"].includes(tipoIdentificacion) && (
              <div className="relative z-0 w-full group">
                <select
                  name="EsCedula"
                  id="EsCedula"
                  className={StyleSelect}
                  x-model="item.gst"
                  {...register("EsCedula", {})}
                //onChange={handleEsRucChange}
                >
                  <option value="0">NO</option>
                  <option value="1">SI</option>
                </select>
                <label htmlFor="EsCedula" className={StyleLabel}>
                  También es RUC
                </label>
              </div>
            )}
          </div> */}

          <h1 className="text-lg mb-8 dark:text-white   font-semibold p-2 border-b border-b-gray-400">
            Datos Adicionales
          </h1>
          <div className="w-full grid md:grid-cols-5 md:gap-4 pt-2 ">
            <div className="relative z-0 mb-2 w-full ">
              <NumberFormat
                format="##-####-###"
                className={
                  errors.telefonoconvencional ? StyleInputError : StyleInput
                }
                placeholder=" "
                id="telefonoconvencional"
                name="telefonoconvencional"
                {...register("telefonoconvencional", {
                  maxLength: {
                    value: 15,
                    message: "No más de 15 carácteres!",
                  },
                  minLength: {
                    value: 4,
                    message: "Mínimo 4 carácteres",
                  },
                })}
                maxLength="15"
                onChange={(e) =>
                  setValue("telefonoconvencional", e.target.value)
                }
                value={convencionalObs}
              />

              <div
                htmlFor="telefonoconvencional"
                className={
                  errors.telefonoconvencional ? StyleLabelError : StyleLabel
                }
              >
                <div className=" flex ">
                  Teléfono convencional (Opcional){" "}
                  {/*  <label className="border bg-green-400 relative"> */}
                  <div className="px-2 relative group">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 30 30"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                      />
                    </svg>
                    <div
                      className="absolute  text-white text-sm  -top-8 -left-8 hidden dark:bg-white dark:text-black   
                        rounded-md bg-gray-800 group-hover:flex text-center p-1"
                    >
                      (EJ:042890005)
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {["RUC"].includes(tipoIdentificacion) && (
              <div className="relative z-0 w-full group">
                <select
                  name="EsRuc"
                  id="EsRuc"
                  className={StyleSelect}
                  x-model="item.gst"
                  {...register("EsRuc", {})}
                  //onChange={handleEsRucChange}
                >
                  <option value="1">SI</option>
                  <option value="0">NO</option>
                </select>
                <label htmlFor="EsRuc" className={StyleLabel}>
                  También es Cédula
                </label>
              </div>
            )}

            {["Cédula"].includes(tipoIdentificacion) && (
              <div className="relative z-0 w-full group">
                <select
                  name="EsCedula"
                  id="EsCedula"
                  className={StyleSelect}
                  x-model="item.gst"
                  {...register("EsCedula", {})}
                  //onChange={handleEsRucChange}
                >
                  <option value="0">NO</option>
                  <option value="1">SI</option>
                </select>
                <label htmlFor="EsCedula" className={StyleLabel}>
                  También es RUC
                </label>
              </div>
            )}
            <div className="relative z-0  w-full group">
              <select
                name="IsPartRel"
                id="IsPartRel"
                className={StyleSelect}
                x-model="item.gst"
                {...register("IsPartRel", {})}
              >
                <option value="0">NO</option>
                <option value="1">SI</option>
              </select>
              <label htmlFor="IsPartRel" className={StyleLabel}>
                Es Parte Relacionada
              </label>
            </div>
            <div className="relative z-0 mb-2 w-full group">
              <select
                name="IsTransportista"
                id="IsTransportista"
                className={StyleSelect}
                x-model="item.gst"
                {...register("IsTransportista", {})}
                onChange={(e) => setToggleIsTransportista(e.target.value)}
              >
                <option value="0">NO</option>
                <option value="1">SI</option>
              </select>
              <label htmlFor="IsTransportista" className={StyleLabel}>
                Es transportista
              </label>
            </div>
            {ToggleIsTransportista === "1" ? (
              <div className="relative z-0 mb-2 w-full group">
                <input
                  type="text"
                  className={errors.Placa ? StyleInputError : StyleInput}
                  placeholder=" "
                  id="Placa"
                  name="Placa"
                  {...register("Placa", {
                    maxLength: {
                      value: 10,
                      message: "No más de 10 carácteres!",
                    },
                    minLength: {
                      value: 4,
                      message: "Mínimo 4 carácteres",
                    },
                    required: {
                      value: true,
                      message: "Nombre es requerido",
                    },
                    pattern: UTF8RegExp,
                  })}
                  maxLength="10"
                />
                <label
                  htmlFor="Placant"
                  className={errors.Placa ? StyleLabelError : StyleLabel}
                >
                  Placa <b>(GMT-1234)</b>
                </label>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="">
          <div>
            <div>
              <p
                onClick={() => setOpenCorreo(!OpenCorreo)}
                className="text-lg font-semibold p-2 border-b border-b-gray-400 select-none cursor-pointer dark:text-white flex gap-x-2  items-center   font-body"
              >
                Personalización de correos de envío (Opcional){" "}
                {!OpenCorreo ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                )}
              </p>
              {OpenCorreo ? (
                <MailsForm setValue={setValue} watch={watch} />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="flex align-middle lg:justify-end justify-center my-2  ">
          <button>
            <a
              onClick={() => navigate("/emision/reporte/contribuyentes")}
              className="text-white bg-[#6B6B6B] hover:bg-gray-600 focus:ring-4 focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-base px-6 py-2.5 text-center md:mr-5 mb-3 md:mb-0 inline-flex items-center justify-center"
            >
              Cancelar
            </a>
          </button>
          {!IsLoading ? (
            <button
              type="submit"
              disabled={IsLoading}
              className=" text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-base px-6 py-2.5 text-center md:mr-5 mb-3 md:mb-0 inline-flex items-center justify-center"
            >
              <p>Agregar</p>
            </button>
          ) : (
            <button
              disabled
              className=" text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-base px-6 py-2.5 text-center md:mr-5 mb-3 md:mb-0 inline-flex items-center justify-center"
            >
              <Oval
                height={18}
                width={65}
                color="#FFFFFF"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#233876"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default AddContribuyente;
