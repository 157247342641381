import { useDispatch, useSelector } from "react-redux"
import { WarningIcon } from "../../../../Icons/WarningIcon"
import { BodegaModalOff } from "./modalCrearBodega.slice"
import { useContext } from "react"
import { BodegaContext } from "./context/BodegasContext"


export const ModalEliminarBodega = () => {

    const { deleteBodega } = useContext(BodegaContext)
    const registro = useSelector((state) => state.modalBodega.registroAfectado)
    const dispatch = useDispatch()

    const doBodegaDelete = () => {
        dispatch(BodegaModalOff())
        deleteBodega(registro.id)
    }

    return (
        <section style={{ padding: "38px", height: "fit-content", width: "fit-content", backgroundColor: "white", position: "relative" }} className="shadow-xl rounded-lg flex flex-col gap-10 items-center">
            <WarningIcon />
            <h1 className="text-2xl font-semibold">Estas seguro que deseas eliminar la bodega {registro.nombreBodega}</h1>
            <div className="flex flex-row gap-12 text-white">
                <button onClick={() => doBodegaDelete()} className="bg-green-400 hover:bg-green-600 p-6 h-8 text-[12px]  w-28 flex items-center justify-center rounded-xl">Confirmar</button>
                <button onClick={() => dispatch(BodegaModalOff())} className="bg-gray-600 p-6 h-8 text-[12px] hover:bg-gray-700 flex items-center justify-center w-28 rounded-xl">Cancelar</button>
            </div>
        </section>
    )
}