import React, { useState } from "react";
import { CardPlanesFactura } from "../Components/CardPlanesFactura";
import { PlanNatural } from "../data/PlanNatural";
import { PlanFactura } from "../data/PlanFactura";


const FormFactuelect = () => {
  const itemTexts = PlanFactura.map((item) => item.text);

  const [Value, setValue] = useState("50");
  const precios = {
    "50": "10.17",
    "100": "20.16",
    "200": "39.20",
    "400": "53.76",
    "600": "76.16",
    "800": "98.56",
    "1500": "174.72",
  };

  const active =
    "relative inline-flex items-center px-4 py-2 rounded-l-md border transition-colors duration-300  border-gray-300 bg-[#00314D] text-white text-sm font-medium text-gray-700  focus:z-10 focus:outline-none  ";
  const inactive =
    "relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500";

  return (
    <div className="">
        <div className="bg-white dark:bg-gray-700 p-3 rounded-md">

       
      <div className="bg-[#DBDBDB] dark:text-white  rounded-md dark:bg-gray-700 p-4 ">
        <p className="font-semibold text-2xl">Planes de Facturación Electrónica</p>
        <p>Nueva Facturación Electrónica</p>
      </div>
      </div>
      <div className="bg-white dark:bg-gray-700 p-4 my-4 rounded-lg">
  
      <div className="my-10 dark:text-white justify-center items-center grid gap-x-2">
        <p className="dark:text-white text-center mb-6">Seleccione cantidad de comprobantes:</p>
        <div className="  justify-center items-center lg:flex ">
          <button
            onClick={() => setValue("50")}
            type="button"
            className={Value === "50" ? active : inactive}
          >
            50
          </button>
          <button
            onClick={() => setValue("100")}
            type="button"
            className={
              Value === "100"
                ? "-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-[#00314D] text-white text-sm font-medium   focus:z-10 focus:outline-none "
                : "-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 transition-colors duration-300"
            }
            //className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          >
            100
          </button>
          <button
            onClick={() => setValue("200")}
            type="button"
            className={
              Value === "200"
                ? "-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-[#00314D] text-white text-sm font-medium   focus:z-10 focus:outline-none "
                : "-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 transition-colors duration-300"
            }
          >
           200
          </button>
          <button
            onClick={() => setValue("400")}
            type="button"
            className={
              Value === "400"
                ? "-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-[#00314D] text-white text-sm font-medium   focus:z-10 focus:outline-none "
                : "-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 transition-colors duration-300"
            }
          >
           400
          </button>
          <button
            onClick={() => setValue("600")}
            type="button"
            className={
              Value === "600"
                ? "-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-[#00314D] text-white text-sm font-medium   focus:z-10 focus:outline-none "
                : "-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 transition-colors duration-300"
            }
            //className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          >
           600
          </button>
          <button
            onClick={() => setValue("800")}
            type="button"
            className={
              Value === "800"
                ? "-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-[#00314D] text-white text-sm font-medium   focus:z-10 focus:outline-none  "
                : "-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 transition-colors duration-300"
            }
            //className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          >
          800
          </button>
          <button
            onClick={() => setValue("1500")}
            type="button"
            className={
              Value === "1500"
                ? "-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-[#00314D] text-sm font-medium text-white  focus:z-10 focus:outline-none "
                : "-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
            }
            //className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          >
          1500
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-10 max-w-[30rem] my-6 mx-auto">
        <CardPlanesFactura
          link="https://api.whatsapp.com/send/?phone=593961820002&text=+Hola%2C+me+interesa+contratar+un+plan+de+comprobantes+electr%C3%B3nicos+anuales&type=phone_number&app_absent=0"
          titulo="Vigencia 1 año"
          precio={precios[Value] || null}
          lista={itemTexts}
        />
       
      </div>
            
      </div>
    </div>
  );
};

export default FormFactuelect;
