import React from "react";
import TablaReporteMasivo from "./TablaReporteMasivo";
import ReporteMasivoProvider from "./ReporteMasivoProvider";

const ReporteSolicitudMasivo = () => {
  return (
    <ReporteMasivoProvider>
      {/* <div className="space-y-6 xl:mx-4 my-2"> */}
     
        {/* <div className="bg-white shadow  dark:bg-gray-900 sm:rounded-lg md:py-2 md:px-4 py-2 px-2"> */}
        <div className="mx-4 my-3">
          <TablaReporteMasivo />
        </div>
      
    </ReporteMasivoProvider>
  );
};

export default ReporteSolicitudMasivo;
