import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { post } from "../../services";
import { ToastContainer, toast } from "react-toastify";
import fondocubo from "../../img/fondo.jpg";
import cubos from "../../img/cubo.svg";
import { Oval } from "react-loader-spinner";
import Logoo from "../../img/isologo.png";
const ResetNewPass = () => {
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  const [SubmitPass, setSubmitPass] = useState(false);
  const { register, errors, handleSubmit } = useForm();
  const [passwords, setPasswords] = useState({
    Password: "",
    ConfirmPassword: "",
  });

  const [passwordError, setPasswordError] = useState("");
  const [error, setError] = useState(false);
  const [token, settoken] = useState("");
  const [email, setemail] = useState("");
  const [showPass, setShowPass] = useState(false);
  const handlesShow = () => {
    setShowPass(!showPass);
  };

  const [passwordValidations, setPasswordValidations] = useState({
    uppercase: false,
    lowercase: false,
    specialCharacter: false,
    number: false,
  });

  const [showPass2, setShowPass2] = useState(false);
  const handlesShow2 = () => {
    setShowPass2(!showPass2);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (query.get("token") !== null) {
      settoken(query.get("token"));
      setemail(query.get("email"));
    }
  }, [query]);

  useEffect(() => {
    const password = passwords.Password;
    const confirmPassword = passwords.ConfirmPassword;
    const validations = {
      uppercase: /(?=.*[A-Z])/.test(password),
      lowercase: /(?=.*[a-z])/.test(password),
      //specialCharacter: /(?=.*[@$!%*?&])/.test(password),
      specialCharacter: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password),
      number: /\d/.test(password),
      minLength: password.length >= 8,
      maxLength: password.length <= 15,
      passwordsMatch: password === confirmPassword,
    };
    setPasswordValidations(validations);
  }, [passwords.Password, passwords.ConfirmPassword]);

  const onSubmit = async (temp) => {
    try {
      if (passwords.Password !== passwords.ConfirmPassword) {
        setPasswordError("Las contraseñas no coinciden");
        return;
      }

      const passwordPattern =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,15}$/;
      if (!passwordPattern.test(passwords.Password)) {
        setPasswordError(
          "La contraseña debe contener letras mayúsculas y minúsculas, caracteres especiales y números."
        );
        return;
      }

      temp.Token = token;
      temp.Email = email;

      const postRes = await post("/api/cuentas/ResetPassword", temp);
      const toastPro = () =>
        new Promise((resolve) => setInterval(() => resolve(postRes), 2000));

      await toast.promise(toastPro(), {
        pending: {
          render() {
            setSubmitPass(true);
            return "Cambiando contraseña...";
          },
          isLoading: true,
        },
        success: {
          render() {
            navigate("/login");
            setSubmitPass(false);
            return "Contraseña cambiada con exito.";
          },
          autoClose: 2000,
          closeOnClick: true,
        },
        error: {
          render() {
            setSubmitPass(false);
            return "Ah sucedido algo, intente nuevamente.";
          },
          autoClose: 2000,
          closeOnClick: true,
        },
      });
    } catch (error) {
      // console.log(error);
      if (error.code === "ERR_BAD_REQUEST") {
        setError(true);
      }
    }
  };

  return (
    <>
      <div className="flex items-center justify-center min-h-screen bg-[#00314D]">
        <div className="w-full max-w-lg px-10 py-8 mx-auto bg-white rounded-lg shadow-xl">
          <div className="max-w-md mx-auto space-y-6">
            <center>
              <div className="inline-flex ">
                <img
                  src={Logoo}
                  className=" self-center w-16 float-left mr-2 block"
                  alt="asd"
                />
                <span
                  className=" my-4 block truncate dark:text-white text-3xl font-body"
                  style={{ fontWeight: 800 }}
                >
                  Intuito S.A.
                </span>
              </div>
            </center>

            <div className="font-sans text-center text-gray-500">
              <label className="">Restablecimiento de contraseña</label>
              <br></br>
              <label className="">
                Ingresa tu <b>nueva contraseña</b> para recuperar tu cuenta
              </label>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="pt-2">
                <div className="grid md:grid-cols-1 md:gap-4">
                  <label className="">Correo Eléctronico</label>
                  <div className=" relative   rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        className="w-6 h-6 fill-gray-500"
                      >
                        <path d="M504 232C504 95.751 394.053 8 256 8 118.94 8 8 118.919 8 256c0 137.059 110.919 248 248 248 52.926 0 104.681-17.079 147.096-48.321 5.501-4.052 6.423-11.924 2.095-17.211l-15.224-18.597c-4.055-4.954-11.249-5.803-16.428-2.041C339.547 442.517 298.238 456 256 456c-110.28 0-200-89.72-200-200S145.72 56 256 56c109.469 0 200 65.02 200 176 0 63.106-42.478 98.29-83.02 98.29-19.505 0-20.133-12.62-16.366-31.463l28.621-148.557c1.426-7.402-4.245-14.27-11.783-14.27h-39.175a12.005 12.005 0 0 0-11.784 9.735c-1.102 5.723-1.661 8.336-2.28 13.993-11.923-19.548-35.878-31.068-65.202-31.068C183.412 128.66 120 191.149 120 281.53c0 61.159 32.877 102.11 93.18 102.11 29.803 0 61.344-16.833 79.749-42.239 4.145 30.846 28.497 38.01 59.372 38.01C451.467 379.41 504 315.786 504 232zm-273.9 97.35c-28.472 0-45.47-19.458-45.47-52.05 0-57.514 39.56-93.41 74.61-93.41 30.12 0 45.471 21.532 45.471 51.58 0 46.864-33.177 93.88-74.611 93.88z" />
                      </svg>
                    </div>
                    <input
                      type="email"
                      value={email}
                      className="
                          block 
                          w-full
                          px-4 py-2  
                          text-xl 
                          pl-10
                          font-normal
                          border focus:outline-none
                          rounded-lg transition 
                          form-control dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:border-blue-600 border-gray-500 text-gray-700 dark:bg-gray-700 dark:focus:text-white dark:border-gray-600  dark:placeholder-gray-400 dark:text-white focus:text-gray-700 
                          "
                      placeholder=" "
                    />
                  </div>
                  <div className="relative z-0  w-full group">
                    <div className="absolute inset-y-0 left-0 pl-3 pt-6 flex items-center ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6 stroke-gray-500"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                        />
                      </svg>
                    </div>
                    <div className="absolute inset-y-0 right-0 pr-3 pt-6 flex items-center ">
                      <button type="button" onClick={handlesShow}>
                        {showPass ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 576 512"
                            className="w-6 h-6 fill-gray-500"
                          >
                            <path d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 640 512"
                            className="w-6 h-6 fill-gray-500"
                          >
                            <path d="M634 471L36 3.51A16 16 0 0 0 13.51 6l-10 12.49A16 16 0 0 0 6 41l598 467.49a16 16 0 0 0 22.49-2.49l10-12.49A16 16 0 0 0 634 471zM296.79 146.47l134.79 105.38C429.36 191.91 380.48 144 320 144a112.26 112.26 0 0 0-23.21 2.47zm46.42 219.07L208.42 260.16C210.65 320.09 259.53 368 320 368a113 113 0 0 0 23.21-2.46zM320 112c98.65 0 189.09 55 237.93 144a285.53 285.53 0 0 1-44 60.2l37.74 29.5a333.7 333.7 0 0 0 52.9-75.11 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64c-36.7 0-71.71 7-104.63 18.81l46.41 36.29c18.94-4.3 38.34-7.1 58.22-7.1zm0 288c-98.65 0-189.08-55-237.93-144a285.47 285.47 0 0 1 44.05-60.19l-37.74-29.5a333.6 333.6 0 0 0-52.89 75.1 32.35 32.35 0 0 0 0 29.19C89.72 376.41 197.08 448 320 448c36.7 0 71.71-7.05 104.63-18.81l-46.41-36.28C359.28 397.2 339.89 400 320 400z" />
                          </svg>
                        )}
                      </button>
                    </div>
                    <label className="">Contraseña Nueva</label>
                    <input
                      type={showPass ? "text" : "password"}
                      className="block 
                            w-full
                            px-4 py-2  
                            text-xl 
                            font-normal
                            pl-10
                            pr-10
                          
                            border focus:outline-none
                            rounded-lg transition
                            form-control dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:border-blue-600 border-gray-500 text-gray-700 dark:bg-gray-700 dark:focus:text-white dark:border-gray-600  dark:placeholder-gray-400 dark:text-white focus:text-gray-700
                            "
                      placeholder=" "
                      required=""
                      {...register("Password", {
                        required: {
                          value: true,
                          message: "Contraseña es requerido",
                        },
                        maxLength: {
                          value: 15,
                          message: "No más de 15 carácteres!",
                        },
                        minLength: {
                          value: 8,
                          message: "Mínimo 8 carácteres",
                        },
                      })}
                      value={passwords.Password}
                      onChange={(e) =>
                        setPasswords((prevPasswords) => ({
                          ...prevPasswords,
                          Password: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className="relative z-0 mb-6  w-full group">
                    <div className="absolute inset-y-0 left-0 pl-3 pt-6 flex items-center ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6 stroke-gray-500"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                        />
                      </svg>
                    </div>
                    <div className="absolute inset-y-0 right-0 pr-3 pt-6 flex items-center ">
                      <button type="button" onClick={handlesShow2}>
                        {showPass2 ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 576 512"
                            className="w-6 h-6 fill-gray-500"
                          >
                            <path d="M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 640 512"
                            className="w-6 h-6 fill-gray-500"
                          >
                            <path d="M634 471L36 3.51A16 16 0 0 0 13.51 6l-10 12.49A16 16 0 0 0 6 41l598 467.49a16 16 0 0 0 22.49-2.49l10-12.49A16 16 0 0 0 634 471zM296.79 146.47l134.79 105.38C429.36 191.91 380.48 144 320 144a112.26 112.26 0 0 0-23.21 2.47zm46.42 219.07L208.42 260.16C210.65 320.09 259.53 368 320 368a113 113 0 0 0 23.21-2.46zM320 112c98.65 0 189.09 55 237.93 144a285.53 285.53 0 0 1-44 60.2l37.74 29.5a333.7 333.7 0 0 0 52.9-75.11 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64c-36.7 0-71.71 7-104.63 18.81l46.41 36.29c18.94-4.3 38.34-7.1 58.22-7.1zm0 288c-98.65 0-189.08-55-237.93-144a285.47 285.47 0 0 1 44.05-60.19l-37.74-29.5a333.6 333.6 0 0 0-52.89 75.1 32.35 32.35 0 0 0 0 29.19C89.72 376.41 197.08 448 320 448c36.7 0 71.71-7.05 104.63-18.81l-46.41-36.28C359.28 397.2 339.89 400 320 400z" />
                          </svg>
                        )}
                      </button>
                    </div>
                    <label className="">Confirmar Contraseña</label>
                    <input
                      type={showPass2 ? "text" : "password"}
                      className="block 
                            w-full
                            px-4 py-2  
                            text-xl 
                            font-normal
                            
                            pl-10
                            pr-10
                            border focus:outline-none
                            rounded-lg transition
                            form-control dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:border-blue-600 border-gray-500 text-gray-700 dark:bg-gray-700 dark:focus:text-white dark:border-gray-600  dark:placeholder-gray-400 dark:text-white
                             focus:text-gray-700
                            "
                      placeholder=" "
                      required=""
                      {...register("ConfirmPassword", {
                        required: {
                          value: true,
                          message: "Contraseña es requerido",
                        },
                        maxLength: {
                          value: 15,
                          message: "No más de 15 carácteres!",
                        },
                        minLength: {
                          value: 2,
                          message: "Mínimo 2 carácteres",
                        },
                      })}
                      value={passwords.ConfirmPassword}
                      onChange={(e) =>
                        setPasswords((prevPasswords) => ({
                          ...prevPasswords,
                          ConfirmPassword: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-2   justify-center items-center">
                  <div className="flex items-center ">
                    {passwordValidations.uppercase ? (
                      <div className="group duration-300 transition-all">
                        <div className="flex items-center duration-300 transition-all">
                          <div
                            className=" h-5 w-5 flex items-center justify-center "
                            aria-hidden="true"
                          >
                            <svg
                              className="h-full w-full text-indigo-600 group-hover:text-indigo-800"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </div>
                          <p className=" ml-1 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                            Mayusculas
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="group ">
                        <div className="flex items-center">
                          <div
                            className=" h-5 w-5 flex items-center justify-center "
                            aria-hidden="true"
                          >
                            <div className="h-2 w-2 bg-gray-300 rounded-full group-hover:bg-gray-400"></div>
                          </div>
                          <p className=" text-sm font-medium text-gray-500 group-hover:text-gray-900">
                            Mayusculas
                          </p>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="flex items-center ">
                    {passwordValidations.lowercase ? (
                      <div className="group duration-300 transition-all">
                        <div className="flex items-center duration-300 transition-all">
                          <div
                            className=" h-5 w-5 flex items-center justify-center "
                            aria-hidden="true"
                          >
                            <svg
                              className="h-full w-full text-indigo-600 group-hover:text-indigo-800"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </div>
                          <p className=" ml-1 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                            Minusculas
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="group ">
                        <div className="flex items-center">
                          <div
                            className=" h-5 w-5 flex items-center justify-center "
                            aria-hidden="true"
                          >
                            <div className="h-2 w-2 bg-gray-300 rounded-full group-hover:bg-gray-400"></div>
                          </div>
                          <p className=" text-sm font-medium text-gray-500 group-hover:text-gray-900">
                            Minusculas
                          </p>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="flex items-center ">
                    {passwordValidations.specialCharacter ? (
                      <div className="group duration-300 transition-all">
                        <div className="flex items-center duration-300 transition-all">
                          <div
                            className=" h-5 w-5 flex items-center justify-center "
                            aria-hidden="true"
                          >
                            <svg
                              className="h-full w-full text-indigo-600 group-hover:text-indigo-800"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </div>
                          <p className=" ml-1 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                            Carácteres especiales
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="group ">
                        <div className="flex items-center">
                          <div
                            className=" h-5 w-5 flex items-center justify-center "
                            aria-hidden="true"
                          >
                            <div className="h-2 w-2 bg-gray-300 rounded-full group-hover:bg-gray-400"></div>
                          </div>
                          <p className=" text-sm font-medium text-gray-500 group-hover:text-gray-900">
                            Carácteres especiales
                          </p>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="flex items-center ">
                    {passwordValidations.number ? (
                      <div className="group duration-300 transition-all">
                        <div className="flex items-center duration-300 transition-all">
                          <div
                            className=" h-5 w-5 flex items-center justify-center "
                            aria-hidden="true"
                          >
                            <svg
                              className="h-full w-full text-indigo-600 group-hover:text-indigo-800"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </div>
                          <p className=" ml-1 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                            Números
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="group ">
                        <div className="flex items-center">
                          <div
                            className=" h-5 w-5 flex items-center justify-center "
                            aria-hidden="true"
                          >
                            <div className="h-2 w-2 bg-gray-300 rounded-full group-hover:bg-gray-400"></div>
                          </div>
                          <p className=" text-sm font-medium text-gray-500 group-hover:text-gray-900">
                            Números
                          </p>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="flex items-center ">
                    {passwordValidations.minLength ? (
                      <div className="group duration-300 transition-all">
                        <div className="flex items-center duration-300 transition-all">
                          <div
                            className=" h-5 w-5 flex items-center justify-center "
                            aria-hidden="true"
                          >
                            <svg
                              className="h-full w-full text-indigo-600 group-hover:text-indigo-800"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </div>
                          <p className=" ml-1 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                            Más de 8 caracteres
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="group ">
                        <div className="flex items-center">
                          <div
                            className=" h-5 w-5 flex items-center justify-center "
                            aria-hidden="true"
                          >
                            <div className="h-2 w-2 bg-gray-300 rounded-full group-hover:bg-gray-400"></div>
                          </div>
                          <p className=" text-sm font-medium text-gray-500 group-hover:text-gray-900">
                            Más de 8 caracteres
                          </p>
                        </div>
                      </div>
                    )}
                  </div>

                  {passwords.ConfirmPassword && (
                    <div className="flex items-center">
                      {passwordValidations.passwordsMatch ? (
                        <div className="group duration-300 transition-all">
                          <div className="flex items-center duration-300 transition-all">
                            <div
                              className=" h-5 w-5 flex items-center justify-center "
                              aria-hidden="true"
                            >
                              <svg
                                className="h-full w-full text-indigo-600 group-hover:text-indigo-800"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </div>
                            <p className=" ml-1 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                              Las contraseñas coinciden
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="group ">
                          <div className="flex items-center">
                            <div
                              className=" h-5 w-5 flex items-center justify-center "
                              aria-hidden="true"
                            >
                              <div className="h-2 w-2 bg-gray-300 rounded-full group-hover:bg-gray-400"></div>
                            </div>
                            <p className=" text-sm font-medium text-gray-500 group-hover:text-gray-900">
                              Las contraseñas coinciden
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="pt-5">
                  <button
                    type="submit"
                    disabled={SubmitPass}
                    className="sm:w-full inline-flex items-center justify-center  md:w-full lg:w-full py-4 px-8  text-white bg-blue-500 rounded-lg shadow-lg hover:bg-blue-900 focus:ring-4 focus:ring-red-100 focus:outline-none"
                  >
                    {SubmitPass ? (
                      <Oval
                        height={19}
                        width={19}
                        color="#FFFFFF"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#233876"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                      />
                    ) : (
                      "Enviar"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetNewPass;
