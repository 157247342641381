import { ModalButtons } from "../../Shared/ModalButtons"
import { BodegaModalOff } from "./modalCrearBodega.slice"
import { InputGlobal } from "../../context/inputContext"
import { FormProvider, useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useContext } from "react"
import { BodegaContext } from "./context/BodegasContext"

export const ModalEditarBodega = () => {

    const {updateBodega} = useContext(BodegaContext)
    const registro = useSelector((state) => state.modalBodega.registroAfectado)
    const dispatch = useDispatch()

    const methods = useForm({
        defaultValues: {
            codigo: registro.codigo,
            nombreBodega: registro.nombreBodega,
            establecimiento: registro.establecimiento,
            direccion: registro.direccion
        }
    })

    const doBodegaEdit = (data) => {
        dispatch(BodegaModalOff())
        updateBodega(data, registro.id)
    }

    return (
        <FormProvider {...methods}>
            <section style={{ padding: "20px", height: "fit-content", width: "50%", backgroundColor: "white", position: "relative" }} className="shadow-xl rounded-lg flex flex-col gap-4">
                <div className="bg-gray-300 rounded-lg text-[12px] w-full flex flex-col justify-center gap-2 p-6">
                    <h1 className="font-semibold text-2xl">Información de Bodega</h1>
                    <h4>Editar Información</h4>
                </div>
                <form onSubmit={methods.handleSubmit(doBodegaEdit)} className="flex flex-col gap-8">
                    <div className="flex flex-col gap-4">
                        <InputGlobal title={"Código"} type={"text"} validations={{required: true}} isHook={true} name={"codigo"} isPlaceHolder={"Digite el codigo de la bodega"} />
                        <InputGlobal title={"Nombre de la Bodega"} validations={{required: true}} type={"text"} name={"nombreBodega"} isHook={true} isPlaceHolder={"Digite el nombre de la bodega"} />
                        <InputGlobal title={"Establecimiento"} type={"text"} validations={{required: true}} name={"establecimiento"} isHook={true} isPlaceHolder={"000"} />
                        <InputGlobal title={"Dirección"} type={"text"} validations={{required: true}} name={"direccion"} isPlaceHolder={"Digite el nombre de la dirección"} isHook={true} />
                    </div>
                    <ModalButtons closeFn={BodegaModalOff} />
                </form>
            </section>
        </FormProvider>

    )
}