import React from "react";

const MesageErrorTable = ({ message = "Advertencia", colSpan = "2" }) => {

    {/*
        <div className="text-sm mt-1 text-yellow-400 dark:text-yellow-400 inline-flex">

            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" className="w-4 h-4 mr-2 fill-current" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span className="font-semibold">{message}</span>
    </div>*/}
    return (
        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <td colSpan={colSpan} className="py-0 px-6 text-center">
                <div className="text-sm mt-1 text-red-500 dark:text-red-500 inline-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <div style={{ position: 'relative' }} >
                        <span className="font-semibold">{message}</span>
                    </div>
                </div>
            </td>
        </tr>

    )
}

export default MesageErrorTable;