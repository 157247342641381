import React, { useState, useContext } from "react";
import { ReporteFacturaContext } from "../ReporteFacturaProvider";
import useGlobalToastify from "../../../../features/hooks/GlobalToastify/useGlobalToastify";

const FacturasTabla = () => {
    const {
        groupedData
    } = useContext(ReporteFacturaContext);

    const { ErrorToast } = useGlobalToastify();

    const [pagina, setPagina] = useState(1);
    const elementosPorPagina = 10;

    const handleClickNextPage = () => {
        if (pagina < totalPaginas) setPagina(pagina + 1);
    };

    const handleClickPreviousPage = () => {
        if (pagina > 1) setPagina(pagina - 1);
    };

    const handleClickFirstPage = () => {
        setPagina(1);
    };

    const handleClickLastPage = () => {
        setPagina(totalPaginas);
    };

    const getPaginatedData = (data) => {
        const startIndex = (pagina - 1) * elementosPorPagina;
        const endIndex = startIndex + elementosPorPagina;
        return data.slice(startIndex, endIndex);
    };


    const dataToPaginate = getPaginatedData(groupedData);

    if (groupedData.length === 0) {
        ErrorToast('No se encontraron datos.');
        return null;
    }

    const totalPaginas = Math.ceil(groupedData.length / elementosPorPagina);

    return (
        <div className="my-6">
            <div className="overflow-x-auto h-[65vh]">
                <table className="min-w-full bg-white border border-gray-300 text-sm rounded-lg shadow-md text-center">
                    <thead className="bg-gray-200 text-gray-600  uppercase border-b border-gray-300">
                        <tr>
                            {[
                                'Establecimiento', 'Pto Emisión', 'Secuencial', 'Fecha Emisión',
                                'Razón Social Cliente', 'N. Identificación Cliente', 'Dirección Cliente',
                                'Correo Cliente', 'Guia Remisión','Código Principal', 'Código Auxiliar', 'Descripción',
                                'Cantidad', 'Precio Unitario', 'Descuento', 'Total Sin Impuesto',
                                'Porcentaje IVA', 'Valor IVA', 'Total con IVA', 'Inf. Adicional Producto 1',
                                'Inf. Adicional Producto Valor 1', 'Inf. Adicional Producto 2',
                                'Inf. Adicional Producto Valor 2', 'Inf. Adicional Producto 3',
                                'Inf. Adicional Producto Valor 3', 'Código Forma Pago', 
                                'Plazo Forma Pago', 'Unidad Tiempo Forma Pago', 'Inf. Adicional Factura Nombre 1',
                                'Inf. Adicional Factura Valor 1', 'Inf. Adicional Factura Nombre 2',
                                'Inf. Adicional Factura Valor 2', 'Inf. Adicional Factura Nombre 3',
                                'Inf. Adicional Factura Valor 3', 'Inf. Adicional Factura Nombre 4',
                                'Inf. Adicional Factura Valor 4', 'Inf. Adicional Factura Nombre 5',
                                'Inf. Adicional Factura Valor 5', 'Inf. Adicional Factura Nombre 6',
                                'Inf. Adicional Factura Valor 6', 'Inf. Adicional Factura Nombre 7',
                                'Inf. Adicional Factura Valor 7', 'Inf. Adicional Factura Nombre 8',
                                'Inf. Adicional Factura Valor 8', 'Inf. Adicional Factura Nombre 9',
                                'Inf. Adicional Factura Valor 9', 'Inf. Adicional Factura Nombre 10',
                                'Inf. Adicional Factura Valor 10', 'Inf. Adicional Factura Nombre 11',
                                'Inf. Adicional Factura Valor 11', 'Inf. Adicional Factura Nombre 12',
                                'Inf. Adicional Factura Valor 12', 'Inf. Adicional Factura Nombre 13',
                                'Inf. Adicional Factura Valor 13', 'Inf. Adicional Factura Nombre 14',
                                'Inf. Adicional Factura Valor 14', 'Inf. Adicional Factura Nombre 15',
                                'Inf. Adicional Factura Valor 15'
                            ].map((header) => (
                                <th key={header} className="px-4 py-2 border-b border-gray-300 border-r">{header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="text-gray-700 text-xs">
                        {dataToPaginate.map((row, index) => (
                            <React.Fragment key={index}>
                                {row.DETALLES.map((detalle, idx) => (
                                    <tr key={idx} className="hover:bg-gray-100 border-b border-gray-300" >
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.ESTABLECIMIENTO || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.PTOEMISION || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.SECUENCIAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.FECHAEMISION || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.CLIENTE || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.IDENTIFICACIONCLIENTE || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.DIRECCIONCLIENTE || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.CORREOCLIENTE || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.GUIAREMISION || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{detalle.CODIGOPRINCIPAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{detalle.CODIGOAUXILIAR || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{detalle.DESCRIPCION || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{detalle.CANTIDAD || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">${detalle.PRECIOUNITARIO ? detalle.PRECIOUNITARIO.toFixed(3) : 0 || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">$ {detalle.DESCUENTO == 0 ? 0 : detalle.DESCUENTO.toFixed(3)}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">$ {detalle.TOTALSINIMPUESTO.toFixed(3) || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{detalle.TARIFAIVA || 0 }</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">$ {detalle.VALORIVA == 0 ? 0 : detalle.VALORIVA.toFixed(3) || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">$ {detalle.TOTAL.toFixed(3) || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{detalle.ADICIONALES?.[0]?.INFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{detalle.ADICIONALES?.[0]?.VALORINFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{detalle.ADICIONALES?.[1]?.INFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{detalle.ADICIONALES?.[1]?.VALORINFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{detalle.ADICIONALES?.[2]?.INFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{detalle.ADICIONALES?.[2]?.VALORINFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.FORMASPAGO[0]?.IDFORMAPAGOSRI || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.FORMASPAGO[0]?.PLAZO || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.FORMASPAGO[0]?.UNIDADTIEMPO || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.ADICIONAL?.[0]?.INFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.ADICIONAL?.[0]?.VALORINFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.ADICIONAL?.[1]?.INFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.ADICIONAL?.[1]?.VALORINFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.ADICIONAL?.[2]?.INFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.ADICIONAL?.[2]?.VALORINFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.ADICIONAL?.[3]?.INFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.ADICIONAL?.[3]?.VALORINFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.ADICIONAL?.[4]?.INFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.ADICIONAL?.[4]?.VALORINFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.ADICIONAL?.[5]?.INFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.ADICIONAL?.[5]?.VALORINFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.ADICIONAL?.[6]?.INFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.ADICIONAL?.[6]?.VALORINFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.ADICIONAL?.[7]?.INFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.ADICIONAL?.[7]?.VALORINFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.ADICIONAL?.[8]?.INFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.ADICIONAL?.[8]?.VALORINFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.ADICIONAL?.[9]?.INFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.ADICIONAL?.[9]?.VALORINFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.ADICIONAL?.[10]?.INFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.ADICIONAL?.[10]?.VALORINFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.ADICIONAL?.[11]?.INFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.ADICIONAL?.[11]?.VALORINFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.ADICIONAL?.[12]?.INFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.ADICIONAL?.[12]?.VALORINFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.ADICIONAL?.[13]?.INFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.ADICIONAL?.[13]?.VALORINFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.ADICIONAL?.[14]?.INFADICIONAL || ''}</td>
                                        <td className="px-4 py-2 border-b border-gray-300 border-r">{row.ADICIONAL?.[14]?.VALORINFADICIONAL || ''}</td>
                                    </tr>
                                ))}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="mt-4 flex justify-between items-center">
                <button
                    onClick={handleClickFirstPage}
                    className={`px-4 py-2 border border-gray-300 rounded-lg ${pagina === 1 ? 'bg-gray-200 text-gray-400 cursor-not-allowed' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
                    disabled={pagina === 1}
                >
                    {'<< '}
                </button>
                <button
                    onClick={handleClickPreviousPage}
                    className={`px-4 py-2 border border-gray-300 rounded-lg ${pagina === 1 ? 'bg-gray-200 text-gray-400 cursor-not-allowed' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
                    disabled={pagina === 1}
                >
                    {'< '}
                </button>
                <span className="text-gray-700">{`Pagina ${pagina} de ${totalPaginas}`}</span>
                <button
                    onClick={handleClickNextPage}
                    className={`px-4 py-2 border border-gray-300 rounded-lg ${pagina === totalPaginas ? 'bg-gray-200 text-gray-400 cursor-not-allowed' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
                    disabled={pagina === totalPaginas}
                >
                    {' >'}
                </button>
                <button
                    onClick={handleClickLastPage}
                    className={`px-4 py-2 border border-gray-300 rounded-lg ${pagina === totalPaginas ? 'bg-gray-200 text-gray-400 cursor-not-allowed' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
                    disabled={pagina === totalPaginas}
                >
                    {' >>'}
                </button>
            </div>
        </div>
    );
};

export default FacturasTabla;
