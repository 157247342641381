import React from "react";
import { SmallModalInfoSri } from "../../features/components/SmallModalInfoSri";

const ModalInfo = () => {
  return (
    <SmallModalInfoSri
      buttonModal={"SriIsDownModal"}
      submitText={"INFO"}
      title="Intuito S.A. | Aviso!"
    >
      {/* <div className="   w-full group">
        <p className="text-xl text-center font-bold text-blue-600">
          INTERMITENCIA SRI
        </p>

        <p className="text-lg">
          <b>Si eres agente de retención </b>, en estos momentos la recepción y aprobación de documentos electrónicos
          por parte del <b>SRI</b> se encuentra intermitente.
        </p>

        <p className="text-lg">
          Recuerda que puedes seguir creando y enviando tus documentos sin
          ningún problema, Intuito los enviará de forma automática al <b>SRI</b> cuando
          el servicio se normalice.
        </p>
      </div> */}

      <div className="   w-full group">
        <p className="text-xl text-center font-bold text-blue-600">
          COMUNICADO
        </p>
        <p className="text-lg">
          <b>Si eres agente de retención </b>, se han presentado inconvenientes con el <b>código 332</b>  en las retenciones
          emitidas, que indica un error de validación en el comprobante. Este
          problema parece estar relacionado con una intermitencia tecnológica en
          el SRI. Recomendamos intentar nuevamente la generación del comprobante
          dentro de las próximas horas.
        </p>
        <p className="text-lg">
        Agradecemos su comprensión y lamentamos las molestias ocasionadas.

        </p>
      </div>
    </SmallModalInfoSri>
  );
};
export default ModalInfo;
