import { useContext } from "react";
import { EmitirDocContext } from "./EmitirDocProvider";
import { useSelector } from "react-redux";
import { getFechaV1 } from "../../../services/Utilitario";
import useVistaPrevia from "../hooks/useVistaPrevia";
const ButtonDown = () => {
  const {
    formik,

    Totales,
    DataFromAdd,
    InformacionAdicionalList,
  } = useContext(EmitirDocContext);

  const empresa = useSelector((store) => store.empresa.empresa);
  const { GetVistaPreviaRetencion, IsLoading } = useVistaPrevia();

  const handleVistaPrevia = async () => {
    let parsedInfo = {
      IdentificacionContribuyente: formik.values.clienteIdentificacion,
      RazonSocialContribuyente: formik.values.clienteRazonSocial,
      Direccion: formik.values.clienteDireccion,
      Correo: formik.values.clienteEmail.replace(/,/g, ";").replace(/\s+/g, ""),
      TelefonoCelular: formik.values.clienteTelefono,
      CodigoComprobanteModificar: formik.values.codigoComprobanteModificar,
      IdContribuyente: formik.values.idContribuyente,
      IsPartRel: formik.values.IsPartRel === "1" ? "True" : "False",
      Ambiente: process.env.REACT_APP_ENVIRONMENT,
      IdEmpresa: empresa.idEmpresa,

      FechaEmision: getFechaV1(formik.values.fechaEmision),
      PeriodoFiscal: formik.values.periodoFiscal,
      ImporteTotal: Totales.importeTotal,
      TotalSinImpuestos: Totales.totalSinImpuesto,
      TotalDescuento: Totales.totalDescuentos,
      DocsSustento: DataFromAdd,
      infoAdicional: InformacionAdicionalList.map((x) => {
        return {
          Descripcion: x.nombre,
          Valor: x.valor,
        };
      }),
      IdImpuestoNoObjectoImpuesto:
        Totales.subTotalNoObjetoIva !== 0
          ? "8E7CF164-C66B-4312-945F-57AE08C86A33"
          : "",
      IdImpuestoExentoIva:
        Totales.subTotalExentoIva !== 0
          ? "F03C7F10-2773-4505-ACCB-B2FA88D62049"
          : "",
      IdImpuestoIVA12:
        Totales.subTotalIva !== 0 ? "A5B8F607-8D38-4150-97AF-C591F83678D8" : "",
      IdImpuestoIVA0:
        Totales.subTotalIva0 !== 0
          ? "81BAF871-E113-48CF-8AA8-670CB43494B6"
          : "",
      TotalTotalIva0: 0,
      TotalTotalIva: Totales.totalIva,
      TotalNoObjetoImpuesto: 0,
      TotalExentoIva: 0,
      TotalBaseIva0: Totales.subTotalIva0,
      TotalBaseIva: Totales.subTotalIva,
      TotalBaseNoObjetoImpuesto: Totales.subTotalNoObjetoIva,
      TotalBaseExentoIva: Totales.subTotalExentoIva,
      Establecimiento: formik.values.establecimiento,
      PuntoEmision: formik.values.puntoEmision,
      Secuencial: formik.values.secuencial,
    };

    if (formik.values.clienteEmail !== null)
      if (formik.values.clienteEmail.trim() !== "")
        parsedInfo.infoAdicional.push({
          Valor: formik.values.clienteEmail.replace(/,/g, ";").replace(/\s+/g, ""),
          Descripcion: "email",
        });

    let data = await GetVistaPreviaRetencion(parsedInfo);
    let byteCharacters = atob(data);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], { type: "application/pdf;base64" });
    let fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };
  return (
    <>
      {IsLoading && (
        <div
          style={{ width: "100vw", height: "100vh", zIndex: "100" }}
          className="bg-opacity-70 fixed justify-center align-middle top-0 l-0 bg-white text-center"
        >
          <div className="w-full h-full flex justify-center items-center py-4">
            <div
              className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-blue-600"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}
      <div className="flex lg:justify-end justify-center space-x-2 py-3 p-3 rounded-b-lg  bg-white dark:bg-gray-900">
        <div className="group relative  border-gray-300 border rounded-md inline-block">
          <button
            type="button"
            onClick={handleVistaPrevia}
            className="inline-flex h-10 w-10 cursor-pointer items-center justify-center rounded-xl  hover:shadow-md    transition dark:bg-gray-900 dark:hover:bg-gray-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6 dark:text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>

            <div className="absolute mx-auto w-24  -top-8 -left-8 hidden dark:bg-white dark:text-black    rounded-md bg-gray-800 group-hover:flex text-center p-1">
              <p className="text-white dark:text-black  mx-auto text-sm">
                Vista previa{" "}
              </p>
            </div>
          </button>
        </div>
        <div>
          <button
            onClick={formik.handleSubmit}
            className="border p-2 rounded-lg text-white bg-[#04BC53] hover:bg-[#03a448] transition-all duration-150 dark:bg-gray-700 dark:border-none w-auto flex"
          >
            <p className=" mx-2 text-white">Enviar al SRI</p>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 mr-2 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
              />
            </svg>
          </button>
        </div>
      </div>
    </>
  );
};

export default ButtonDown;
