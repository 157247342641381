import React, { useContext, useState } from "react";
import MesageWarningTable from "./MesageWarningTable";
import { useForm } from "react-hook-form";
import { StyleInput, StyleInputError } from "../Constantes";
import useGlobalToastify from "../hooks/GlobalToastify/useGlobalToastify";
import LengthCounter from "./LengthCounter";

const InformacionAdicionalUseForm = ({ Contexto }) => {
  const [IsLoading, setIsLoading] = useState(false);

  const { InformacionAdicionalList, setInformacionAdicionalList } =
    useContext(Contexto);

  const {
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    register,
    watch,
  } = useForm({ defaultValues: { nombre: "", valor: "" } });

  const handleDeleteElement = (index) => {
    InformacionAdicionalList.splice(index, 1);
    setInformacionAdicionalList([...InformacionAdicionalList]);
  };

  const handleAddElement = () => {
    setIsLoading(true);
    const data = { ...getValues() };

    if (data.nombre === "" || data.valor === "") {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
      return ErrorToast(
        "Falta rellenar uno de los campos en el apartado de informacion adicional!"
      );
    }

    if (data.nombre.length > 300) {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
      return ErrorToast("Nombre no debe tener más de 300 carácteres!");
    }

    if (data.valor.length > 300) {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
      return ErrorToast("Valor no debe tener más de 300 carácteres!");
    }

    if (data.nombre.trim().length < 1 || data.valor.trim().length < 1) {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
      return ErrorToast(
        "Remover espacios en blanco y agregar caracteres validos en la información adicional!"
      );
    }

    setInformacionAdicionalList([data, ...InformacionAdicionalList]);
    setValue("nombre", "");
    setValue("valor", " ");
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const { ErrorToast } = useGlobalToastify();

  return (
    <div className=" flex-wrap justify-between py-4 px-4 w-full   overflow-x-auto  ">
      {/* <h3 className="text-sm  py-1.5 my-2 px-2 text-center bg-[#003B5B] dark:text-gray-50 dark:bg-gray-700 border-transparent rounded-t-lg font-medium leading-6 text-white">
        Información adicional
      </h3> */}

      <div className="grid md:grid-cols-3 md:gap-6 ">
        <div className="relative z-0 mb-2 w-full group">
          <label className="block text-sm font-medium text-black dark:text-white">
            {" "}
            Nombre: <LengthCounter itemLength={watch("nombre")} />
          </label>
          <input
            type="text"
            className={errors["nombre"] ? StyleInputError : StyleInput}
            {...register("nombre", {
              maxLength: {
                value: 300,
                message: "Nombre no debe tener más de 300 carácteres!",
              },
            })}
          />
        </div>
        <div className="relative z-0 mb-2 w-full group">
          <label className="block text-sm font-medium text-black dark:text-white">
            {" "}
            Detalle: <LengthCounter itemLength={watch("valor")} />
          </label>
          <input
            type="text"
            className={errors["valor"] ? StyleInputError : StyleInput}
            {...register("valor", {
              maxLength: {
                value: 300,
                message: "Nombre no debe tener más de 300 carácteres!",
              },
            })}
          />
        </div>
        <div className="relative z-0 mb-2 pt-3 w-full group">
          <button
            type="button"
            disabled={IsLoading}
            onClick={handleAddElement}
            className=" inline-block mt-1 text-white bg-blue-600 w-full lg:w-28  hover:bg-blue-700  font-semibold p-2 text-[0.813rem] border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:shadow-outline-grayduration-150
              ease-in-out"
          >
            {IsLoading ? (
              "Cargando..."
            ) : (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="inline mr-1   -ml-1 w-5 h-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>{" "}
                AGREGAR
              </>
            )}
          </button>
        </div>
      </div>
{InformacionAdicionalList.length > 0 && 


<div className=" relative  overflow-auto border rounded-md dark:border-gray-600   ">
<table className="w-full text-sm text-center divide-y divide-gray-300 dark:divide-gray-600 text-gray-500 dark:text-gray-400 ">
  <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
    <tr className="">
      <th className="px-1 py-1 uppercase">Nombre</th>
      <th className="px-1 py-1 uppercase">Detalle</th>

      <th className="px-1 py-1"></th>
    </tr>
  </thead>
  <tbody className="divide-y divide-gray-300  dark:divide-gray-600">
    {InformacionAdicionalList.length === 0 ? (
      <MesageWarningTable
        colSpan="3"
        message="No se ha ingresado información"
      />
    ) : null}
    {InformacionAdicionalList.map((el, index) => (
      <tr key={index} className="bg-white dark:bg-gray-800 text-center">
        <td className="py-1 px-1"> {el.nombre}</td>
        <td className="py-1 px-1"> {el.valor}</td>
        <td className="py-1 px-1">
          {" "}
          <button
            className=""
            type="button"
            onClick={() => handleDeleteElement(index)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
          </button>
        </td>
      </tr>
    ))}
  </tbody>
</table>
</div>

}
     
    </div>
  );
};

export default InformacionAdicionalUseForm;
