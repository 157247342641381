import { CrearCategoriaList } from "./CrearCategoriaList";
import { CrearCategoriaForm } from "./CrearCategoriaForm";

export const CrearCategoria = () => {
  return (
    <section className="w-full h-full flex flex-col gap-10">
      <CrearCategoriaForm />
      <CrearCategoriaList />
    </section>
  );
};
