import React from "react";
import { NavLink } from "react-router-dom";
import {
  StyleCardDasb,
  StyleCardDasbInactive,
  StyleSvg,
  StyleSvgInactive,
  StyleTextCardDasb,
  StyleTextCardDasbInactive,
} from "../../features/Constantes";

const DashboarItem = ({ isActive = true, title, route, svg }) => {
  return (
    <li className={isActive ? StyleCardDasb : StyleCardDasbInactive}>
      {isActive ? (
        <NavLink exact="true" to={route}>
          <div className="flex-1 flex flex-col p-8">
            {/* <img src={svg} alt="icon" className="w-32 mx-auto" /> */}
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className={isActive ? StyleSvg : StyleSvgInactive}
              >
                {svg}
              </svg>
            <h3
              className={
                isActive ? StyleTextCardDasb : StyleTextCardDasbInactive
              }
            >
              {title}
            </h3>
          </div>
        </NavLink>
      ) : (
        <div exact="true" to={route}>
          <div className="flex-1 flex flex-col p-8">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className={isActive ? StyleSvg : StyleSvgInactive}
            >
              {svg}
            </svg>

            <h3
              className={
                isActive ? StyleTextCardDasb : StyleTextCardDasbInactive
              }
            >
              {title}
            </h3>
          </div>
        </div>
      )}
    </li>
  );
};

export default DashboarItem;
