import { post } from ".";

export const  PostFactura = async (doc) => {
    try {

        return await post("/api/FacturasEmitidas/GuardarFactura", doc);
    } 
    catch (error) {
     
        throw error;
    }
}