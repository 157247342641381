import React from "react";
import FormFactura from "./FormFactura";
import EmitirFacturaProvider from "./EmitirFacturaProvider";
import Modals from "./Modals";
import ModalGuardar from "./ModalGuardar";
import ModalAdd from "../../Mantenimiento/ProductosServicios/ModalAdd";
import ReporteServicioProvider from "../../Mantenimiento/ProductosServicios/ReporteServicioProvider";
import ReembolsoProvider from "../../../features/hooks/Reembolso/ReembolsoProvider";
import ModalConsumAlert from "./ModalConsumAlert";
import FacturaExportacionProvider from "./hooks/FacturaExportacion/FacturaExportacionProvider";
import ModalGuardarPTVenta from "./ModalPuntoVenta";

const EmitirFactura = () => {
  return (
    <ReembolsoProvider>
      <FacturaExportacionProvider>
        <EmitirFacturaProvider>
          <button
            type="button"
            className="hidden hover:bg-gray-200  dark:hover:bg-gray-700  rounded-lg p-2  text-sm font-medium leading-5  dark:text-gray-400 focus:outline-none focus:shadow-outline-grayduration-150 ease-in-out"
            data-bs-toggle="modal"
            data-bs-target="#ModalConsumAlert"
            id="ModalConsumAlertButton"
          ></button>
          <ModalConsumAlert />
          <div className=" p-4">
            <FormFactura />
          </div>
          <Modals />
          <ModalGuardarPTVenta />
          <ModalGuardar />
          <ReporteServicioProvider>
            <ModalAdd />
          </ReporteServicioProvider>
        </EmitirFacturaProvider>
      </FacturaExportacionProvider>
    </ReembolsoProvider>
  );
};

export default EmitirFactura;
