import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  StyleInput,
  StyleInputError,
  StyleLabel,
  StyleLabelError,
} from "../../../features/Constantes";
import { LargeModalForm } from "../../../features/components/LargeModalForm";
import { useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { getListV2 } from "../../../services";
import { EmitirLiquidacionContext } from "./EmitirLiquidacionProvider";
import { ParsearFormaPago } from "../../../features/bl/DocumentSharedBL";

const BuscarLiquidacionModal = () => {
  
  const { setDetalleList, DetalleList, formik, FormaPagoList, setFormaPagoList, setInformacionAdicionalList } = useContext(EmitirLiquidacionContext);

  const [IsLoading, setIsLoading] = useState(false);
  const [ErrorSecuencialState, setErrorSecuencialState] = useState(0);

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    setValue("secuencialFactura", "000-000-000000000");
  }, []);

  const empresa = useSelector((store) => store.empresa.empresa);

  const renderMessage = (val) => {
    switch (val) {
      case 1:
        return (
          <p className=" text-green-500 text-sm -translate-y-5 mt-2">
            El comprobante ingresado es correcto.
          </p>
        );

      case 2:
        return (
          <p className=" text-yellow-500 text-sm -translate-y-5 mt-2 ">
            El comprobante ingresado existe, pero no está autorizado.
          </p>
        );

      case 3:
        return (
          <p className="  text-sm text-red-600 -translate-y-5 mt-2">
            El comprobante ingresado no existe.
          </p>
        );

      default:
        break;
    }
  };

  const handleSubmitModal = async () => {
    try {
      setIsLoading(true);
      let datas = getValues().secuencialFactura.split("-");
      let res = await getListV2(
        empresa,
        "GetDetallesBySecuencial",
        `?Establecimiento=${Number(datas[0])}&PuntoEmision=${Number(
          datas[1]
        )}&Secuencial=${Number(datas[2])}`
      );
      res.factura.detalleFactura = res.detalleFactura;

      let contribuyente = res.factura.idContribuyenteNavigation;
      formik.setFieldValue("clienteIdentificacion", contribuyente.identificacion);
      formik.setFieldValue("clienteRazonSocial", contribuyente.razonSocial);
      formik.setFieldValue("clienteDireccion", contribuyente.direccion);
      formik.setFieldValue("clienteEmail", contribuyente.correo);
      formik.setFieldValue("clienteTelefono", contribuyente.telefonoCelular);

      let parseInfoAdicional = res.factura.informacionAdicionalFactura.map(el => {
        return {
          nombre: el.descripcion,
          valor: el.valor
        }
      })
      
      setInformacionAdicionalList(parseInfoAdicional);

      if (res !== "" && res !== undefined) {
        setDetalleList([...res.factura.detalleFactura])
        setFormaPagoList([...ParsearFormaPago(res.factura.formaPagoFactura)])
        if (
          res.docElectronico.idEstadoDocumentoElectronico.toUpperCase() !== "1777AA9D-FA14-49BD-B9F0-ECE6D2952756"
        ) {
          setErrorSecuencialState(2);
        } else {
          setErrorSecuencialState(1);
        }
      }
    } catch (ex) {
    //  console.log(ex);
      setErrorSecuencialState(3);
    } finally {
      setIsLoading(false);
      document.getElementById("searchFacturaModal").click();
    }
  };

  const handleComprobarComprobante = async () => {
    try {
      let datas = getValues().secuencialFactura.split("-");
      let res = await getListV2(
        empresa,
        "GetDetallesBySecuencial",
        `?Establecimiento=${Number(datas[0])}&PuntoEmision=${Number(
          datas[1]
        )}&Secuencial=${Number(datas[2])}`
      );
      // console.log(res)
      if (res !== "" && res !== undefined) {
        if (
          res.docElectronico.idEstadoDocumentoElectronico.toUpperCase() !== "1777AA9D-FA14-49BD-B9F0-ECE6D2952756"
        ) {
          setErrorSecuencialState(2);
        } else {
          setErrorSecuencialState(1);
        }
      } 
    } catch (ex) {
      //console.log(ex);
      setErrorSecuencialState(3);
    }
  };

  return (
    <LargeModalForm
      buttonModal={"searchFacturaModal"}
      IsLoading={IsLoading}
      submitText={"Agregar"}
      title="Replicar factura"
      submitModal={handleSubmit(handleSubmitModal)}
    >
      <div className="w-full mb-2 md:mb-0 mt-5 grid md:grid-cols-3 md:gap-6">
        <div className="w-full col-span-2">
          <NumberFormat
            format="###-###-#########"
            id="secuencialFactura"
            name="secuencialFactura"
            placeholder=" "
            className={
              errors.secuencialFactura ? StyleInputError : StyleInput
            }
            {...register("secuencialFactura", {
              required: true,
            })}
            onChange={(e) => setValue("secuencialFactura", e.target.value)}
            value={getValues().secuencialFactura}
          />
          <label
            htmlFor="secuencialFactura"
            className={
              errors.secuencialFactura ? StyleLabelError : StyleLabel
            }
          >
            Secuencial comprobante
          </label>
          {renderMessage(ErrorSecuencialState)}
        </div>
        <div className="mb-2 w-full group">
          <button
            onClick={handleComprobarComprobante}
            type="button"
            className="  text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2 text-center dark:bg-green-600 dark:focus:ring-green-800 dark:hover:bg-green-700"
          >
            COMPROBAR
          </button>
        </div>
      </div>
    </LargeModalForm>
  );
};

export default BuscarLiquidacionModal;
