import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import MesageWarningTable from "../../../features/components/MesageWarningTable";
import {
  StyleInput,
  StyleInputError,
  StyleLabel,
  StyleLabelError,
} from "../../../features/Constantes";
import useGlobalToastify from "../../../features/hooks/GlobalToastify/useGlobalToastify";
import LengthCounter from "../../../features/components/LengthCounter";
import { toast } from "react-toastify";

const InformacionAdicional = ({ InformacionAdicionalList, setInformacionAdicionalList, }) => {
  const [IsLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, setValue, formState: { errors }, watch, reset } = useForm({ defaultValues: { nombre: "", valor: "" }, mode: "onSubmit" });
  const { ErrorToast } = useGlobalToastify();

  const onClickHandlerInfoAdicional = async (dataClick) => {
    setIsLoading(true);
    const validate = InformacionAdicionalList.length <= 2;
    const toastShow = () => new Promise((resolve) => setInterval(() => resolve(validate), 2000));

    if (validate) {
      setInformacionAdicionalList([...InformacionAdicionalList, { ...dataClick }]);
      setTimeout(() => setIsLoading(false), 2000);
      reset();
      return
    } else {
      await toast.promise(toastShow(), {
        success: {
          render() {
            if (validate === false) {
              setIsLoading(false);
              return 'No puedes agregar más de 3 detalles adicionales a un producto!'
            }
          },
          type: 'info',
          autoClose: 2000,
          closeOnClick: true,
        },
        error: {
          render() {
            setIsLoading(false);
            return 'Algo sucedio, intenta nuevamente.'
          }
        }
      });
      return
    }
  };

  const deleteInfoAdicional = (index) => {
    InformacionAdicionalList.splice(index, 1);
    setInformacionAdicionalList([...InformacionAdicionalList]);
  };

  useEffect(() => {
    Object.values(errors)[0] && ErrorToast(Object.values(errors)[0].message);
  }, [errors]);

  return (
    <div>
      <div className="my-8  flex-wrap justify-between mb-0">
       
        <div className="w-full pb-3 grid md:grid-cols-6 md:gap-2 mb-2 md:mb-0">
          <div className="md:col-span-2 relative z-0 mb-2 w-full group">
            <input
              type="text"
              className={errors["nombre"] ? StyleInputError : StyleInput}
              {...register("nombre", {
                required: {
                  value: true,
                  message: "Nombre en información adicional es requerido",
                },
                maxLength: {
                  value: 300,
                  message: "Nombre no debe tener más de 300 carácteres!",
                },
                minLength: {
                  value: 1,
                  message: "Nombre debe tener mínimo 1 carácter",
                },
              })}
              maxLength="300"
            />
            <label
              htmlFor="nombre"
              className={errors["nombre"] ? StyleLabelError : StyleLabel}
            >
              NOMBRE <LengthCounter itemLength={watch("nombre")} />
            </label>
          </div>

          <div className="md:col-span-3 relative z-0 mb-2 w-full group">
            <input
              type="text"
              placeholder=" "
              className={errors["valor"] ? StyleInputError : StyleInput}
              {...register("valor", {
                required: {
                  value: true,
                  message: "Valor en información adicional es requerido",
                },
                maxLength: {
                  value: 300,
                  message: "Valor no debe tener más de 300 carácteres!",
                },
                minLength: {
                  value: 1,
                  message: "Valor debe tener mínimo 1 carácter",
                },
              })}
              maxLength="300"
            />
            <label
              htmlFor="valor"
              className={errors["valor"] ? StyleLabelError : StyleLabel}
            >
              DETALLE <LengthCounter itemLength={watch("valor")} />
            </label>
          </div>

          <div className="relative z-0 mb-2 w-full group">
            <button
              disabled={IsLoading}
              onClick={handleSubmit(onClickHandlerInfoAdicional)}
              type="button"
              className=" text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-base px-2 py-1.5 text-center md:mr-5 mb-3 md:mb-0 inline-flex items-center justify-center gap-1"
            >
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className=" w-5 h-5 dark:stroke-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </>
            </button>
          </div>
        </div>
        {InformacionAdicionalList.length > 0 && 
         <div className="overflow-x-auto  relative overflow-hidden shadow ring-1 ring-black  ring-opacity-5 md:rounded-lg">
         <table className="w-full text-sm text-center text-gray-500 dark:text-gray-400">
           <thead className="text-xs text-gray-700 uppercase divide-y bg-[#D7D7D7]   dark:bg-gray-600 dark:text-gray-400">
             <tr>
               <th scope="col" className="py-2 px-6 dark:text-white ">
                 NOMBRE
               </th>
               <th scope="col" className="py-1 px-6 dark:text-white  ">
                 DETALLE
               </th>
               <th scope="col" className="py-1 px-6"></th>
             </tr>
           </thead>
           <tbody className="divide-y divide-gray-200 dark:divide-gray-600 bg-white">
             {InformacionAdicionalList.length === 0 ? (
               <MesageWarningTable
                 colSpan="3"
                 message="No se ha ingresado información"
               />
             ) : null}
             {InformacionAdicionalList.map((el, index) => (
               <tr
                 key={index}
                 className="bg-white dark:bg-gray-800 dark:hover:bg-gray-900 hover:bg-gray-200  border-b-gray-600  "
               >
                 <td className="py-1 px-6">{el.nombre}</td>
                 <th
                   scope="row"
                   className="py-0 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                 >
                   {el.valor}
                 </th>
                 <td className="py-1 px-6 text-red-500 hover:text-red-600 ">
                   <button onClick={() => deleteInfoAdicional(index)}>
                     <svg
                       xmlns="http://www.w3.org/2000/svg"
                       className="h-5 w-5"
                       fill="none"
                       viewBox="0 0 24 24"
                       stroke="currentColor"
                       strokeWidth="2"
                     >
                       <path
                         strokeLinecap="round"
                         strokeLinejoin="round"
                         d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                       />
                     </svg>
                   </button>
                 </td>
               </tr>
             ))}
           </tbody>
         </table>
       </div>
        }
       
      </div>
    </div>
  );
};

export default InformacionAdicional;
