import { FormProvider, useForm } from "react-hook-form";
import { InputGlobal } from "../../../../Inventario/context/inputContext";
import { SelectGlobal } from "../../../../Inventario/context/selectContext";

export const HistorialForm = () => {
  const SearchActive =
    "p-3 rounded-lg text-white bg-blue-600 text-[13px] transition-all h-fit w-fit hover:bg-blue-700 pr-5 pl-5 flex flex-row gap-2 items-center";

  const methods = useForm();

  return (
    <FormProvider {...methods}>
      {" "}
      <form className="w-full px-3 p-6 border flex flex-col gap-4">
        <h1 className="text-xl ml-4 font-semibold">
          Factura de Servicios Contratados
        </h1>
        <div className="bg-gray-200 p-6 responsiveGrid gap-6 rounded-lg">
          <InputGlobal
            title={"Fecha de Inicio"}
            max={6}
            type={"date"}
            isHook={true}
            name={"fechaInicio"}
          />
          <InputGlobal
            title={"Fecha de Fin"}
            max={6}
            type={"date"}
            isHook={true}
            name={"fechaFin"}
          />
          <InputGlobal
            title={"Contribuyente / RUC"}
            max={6}
            type={"text"}
            isHook={true}
            name={"contribuyenteRUC"}
            isPlaceHolder={"Digite el Contribuyente o el RUC"}
          />
          <SelectGlobal title={"Estado"} name={"estado"}>
            <option value="" selected hidden>
              Seleccione el Estado
            </option>
          </SelectGlobal>
        </div>
        <button type="submit" className={SearchActive}>
          <span>
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
            </svg>
          </span>
          Buscar
        </button>
      </form>
    </FormProvider>
  );
};
