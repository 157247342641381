import React from "react";
import MailsFields from "./MailsFields";

const MailsForm = ({ setValue, watch }) => {
  const emailFacturaObs = watch("configuracion.emailFactura");
  const emailRetencionObs = watch("configuracion.emailRetencion");
  const emailGuiaRemisionObs = watch("configuracion.emailGuiaRemision");
  const emailNotaCreditoObs = watch("configuracion.emailNotaCredito");
  const emailNotaDebitoObs = watch("configuracion.emailNotaDebito");
  const emailLiquidacionObs = watch("configuracion.emailLiquidacion");

  return (
    <div className="my-4">
      <div className="grid lg:grid-cols-4 grid-cols-1 gap-6 mb-10 ">
        <div>
          <p className="dark:text-white">Factura</p>
          <MailsFields
            setValue={(el) => setValue("configuracion.emailFactura", el)}
            defValue={emailFacturaObs}
          />
        </div>
        <div>
          <p className="dark:text-white">Retención</p>
          <MailsFields
            setValue={(el) => setValue("configuracion.emailRetencion", el)}
            defValue={emailRetencionObs}
          />
        </div>
        <div>
          <p className="dark:text-white">Guia de remisión</p>
          <MailsFields
            setValue={(el) => setValue("configuracion.emailGuiaRemision", el)}
            defValue={emailGuiaRemisionObs}
          />
        </div>
       
      </div>
      <div className="grid lg:grid-cols-4 grid-cols-1 gap-6 ">
      <div>
          <p className="dark:text-white">Nota de crédito</p>
          <MailsFields
            setValue={(el) => setValue("configuracion.emailNotaCredito", el)}
            defValue={emailNotaCreditoObs}
          />
        </div>
        <div>
          <p className="dark:text-white">Nota de débito</p>
          <MailsFields
            setValue={(el) => setValue("configuracion.emailNotaDebito", el)}
            defValue={emailNotaDebitoObs}
          />
        </div>
        <div>
          <p className="dark:text-white">Liquidación</p>
          <MailsFields
            setValue={(el) => setValue("configuracion.emailLiquidacion", el)}
            defValue={emailLiquidacionObs}
          />
        </div>
      </div>
    </div>
  );
};

export default MailsForm;
