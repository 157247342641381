import React from 'react'

export  const SmallModalInfoComunicate = (props) => {
  return (
    <div className="modal fade fixed -top-20 left-0  hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id={`${props.buttonModal}`}
    tabIndex="-1"
    aria-labelledby={`${props.buttonModal}Label`}
    aria-hidden="true" aria-modal="true" role="dialog">
  <div className="modal-dialog modal-lg modal-dialog-centered relative w-auto pointer-events-none">
    <div className="modal-content border-none shadow-lg relative flex flex-col w-[80%] mx-auto pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
      <div className="modal-header flex flex-shrink-0 items-center justify-between  border-b border-gray-200 rounded-t-md">
        <h5 className="text-base my-2 w-full mx-2 font-medium  leading-normal text-gray-800" id="exampleModalScrollableLabel">
          {props.title}
        </h5>
        <button
          type="button" data-bs-dismiss="modal"
          aria-label="Close"
          id={`${props.buttonModal}close`}
          className="shadow absolute -right-5 -top-5 w-10 h-10 rounded-full bg-white text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer"
        >
          <svg
            className="fill-current w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
          </svg>
        </button>
      </div>
      <div className="modal-body relative p-2 my-2.5">
        {props.children}
      </div>
    </div>
  </div>
</div>
  )
}
