import axios from "axios";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import MesageWarningTable from "../../../../features/components/MesageWarningTable";
import {
  StyleInput,
  StyleSelect,
  StyleSelectForm,
} from "../../../../features/Constantes";
import { getListV2 } from "../../../../services";
import { roundToTwo } from "../../../../services/Utilitario";
import { ModalAddImpuestoContext } from "./ModalAddImpuestoProvider";
import { toast } from "react-toastify";

const RentaSection = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [PorcentajeRetener, setPorcentajeRetener] = useState("");
  const [ImprentaList, setImprentaList] = useState([]);
  const [BaseImponibleRet, setBaseImponibleRet] = useState(0);
  const [CodRentaSelected, setCodRentaSelected] = useState({});
  const [CodigoRetencion, setCodigoRetencion] = useState("");
  const [ValorRetenido, setValorRetenido] = useState(0);
  const [TipoImpuesto, setTipoImpuesto] = useState(0);

  const [DetalleImpuestoLista, setDetalleImpuestoLista] = useState([]);

  useEffect(() => {
    (async () => {
      let data = await axios.get("api/impuestos/detalles");
      setDetalleImpuestoLista(data.data._embedded);
      data.data._embedded.sort((a, b) => {
        if (a.detalle === "IVA 15") {
          return -1;
        } else if (b.detalle === "IVA 15") {
          return 1;
        } else if (a.detalle === "IVA0") {
          return -1;
        } else if (b.detalle === "IVA0") {
          return 1;
        }

        return a.detalle.localeCompare(b.detalle);
      });

      const defaultElement = data.data._embedded.find(
        (el) => el.detalle === "IVA 15"
      );

      if (defaultElement) {
        setTipoImpuesto(JSON.stringify(defaultElement));
      }
    })();
  }, []);

  const { RentaList, setRentaList } = useContext(ModalAddImpuestoContext);

  const empresa = useSelector((store) => store.empresa.empresa);

  useEffect(() => {
    (async () => {
      let imprenta = await getListV2(empresa, "GetCodRentaRetencion", "");
      setImprentaList(imprenta._embedded);
    })();
  }, []);

  const handleDeleteElementRetencion = (index) => {
    RentaList.splice(index, 1);
    setRentaList([...RentaList]);
  };

  const onClickHandlerRetencion = () => {
    setIsLoading(true)

    if (CodigoRetencion === "") {
      toast('Asegurate de seleccionar un codigo de renta', { type: "info", isLoading: false, onClose: 2000, closeOnClick: true, });
      setTimeout(() => {
        setIsLoading(false);
      }, 2000)
      return;
    } else {
      let data = {
        Codigo: 1,
        CodigoRetencion,
        BaseImponible: roundToTwo(parseFloat(BaseImponibleRet)),
        ValorRetenido: roundToTwo(parseFloat(ValorRetenido)),
        PorcentajeRetener: roundToTwo(parseFloat(PorcentajeRetener)),
        isRenta: true,
        TipoImpuesto,
      };

      setRentaList([...RentaList, data]);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000)
    }
  };

  const ChangeCodRenta = (e) => {
    const selectedValue = e.value;

    let element = ImprentaList[selectedValue];
    setCodRentaSelected(element);
    setPorcentajeRetener(element.porcentaje);
    setCodigoRetencion(element.codigo);
  };

  useEffect(() => {
    if (BaseImponibleRet >= 0) {
      let valor = ((BaseImponibleRet * PorcentajeRetener) / 100).toFixed(2);
      setValorRetenido(valor);
    }
  }, [BaseImponibleRet, PorcentajeRetener]);

  return (
    <>
      <div
        className=" p-4 bg-gray-50 rounded-lg dark:bg-gray-800"
        id="dashboard"
        role="tabpanel"
        aria-labelledby="dashboard-tab"
      >
        <div className="py-0  flex-wrap justify-between mb-0">
        <div className="w-full grid md:grid-cols-1 md:gap-2 mb-2 md:mb-0">
      <div className="md:col-span-2 relative z-[100000] mb-2 w-full group">
        <label className="block text-sm font-medium text-black dark:text-white">
          Código renta
        </label>
        <Select
          name="CodigoRetencion"
          id="CodigoRetencion"
          onChange={(e) => ChangeCodRenta(e)}
          className="w-full"
          x-model="item.gst"
          options={ImprentaList.map((el, key) => ({
            value: key,
            label: `${el.codigo} - ${el.nombre}`,
          }))}
          menuPortalTarget={document.body}  
          styles={{
            menuPortal: base => ({ ...base, zIndex: 9999 }),
          }}
          menuPosition="fixed"
        />
      </div>
    </div>
          <div className="flex lg:flex-row flex-col gap-1 my-3">
            <div className="basis-1/6">
              <label className="block text-sm font-medium text-black dark:text-white">
                {" "}
                % Retener{" "}
              </label>
              {CodRentaSelected.porcentajeLibre ? (
                <input
                  type="text"
                  className={StyleInput}
                  placeholder=" "
                  id="PorcentajeRetener"
                  name="PorcentajeRetener"
                  onChange={(e) => setPorcentajeRetener(e.target.value)}
                  value={PorcentajeRetener}
                />
              ) : (
                <input
                  readOnly
                  type="text"
                  className={StyleInput + " bg-gray-200"}
                  placeholder=" "
                  id="PorcentajeRetener"
                  name="PorcentajeRetener"
                  onChange={(e) => setPorcentajeRetener(e.target.value)}
                  value={PorcentajeRetener}
                />
              )}
            </div>
            <div className="basis-1/3">
              <label className="block text-sm font-medium text-black dark:text-white">
                {" "}
                Valor de impuesto{" "}
              </label>
              <select
                className={StyleSelect}
                placeholder=" "
                id="valorImpuesto"
                name="valorImpuesto"
                value={TipoImpuesto}
                onChange={(e) => setTipoImpuesto(e.target.value)}
              >
                {DetalleImpuestoLista.map((el) => {
                  return (
                    <option key={el.codigo} value={JSON.stringify(el)}>
                      {el.detalle}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="basis-1/6">
              <label className="block text-sm font-medium text-black dark:text-white">
                {" "}
                Base impto.{" "}
              </label>
              <input
                type="number"
                className={StyleInput}
                placeholder=" "
                id="BaseImponibleRet"
                name="BaseImponibleRet"
                //    onChange={(e) => setBaseImponibleRet(e.target.value)}
                onChange={(e) => setBaseImponibleRet(e.target.value)}
                value={BaseImponibleRet}
              />
            </div>
            <div className="basis-1/6">
              <label className="block text-sm font-medium text-black dark:text-white">
                {" "}
                Valor retenido{" "}
              </label>
              <input
                type="text"
                className={StyleInput}
                placeholder=" "
                id="ValorRetenido"
                name="ValorRetenido"
                onChange={(e) => setValorRetenido(e.target.value)}
                value={ValorRetenido}
              />
            </div>
            <div className="basis-1/6 my-auto pt-4">
              <button
                disabled={isLoading}
                onClick={onClickHandlerRetencion}
                type="button"
                className="flexdark:hover:bg-gray-700 bg-blue-600 hover:bg-blue-700  rounded-lg p-2 items-center justify-between px-2 text-sm font-medium leading-5 dark:text-gray-400 focus:outline-none focus:shadow-outline-gray"
              >
                {
                  isLoading ? '....' : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 dark:stroke-white text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  )
                }
              </button>
            </div>
          </div>
          {RentaList.length > 0 &&
            <div className="overflow-x-auto relative  mt-8">
            <table className="w-full text-sm text-left divide-y divide-gray-300 text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="py-1 px-6 ">
                    Codigo ret.
                  </th>
                  <th scope="col" className="py-1 px-6 ">
                    Base impto.
                  </th>
                  <th scope="col" className="py-1 px-6 ">
                    Valor impto.
                  </th>
                  <th scope="col" className="py-1 px-6 "></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-300">
                {RentaList.length === 0 ? (
                  <MesageWarningTable
                    colSpan="4"
                    message="No se ha ingresado información"
                  />
                ) : null}
                {RentaList.map((el, index) => (
                  <tr key={index} className="bg-white dark:bg-gray-800">
                    <td className="py-1 px-6">
                      {el.CodigoRetencion} - {el.PorcentajeRetener}%
                    </td>
                    <th
                      scope="row"
                      className="py-0 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {el.BaseImponible}
                    </th>
                    <th
                      scope="row"
                      className="py-0 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {el.ValorRetenido}
                    </th>
                    <td className="py-1 px-6 text-red-500 hover:text-red-600 ">
                      <button
                        onClick={() => handleDeleteElementRetencion(index)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                          />
                        </svg>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          }
        
        </div>
      </div>
    </>
  );
};

export default RentaSection;
