import { useContext, useState } from "react";

import {
  TableStyle,
  TableStyleDiv,
  TableStyleTBody,
  TableStyleTHead,
} from "../../../../../features/Constantes";
import {
  AddIcon,
  DeleteIcon,
  DownloadIcon,
  UploadIcon,
} from "../../../../../Icons/ButtonIcons";
import { TransferenciasProviderContext } from "./Context/TransferenciasProvider";

export default function TransferenciasDetalle() {
  const { setRows, rows } = useContext(TransferenciasProviderContext);
  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        codigo: "",
        cuenta: "",
        cantidad: "",
        costo: "",
        lote: "",
        fechaCaducidad: "",
      },
    ]);
  };
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeRowIndex, setActiveRowIndex] = useState(null);
  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const newRows = [...rows];
    newRows[index][name] = value;
    setRows(newRows);


    if (name === "codigo") {
      // Establecer la fila activa
      setActiveRowIndex(index);

      // Obtener datos existentes del localStorage
      const existingData = JSON.parse(localStorage.getItem("Productos")) || [];

      // Filtrar productos basados en el input
      const filtered = existingData.filter(
        (product) =>
          product.codigo.includes(value) ||
          product.nombreProducto.toLowerCase().includes(value.toLowerCase())
      );

      setFilteredProducts(filtered);
      setIsDropdownOpen(value.trim() !== ""); // Mostrar el menú desplegable si el input no está vacío
    } else {
      setIsDropdownOpen(false); // Ocultar el menú desplegable si el campo no es 'codigo'
    }
  };

  const handleSelectProduct = (index, product) => {
    const newRows = [...rows];
    newRows[index].codigo = product.codigo + " - " + product.nombreProducto;
    setRows(newRows);
    setIsDropdownOpen(false);
    setActiveRowIndex(null); // Restablecer la fila activa
  };
  return (
    <>
      <div>
        <div name="InformeGeneral" className="border rounded-lg">
          <p className="bg-[#003B5B] text-white font-semibold rounded-t-lg p-3">
            Detalle de la Transacción
          </p>
          <div className="p-4">
            <div className="flex flex-row gap-1 text-white font-semibold text-[12px]">
              <button
                type="button"
                onClick={handleAddRow}
                className="p-3 rounded-lg bg-blue-600 hover:bg-blue-700 pr-5 pl-5 flex flex-row gap-2 items-center"
              >
                <span>
                  <AddIcon />
                </span>
                Agregar
              </button>
              <button className="p-3 rounded-lg bg-blue-600 hover:bg-blue-700 pl-5 pr-5 flex flex-row gap-2 items-center">
                <span>
                  <UploadIcon />
                </span>
                Importar
              </button>
              <button className="p-3 pl-8 pr-8 rounded-lg bg-gray-600 hover:bg-gray-700 flex flex-row gap-2 items-center">
                {" "}
                <span>
                  <DownloadIcon />
                </span>
                Descargar datos
              </button>
            </div>

            <div className="px-4 pt-4 sm:px-6 lg:px-8">
              <div className=" flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle px-1">
                    <div className={TableStyleDiv + " whitespace-nowrap"}>
                      <table className={TableStyle}>
                        <thead
                          className={
                            TableStyleTHead + " bg-[#d9d9d9] text-sm divide-x"
                          }
                        >
                          <tr>
                            <th scope="col" className="px-3 py-3.5">
                              Codigo / Producto
                            </th>
                            <th scope="col" className="px-3 py-3.5">
                              Cuenta Analitica
                            </th>
                            <th scope="col" className="px-3 py-3.5">
                              Cantidad
                            </th>
                            <th scope="col" className="px-3 py-3.5">
                              Costo
                            </th>
                            <th scope="col" className="px-3 py-3.5">
                              Lote
                            </th>
                            <th scope="col" className="px-3 py-3.5">
                              Fecha de Caducidad
                            </th>
                            <th scope="col" className="px-3 py-3.5">
                              Eliminar
                            </th>
                          </tr>
                        </thead>
                        <tbody className={TableStyleTBody}>
                          {rows.map((row, index) => (
                            <tr key={index} className="text-center">
                                 <td className="p-2 relative">
                                <input
                                  type="text"
                                  name="codigo"
                                  value={row.codigo}
                                  onChange={(e) => handleChange(index, e)}
                                  className="border-b border-gray-300 bg-transparent text-center focus:outline-none focus:border-none  p-1 w-full"
                                />
                                {isDropdownOpen && activeRowIndex === index && (
                                  <ul className=" bg-white border border-gray-300 mt-1 w-full">
                                    {filteredProducts.map((product) => (
                                      <li
                                        key={product.codigo}
                                        onClick={() =>
                                          handleSelectProduct(index, product)
                                        }
                                        className="p-2 cursor-pointer hover:bg-gray-200"
                                      >
                                        {product.codigo} -{" "}
                                        {product.nombreProducto}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  name="cuenta"
                                  value={row.cuenta}
                                  onChange={(e) => handleChange(index, e)}
                                  className="border-b border-gray-300  text-center p-1 w-full focus:outline-none focus:border-none"
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  name="cantidad"
                                  value={row.cantidad}
                                  onChange={(e) => handleChange(index, e)}
                                  className="border-b border-gray-300 text-center  p-1 w-full focus:outline-none focus:border-none"
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  name="costo"
                                  value={row.costo}
                                  onChange={(e) => handleChange(index, e)}
                                  className="border-b border-gray-300 text-center  p-1 w-full focus:outline-none focus:border-none"
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  name="lote"
                                  value={row.lote}
                                  onChange={(e) => handleChange(index, e)}
                                  className="border-b border-gray-300 text-center  p-1 w-full focus:outline-none focus:border-none"
                                />
                              </td>
                              <td>
                                <input
                                  type="date"
                                  name="fechaCaducidad"
                                  value={row.fechaCaducidad}
                                  onChange={(e) => handleChange(index, e)}
                                  className="border-b border-gray-300 text-center  p-1 w-full focus:outline-none focus:border-none"
                                />
                              </td>
                              <td>
                                <button
                                  onClick={() => {
                                    const newRows = rows.filter(
                                      (_, i) => i !== index
                                    );
                                    setRows(newRows);
                                  }}
                                >
                                  <DeleteIcon />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
