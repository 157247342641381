import { roundNumber, roundToTwo } from "../../services/Utilitario";

export const CalcularSubTotales = (Detalles, FormaPagos = []) => {

  let subTotalIva0 = 0;
  let subTotalNoObjetoIva = 0;
  let subTotalExentoIva = 0;
  let subTotalIva = 0;
  let totalIva = 0;
  let propina = 0;
  let totalIce = 0;
  let totalIRBPNR = 0;
  let totalDescuentos = 0;
  
  Detalles.forEach((o) => {
    o.subTotal = roundNumber((o.cantidad * o.precioUnitario - (o.descuento ? o.descuento : 0)), 6);
    if (o.descuento) totalDescuentos += parseFloat(o.descuento);
    //o.idImpuestoIvaNavigation.codigo
    switch (o.idImpuestoIvaNavigation.idImpuesto) {
      case 1:
        switch (o.idImpuestoIvaNavigation.codigo) {
          case 0:
            subTotalIva0 = roundToTwo(roundToTwo(subTotalIva0) + o.subTotal);
            break;
          case 6:
            subTotalNoObjetoIva = roundToTwo(
              roundToTwo(subTotalNoObjetoIva) + o.subTotal
            );
            break;
          case 7:
            subTotalExentoIva = roundToTwo(
              roundToTwo(subTotalExentoIva) + o.subTotal
            );
            break;
          default:
            //console.log("1111=" + subTotalIva);
            o.iva = o.subTotal * o.idImpuestoIvaNavigation.porcentaje;
            //o.total = roundToTwo(o.total+o.iva)
            totalIva +=  o.iva;
            subTotalIva += o.subTotal;
            //console.log("2222=" + subTotalIva);
            break;
        }
        break;
      case 2:
        alert("Hey");
        break;
      default:
        //alert('Default case');
        break;
    }
  });
  let totalSinImpuesto = roundToTwo(
    roundToTwo(subTotalIva) +
      roundToTwo(subTotalIva0) +
      roundToTwo(subTotalNoObjetoIva) +
      roundToTwo(subTotalExentoIva)
  );

  let importeTotal = roundToTwo(
    roundToTwo(totalSinImpuesto) + roundToTwo(propina) + roundToTwo(totalIva)
  );
  let formasPagoFactura = 0;
  FormaPagos.forEach((el) => {
    formasPagoFactura += roundToTwo(el.valor);
  });
  let ValorTotal = roundToTwo(importeTotal) - roundToTwo(formasPagoFactura);

  return {
    subTotalIva,
    subTotalIva0,
    subTotalNoObjetoIva,
    subTotalExentoIva,
    totalSinImpuesto,
    totalDescuentos,
    totalIce,
    totalIva,
    totalIRBPNR,
    propina,
    importeTotal,
    ValorTotal,
  };
};

export const TotalInicial = {
  subTotalIva: 0,
  subTotalIva0: 0,
  subTotalNoObjetoIva: 0,
  subTotalExentoIva: 0,
  totalSinImpuesto: 0,
  totalDescuentos: 0,
  totalIce: 0,
  totalIva: 0,
  totalIRBPNR: 0,
  propina: 0,
  importeTotal: 0,
};


//FACTURAS BUSCADAS
export const ParsearFormaPago = (FormaPago) => {
  let data = FormaPago.map(el => {
    return {
      tipoPago: el.idFormaPagoSri,
      descripcionTipoPago: el.idFormaPagoSriNavigation.descripcion,
      tiempoPago: el.unidaDeTiempo,
      plazoPago: el.plazo,
      valor: el.total
    }
  });

  return data;
}