import React from "react";

const EstadoTable = ({ nombre = "NONE", codigo='' }) => {
    
    /*
cod id_tabla                                descripcion 
10	4D10C970-5024-491D-AE6E-499A54E33B9E	ANULADO*
9	C671F825-941A-4F9C-B3AA-9434F647643B	Error*
8	1477AA9B-FA14-49BD-B9F0-ECE6D2952756	Registrado*
0	1677AA9C-FA14-49BD-B9F0-ECE6D2952756	Firmado*
1	1777AA9D-FA14-49BD-B9F0-ECE6D2952756	Autorizado*
2	2077AA9F-FA14-49BD-B9F0-ECE6D2952756	No Autorizado*
3	1877AA9E-FA14-49BD-B9F0-ECE6D2952756	Devuelto*
4	2177AA9F-FA14-49BD-B9F0-ECE6D2952756	Recibido
5	2377AA9F-FA14-49BD-B9F0-ECE6D2952756	Error Al Firmar
6	2277AA9F-FA14-49BD-B9F0-ECE6D2952756	No Encontrado*
7	1977AA9F-FA14-49BD-B9F0-ECE6D2952756	Procesando
    */
    return (
        <div className="flex items-center justify-center px-3 py-2.5 text-center">
            {(() => {
                switch (codigo.toUpperCase()) {
                    case '9':
                    case '3':
                    case '2':
                    case '5':
                        return (<div className="h-2.5 w-2.5 rounded-full bg-red-500 mr-2"></div>)
                    case '1':
                        return (<div className="h-2.5 w-2.5 rounded-full bg-green-400 mr-2"></div>)
                    case '10':
                        return (<div className="h-2.5 w-2.5 rounded-full bg-yellow-400 mr-2"></div>)                    
                    case '8':
                        return (<div className="h-2.5 w-2.5 rounded-full bg-gray-500 mr-2"></div>)
                    case '0':
                        return (<div className="h-2.5 w-2.5 rounded-full bg-blue-200 mr-2"></div>)                    
                    /*case '1477AA9B-FA14-49BD-B9F0-ECE6D2952756':
                        return (<div className="h-2.5 w-2.5 rounded-full bg-blue-500 mr-2"></div>)*/
                    default:
                        return (<div className="h-2.5 w-2.5 rounded-full bg-gray-800 mr-2"></div>)
                }
            })()}
            {nombre.toUpperCase()}
        </div>
    )
}

export default EstadoTable;