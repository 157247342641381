import React, { useState, useEffect, useContext } from "react";
import { StyleInput, StyleSelect } from "../../../../features/Constantes";
import Select from "react-select";
import Paises02 from "../../../../data/pais02.json";
import Paises01 from "../../../../data/pais01.json";
import { ModalAddImpuestoContext } from "./ModalAddImpuestoProvider";

const InfoOpcionalImpuesto = ({ register, setValue }) => {
  const [options, setOptions] = useState([]);
  const [selecttiporegi, setselecttiporegi] = useState("01");

  useEffect(() => {
    if (selecttiporegi === "01" || selecttiporegi === "03") {
    setValue("TipoRegi", selecttiporegi === "01" ? "01" : "03");
      let options = Paises01.map((el) => {
        return {
          label: el.Pais,
          value: el.Codigo,
        };
      });

      setOptions(options);
    } else if (selecttiporegi === "02") {
    setValue("TipoRegi", "02");
      let options = Paises02.map(({ Codigo, Pais }) => ({
        label: Pais,
        value: Codigo,
      }));
      setOptions(options);
    }
  }, [selecttiporegi]);

  const handleChange = (event) => setselecttiporegi(event.target.value);

  const { PagoLocExt } = useContext(ModalAddImpuestoContext);

  useEffect(() => {
    if(PagoLocExt === "02") {
      setValue("PagoRegFis", true);
    } else {
      setValue("PagoRegFis", null);
    }
  }, [PagoLocExt]);

  return (
    <>
      <div id="accordion-arrow-icon " data-accordion="open">
        <div
          id="accordion-arrow-icon-body-2"
          className={PagoLocExt === "02" ? "" : "hidden"}
        >
          <hr className="py-2" />
          <div className="w-full  mb-2 md:mb-0">
            <div className="relative z-0 mb-2 w-full group">
              <div className="w-full grid md:grid-cols-3 md:gap-2 mb-2 ">
                <div className="relative z-0 mb-2 w-full group">
                  <label className="block text-sm font-medium text-black dark:text-white">
                    {" "}
                    Tipo regi.{" "}
                  </label>
                  <select
                    value={selecttiporegi}
                    onChange={handleChange}
                    className={StyleSelect}
                    id="TipoRegi"
                    name="TipoRegi"
                  >
                    <option value="01">Régimen General</option>
                    <option value="02">Régimen Fiscal</option>
                    <option value="03">
                      Régimen fiscal preferente o jurisdicción de menor
                      imposición
                    </option>
                  </select>
                </div>
                {PagoLocExt !== "02" && (
                  <div className="relative z-0 mb-2 w-full group">
                    <label className="block text-sm font-medium text-black dark:text-white">
                      {" "}
                      Pago reg. fis.{" "}
                    </label>
                    <input
                      type="checkbox"
                      className={StyleInput}
                      placeholder=" "
                      id="PagoRegFis"
                      name="PagoRegFis"
                      {...register("PagoRegFis", {})}
                    />
                  </div>
                )}
                <div className="relative z-0 mb-2 w-full group">
                  <label className="block text-sm font-medium text-black dark:text-white">
                    {" "}
                    Conv. dob. trib.{" "}
                  </label>
                  <input
                    type="checkbox"
                    className={StyleInput}
                    placeholder=" "
                    id="AplicConvDobTrib"
                    name="AplicConvDobTrib"
                    {...register("AplicConvDobTrib", {})}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="w-full  mb-2 md:mb-0">
            <div
              className="relative  mb-2 w-full group "
              style={{ zIndex: 80 }}
            >
              <div className="w-full grid md:grid-cols-3 z-[100] md:gap-2 mb-2 ">
                <div className="relative z-[100] mb-2 w-full  group">
                  <label className="block text-sm font-medium text-black dark:text-white">
                    {" "}
                    País efec. pago*{" "}
                  </label>
                   <Select
                    id="PaisEfecPago"
                    name="PaisEfecPago"
                    onChange={(el) => setValue("PaisEfecPago", el.value)}
                    options={options}
                    menuPortalTarget={document.body}  
                    styles={{
                      menuPortal: base => ({ ...base, zIndex: 9999 }),
                    }}
                    menuPosition="fixed"
                  />
                </div>
                <div className=" relative z-0 mb-2 w-full group">
                  <label className="block text-sm font-medium text-black dark:text-white">
                    {" "}
                    Suj. ret. nor. leg.{" "}
                  </label>
                  <input
                    type="checkbox"
                    className={StyleInput}
                    placeholder=" "
                    id="PagExtSujRetNorLeg"
                    name="PagExtSujRetNorLeg"
                    {...register("PagExtSujRetNorLeg", {})}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoOpcionalImpuesto;
