import React from "react";
import {
  StyleInput,
  StyleInputError,
  StyleLabel,
  StyleLabelError,
} from "../../Constantes";
import { useFormContext } from "react-hook-form";

const InputDate = (props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <label
        htmlFor={props.name}
        className={
          errors[props.name]
            ? StyleLabelError
            : StyleLabel + " -translate-x-0 -translate-y-0 text-left"
        }
      >
        {props.title || props.name}
      </label>
      <input
        type="date"
        placeholder=" "
        className={errors[props.name] ? StyleInputError : StyleInput}
        {...register(props.name, props.validations)}
        {...props}
      />
      {/* <label
        htmlFor={props.name}
        className={errors[props.name] ? StyleLabelError : StyleLabel}
      >
        {props.title || props.name}
      </label> */}
    </>
  );
};

export default InputDate;
