import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { post } from "../../../services";
import {
  StyleSelect,
  StyleLabel,
  StyleInput,
  StyleInputError,
  StyleLabelError,
} from "../../../features/Constantes";
import { LargeModalForm } from "../../../features/components/LargeModalForm";
import SelectItems from "../../../services/provincias.json";
import MesageWarningTable from "../../../features/components/MesageWarningTable";
import { EstablecimientosContext } from "./EstablecimientosProvider";
import axios from "axios";
import { useEffect } from "react";
import { Oval } from "react-loader-spinner";

import { toast } from "react-toastify";

const ModalAdd = () => {
  const empresa = useSelector((store) => store.empresa.empresa);
  const usuario = useSelector((store) => store.app.user);

  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    reset,
  } = useForm("onSubmit");

  const PuntosEmisionForm = useForm("onSubmit");

  const { fetchData } = useContext(EstablecimientosContext);

  useEffect(() => {
    setCantonesList(SelectItems[1].cantones);
    setParroquiasList(
      Object.values(Object.values(SelectItems[1].cantones)[0].parroquias)
    );
  }, []);

  const [CantonesList, setCantonesList] = useState({});
  const [ParroquiasList, setParroquiasList] = useState({});
  const [PuntosEmisionList, setPuntosEmisionList] = useState([]);
  const [LoadingImage, setLoadingImage] = useState(false);

  const [Preview, setPreview] = useState(
    process.env.PUBLIC_URL + "/img/default-logo.png"
  );
  const [IsLoading, setIsLoading] = useState(false);

  const guardarEstablecimiento = async (valores, resetForm) => {
    setIsLoading(true);
    try {
      if (getValues().Codigo.length !== 3) return;
      if (PuntosEmisionList.length < 1) return;

      let isExist = await axios.get(
        `api/establecimientos/getbycode/${empresa.idEmpresa}?codigo=${Number(
          getValues().Codigo
        )}`
      );
      if (isExist.data) {
        toast.error("Ya existe un establecimiento con el mismo código", {
          autoClose: 2000,
        });
        return;
      } else {
        clearErrors();
        valores.IdEmpresa = empresa.idEmpresa;
        valores.UsuarioCreacionId = usuario.identificacion;
        valores.Ciudad = valores.Canton;
        valores.Activo = 1;
        valores.PuntoEmision = PuntosEmisionList;
        delete valores.Canton;

        let res = await post(
          "api/Establecimientos/GuardarEstablecimiento",
          valores
        );
        if (res.data) {
          try {
            fetchData();
            await UploadedImage(res.data.idEstablecimiento);
            //document.querySelector("#crearEstablecimientoclose").click();
          } catch (error) {
            //toast.success("Establecimiento creado");
          }
          //if (res.data.urlImage)
        }
        reset();
        setPuntosEmisionList([]);
        setIsLoading(false);
        document.querySelector("#crearEstablecimientoclose").click();
        // console.log(res.data.urlImage + " INFORMACION URL IMAGEN");
        // console.log(res.data.idEstablecimiento + " INFORMACION ESTABLECIMIENTO");
        // if (res.data.urlImage) await UploadedImage(res.data.idEstablecimiento);
        // console.log("fin res data url imagen")
      }
    } catch (error) {
      document.querySelector("#crearEstablecimientoclose").click();
      // console.log(error);
    }
    setIsLoading(false);

    // reset(PuntosEmisionList.Codigo);
    // reset(PuntosEmisionList.Descripcion);

    //resetForm();
  };

  const handleChangeProvincia = (e) => {
    setCantonesList([]);
    setParroquiasList([]);
    setValue("Canton", "");
    setValue("Parroquia", "");
    let valores = Object.keys(SelectItems).filter((el) => {
      return SelectItems[el].provincia === e.target.value;
    });
    setCantonesList(SelectItems[valores[0]].cantones);
    setValue(
      "Canton",
      Object.values(SelectItems[valores[0]].cantones)[0].canton
    );
    setParroquiasList(
      Object.values(
        Object.values(SelectItems[valores[0]].cantones)[0].parroquias
      )
    );
    setValue(
      "Parroquia",
      Object.values(
        Object.values(SelectItems[valores[0]].cantones)[0].parroquias
      )[0]
    );
  };

  const handleChangeCanton = (e) => {
    setParroquiasList([]);
    setValue("Parroquia", "");
    let valores = Object.keys(CantonesList).filter((el) => {
      return CantonesList[el].canton === e.target.value;
    });

    setParroquiasList(CantonesList[valores[0]].parroquias);
    setValue(
      "Parroquia",
      Object.values(CantonesList[valores[0]].parroquias)[0]
    );
  };

  const handleChangeImage = (e) => {
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = (el) => {
      setPreview(reader.result);
    };
  };

  const UploadedImage = async (id) => {
    setLoadingImage(true);
    let formData = new FormData();
    let e = document.getElementById("dropzone-file");
    formData.append("_establecimiento", e.files[0]);
    //console.log("error data" + formData.append("_establecimiento", e.files[0]));
    let res = await post(
      `api/Establecimientos/GuardarImgEstablecimiento/${id}`,
      formData
    );
    // console.log(res + "IMAGEN");
    setLoadingImage(false);
  };

  const handleAddPuntoEmision = (e) => {
    setIsLoading(true);
    let values = PuntosEmisionList.find((el) => el.Codigo === e.Codigo);

    if (values !== undefined) {
      toast.info("Ya tienes añadido un punto de emision con el mismo codigo!", {
        autoClose: 2000,
      });
      PuntosEmisionForm.setValue("Codigo", "");
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
      return;
    }

    setPuntosEmisionList([...PuntosEmisionList, e]);
    PuntosEmisionForm.setValue("Codigo", "");
    PuntosEmisionForm.setValue("Descripcion", "");

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const handleDeleteElement = (el, index) => {
    PuntosEmisionList.splice(index, 1);
    setPuntosEmisionList([...PuntosEmisionList]);
  };


















  return (
    <LargeModalForm
     IsLoading={IsLoading}
      //onClose={handleCloseModal}
      buttonModal={"crearEstablecimiento"}
      submitText={"Agregar"}
      title="Agregar establecimiento"
      subTitle={`Ruc: ${empresa.ruc} - Razón social: ${empresa.razonSocial}`}
      submitModal={handleSubmit(guardarEstablecimiento)}
    >
      {/* <div className="flex align-center justify-center mb-6">
        <p>
          <b className="dark:text-gray-300">Ruc: </b>
          <b className="dark:text-gray-100">{empresa.ruc} -</b>{" "}
          <b className="dark:text-gray-300">Razon Social: </b>
          <b className="dark:text-gray-100"> {empresa.razonSocial}</b>
        </p>
      </div> */}
      <div className="my-6">
        {!LoadingImage ? (
          <img
            className="mx-auto upload-image-size object-cover outline outline-gray-300 outline-1 rounded shadow-sm"
            src={Preview}
            height="90"
            width="230"
            alt="your logo"
          />
        ) : (
          <div
            className="flex align-middle justify-center mx-auto"
            style={{ height: 90 }}
          >
            <Oval
              height={50}
              width={50}
              color="#FFFFFF"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#233876"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        )}{" "}
      </div>
      <div className="flex justify-around mt-5 items-center ">
        <div className="w-[300px]">
          <input
            className="form-control    block    w-full    px-3    py-1.5    text-base    font-normal    dark:border-gray-600    dark:bg-gray-700    dark:text-white    text-gray-700    bg-white bg-clip-padding    border border-solid border-gray-300    rounded    transition    ease-in-out    m-0    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            accept="image/png, image/gif, image/jpeg"
            id="dropzone-file"
            onChange={handleChangeImage}
            type="file"
          />
        </div>
      </div>
      <div className="relative z-0 mb-2  pt-2 w-full group">
        <h5 className="font-bold  mb-6 text-gray-800 dark:text-white  pb-2 border-b-gray-600 border-b">
          Establecimiento
        </h5>

        <form>
          <div className="relative z-0 mb-2  pt-2 w-full group">
            <div className="w-full grid md:grid-cols-6 md:gap-4 mb-2 ">
              <div className="relative z-0 mb-2 w-full col-span-6 lg:col-span-1 group">
                <input
                  type="text"
                  className={errors.Codigo ? StyleInputError : StyleInput}
                  placeholder=" "
                  id="Codigo"
                  name="Codigo"
                  {...register("Codigo", {
                    required: {
                      value: true,
                      message: "Codigo es requerido",
                    },
                    maxLength: {
                      value: 3,
                      message: "No más de 3 carácteres!",
                    },
                    minLength: {
                      value: 3,
                      message: "Mínimo 3 carácteres",
                    },
                  })}
                />
                <label
                  htmlFor="Codigo"
                  className={errors.Codigo ? StyleLabelError : StyleLabel}
                >
                  Establ.(SRI) *
                </label>
              </div>
              <div className="relative z-0 mb-2 w-full col-span-6 lg:col-span-2 group">
                <input
                  type="text"
                  className={
                    errors.NombreComercial ? StyleInputError : StyleInput
                  }
                  placeholder=" "
                  id="NombreComercial"
                  name="NombreComercial"
                  {...register("NombreComercial", {
                    maxLength: {
                      value: 300,
                      message: "No más de 3 carácteres!",
                    },
                    minLength: {
                      value: 1,
                      message: "Mínimo 1 carácteres",
                    },
                  })}
                />
                <label
                  htmlFor="NombreComercial"
                  className={
                    errors.NombreComercial ? StyleLabelError : StyleLabel
                  }
                >
                  Nombre comercial
                </label>
              </div>
              <div className="relative z-0 mb-2 col-span-6 lg:col-span-3 w-full group">
                <input
                  type="text"
                  className={errors.Direccion ? StyleInputError : StyleInput}
                  placeholder=" "
                  id="Direccion"
                  name="Direccion"
                  {...register("Direccion", {
                    required: {
                      value: true,
                      message: "Direccion es requerido",
                    },
                    maxLength: {
                      value: 300,
                      message: "No más de 300 carácteres!",
                    },
                    minLength: {
                      value: 4,
                      message: "Mínimo 4 carácteres",
                    },
                  })}
                />
                <label
                  htmlFor="Direccion"
                  className={errors.Direccion ? StyleLabelError : StyleLabel}
                >
                  Dirección *
                </label>
              </div>
              <div className="relative z-0 mb-2 col-span-6 lg:col-span-2 w-full group">
                <select
                  type="text"
                  className={StyleSelect}
                  placeholder=" "
                  id="Provincia"
                  name="Provincia"
                  {...register("Provincia", {
                    required: {
                      value: true,
                      message: "Provincia es requerido",
                    },
                  })}
                  onChange={handleChangeProvincia}
                >
                  {Object.keys(SelectItems).map((el, index) => {
                    return (
                      <option key={index} value={SelectItems[el].provincia}>
                        {SelectItems[el].provincia}
                      </option>
                    );
                  })}
                </select>
                <label htmlFor="Provincia" className={StyleLabel}>
                  Provincia *
                </label>
              </div>

              <div className="relative z-0 mb-2 col-span-6 lg:col-span-2 w-full group">
                <select
                  type="text"
                  className={StyleSelect}
                  placeholder=" "
                  id="Canton"
                  name="Canton"
                  {...register("Canton", {
                    required: {
                      value: true,
                      message: "Canton es requerido",
                    },
                  })}
                  onChange={handleChangeCanton}
                >
                  {Object.keys(CantonesList).map((el, index) => {
                    return (
                      <option key={index} value={CantonesList[el].canton}>
                        {CantonesList[el].canton}
                      </option>
                    );
                  })}
                </select>
                <label htmlFor="Canton" className={StyleLabel}>
                  Cantón *
                </label>
              </div>

              <div className="relative z-0 mb-2 col-span-6 lg:col-span-2 w-full group">
                <select
                  type="text"
                  className={StyleSelect}
                  placeholder=" "
                  id="Parroquia"
                  name="Parroquia"
                  {...register("Parroquia", {
                    required: {
                      value: true,
                      message: "Parroquia es requerido",
                    },
                  })}
                >
                  {Object.values(ParroquiasList).map((el, index) => {
                    return (
                      <option key={index} value={el}>
                        {el}
                      </option>
                    );
                  })}
                </select>
                <label htmlFor="Canton" className={StyleLabel}>
                  Parroquia *
                </label>
              </div>
            </div>
          </div>

          <div>
            <label className="text-gray-800   dark:text-white block mt-15 mb-8 font-bold text-base  tracking-wide">
              Puntos de emisión
            </label>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-2">
              <div className="relative z-0   group">
                <input
                  type="number"
                  className={
                    PuntosEmisionForm.formState.errors.Codigo
                      ? StyleInputError
                      : StyleInput
                  }
                  placeholder=" "
                  id="Codigo"
                  name="Codigo"
                  {...PuntosEmisionForm.register("Codigo", {
                    required: {
                      value: true,
                      message: "Codigo es requerido",
                    },
                    maxLength: {
                      value: 3,
                      message: "No más de 3 carácteres!",
                    },
                    minLength: {
                      value: 3,
                      message: "Mínimo 3 carácteres",
                    },
                  })}
                />
                <label
                  htmlFor="floating_last_name"
                  className={
                    PuntosEmisionForm.formState.errors.Codigo
                      ? StyleLabelError
                      : StyleLabel
                  }
                >
                  Cód. Punto de emisión
                </label>
              </div>
              <div className="relative     z-0  group">
                <input
                  type="text"
                  maxLength={100}
                  className={
                    PuntosEmisionForm.formState.errors.Descripcion
                      ? StyleInputError
                      : StyleInput
                  }
                  placeholder=" "
                  id="Descripcion"
                  name="Descripcion"
                  {...PuntosEmisionForm.register("Descripcion", {
                    required: {
                      value: true,
                      message: "Descripcion es requerido",
                    },
                    maxLength: {
                      value: 100,
                      message: "No más de 100 carácteres!",
                    },
                    minLength: {
                      value: 1,
                      message: "Mínimo 1 carácteres",
                    },
                  })}
                />
                <label
                  htmlFor="floating_last_name"
                  className={
                    PuntosEmisionForm.formState.errors.Descripcion
                      ? StyleLabelError
                      : StyleLabel
                  }
                >
                  Descripción
                </label>
              </div>
              <div className="">
                <button
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto  px-2.5 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  type="button"
                  disabled={IsLoading}
                  onClick={PuntosEmisionForm.handleSubmit(
                    handleAddPuntoEmision
                  )}
                >
                  {IsLoading ? "Añadiendo" : "AGREGAR"}
                </button>
              </div>
            </div>
          </div>

          {PuntosEmisionList.length > 0 && (
            <div className="max-h-32 overflow-auto border rounded-md ">
              <table className="w-full text-sm text-center  text-gray-500 dark:text-gray-400">
                <thead className="text-xs my-5 text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="py-1 px-6 ">
                      CODIGO
                    </th>
                    <th scope="col" className="py-1 px-6 ">
                      DESCRIPCIÓN
                    </th>
                    <th scope="col" className="py-1 px-6 "></th>
                  </tr>
                </thead>
                <tbody>
                  {PuntosEmisionList.length === 0 ? (
                    <MesageWarningTable
                      colSpan="3"
                      message="Agregar mínimo un punto de emisión para crear establecimiento"
                    />
                  ) : null}
                  {PuntosEmisionList.map((el, index) => (
                    <tr key={index} className="bg-white dark:bg-gray-800">
                      <td className="py-1 px-6">{el.Codigo}</td>
                      <td className="py-1 px-6">{el.Descripcion}</td>
                      <td className="py-1 px-6 text-red-500 hover:text-red-600 ">
                        <button
                          onClick={(e, index) => handleDeleteElement(e, index)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </form>
      </div>
    </LargeModalForm>
  );
};

export default ModalAdd;
