import { Switch } from "@headlessui/react";
import React, { useContext, useState } from "react";
import { ReembolsoContext } from "./ReembolsoProvider";

const ActiveReembolsoButton = () => {
  const { ActiveReembolso, setActiveReembolso } = useContext(ReembolsoContext);

  return (
    <>
      <div className="inline-flex">
        
        {ActiveReembolso ? (
          <Switch
            checked={ActiveReembolso}
            onChange={(e) => setActiveReembolso(!ActiveReembolso)}
            className={`${ActiveReembolso ? "bg-blue-600" : "bg-blue-600"}
  relative inline-flex flex-shrink-0 md:h-6 md:w-11 w-9 h-5  border-2 my-auto border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={`${ActiveReembolso ? "translate-x-5" : "translate-x-0"}
      pointer-events-none inline-block md:h-5 md:w-5 h-4 w-4 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
        ) : (
          <Switch
            checked={ActiveReembolso}
            onChange={(e) => setActiveReembolso(!ActiveReembolso)}
            className={`${ActiveReembolso ? "bg-gray-400" : "bg-gray-400"}
  relative inline-flex flex-shrink-0 md:h-6 md:w-11 w-9 h-5 border-2 my-auto border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={`${ActiveReembolso ? "translate-x-5" : "translate-x-0"}
      pointer-events-none inline-block md:h-5 md:w-5 w-4 h-4 transform rounded-full bg-white  shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
        )}
        <p className="text-lg  font-sans my-auto lg:ml-2 pr-2 dark:text-white">Reembolso</p>
      </div>
    </>
  );
};

export default ActiveReembolsoButton;
