import React, { createContext, useState } from "react";

export const FacturaExportacionContext = createContext();

const FacturaExportacionProvider = (props) => {
  const [IsActiveExportacion, setIsActiveExportacion] = useState(false);
  const [ExportacionInfo, setExportacionInfo] = useState(null);
  const [listExportacion, setListExportacion] = useState({});

  return (
    <FacturaExportacionContext.Provider
      value={{
        listExportacion,
        setListExportacion,
        IsActiveExportacion,
        setIsActiveExportacion,
        ExportacionInfo,
        setExportacionInfo
      }}
    >
      {props.children}
    </FacturaExportacionContext.Provider>
  );
};

export default FacturaExportacionProvider;
