import React, { useContext, useEffect, useState } from "react";
import { set, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { LargeModalForm } from "../../components/LargeModalForm";
import {
  StyleInput,
  StyleInputError,
  StyleLabel,
  StyleLabelError,
  StyleSelect,
} from "../../Constantes";
import ComprobantesReembolsos from "../../../services/ComprobantesReembolso.json";
import { roundToTwo } from "../../../services/Utilitario";
import { ReembolsoContext } from "./ReembolsoProvider";
import { getListV2 } from "../../../services";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import MesageWarningTable from "../../components/MesageWarningTable";

const ReembolsoModal = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    reset,
    setError,
    watch,
    clearErrors,
  } = useForm({ mode: "onBlur" });

  const {
    setReembolsoList,
    ReembolsoList,
    EditReembolso,
    IsEditReembolso,
    setEditReembolso,
    setContador,
    contador,
  } = useContext(ReembolsoContext);

  const empresa = useSelector((store) => store.empresa.empresa);
  const [DataReembolso, setDataReembolso] = useState([]);

  const NumeroComprobantesObs = watch("NumeroComprobante");

  const [numComprobante, setNumComprobante] = useState("");
  const [IsLoading, setIsLoading] = useState(false);

  const handleSubmitModal = async () => {
    const data = { ...getValues() };
    console.log(data);
    const ModeloReembolsoSubmit = {
      ...data,
      DataReembolso,
    };

    setIsLoading(true);
    // var longitudAutorizacionComprobante = getValues().AutorizacionComprobante.length;
    var longitudNumeroComprobante = getValues().NumeroComprobante.replace(
      / /g,
      ""
    ).length;

    // var totaliva = getValues().Iva0;
    // var totaliva2 = getValues().Iva12;
    // var totaliva3 = getValues().Iva15;
    // var totaliva4 = getValues().Iva5;
    // var totaliva5 = getValues().Iva8;
    // var totaliva6 = getValues().Iva13;

    if (longitudNumeroComprobante !== 17) {
      toast.info("Longitud Minima de Numero Comprobante", { autoClose: 2000 });
      setIsLoading(false);
      return;
    }

    // const very = !(
    //   Number(totaliva) >= 0 ||
    //   Number(totaliva2) >= 0 ||
    //   Number(totaliva3) >= 0 ||
    //   Number(totaliva4) >= 0 ||
    //   Number(totaliva5) >= 0 ||
    //   Number(totaliva6) >= 0
    // );
    // debugger;

    // if (very) {
    //   toast.info("Asegúrate de ingresar los subtotales correctamente", {
    //     autoClose: 2000,
    //   });
    //   setIsLoading(false);
    //   return;
    // }

    const showToast = () =>
      new Promise((resolve) => setInterval(() => resolve(), 2000));

    await toast.promise(showToast, {
      success: {
        render() {
          setIsLoading(false);
          return IsEditReembolso ? "Reembolso editado" : "Reembolso añadido";
        },
        autoClose: 2000,
        closeOnClick: true,
      },
      error: {
        render() {
          setIsLoading(false);
          return "Algo sucedio, intente nuevamente.";
        },
        autoClose: 2000,
        closeOnClick: true,
      },
    });

    if (IsEditReembolso) {
      ReembolsoList[EditReembolso] = { ...ModeloReembolsoSubmit };
      setReembolsoList([...ReembolsoList]);
    } else {
      setReembolsoList([...ReembolsoList, ModeloReembolsoSubmit]);
    }

    handleCloseModal();
    setDataReembolso([]);

    document.getElementById("addReembolsoFacturaModal").click();
    setIsLoading(false);
    reset();
  };

  const handleCloseModal = () => {
    reset({
      AutorizacionComprobante: "",
      FechaEmisionComprobante: "",
      Iva0: "",
      Iva12: "",
      NumeroComprobante: "",
      RazonSocialProveedor: "",
      RucProveedor: "",
      TipoDocumento: ComprobantesReembolsos[0].Codigo,
    });
  };

  const handleOnBlurProveedor = async (e) => {
    try {
      const { value } = e.target;

      const data = await getListV2(
        empresa,
        "SearchContribReembolso",
        `?identificacion=${
          value.length === 13 ? value.substr(0, 10).trim() : value.trim()
        }`
      );

      //console.log(data);
      clearErrors("RucProveedor");
      setValue("RazonSocialProveedor", data.razonSocial);
    } catch (ex) {
      console.error(ex);
      setValue("RazonSocialProveedor", "");
      setError("RucProveedor", "ekisde");
    }
  };
  const [ImpuestosList, setImpuestosList] = useState([]);
  const getImpuestos = async () => {
    let data = await getListV2(
      empresa,
      "GetAllimpDetalles",
      "?activo=true&idImpuesto=1"
    );
    setImpuestosList(data._embedded);
    setSelectedImpuesto(data._embedded[0]);
  };

  useEffect(() => {
    getImpuestos();
    console.log(contador);
    if (!IsEditReembolso) {
      setReembolsoList([]);
      return handleCloseModal();
    }

    if (EditReembolso !== null && ReembolsoList[EditReembolso]) {
      reset({ ...ReembolsoList[EditReembolso] });
      setDataReembolso(ReembolsoList[EditReembolso].DataReembolso);
    }
  }, [EditReembolso, IsEditReembolso, contador]);

  const handleKeyPress = (event) => {
    if (event.key === "e" || event.key === "E") {
      event.preventDefault();
    }
  };

  const [selectedImpuesto, setSelectedImpuesto] = useState(null);

  const ejempplo = watch("SubTotalReembolso");
  const calculo = roundToTwo(
    selectedImpuesto 
      ? ejempplo * selectedImpuesto.porcentaje
      : ejempplo
  );

  const handleAddElement = () => {
    const data = { ...getValues() };

    if (data.SubTotalReembolso === "") {
      toast.info("Agregue un impuesto", { autoClose: 2000 });
      return;
    }
    const exists = DataReembolso.find(
      (el) =>
        el.Impuesto.idImpuestoDetalle === selectedImpuesto.idImpuestoDetalle
    );
    if (exists) {
      toast.info("El impuesto ya fue agregado", { autoClose: 2000 });
      return;
    }
    const ModeloReembolo = {
      SubTotalReembolso: data.SubTotalReembolso,
      Impuesto: selectedImpuesto,
      TotalIvaReembolso: calculo,
    };
    console.log(ModeloReembolo);
    setDataReembolso([...DataReembolso, ModeloReembolo]);
    setValue("SubTotalReembolso", "");
    setValue("TotalIvaReembolso", "");
  };

  const sumaTotal = DataReembolso.reduce((acc, el) => {
    return (
      acc +
      (parseFloat(el.TotalIvaReembolso) + parseFloat(el.SubTotalReembolso))
    );
  }, 0);

  const handleDeleteElement = (index) => {
    DataReembolso.splice(index, 1);
    setDataReembolso([...DataReembolso]);
  };

  const handleChange = (e) => {
    const selectedIndex = e.target.options.selectedIndex;
    const selectedOption = ImpuestosList[selectedIndex];
    setSelectedImpuesto(selectedOption);
  };

  return (
    <LargeModalForm
      buttonModal={"addReembolsoFacturaModal"}
      submitText={IsEditReembolso ? "Editar" : "Agregar"}
      title="Reembolso"
      subTitle={`${IsEditReembolso ? "Editar" : "Agregar"} información`}
      submitModal={handleSubmit(handleSubmitModal)}
      IsLoading={IsLoading}
    >
      <div>
        <div className=" text-base font-semibold border-b mt-5">
          <p className="my-2">Información del Proveedor</p>
        </div>
        <div className="grid md:grid-cols-3 mt-10  md:gap-1 ">
          <div className="relative z-0  w-full group">
            <input
              type="text"
              className={errors.RucProveedor ? StyleInputError : StyleInput}
              placeholder=" "
              {...register("RucProveedor", {
                maxLength: {
                  message:
                    "Longitud Maxima de RucProveedor no debe ser mayor a 20",
                  value: 20,
                },
                minLength: {
                  message:
                    "Longitud Minima de RucProveedor no debe ser menor a 1",
                  value: 1,
                },
                required: {
                  message: "Longitud Minima de RucProveedor es requerido",
                  value: true,
                },
              })}
              onBlur={handleOnBlurProveedor}
            />
            <label
              htmlFor="RucProveedor"
              className={errors.RucProveedor ? StyleLabelError : StyleLabel}
            >
              Número de Identificación
            </label>
          </div>
          <div className="relative z-0  w-full group">
            <input
              type="text"
              className={StyleInput}
              placeholder=" "
              id="RazonSocialProveedor"
              name="RazonSocialProveedor"
              readOnly
              {...register("RazonSocialProveedor", {
                required: {
                  message:
                    "Longitud Minima de RazonSocialProveedor es requerido",
                  value: true,
                },
              })}
            />
            <label htmlFor="floating_last_name" className={StyleLabel}>
              Razón social
            </label>
          </div>
          <div className="relative z-0  w-full group">
            <select
              name="TipoProveedor"
              id="TipoProveedor"
              {...register("TipoProveedor")}
              className={StyleSelect}
            >
              <option value={"01"}>PERSONA NATURAL</option>
              <option value={"02"}>SOCIEDAD</option>
            </select>
            <label htmlFor="floating_last_name" className={StyleLabel}>
              Perfil contribuyente
            </label>
          </div>
        </div>
      </div>
      <div>
        <div className=" text-base font-semibold border-b mt-2">
          <p className="my-2">Detalles del comprobante</p>
        </div>

        <div className="grid md:grid-cols-3 mt-10  md:gap-1 ">
          <div className="relative z-0  w-full group">
            <select
              name="TipoDocumento"
              id="TipoDocumento"
              {...register("TipoDocumento")}
              className={StyleSelect}
            >
              {ComprobantesReembolsos.map((el, key) => (
                <option key={key} value={el.Codigo}>
                  {el.Codigo} - {el.Nombre}
                </option>
              ))}
            </select>
            <label htmlFor="floating_last_name" className={StyleLabel}>
              Comprobantes de reembolso
            </label>
          </div>
          <div className="relative z-0  w-full group">
            <NumberFormat
              type="text"
              className={
                errors.NumeroComprobante ? StyleInputError : StyleInput
              }
              format="###-###-#########"
              id="NumeroComprobante"
              name="NumeroComprobante"
              {...register("NumeroComprobante", {
                maxLength: {
                  message:
                    "Longitud Maxima de NumeroComprobante no debe ser mayor a 17",
                  value: 17,
                },
                minLength: {
                  message:
                    "Longitud Minima de NumeroComprobante no debe ser menor a 17",
                  value: 17,
                },
                required: {
                  message: "NumeroComprobante es requerido",
                  value: true,
                },
              })}
              value={NumeroComprobantesObs}
              onChange={(e) => setValue("NumeroComprobante", e.target.value)}
            />
            <label
              htmlFor="floating_last_name"
              className={
                errors.NumeroComprobante ? StyleLabelError : StyleLabel
              }
            >
              Número comprobante
            </label>
          </div>

          <div className="relative z-0  w-full group">
            <input
              type="text"
              className={
                errors.AutorizacionComprobante ? StyleInputError : StyleInput
              }
              placeholder=" "
              id="AutorizacionComprobante"
              name="AutorizacionComprobante"
              max={49}
              maxLength={49}
              {...register("AutorizacionComprobante", {
                maxLength: {
                  message:
                    "Longitud Maxima de AutorizacionComprobante no debe ser mayor a 49",
                  value: 49,
                },
                minLength: {
                  message:
                    "Longitud Minima de AutorizacionComprobante no debe ser menor a 10",
                  value: 10,
                },
                required: {
                  message: "AutorizacionComprobante es requerido",
                  value: true,
                },
              })}
              onChange={(e) => {
                e.target.value.trim();
                e.target.value.replace(/ /g, "");
                setNumComprobante(e.target.value.replace(/[^0-9]/g, ""));
              }}
            />
            <label
              htmlFor="floating_last_name"
              className={
                errors.AutorizacionComprobante ? StyleLabelError : StyleLabel
              }
            >
              Autorización comprobante
            </label>
          </div>

          <div className="relative z-0 py-2 w-full group">
            <input
              type="date"
              className={
                errors.FechaEmisionComprobante ? StyleInputError : StyleInput
              }
              placeholder=" "
              id="FechaEmisionComprobante"
              name="FechaEmisionComprobante"
              {...register("FechaEmisionComprobante", {
                required: {
                  message: "FechaEmisionComprobante es requerido",
                  value: true,
                },
              })}
            />
            <label
              htmlFor="floating_last_name"
              className={
                errors.FechaEmisionComprobante ? StyleLabelError : StyleLabel
              }
            >
              Fecha emisión comprobante
            </label>
          </div>
        </div>
      </div>
      <div className=" text-base font-semibold border-b mt-2">
        <p className="my-2">Totales</p>
      </div>
      <div className="grid grid-cols-4 mt-10 gap-4">
        <div className="relative z-0  w-full group">
          <input
            className={StyleInput}
            type="number"
            onKeyDown={handleKeyPress}
            placeholder=" "
            decimalScale={2}
            id="SubTotalReembolso"
            name="SubTotalReembolso"
            {...register("SubTotalReembolso", {})}
          />
          <label htmlFor="floating_last_name" className={StyleLabel}>
            Subtotal
          </label>
        </div>
        <div className="relative z-0  w-full group">
          <select
            name="Impuesto"
            id="Impuesto"
            {...register("Impuesto")}
            className={StyleSelect}
            onChange={handleChange}
          >
            {ImpuestosList.map((el, index) => (
              <option
                key={index}
                data-index={index}
                value={el.idImpuestoDetalle}
              >
                {el.detalle}
              </option>
            ))}
          </select>
          <label htmlFor="floating_last_name" className={StyleLabel}>
            Impuesto
          </label>
        </div>
        <div className="relative z-0  w-full group">
          <input
            type="text"
            className={StyleInput}
            placeholder=" "
            readOnly
            id="TotalIvaReembolso"
            name="TotalIvaReembolso"
            value={calculo || 0}
            {...register("TotalIvaReembolso", {})}
          />
          <label htmlFor="floating_last_name" className={StyleLabel}>
            Valor de IVA
          </label>
        </div>
        <div>
          <button
            type="button"
            onClick={handleAddElement}
            className="bg-[#003B5B] text-white rounded-lg p-2 text-sm font-medium leading-5  focus:outline-none focus:shadow-outline-grayduration-150 ease-in-out"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </button>
        </div>
      </div>
      <div>
        <div className="overflow-x-auto  relative overflow-hidden shadow ring-1 ring-black  ring-opacity-5 md:rounded-lg">
          <table className="w-full text-sm text-center text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase divide-y bg-[#D7D7D7]   dark:bg-gray-600 dark:text-gray-400">
              <tr>
                <th scope="col" className="py-2 px-6 dark:text-white ">
                  Subtotal
                </th>
                <th scope="col" className="py-1 px-6 dark:text-white  ">
                  Tipo IVA
                </th>
                <th scope="col" className="py-1 px-6">
                  Total Iva
                </th>
                <th scope="col" className="py-1 px-6"></th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-gray-600 bg-white">
              {DataReembolso.length === 0 ? (
                <MesageWarningTable
                  colSpan="3"
                  message="No se ha ingresado información"
                />
              ) : null}
              {DataReembolso.map((el, index) => (
                <tr
                  key={index}
                  className="bg-white dark:bg-gray-800 dark:hover:bg-gray-900 hover:bg-gray-200  border-b-gray-600  "
                >
                  <td className="py-1 px-6">{el.SubTotalReembolso}</td>
                  <td className="py-0 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {el.Impuesto ? el.Impuesto.detalle : ""}
                  </td>
                  <td className="py-1 px-6">{el.TotalIvaReembolso}</td>
                  <td className="py-1 px-6 text-red-500 hover:text-red-600 ">
                    <button
                      type="button"
                      onClick={() => handleDeleteElement(index)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                    </button>
                  </td>
                </tr>
              ))}
              <tr>
                <td className="py-1 px-6"></td>
                <td className="py-1 px-6">Total</td>
                <td className="py-1 px-6">{roundToTwo(sumaTotal)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </LargeModalForm>
  );
};

export default ReembolsoModal;