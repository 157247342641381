import React, { useContext, Fragment } from "react";
import useVistaPrevia from "../hooks/useVistaPrevia";
import { Menu, Transition } from "@headlessui/react";
import { EmitirGuiaRemisionContext } from "./EmitirGuiaRemisionProvider";
import { useSelector } from "react-redux";
const MobileSubmitButton = () => {
  const { GetVistaPreviaGuiaRemision, IsLoading } = useVistaPrevia();
  const empresa = useSelector((store) => store.empresa.empresa);
  const { formik, DestinatariosList, DetalleList, InformacionAdicionalList } =
    useContext(EmitirGuiaRemisionContext);
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const handleVistaPrevia = async () => {
    let parsedInfo = {
      IdEmpresa: empresa.idEmpresa,
      RucEmpresa: empresa.ruc,
      Establecimiento: formik.values.establecimiento,
      PuntoEmision: formik.values.puntoEmision,
      Secuencial: formik.values.secuencial,
      Ambiente: process.env.REACT_APP_ENVIRONMENT,
      NumAutDocSustento: formik.values.numAutDocSustento,
      FechaEmision: formik.values.fechaInicio,
      FechaInicioTransporte: formik.values.fechaInicio,
      FechaFinTransporte: formik.values.fechaFin,
      TipoIdentificacionTransportista:
        formik.values.tipoIdentificacionTransportista,
      RucTransportista: formik.values.transportistaIdentificacion,
      RazonSocialTransportista: formik.values.transportistaRazonSocial,
      CorreoTransportista: formik.values.transportistaEmail,
      IdTransportista: formik.values.IdTransportista,
      Placa: formik.values.placa,
      DirPartida: formik.values.puntoPartidaComprobante,
      destinatario: [...DestinatariosList],
      infoAdicional: InformacionAdicionalList.map((x) => {
        return {
          Nombre: x.nombre,
          Valor: x.valor,
        };
      }),
    };

    if (formik.values.transportistaEmail !== null)
      if (formik.values.transportistaEmail.trim() !== "")
        parsedInfo.infoAdicional.push({
          Valor: formik.values.transportistaEmail,
          Nombre: "email",
        });

    let data = await GetVistaPreviaGuiaRemision(parsedInfo);
    let byteCharacters = atob(data);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], { type: "application/pdf;base64" });
    let fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  return (
    <Menu as="div" className="relative text-left  my-auto lg:hidden md:hidden ">
      
        <Menu.Button className="flex items-center dark:text-white  text-sm font-medium text-gray-700 ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
            />
          </svg>
        </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  type="button"
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "group flex items-center px-4 py-2 text-sm"
                  )}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-5 w-5 mr-1 text-gray-400 "
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>
                  Limpiar
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default MobileSubmitButton;
