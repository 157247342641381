import { FormBaseInventario } from "../../Shared/FormBaseInventario"
import { InputGlobal } from "../../context/inputContext"
import { SelectGlobal } from "../../context/selectContext"
export const CrearCategoriaForm = () => {

    const createCategory = (data) => {
        console.log(data)
    }

    return (
        <FormBaseInventario title={"Registro de Categorías de Productos"} submitFn={createCategory}>
            <InputGlobal title={"Código"} isHook={true} name={"codigo"} />
            <InputGlobal title={"Nombre de la Categoría"} isHook={true} name={"nombreCategoria"} />
            <SelectGlobal title={"Metodo de Coste"} name={"metodoCoste"}>
                <option value="Costo Promedio">Costo Promedio</option>
            </SelectGlobal>
            <SelectGlobal title={"Valoración de Inventario"} name={"valoracionInventario"}>
                <option value="manual">Manual</option>
                <option value="consumible">Automatizado</option>
            </SelectGlobal>
            <SelectGlobal title={"Cuenta de Activos"} name={"cuentaActivos"}>

            </SelectGlobal>
            <SelectGlobal title={"Cuenta de Ingresos"} name={"cuentaIngresos"}>

            </SelectGlobal>
            <SelectGlobal title={"Cuenta de Egresos y Costos"} name={"cuentaEgresosCostos"}>

            </SelectGlobal>
        </FormBaseInventario>
    )
}