import React, { useState } from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { NavLink } from "react-router-dom";

const SRI = (props) => {
  const [isPagesMenuOpen, setIsPagesMenuOpen] = useState(false);

  const [isPagesMenuOpen2, setIsPagesMenuOpen2] = useState(false);

  const hanldeclick = () => {
    setIsPagesMenuOpen(!isPagesMenuOpen);
    props.toggleItem("item6");
  };

  const hanldeclick2 = () => {
    setIsPagesMenuOpen2(!isPagesMenuOpen2);
  };

  return (
    <ul
      className="pt-2"
      //title={!props.OpenMenu ? "Reportes emitidos" : ""}

      title={"SRI"}
    >
      {props.OpenMenu ? (
        <Menu>
          <Menu.Button
            className={"w-full text-white"}
            onClick={props.OpenMenu ? hanldeclick : <></>}
          >
            <li className=" text-sm flex justify-between items-center  hover:text-black gap-x-4 cursor-pointer p-2 hover:bg-gray-100 rounded-md">
              <div className="flex gap-x-4 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                  />
                </svg>

                <span
                  className={`${
                    !props.OpenMenu && "hidden"
                  } origin-left duration-200`}
                >
                  {props.modulo}
                </span>
              </div>

              <span
                className={`${
                  !props.OpenMenu && "hidden"
                } origin-left duration-200`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className={`w-4 h-4 ${
                    isPagesMenuOpen
                      ? "rotate-180 duration-300"
                      : "rotate-0 duration-300"
                  }`}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </span>
            </li>
          </Menu.Button>
        </Menu>
      ) : (
        <Menu>
          <Menu.Button className={"w-full lg:table-cell hidden"}>
            <li className="  text-sm flex items-center text-gray-300 hover:text-black gap-x-4 cursor-pointer p-2 hover:bg-gray-100 rounded-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                />
              </svg>

              {props.modulo === "Mantenimiento" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21.75 6.75a4.5 4.5 0 01-4.884 4.484c-1.076-.091-2.264.071-2.95.904l-7.152 8.684a2.548 2.548 0 11-3.586-3.586l8.684-7.152c.833-.686.995-1.874.904-2.95a4.5 4.5 0 016.336-4.486l-3.276 3.276a3.004 3.004 0 002.25 2.25l3.276-3.276c.256.565.398 1.192.398 1.852z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.867 19.125h.008v.008h-.008v-.008z"
                  />
                </svg>
              ) : (
                <></>
              )}
              {props.modulo === "Base de datos" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
                  />
                </svg>
              ) : (
                <></>
              )}
            </li>
          </Menu.Button>
          {props.OpenMenu ? (
            <></>
          ) : (
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className=" ml-16 -mt-[40px] z-50 absolute w-56 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <ul className="py-1">
                  {/* {props.items.map((item, index) => menus(item.codigo, index))} */}
                  <li className="py-2 px-2 hover:text-white hover:bg-[#5F7F93] transition-colors truncate  w-full duration-300    dark:hover:text-gray-200">
                    <a
                      href="https://srienlinea.sri.gob.ec/sri-en-linea/SriRucWeb/ConsultaRuc/Consultas/consultaRuc"
                      rel="noopener noreferrer" //solo borrar en caso de fallo esta linea
                      target="_blank"
                    >
                      Consulta de RUC
                    </a>
                  </li>

                  <li className="px-2 py-2 hover:text-white hover:bg-[#5F7F93] transition-colors truncate duration-150  dark:hover:text-gray-200">
                    <a
                      className="truncate"
                      href="https://srienlinea.sri.gob.ec/comprobantes-electronicos-internet/publico/validezComprobantes.jsf"
                      rel="noopener noreferrer" //solo borrar en caso de fallo esta linea
                      target="_blank"
                    >
                      Validez de comprobantes electrónicos
                    </a>
                  </li>

                  <li className="px-2 py-2 hover:text-white hover:bg-[#5F7F93]  truncate transition-colors duration-150  dark:hover:text-gray-200">
                    <a
                      target="_blank"
                      href="https://srienlinea.sri.gob.ec/comprobantes-electronicos-internet/pages/solicitud/anulacion/menuAnulacion.jsf"
                      rel="noopener noreferrer" //solo borrar en caso de fallo esta linea
                    >
                      Solicitud de anulación de comprobantes en el SRI
                    </a>
                  </li>
                </ul>
              </Menu.Items>
            </Transition>
          )}
        </Menu>
      )}

      {props.openMobil ? (
        <ul className="" onClick={hanldeclick2}>
          <li className=" text-sm flex items-center text-gray-300 hover:text-black gap-x-4 cursor-pointer p-2 hover:bg-gray-100 rounded-md">
            {props.modulo === "Base de datos" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
                />
              </svg>
            ) : (
              <></>
            )}

            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
              />
            </svg>

            <span
              className={`${!props.OpenMenu && ""} origin-left duration-200`}
            >
              {props.modulo}
            </span>
            <span
              className={`${!props.OpenMenu && ""} origin-left duration-200`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className={`w-4 h-4 ${
                  isPagesMenuOpen2
                    ? "rotate-180 duration-300"
                    : "rotate-0 duration-300"
                }`}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </span>
          </li>
        </ul>
      ) : null}

      {/* <button onClick={props.OpenMenu ? hanldeclick : null} className="w-full">
      
    </button> */}
      {props.openItem === "item6" ? (
        <ul
          className={` mt-2  [&>li]:bg-[#003B5B] [&>li]:dark:bg-gray-800 overflow-hidden text-sm font-medium text-white duration-1000 delay-300 rounded-md shadow-inner  dark:text-gray-400 dark:bg-gray-900 ${
            !props.OpenMenu && "hidden"
          }`}
          aria-label="submenu"
        >
          {/* {props.items.map((item, index) => menus(item.codigo, index))} */}
          <li className="py-3 px-12 bg-[#5F7F93]  hover:bg-[#5F7F93] hover: truncate    transition-colors  w-full duration-300    dark:hover:text-gray-200">
            <a
              title="Consulta de RUC"
              href="https://srienlinea.sri.gob.ec/sri-en-linea/SriRucWeb/ConsultaRuc/Consultas/consultaRuc"
              rel="noopener noreferrer" //solo borrar en caso de fallo esta linea
              target="_blank"
            >
              Consulta de RUC
            </a>
          </li>

          <li className="py-3 px-12 bg-[#5F7F93] hover:bg-[#5F7F93] hover:  truncate  transition-colors  w-full duration-300    dark:hover:text-gray-200">
            <a
              title="Validez de comprobantes electrónicos"
              href="https://srienlinea.sri.gob.ec/comprobantes-electronicos-internet/publico/validezComprobantes.jsf"
              rel="noopener noreferrer" //solo borrar en caso de fallo esta linea
              target="_blank"
            >
              Validez de comprobantes electrónicos
            </a>
          </li>

          <li className="py-3 px-12 bg-[#5F7F93] hover:bg-[#5F7F93] hover: truncate   transition-colors  w-full duration-300    dark:hover:text-gray-200">
            <a
              title="Solicitud de anulación de comprobantes en el SRI"
              target="_blank"
              href="https://srienlinea.sri.gob.ec/comprobantes-electronicos-internet/pages/solicitud/anulacion/menuAnulacion.jsf"
              rel="noopener noreferrer" //solo borrar en caso de fallo esta linea
            >
              Solicitud de anulación de comprobantes en el SRI
            </a>
          </li>
        </ul>
      ) : null}

      {isPagesMenuOpen2 ? (
        <ul
          className={` mt-2  [&>li]:bg-[#003B5B] [&>li]:dark:bg-gray-800 overflow-hidden text-sm font-medium text-white duration-1000 delay-300 rounded-md shadow-inner  dark:text-gray-400 dark:bg-gray-900 ${
            !props.OpenMenu && ""
          }`}
          aria-label="submenu"
        >
          {/* {props.items.map((item, index) => menus(item.codigo, index))} */}
          <li className="py-3 px-12 bg-[#5F7F93]  hover:bg-[#5F7F93] hover: truncate    transition-colors  w-full duration-300    dark:hover:text-gray-200">
            <a
              title="Consulta de RUC"
              href="https://srienlinea.sri.gob.ec/sri-en-linea/SriRucWeb/ConsultaRuc/Consultas/consultaRuc"
              rel="noopener noreferrer" //solo borrar en caso de fallo esta linea
              target="_blank"
            >
              Consulta de RUC
            </a>
          </li>

          <li className="py-3 px-12 bg-[#5F7F93] hover:bg-[#5F7F93] hover:  truncate  transition-colors  w-full duration-300    dark:hover:text-gray-200">
            <a
              title="Validez de comprobantes electrónicos"
              href="https://srienlinea.sri.gob.ec/comprobantes-electronicos-internet/publico/validezComprobantes.jsf"
              rel="noopener noreferrer" //solo borrar en caso de fallo esta linea
              target="_blank"
            >
              Validez de comprobantes electrónicos
            </a>
          </li>

          <li className="py-3 px-12 bg-[#5F7F93] hover:bg-[#5F7F93] hover: truncate   transition-colors  w-full duration-300    dark:hover:text-gray-200">
            <a
              title="Solicitud de anulación de comprobantes en el SRI"
              target="_blank"
              href="https://srienlinea.sri.gob.ec/comprobantes-electronicos-internet/pages/solicitud/anulacion/menuAnulacion.jsf"
              rel="noopener noreferrer" //solo borrar en caso de fallo esta linea
            >
              Solicitud de anulación de comprobantes en el SRI
            </a>
          </li>
        </ul>
      ) : null}
    </ul>
  );
};

export default SRI;
