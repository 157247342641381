import React, { createContext, useState } from "react";

export const ReembolsoContext = createContext();

const ReembolsoProvider = (props) => {
  const [SumaReembolso, setSumaTotal] = useState(0);


  const [EditReembolso, setEditReembolso] = useState({});
  const [contador, setContador] = useState(0);

  const [IsEditReembolso, setIsEditReembolso] = useState(false);
  const [ActiveReembolso, setActiveReembolso] = useState(false);
  const [ReembolsoList, setReembolsoList] = useState([]);
  const handleDeleteElement = (index) => {
    ReembolsoList.splice(index, 1);
    setReembolsoList([...ReembolsoList]);
  };
  return (
    <ReembolsoContext.Provider
      value={{
        EditReembolso,
        SumaReembolso,
        setSumaTotal,
        handleDeleteElement,
        setEditReembolso,
        IsEditReembolso,
        setIsEditReembolso,
        ActiveReembolso,
        setActiveReembolso,
        ReembolsoList,
        setReembolsoList,
        setContador,
        contador
      }}
    >
      {props.children}
    </ReembolsoContext.Provider>
  );
};

export default ReembolsoProvider;
