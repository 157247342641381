import { FormBaseInventario } from "../../Shared/FormBaseInventario"
import { InputGlobal } from "../../context/inputContext"

export const CrearSeccionForm = () => {

    const createSection = (data) => {
        console.log(data)
    }
    return (
        <FormBaseInventario title={"Registro de Secciónes"} submitFn={createSection}>
            <InputGlobal name={"bodega"} title={"Bodega"} type={"text"} isHook={true} isPlaceHolder={"Digite el nombre de la bodega"}/>
            <InputGlobal name={"seccion"} title={"Sección"} type={"text"} isHook={true}/>
        </FormBaseInventario>
    )
}