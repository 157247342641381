import React from "react";
import { SmallModalInfo } from "../../../features/components/SmallModalInfo";

const ModalConsumAlert = () => {
  return (
    <SmallModalInfo buttonModal="ModalConsumAlert" title="¡Aviso!">
      <div className="relative z-0 w-full group">
        <div className="text-center">
          <div className="">

     
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-12 h-12 mx-auto rounded-full  bg-yellow-400 p-1.5  "
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
            />
          </svg>
          </div>

          <p className="text-black my-6">
            La factura supera los 50 USD de importe total por cuanto no puede
            ser emitida a nombre del CONSUMIDOR FINAL
          </p>
        </div>
      </div>
    </SmallModalInfo>
  );
};

export default ModalConsumAlert;
