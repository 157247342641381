import { roundToTwo } from "../../services/Utilitario";

export const CalcularSubTotales = (Detalles, FormaPagos = []) => {
  let subTotalIva0 = 0;
  let subTotalIva15 = 0;
  let subTotalIva5 = 0;
  let subTotalIva13 = 0;
  let subTotalIva14 = 0;
  let subTotalIva8 = 0;
  let subTotalNoObjetoIva = 0;
  let subTotalExentoIva = 0;
  let subTotalIva = 0;
  let totalIva = 0;
  let totalIva5 = 0;
  let totalIva15 = 0;
  let totalIva8 = 0;
  let totalIva13 = 0;
  let totalIva14 = 0;
  let propina = 0;
  let totalIce = 0;
  let totalIRBPNR = 0;
  let totalDescuentos = 0;
  let TipoImpuesto = "15%";

  Detalles.forEach((o) => {
    o.subTotal = roundToTwo(o.valor);

    if (o.descuento) totalDescuentos += parseFloat(o.descuento);
    //o.idImpuestoIvaNavigation.codigo
    switch (o.idImpuestoIvaNavigation.idImpuesto) {
      case 1:
        switch (o.idImpuestoIvaNavigation.codigo) {
          case 0:
            subTotalIva0 = roundToTwo(roundToTwo(subTotalIva0) + o.subTotal);

            break;
          case 6:
            subTotalNoObjetoIva = roundToTwo(
              roundToTwo(subTotalNoObjetoIva) + o.subTotal
            );
            break;
          case 7:
            subTotalExentoIva = roundToTwo(
              roundToTwo(subTotalExentoIva) + o.subTotal
            );
            break;
          case 4:
            o.iva = roundToTwo(
              o.subTotal * o.idImpuestoIvaNavigation.porcentaje
            );
            totalIva15 = roundToTwo(roundToTwo(totalIva15) + o.iva);
            subTotalIva15 = roundToTwo(roundToTwo(subTotalIva15) + o.subTotal);

            break;
          case 5:
            o.iva = roundToTwo(
              o.subTotal * o.idImpuestoIvaNavigation.porcentaje
            );
            totalIva5 = roundToTwo(roundToTwo(totalIva5) + o.iva);

            subTotalIva5 = roundToTwo(roundToTwo(subTotalIva5) + o.subTotal);

            break;
          case 10:
            o.iva = roundToTwo(
              o.subTotal * o.idImpuestoIvaNavigation.porcentaje
            );
            totalIva13 = roundToTwo(roundToTwo(totalIva13) + o.iva);
            subTotalIva13 = roundToTwo(roundToTwo(subTotalIva13) + o.subTotal);
            break;
          case 3:
            o.iva = roundToTwo(
              o.subTotal * o.idImpuestoIvaNavigation.porcentaje
            );
            totalIva14 = roundToTwo(roundToTwo(totalIva14) + o.iva);
            subTotalIva14 = roundToTwo(roundToTwo(subTotalIva14) + o.subTotal);
            break;
          case 8:
            o.iva = roundToTwo(
              o.subTotal * o.idImpuestoIvaNavigation.porcentaje
            );
            totalIva8 = roundToTwo(roundToTwo(totalIva8) + o.iva);
            subTotalIva8 = roundToTwo(roundToTwo(subTotalIva8) + o.subTotal);
            break;
        
          default:
            //console.log("1111=" + subTotalIva);
            o.iva = roundToTwo(
              o.subTotal * o.idImpuestoIvaNavigation.porcentaje
            );
            //o.total = roundToTwo(o.total+o.iva)
            totalIva = roundToTwo(roundToTwo(totalIva) + o.iva);
            subTotalIva = roundToTwo(roundToTwo(subTotalIva) + o.subTotal);
            // console.log("2222=" + subTotalIva);
            break;
        }
        break;
      case 2:
        alert("Hey");
        break;
      default:
        //alert('Default case');
        break;
    }
  });
  let totalSinImpuesto = roundToTwo(
    roundToTwo(subTotalIva0) +
      roundToTwo(subTotalNoObjetoIva) +
      roundToTwo(subTotalExentoIva) +
      roundToTwo(subTotalIva) +
      roundToTwo(subTotalIva5) +
      roundToTwo(subTotalIva8) +
      roundToTwo(subTotalIva13) +
      roundToTwo(subTotalIva14) +
      roundToTwo(subTotalIva15)
  );

  let importeTotal = roundToTwo(
    roundToTwo(totalSinImpuesto) +
      roundToTwo(propina) +
      roundToTwo(totalIva) +
      roundToTwo(totalIva5) +
      roundToTwo(totalIva15) + roundToTwo(totalIva8) + roundToTwo(totalIva13) + roundToTwo(totalIva14) 
  );
  let formasPagoFactura = 0;
  FormaPagos.forEach((el) => {
    formasPagoFactura += roundToTwo(el.valor);
  });
  let ValorTotal = roundToTwo(importeTotal) - roundToTwo(formasPagoFactura);

  return {
    subTotalIva,
    subTotalIva0,
    subTotalNoObjetoIva,
    subTotalExentoIva,
    totalSinImpuesto,
    totalDescuentos,
    totalIce,
    totalIva,
    totalIRBPNR,
    propina,
    importeTotal,
    ValorTotal,
    TipoImpuesto,
    subTotalIva5,
    subTotalIva8,
    subTotalIva13,
    subTotalIva14,
    subTotalIva15,
    totalIva5,
    totalIva15,
    totalIva8,
    totalIva13,
    totalIva14,

  };
};

export const TotalInicial = {
  subTotalIva: 0,
  subTotalIva0: 0,
  subTotalIva5: 0,
  subTotalIva8: 0,
  subTotalIva13: 0,
  subTotalIva14: 0,
  subTotalIva15: 0,
  subTotalNoObjetoIva: 0,
  subTotalExentoIva: 0,
  totalSinImpuesto: 0,
  totalDescuentos: 0,
  totalIce: 0,
  totalIva: 0,
  totalIva5: 0,
  totalIRBPNR: 0,
  propina: 0,
  importeTotal: 0,
  totalIva15: 0,
  totalIva14: 0,
  totalIva8: 0,
  totalIva13: 0,


};

//FACTURAS BUSCADAS
export const ParsearFormaPago = (FormaPago) => {
  let data = FormaPago.map((el) => {
    return {
      tipoPago: el.idFormaPagoSri,
      descripcionTipoPago: el.idFormaPagoSriNavigation.descripcion,
      tiempoPago: el.unidaDeTiempo,
      plazoPago: el.plazo,
      valor: el.total,
    };
  });

  return data;
};