import { createContext, useState } from "react";
import { useForm } from "react-hook-form";
import { getFechaInicio } from "../../../../../../services/Utilitario";

export const TransferenciasProviderContext = createContext();

const TransferenciasProvider = (props) => {
  const [isOpen, setIsOpen] = useState(false);

    const methods = useForm(
      { defaultValues: { fechaIngreso: getFechaInicio() } }
    );

    const [rows, setRows] = useState([
        { codigo: '', cuenta: '', cantidad: '', costo: '', lote: '', fechaCaducidad: '' },
      ]);
  return (
    <TransferenciasProviderContext.Provider value={{
        rows,
        setRows,
        methods,
        setIsOpen,
        isOpen
    }}>
      {props.children}
    </TransferenciasProviderContext.Provider>
  );
};

export default TransferenciasProvider;
