import { useFormContext } from "react-hook-form";

export const InputGlobal = ({
  name,
  validations,
  pattern,
  step,
  disabled,
  onChange,
  min,
  max,
  readOnly,
  value,
  type,
  title,
  specificStyle,
  isMoneyInput,
  pressfn,
  isHook,
  isPlaceHolder,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const StyleInput =
    "border-gray-300 focus:outline-none left-0 relative focus:border-blue-500 focus:ring-blue-500 focus:ring-1 ring-0 w-full border rounded-md pl-3 text-xs h-[39px] space-x-0";
  const StyleInputError =
    "border-red-500 focus:outline-none left-0 relative  focus:border-red-500 focus:ring-red-500 focus:ring-1 ring-0 w-full border rounded-md pl-3 text-xs h-[39px] space-x-0";

  const StyleDisabledInput =
    "border-gray-300 focus:outline-none left-0 relative focus:border-blue-500 focus:ring-blue-500 focus:ring-1 ring-0 w-full border rounded-md h-[38px] text-xs pl-3 space-x-0 bg-gray-200";

  return (
    <div className={`text-xs ${specificStyle ? specificStyle : ""}`}>
      <label
        htmlFor={title}
        className={
          errors[name]
            ? "w-full text-red-600 my-auto  flex flex-col gap-1 whitespace-nowrap"
            : "w-full text-gray-600 my-auto font-normal flex flex-col gap-1 whitespace-nowrap"
        }
      >
        {title}:
        <div className="relative">
          {isMoneyInput && (
            <span className="absolute z-10 top-[12px] left-1.5">$</span>
          )}
          <input
            type={type ? type : "text"}
            {...register(name, validations)}
            pattern={pattern}
            disabled={disabled ? disabled : false}
            readOnly={readOnly}
            placeholder={isPlaceHolder}
            onKeyPress={pressfn}
            onChange={onChange}
            maxLength={max}
            min={min ? min : undefined}
            step={step}
            value={value}
            className={
              errors[name]
                ? StyleInputError
                : disabled
                ? StyleDisabledInput
                : StyleInput
            }
            id={title}
          />
        </div>
        {errors[name] && errors[name].type === "required" && (
          <span className="text-red-600 text-[10px]">
            El Campo {title.toLowerCase()} es requerido.
          </span>
        )}
        {errors[name] && errors[name].type === "maxLength" && (
          <span className="text-red-600 text-[10px]">
            El Campo {title.toLowerCase()} no puede tener mas de{" "}
            {validations.maxLength} caracteres.
          </span>
        )}
        {/* {errors[name] &&
          errors[name].type === "pattern" &&
          (validations.pattern === AlfaNumerico ? (
            <span className="text-red-600 text-[10px]">
              El Campo {title.toLowerCase()} solo puede contener caracteres
              alfanumericos.
            </span>
          ) : validations.pattern === SoloLetras ? (
            <span className="text-red-600 text-[10px]">
              El Campo {title.toLowerCase()} solo puede contener letras.
            </span>
          ) : (
            <span className="text-red-600 text-[10px]">
              El Campo {title.toLowerCase()} contiene caracteres invalidos.
            </span>
          ))} */}
        {errors[name] && errors[name].type === "min" && type === "date" && (
          <span className="text-red-600 text-[10px]">
            El Campo {title.toLowerCase()} contine una fecha muy antigua.
          </span>
        )}
      </label>
    </div>
  );
};
