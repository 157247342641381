import React from "react";

const LengthCounter = ({ itemLength, maxLength }) => {

  return (
    <>
      <span className="text-gray-400 ml-1.5 text-xs">
        ({itemLength.length} / {maxLength || 300})
      </span>
    </>
  );
};

export default LengthCounter;
