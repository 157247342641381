import React, { createContext, useState } from "react";

export const ModalAddImpuestoContext = createContext();

const ModalAddImpuestoProvider = (props) => {

  const [DocSustentoList, setDocSustentoList] = useState([]);
  const [RentaList, setRentaList] = useState([]);
  const [IvaList, setIvaList] = useState([]);
  const [TotalImpToFormPago, setTotalImpToFormPago] = useState(0);
  const [FormaPagoList, setFormaPagoList] = useState([]);
  const [ShowDividendos, setShowDividendos] = useState(false);
  const [DividendosList, setDividendosList] = useState([]);
  const [PagoLocExt, setPagoLocExt] = useState("01");
  const [isLoad, setIsLoad] = useState(false);

  return (
    <ModalAddImpuestoContext.Provider
      value={{
        DocSustentoList,
        setDocSustentoList,
        RentaList,
        setRentaList,
        IvaList,
        setIvaList,
        FormaPagoList, setFormaPagoList,
        TotalImpToFormPago, setTotalImpToFormPago,
        ShowDividendos, setShowDividendos,
        DividendosList, setDividendosList,
        PagoLocExt, setPagoLocExt,
        isLoad, setIsLoad
      }}
    >
      {props.children}
    </ModalAddImpuestoContext.Provider>
  );
};

export default ModalAddImpuestoProvider;
