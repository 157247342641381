import moment from "moment";
import React, { useEffect, useState } from "react";
import { SmallModalInfoSri } from "../../../features/components/SmallModalInfoSri";
import IconFirma from "../../../img/Info_firma.png";

const ModalFirma = ({ InformacionFirma }) => {
  const [InfoFirma, setInfoFirma] = useState(false);

  useEffect(() => {
    setInfoFirma(InformacionFirma);
  }, [InformacionFirma]);

  return (
    <SmallModalInfoSri buttonModal={"firmaInfoModal"} title="Información de la Firma">
      {InformacionFirma === 404 ? (
        <h1>Contraseña incorrecta o firma no válida!</h1>
      ) : InfoFirma ? (
        <div  className="flex align-top justify-around flex-wrap px-3">
          <div className="lg:w-1/2 w-full">
          <div className="flex align-middle justify-start mb-4">
            <b className="mr-2">Emisor Firma:</b>{" "}
            {/* <h1>{InfoFirma.emisorFirma}</h1> */}
            <input
                         className="border py-0.5 rounded-md text-xs px-2"
            value={InfoFirma.emisorFirma}
            disabled
            />
          </div>
          <div className="flex align-middle justify-start mb-4">
            <b className="mr-2">Fecha Creacion:</b>{" "}
            {/* <h1>{moment(InfoFirma.fechaEmision).format("LLL")}</h1> */}

            <input
                    className="border py-0.5 rounded-md text-xs px-2"
            value={moment(InfoFirma.fechaEmision).format("LLL")}
            disabled
            />
          </div>
          <div className="flex align-middle justify-start my-4">
            <b className="mr-2">Fecha Expiracion:</b>{" "}
            {/* <h1>{moment(InfoFirma.fechaVencimiento).format("LLL")}</h1> */}


            <input
                          className="border py-0.5 rounded-md text-xs px-2"
            value={moment(InfoFirma.fechaVencimiento).format("LLL")}
            disabled
            />
          </div>
          <div className="flex align-middle justify-start mb-4">
            <b className="mr-2">Razon Social:</b>{" "}
            {/* <h1>{InfoFirma.razonSocial}</h1> */}
            <input
              className="border py-0.5 rounded-md text-xs px-2"
            value={InfoFirma.razonSocial}
            disabled
            />
          </div>
          <div className="flex align-middle justify-start mb-4">
            <b className="mr-2">RUC:</b>
            <input
                     className="border py-0.5 rounded-md text-xs px-2"
            value={InfoFirma.ruc}
            disabled
            />
            
             {/* <h1>{InfoFirma.ruc}</h1> */}
          </div>
          </div>
          <div className="lg:w-1/2 w-full">
            <img className="lg:py-0 p-5" src={IconFirma} alt="asdsdd" />
          </div>
        </div>
      ) : (
        <h1>CARGANDO...</h1>
      )}
    </SmallModalInfoSri>
  );
};

export default ModalFirma;
