import React from "react";
import { Oval } from "react-loader-spinner";

export const LargeModalForm = (props) => {
  const handleModalClick = (e) => {

    e.stopPropagation();


  };

  return (
    <div
      onMouseDown={(e) => {
        e.stopPropagation();
        if (typeof props.onClose === 'function' && props.setClose) {
          props.onClose();
        }
      
      }}
      className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
      id={`${props.buttonModal}`}
      tabIndex="-1"
      aria-labelledby={`${props.buttonModal}Label`}
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg relative w-auto pointer-events-none"
        onMouseDown={handleModalClick}
      >
        <div className="modal-content  mt-20 border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-black bg-clip-padding rounded-md outline-none text-current">
          <button
            type="button"
            onClick={props.onClose ? props.onClose : () => {}}
            data-bs-dismiss="modal"
            aria-label="Close"
            id={`${props.buttonModal}close`}
            className="shadow absolute -right-5 -top-5 w-10 h-10 rounded-full bg-white text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div className=" shadow w-full rounded-lg lg:px-4 md:px-4 px-1 py-2 dark:bg-gray-700 bg-white overflow-hidden block lg:p-8 md:p-8">
            {props.title && (
              <h2 className="font-bold text-2xl bg-[#D7D7D7] dark:bg-gray-700 rounded-lg  text-gray-800 dark:text-white p-4">
                {props.title}
                <p className="text-sm font-light">{props.subTitle}</p>
              </h2>
            )}

            <div>{props.children}</div>
            <div className="flex justify-end py-2">
              <button
                type="button"
                onClick={props.onClose ? props.onClose : () => {}}
                data-bs-dismiss="modal"
                aria-label="Close"
                id={`${props.buttonModal}close`}
                disabled={props.IsLoading}
                className=" text-white bg-[#6B6B6B] hover:bg-gray-600 border border-gray-200 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white dark:border-gray-600 font-medium rounded-lg text-base px-6 py-2.5 text-center inline-flex justify-center items-center mr-2 cursor-pointer"
              >
              
                  Cancelar
             
              </button>
              {props.submitText !== null ? (
                <button
                  disabled={props.IsLoading}
                  onClick={props.submitModal}
                  type="submit"
                  className=" text-white bg-[#04BC53] hover:bg-green-600 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-base px-6 py-2.5 text-center md:mr-5 lg:mb-0 md:mb-0 inline-flex items-center justify-center"
                >
                  {props.IsLoading ? (
                    <Oval
                      height={18}
                      width={60}
                      color="#FFFFFF"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#233876"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  ) : (
                    <>{props.submitText}</>
                  )}
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
