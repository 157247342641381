import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import MesageWarningTable from "../../../../features/components/MesageWarningTable";
import { StyleInput, StyleSelectForm } from "../../../../features/Constantes";
import { getListV2 } from "../../../../services";
import { roundToTwo } from "../../../../services/Utilitario";
import { ModalAddImpuestoContext } from "./ModalAddImpuestoProvider";
import { toast } from "react-toastify";

const IvaSection = () => {
  const [CodigoPorcentaje, setCodigoPorcentaje] = useState("");
  const [ImpimpList, setImpimpList] = useState([]);
  const [CodImpuestoDocSustento, setCodImpuestoDocSustento] = useState("");
  const [BaseImponible, setBaseImponible] = useState(0);
  const [ValorImpuesto, setValorImpuesto] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const ChangeCodImp = (e) => {
    let element = ImpimpList[e.value];
    valorimp(BaseImponible);
    setCodImpuestoDocSustento(element.codigo);
    setCodigoPorcentaje(element.porcentaje);
  };

  const calcularSubtotal = () => {
    if (BaseImponible > 0) {
      let valor = ((BaseImponible * CodigoPorcentaje) / 100).toFixed(2);
      setValorImpuesto(valor);
    } else {
      setValorImpuesto(0);
    }
  }

  useEffect(() => {
    calcularSubtotal()
  }, [CodigoPorcentaje, BaseImponible])

  const {
    IvaList,
    setIvaList
  } = useContext(ModalAddImpuestoContext);

  const empresa = useSelector((store) => store.empresa.empresa);

  useEffect(() => {
    (async () => {
      let impimp = await getListV2(empresa, "GetCodImpRetencion", "");
      setImpimpList(impimp._embedded);
    })();
  }, [])

  const valorimp = (value) => setBaseImponible(value);

  const onClickHandlerImpuesto = () => {
    setIsLoading(true)
    if (BaseImponible === null || BaseImponible === undefined || BaseImponible === "") {
      setTimeout(() => {
        setIsLoading(false)
      }, 2000)
      return;
    }
    if (CodigoPorcentaje === null || CodigoPorcentaje === undefined || CodigoPorcentaje === "") {
      toast("Asegurate de seleccionar un codigo de impuesto.", {
        type: "info",
        isLoading,
        autoClose: 2000,
      })
      setTimeout(() => {
        setIsLoading(false)
      }, 2000)
      return;
    }

    let data = {
      Codigo: 2,
      CodigoRetencion: CodImpuestoDocSustento,
      BaseImponible: roundToTwo(parseFloat(BaseImponible)),
      ValorRetenido: roundToTwo(parseFloat(ValorImpuesto)),
      PorcentajeRetener: CodigoPorcentaje,
    };

    setIvaList([...IvaList, data]);
    setTimeout(() => {
      setIsLoading(false)
    }, 2000)
  };

  const handleDeleteElementImpuesto = (index) => {
    IvaList.splice(index, 1);
    setIvaList([...IvaList]);
  };

  return (
    <>
      <div
        className="p-4 bg-gray-50 rounded-lg dark:bg-gray-800"
        id="profile"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        <div className="py-0  flex-wrap justify-between mb-0">
          <div className="w-full grid md:grid-cols-2 md:gap-2 mb-2 md:mb-0 ">
            <div className=" md:col-span-2 relative z-50 mb-2 w-full group ">
              <label className="block text-sm font-medium text-black dark:text-white">
                {" "}
                Código impt.{" "}
              </label>
              <Select
                name="CodImpuestoDocSustento"
                id="CodImpuestoDocSustento"
                onChange={(e) => ChangeCodImp(e)}
                className={StyleSelectForm}
                x-model="item.gst"
                options={ImpimpList.map((el, key) => {
                  return {
                    value: key,
                    label: el.codigo + " - " + el.nombre,
                  };
                })}
                menuPortalTarget={document.body}  
                styles={{
                  menuPortal: base => ({ ...base, zIndex: 9999 }),
                }}
                menuPosition="fixed"
              />

            </div>
          </div>
          <div className="w-full grid md:grid-cols-4 md:gap-2 mb-2 md:mb-0">
            <div className=" relative z-0 mb-2 w-full">
              <label className="block text-sm font-medium text-black dark:text-white">
                {" "}
                Código %{" "}
              </label>
              <input
                readOnly
                type="text"
                className={StyleInput + ' bg-gray-200'}
                placeholder=" "
                id="CodigoPorcentaje"
                name="CodigoPorcentaje"
                onChange={(e) => setCodigoPorcentaje(e.target.value)}
                value={CodigoPorcentaje}
              />
            </div>
            <div className="relative z-0 mb-2 w-full group">
              <label className="block text-sm font-medium text-black dark:text-white">
                {" "}
                Base ImpTO.{" "}
              </label>
              <input
                type="number"
                className={StyleInput}
                placeholder=" "
                id="BaseImponible"
                name="BaseImponible"
                value={BaseImponible}
                onChange={(e) => valorimp(e.target.value)}
              />
            </div>

            <div className="relative z-0 mb-2 w-full group">
              <label className="block text-sm font-medium text-black dark:text-white">
                {" "}
                Total{" "}
              </label>
              <input
                type="text"
                className={StyleInput}
                placeholder=" "
                id="ValorImpuesto"
                name="ValorImpuesto"
                onChange={(e) => setValorImpuesto(e.target.value)}
                value={ValorImpuesto}
              />
            </div>

            <div className="relative z-0 mb-2 pt-4 w-full group">
              <button
                disabled={isLoading}
                onClick={onClickHandlerImpuesto}
                type="button"
                className="  flex  dark:hover:bg-gray-700 hover:bg-blue-700 bg-blue-600 rounded-lg p-2 items-center justify-between px-2   text-sm font-medium leading-5  dark:text-gray-400 focus:outline-none focus:shadow-outline-gray"
              >
                {
                  isLoading ? '...' : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 dark:stroke-white text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  )
                }
              </button>
            </div>
          </div>
          {IvaList.length > 0 &&
           <div className="overflow-x-auto relative pt-3 my-8 ">
           <table className="w-full text-sm text-left text-gray-500 divide-y divide-gray-300 dark:text-gray-400">
             <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
               <tr>
                 <th scope="col" className="py-1 px-6 ">
                   Porcentaje
                 </th>
                 <th scope="col" className="py-1 px-6 ">
                   BASE IMPTO.
                 </th>
                 <th scope="col" className="py-1 px-6">
                   VALOR IMPTO.
                 </th>
                 <th scope="col" className="py-1 px-6 "></th>
               </tr>
             </thead>
             <tbody className="divide-y divide-gray-300">
               {IvaList.length === 0 ? (
                 <MesageWarningTable
                   colSpan="4"
                   message="No se ha ingresado información"
                 />
               ) : null}
               {IvaList.map((el, index) => {
                 return (
                   <tr key={index} className="bg-white dark:bg-gray-800">
                     <td className="py-1 px-6">{el.PorcentajeRetener}%</td>
                     <th
                       scope="row"
                       className="py-0 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                     >
                       {el.BaseImponible}
                     </th>
                     <th
                       scope="row"
                       className="py-0 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                     >
                       {el.ValorRetenido}
                     </th>
                     <td className="py-1 px-6 text-red-500 hover:text-red-600 ">
                       <button
                         onClick={() => handleDeleteElementImpuesto(index)}
                       >
                         <svg
                           xmlns="http://www.w3.org/2000/svg"
                           className="h-5 w-5"
                           fill="none"
                           viewBox="0 0 24 24"
                           stroke="currentColor"
                           strokeWidth="2"
                         >
                           <path
                             strokeLinecap="round"
                             strokeLinejoin="round"
                             d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                           />
                         </svg>
                       </button>
                     </td>

                   </tr>
                 );
               })}
             </tbody>
           </table>
         </div>
          }
         
        </div>
      </div>
    </>
  );
};

export default IvaSection;
