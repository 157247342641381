import React, { useContext } from "react";
import { useSelector } from "react-redux";
import useVistaPrevia from "../hooks/useVistaPrevia";
import { getFechaV1, roundToTwo } from "../../../services/Utilitario";
import { EmitirLiquidacionContext } from "./EmitirLiquidacionProvider";
import { CalcularImpuestosList } from "../EmitirFactura/services/impuestos";

const SubmitButtonLiq = () => {
  const {
    formik,
    DetalleList,
    ValorTotal,
    setTotales,
    setDetalleList,
    setFormaPagoList,
    setInformacionAdicionalList,
    Totales,
    ActiveReembolso,
    ReembolsoList,
    InformacionAdicionalList,
    FormaPagoList,
    handleDeleteElement,
  } = useContext(EmitirLiquidacionContext);

  const { GetVistaPreviaLiquidacion, IsLoading } = useVistaPrevia();

  const empresa = useSelector((store) => store.empresa.empresa);

  const handleVistaPrevia = async () => {

    const ModelReembolso = ReembolsoList.map((x) => {
      let iva0 = "";
      let iva12 = "";
      let iva15 = "";
      let iva5 = "";
      let iva8 = "";
      let iva13 = "";
    
      x.DataReembolso.forEach((y) => {
        switch (y.Impuesto.porcentaje) {
          case 0.12:
            iva12 = y.SubTotalReembolso;
            break;
          case 0.0:
            iva0 = y.SubTotalReembolso;
            break;
          case 0.15:
            iva15 = y.SubTotalReembolso;
            break;
          case 0.05:
            iva5 = y.SubTotalReembolso;
            break;
          case 0.08:
            iva8 = y.SubTotalReembolso;
            break;
          case 0.13:
            iva13 = y.SubTotalReembolso;
            break;
          default:
            break;
        }
      });
      return {
        AutorizacionComprobante: x.AutorizacionComprobante,
        FechaEmisionComprobante: x.FechaEmisionComprobante,
        TipoDocumento: x.TipoDocumento,
        TipoProveedor: x.TipoProveedor,
        RucProveedor: x.RucProveedor,
        NumeroComprobante: x.NumeroComprobante,
        IdIva12: "A5B8F607-8D38-4150-97AF-C591F83678D8",
        IdIva0: "81BAF871-E113-48CF-8AA8-670CB43494B6",
        IdIva15: "BED68281-CF9C-458E-9E0F-517A8FB02528",
        IdIva5: "2ED52B34-BA6C-475B-A82D-0F7C933412A7",
        IdIva8: "20FA15DF-58A0-4546-9DB1-BEE1CCCACF3E",
        IdIva13: "47BFE2B8-3630-4F76-8727-2D6808B862AC",
        Iva12: iva12,
        Iva0: iva0,
        Iva15: iva15,
        Iva5: iva5,
        Iva8: iva8,
        Iva13: iva13,
      };
    });
    
    let parsedInfo = {
      identificacionProveedor: formik.values.clienteIdentificacion,
      razonSocialProveedor: formik.values.clienteRazonSocial,
      direccionProveedor: formik.values.clienteDireccion,
      TelefonoCelular: formik.values.clienteTelefono,
      Correo: formik.values.clienteEmail.replace(/,/g, ";").replace(/\s+/g, ""),
      FechaEmision: getFechaV1(formik.values.fechaEmision),
      GuiaRemision: formik.values.GuiaRemision,
      Establecimiento: formik.values.establecimiento,
      PuntoEmision: formik.values.puntoEmision,
      ImporteTotal: Totales.importeTotal,
      TotalSinImpuestos: Totales.totalSinImpuesto,
      TotalDescuento: Totales.totalDescuentos,
      // TotalTotalIva0: 0,
      // TotalTotalIva: roundToTwo(Totales.totalIva),
      // TotalNoObjetoImpuesto: 0,
      // TotalExentoIva: 0,
      // TotalBaseIva0: Totales.subTotalIva0,
      // TotalBaseIva: roundToTwo(Totales.subTotalIva),
      // TotalBaseNoObjetoImpuesto: Totales.subTotalNoObjetoIva,
      // TotalBaseExentoIva: Totales.subTotalExentoIva,
      // ListaImpuestoliq: await CalcularImpuestosList(DetalleList, empresa),
      ListaImpuestoliq: await CalcularImpuestosList(DetalleList, empresa,Totales),

      Ambiente: process.env.REACT_APP_ENVIRONMENT,
      IdEmpresa: empresa.idEmpresa,
      RucEmpresa: empresa.ruc,
      CodDoc: 3,
      Reembolsos: ActiveReembolso ? [...ModelReembolso] : [],
      InformacionAdicionalLiquidaciones: InformacionAdicionalList.map((x) => {
        return {
          Descripcion: x.nombre,
          Valor: x.valor,
        };
      }),
      FormaPagoLiquidaciones: FormaPagoList.map((x) => {
        return {
          UnidaDeTiempo: x.tiempoPago,
          Plazo: x.plazoPago,
          Total: x.valor,
          IdFormaPagoSri: x.tipoPago,
        };
      }),
      DetalleLiquidaciones: DetalleList.map((e) => {
        // console.log(e);
        return {
          Descripcion: e.descripcion,
          Cantidad: e.cantidad,
          CodigoPrincipal: e.codigoPrincipal,
          CodigoAuxiliar: e.codigoAuxiliar,
          PrecioUnitario: e.precioUnitario,
          Descuento: e.descuento,
          ImpuestoCodigoIva: 2,
          ImpuestoCodigoPorcentajeIva: e.idImpuestoIvaNavigation.codigo,
          PrecioTotalSinImpuesto: roundToTwo(e.subTotal),
          ImpuestoBaseImponibleIva: roundToTwo(e.subTotal),
          ImpuestoValorIva:
            e.idImpuestoIvaNavigation.porcentaje === 0 ? 0 : roundToTwo(e.iva),
          Tarifa: roundToTwo(e.idImpuestoIvaNavigation.porcentaje * 100),
          InformacionAdicionalServicioLiquidaciones:
            e.servicioDetalle === undefined
              ? []
              : e.servicioDetalle === null
              ? []
              : e.servicioDetalle.map((x) => {
                  return {
                    Descripcion: x.nombre,
                    Valor: x.valor,
                  };
                }),
        };
      }),
      IdImpuestoNoObjectoImpuesto: DetalleList.find(
        (e) =>
          e.idImpuestoIva.toUpperCase() ===
          "8E7CF164-C66B-4312-945F-57AE08C86A33"
      )
        ? "8E7CF164-C66B-4312-945F-57AE08C86A33"
        : null,
      IdImpuestoExentoIva: DetalleList.find(
        (e) =>
          e.idImpuestoIva.toUpperCase() ===
          "F03C7F10-2773-4505-ACCB-B2FA88D62049"
      )
        ? "F03C7F10-2773-4505-ACCB-B2FA88D62049"
        : null,
      IdImpuestoIVA12: DetalleList.find(
        (e) =>
          e.idImpuestoIva.toUpperCase() ===
          "A5B8F607-8D38-4150-97AF-C591F83678D8"
      )
        ? "A5B8F607-8D38-4150-97AF-C591F83678D8"
        : null,
      IdImpuestoIVA0: DetalleList.find(
        (e) =>
          e.idImpuestoIva.toUpperCase() ===
          "81BAF871-E113-48CF-8AA8-670CB43494B6"
      )
        ? "81BAF871-E113-48CF-8AA8-670CB43494B6"
        : null,
    };
    if (formik.values.clienteEmail !== null)
      if (formik.values.clienteEmail.trim() !== "")
        parsedInfo.InformacionAdicionalLiquidaciones.push({
          Valor: formik.values.clienteEmail.replace(/,/g, ";").replace(/\s+/g, ""),
          Descripcion: "email",
        });

    let data = await GetVistaPreviaLiquidacion(parsedInfo);
    let byteCharacters = atob(data);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], { type: "application/pdf;base64" });
    let fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  return (
    <>
      {IsLoading && (
        <div
          style={{ width: "100vw", height: "100vh", zIndex: "100" }}
          className="bg-opacity-70 fixed justify-center align-middle top-0 l-0 bg-white text-center"
        >
          <div className="w-full h-full flex justify-center items-center py-4">
            <div
              className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-blue-600"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}
      <div className="flex space-x-2">
      <div className="group relative  border-gray-300 border rounded-md  inline-block">
                <button
                  type="button"
                  onClick={handleVistaPrevia}
                  className="inline-flex h-10 w-10 cursor-pointer items-center justify-center rounded-xl  hover:shadow-md    transition dark:bg-gray-900 dark:hover:bg-gray-800"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-6 w-6 dark:text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>

                  <div className="absolute mx-auto w-24  -top-8 -left-8 hidden dark:bg-white dark:text-black    rounded-md bg-gray-800 group-hover:flex text-center p-1">
                    <p className="text-white dark:text-black  mx-auto text-sm">
                      Vista previa{" "}
                    </p>
                  </div>
                </button>
              </div>
        <button
          onClick={formik.handleSubmit}
          type="submit"
          className="border p-2 rounded-lg bg-[#04BC53] hover:bg-[#03a448] transition-all duration-150 dark:bg-gray-800 dark:border-gray-600 w-auto flex space-x-2"
          data-bs-toggle="tooltip"
          title="GUARDAR DOCUMENTO"
        >
          <p className=" mx-2 text-white">Enviar al SRI</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
            />
          </svg>

          
        </button>
      
      
      </div>
    </>
  );
};

export default SubmitButtonLiq;
